import React from 'react'
import ContentLoader from 'react-content-loader'

const DestinationLoaderComponent = props => (
    <ContentLoader
        height={400}
        width={700}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
        {...props}
    >
        <rect x="525" y="175" rx="0" ry="0" width="125" height="125" />
        <rect x="370" y="175" rx="0" ry="0" width="125" height="125" />
        <rect x="205" y="175" rx="0" ry="0" width="125" height="125" />
        <rect x="50" y="175" rx="0" ry="0" width="125" height="125" />
        <rect x="50" y="5" rx="0" ry="0" width="125" height="125" />
        <rect x="525" y="5" rx="0" ry="0" width="125" height="125" />
        <rect x="370" y="5" rx="0" ry="0" width="125" height="125" />
        <rect x="205" y="5" rx="0" ry="0" width="125" height="125" />
    </ContentLoader>
)

export default DestinationLoaderComponent;