const filterTranslations = {
  "GoMini": "جو ميني",
  "Elite D.D": "ايليت دوبل"
}

export function translateFilters(filters, lang) {
    switch(lang) {
      case "ar":
        return filters.map(filter => {
          if(filterTranslations[filter]) {
            return filterTranslations[filter]
          } else {
            return filter;
          }
        })
    }
  }

export function translateSingleFilter(filter, lang) {
  if(filterTranslations[filter]) return filterTranslations[filter]
  else return filter;
}