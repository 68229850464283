import moment from "moment";
import Axios from "axios";

/**
 * 
 * @param {[]} t1 
 * @param {[]} t2
 * @description ArrayFormat [Year, Month - 1, Day, Hour, Minute, Second]
 * @example [2019, 5, 20, 14, 0, 0] = 2019-6-20 02:00 PM
 */
export async function isCurrentTimeBetween(t1, t2) {
  var startTime = moment.utc(t1);
  var endTime = moment.utc(t2);
  var currentTime = moment.utc(await getServerTime()).add(2, 'hour');
  return currentTime > startTime && currentTime < endTime;
}

export async function getServerTime() {
  try {
    const result = await Axios.get("https://go-bus.com/gobus/time");
    return result.data;
  } catch (error) {
    return null;
  }
}