import React from 'react';

class LoaderComponent extends React.Component {

    render() {
        let style;

        if(this.props["background-color"] || this.props["foreground-color"]) {
            style = {
                border: `5px solid ${this.props["background-color"]}`,
                borderTop: `5px solid ${this.props["foreground-color"]}`
            }
        }
        return (
            <div className="loader" style={style? style : undefined}>
                <div className="preloader-wrapper active">
                    <div className="spinner-layer spinner-blue-only">
                        <div className="circle-clipper left">
                            <div className="circle"></div>
                        </div><div className="gap-patch">
                            <div className="circle"></div>
                        </div><div className="circle-clipper right">
                            <div className="circle"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default LoaderComponent;



