import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';


import { tripPrice, tripTime, tripTimeSegment } from '../../Helpers/TimeParser'

import * as CorporateActions from '../../Actions/CorporateActions'
import * as SearchActions from '../../Actions/SearchActions'


// Componnets 
import MainLayoutComponent from '../MainLayoutComponent'
import StationPlaceholder from '../Utils/Placeholders/StationPlaceholder';
import SuggestedTripsComponent from './SuggestedTripsComponent'
import TripClassComponent from '../Search/Trip/TripClassComponent'

function mapStateToProps(store) {
    return {
        corpStore: store.corporate,
        searchStore: store.search,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        corporateActions: bindActionCreators(CorporateActions, dispatch),
        searchActions: bindActionCreators(SearchActions, dispatch),
    }
}

class StationsListComponent extends Component {
    
    constructor(props) {
        super(props);

    }



    /**
     * get the Single Station Trips
     * @author Ezzat
     */
    componentDidMount() {
        if (this.props.searchStore.stations.length < 1) {
            this.props.searchActions.getStations()
        }
    }

    render() {
        
        return (
            <MainLayoutComponent page={this.props.location.pathname}>
                {this.props.searchStore.stations.length < 1 && <StationPlaceholder />  } 
                {this.props.searchStore.stations.length > 0 && this.renderStations() }
            </MainLayoutComponent>
        );
    }

    renderStations(){
        
        let stations = this.props.searchStore.stations
        // console.log('Station is', stations)

        let grouped_stations = {};
        stations.map(station => {
            if(!grouped_stations[station.RegionNameA_Short]){
                grouped_stations[station.RegionNameA_Short] = []
            }
            grouped_stations[station.RegionNameA_Short].push(station)

        })
        // console.log('Grouped is', grouped_stations)

        return(
            <div className="page-content stations-page">
                <div className="page-title stations-title">
                    <div className="container">
                        <h1>محطات ومؤاني جوباص</h1>
                        <p>محطات ومؤاني جوباص</p>
                    </div>
                </div>
                <div className="container">
                    {
                        Object.keys(grouped_stations).map(city => {
                            return(
                                <div className="stations-list-con">
                                    <h2> محافظة {city}</h2>
                                    {
                                        grouped_stations[city].map(station => {
                                            return(
                                                <div className="station-list">
                                                    <div className="location">
                                                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13809.338187992144!2d31.336996199999998!3d30.0846037!3m2!1i1024!2i768!4f13.1!4m3!3e6!4m0!4m0!5e0!3m2!1sen!2seg!4v1523885826300" width="100%" height="100%" frameBorder="0" allowFullScreen></iframe>
                                                    </div>
                                                    <div className="station-details">

                                                        <h3> <Link to={`stations/${station.Code}`}> {station.NameA} </Link>    </h3>
                                                        {/* <span>التحرير</span> */}
                                                        <a href="destination.html">أكثر من 20 وجهة سفر
                                                            <i className="material-icons">arrow_back</i>
                                                        </a>
                                                    </div>
                                                </div>                        
                                            )
                                            
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StationsListComponent);


