import React, { Component } from "react";
import _ from 'lodash';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { defaultLoaderDelay } from '../../Api/Base';
// Actions
import * as TripActions from '../../Actions/TripActions'
import GlobalModal from "./GlobalModal";
import { Trans } from 'react-i18next';
import { isGoMini } from "../../Helpers/Utils";
import { useCheckoutStore } from "../../new-store/checkoutStore";

function mapStateToProps(store) {
    return {
        locale: store.locale,
        tripsStore: store.trips,
        searchStore: store.search,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        tripActions: bindActionCreators(TripActions, dispatch),
    }
}

class PromoCodeComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            active: false,
            showPromocodeAlert: false,
        }

        this.didShowPromocodeAlert = false;
        this.inputField = null;

        this.getPromoCode = this.getPromoCode.bind(this)
        this.applyPromoCode = this.applyPromoCode.bind(this)

    }

    /**
     * get promo code value 
     * @param {html} 
     * @author Ezzat
     */
    getPromoCode(input) {
        if (this.props.tripsStore.promoCodeError)
            this.props.tripActions.clearPromoCodeError();

        this.promoCode = input.target.value
    }

    applyPromoCode() {
        this.setState({
            loading: true,
        })
        setTimeout(() => {
            this.setState({
                loading: false,
            })
            this.props.tripActions.applyPromoCode(this.promoCode)
        }, defaultLoaderDelay);
        
    }


    toggle = () => {
        let promocodeState = {
            didShowPromocodeAlert: true,
            showPromocodeAlert: this.props.tripsStore.selectedReturningTrip && !isGoMini({ tripsStore: this.props.tripsStore })
                ? this.state.didShowPromocodeAlert
                    ? false
                    : true
                : false
        }
        this.setState({ 
            active: !this.state.active,
            ...promocodeState,
        })


        if (this.props.tripsStore.promoCodeError)
            this.props.tripActions.clearPromoCodeError();
    }

    closePromocodeAlert = () => {
        this.setState({
            showPromocodeAlert: false
        })
    }

    undoPromocode = () => {
        this.setState({
            loading: true,
        })
        setTimeout(() => {
            this.setState({
                loading: false,
            })
            this.props.tripActions.undoPromocode();
        }, defaultLoaderDelay);
    }

    render() {

        const { returnTripInfo, isRoundTrip, showPayment } = useCheckoutStore.getState();

        if (isRoundTrip || returnTripInfo || showPayment === false) {
            return null;
        }

        // let currentPage = this.props.history.location.pathname
        let promoCode = this.props.tripsStore.promoCode
        const trips = this.props.tripsStore;
        const passengersNo = this.props.searchStore.passengersNo;
        const goingTrip = trips.selectedGoingTrip;
        const returningTrip = trips.selectedReturningTrip;

        let roundTripDiscount = 0;

        if (this.props.isRoundTrip && returningTrip && goingTrip) {
            // const totalNoDisc = goingTrip.TripPrice + returningTrip.TripPrice;
            let goingTripDiscount = goingTrip.TripPrice - (goingTrip.TripPrice_GoCome / 2);
            let returningTripDiscount = returningTrip.TripPrice - (returningTrip.TripPrice_GoCome / 2);

            roundTripDiscount = (goingTripDiscount + returningTripDiscount) * passengersNo;
        }

        if (this.props.history.location.pathname.toLowerCase() !== "/booking" && !roundTripDiscount) {
            return null;
        }
        
        return (
            // if in Booking and no
            <div className={this.props.tripsStore.applyingPromoCode || this.state.loading ? "disc-copoun component-loader" : "disc-copoun"}>
                <GlobalModal
                    open={this.state.showPromocodeAlert}
                    onClose={this.closePromocodeAlert}
                    withPaper={true}
                >
                    <p>لقد تم الاستفاده بخصم الذهاب والعوده عفوا لا يمكن الجمع بين خصمين لمزيد من المعلومات يرجى التواصل مع 19567</p>
                </GlobalModal>
                <div className="tickets-component-con">
                    {
                        (roundTripDiscount)
                            ? <RoundTripDiscount discount={roundTripDiscount} />
                            : (
                                (Object.keys(promoCode).length > 0)
                                    ?
                                    (
                                        <PromocodeDiscount discount={promoCode.val} code={promoCode.code} undo={this.undoPromocode} />
                                    )
                                    :
                                    (this.state.active)
                                        ?
                                        <React.Fragment >
                                            <span>
                                                {/* <i className="material-icons">local_offer</i> */}
                                                <Trans i18nKey="cart.promocode.enter_promo">إدخل كوبون خصم</Trans>
                                                <button onClick={this.toggle} className="close"><i className="material-icons">close</i></button>
                                            </span>
                                            <input type="text" ref={ref => this.inputField = ref} onKeyPress={(e) => e.key === 'Enter' ? this.applyPromoCode() : null} autoFocus="autofocus" onChange={this.getPromoCode} />
                                            <a href="#!" onClick={this.applyPromoCode} className="btn"><Trans i18nKey="cart.promocode.discount">خصم</Trans></a>
                                            {
                                                (this.props.tripsStore.promoCodeError.length > 0) &&
                                                <i style={{ color: 'red' }}>
                                                    {this.props.tripsStore.promoCodeError}
                                                </i>
                                            }
                                        </React.Fragment>
                                        :
                                        <button onClick={this.toggle} className="addCode">
                                            <img src="/images/icons/percentage-blue.png" alt="percentage icon"/>
                                            <label><Trans i18nKey="cart.promocode.enter_promo">إدخل كوبون الخصم</Trans></label>
                                        </button>
                            )

                    }

                </div>
            </div>
        );
    }
}

const PromocodeDiscount = ({ discount, code, undo }) => {
    return (
        <div className="discount-row">
            <img src="/images/icons/percentage.png" alt="percentage icon"/>
            <div>
                <p><Trans i18nKey="cart.promocode.headline">تم خصم {{discount}} جنيه من الأجمالي</Trans> </p>
                <p className="grey-text"> <Trans i18nKey="cart.promocode.discount_promo">خصم بروموكود {{code}}</Trans></p>
            </div>
            <span>
                <button onClick={undo} className="close undo-btn"><i className="material-icons">close</i></button>
            </span>
        </div>
    )
}

const RoundTripDiscount = ({ discount }) => {
    return (
        <div className="discount-row">
            <img src="/images/icons/percentage.png" alt="percentage icon"/>
            <div>
                <p><Trans i18nKey="cart.promocode.headline">تم خصم {{discount}} جنيه من الأجمالي</Trans> </p>
                <p className="grey-text"><Trans i18nKey="cart.promocode.round_offer">عرض الذهاب والعودة</Trans></p>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(PromoCodeComponent)
