import React, { Component } from "react";
import seatStyles from "./Seat.module.css";

export default class Seat extends Component {
    static Status = {
        OPEN: 0,
        SELECTED: 1,
        HELD: 2,
        UNKNOWN: 3
    };

    constructor(props) {
        super(props);
        // // update bus layout with current seat
        // props.updateRowsCount(props.x);
    }

    render() {
        let {
            id, // seat id
            x,
            y,
            status, // seat status (held, open, unknown, selected)
            hold,
            unhold,
            heldSeats,
            baseSeats,
            offsetLeft
        } = this.props;

        /** Setting seat styles */
        // select seat style based on its status
        let selectedStyle = seatStyles.seat;

        /** Setting seat position */
        // for setting position (x, y) - element must be position: absolute;
        let customStyles = {};

        if (id === 0) {
            customStyles.left = 60 - (offsetLeft || 0) + "px";
            customStyles.top = "3.2px";
            selectedStyle += " " + seatStyles.driver;
        } else {
            customStyles.left = x - (offsetLeft || 0);
            customStyles.top = y;
        }

        const seatProps = {
            className: `${selectedStyle} ${seatStyles.open}`,
            style: customStyles,
        };

        const isUnAvailable =
            status === Seat.Status.SELECTED ||
            status === Seat.Status.HELD ||
            status === Seat.Status.UNKNOWN;

        const isAvailable = status === Seat.Status.OPEN && !heldSeats.includes(id) && !baseSeats.includes(id);
        const isSelected = heldSeats.includes(id);
        const isSelectable = isAvailable || (baseSeats.includes(id));

        if (isSelectable) {
            seatProps.onClick = hold.bind(this, id);
        }

        if (isAvailable) {
            seatProps.className = `${seatProps.className} ${seatStyles.open}`;
        }

        if (isUnAvailable) {
            seatProps.className = `${seatProps.className} ${seatStyles.held}`;
        }

        if (isSelected) {
            seatProps.className = `${seatProps.className} ${seatStyles.selected}`;
        }

        return <span {...seatProps}>{id !== 0 && id}</span>
    }
}
