import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import SideBarComponent from "./SideBarComponent";

import { Link, Redirect } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Sticky from 'react-sticky-el';

// Actions 
import * as SearchActions from '../../Actions/SearchActions'
import * as TripActions from '../../Actions/TripActions'

// Components
import MainLayoutComponent from "../MainLayoutComponent";    

function mapStateToProps(store) {
    return {
        userStore: store.user,
        searchStore: store.search,
        tripsStore: store.trips
    }
  }
  
  function mapDispatchToProps(dispatch) {
    return {
    }
  }


class MyBookings extends Component {
    constructor(props) {
      super(props)
  
      this.state = {
          isActiveAction: false,
      }
    }


    toggleActionClasses = () => {
        this.setState({ isActiveAction: !this.state.isActiveAction });
    }
    render() {

        let ActionsClasses = (this.state.isActiveAction) ? "actions-box active" : "actions-box";

        return (
            <MainLayoutComponent page={this.props.location.pathname}>
                <div className="page-content">
                    <div className="AccPage">
                        <div className="container">
                            <div className="page-title">
                                <h5>حسابي</h5>
                                <h1> حجوزاتي السابقة</h1>
                            </div>
                            <div className="account-container">
                                <SideBarComponent />
                                <div className="account-content">
                                    <div className="SignleBooking">
                                        <div className="SignleBooking-header">
                                            <div className="BookingItem_date">
                                                <h5 className="label">تاريخ الحجز</h5>
                                                <p>الأربعاء 15 أغسطس 2018</p>
                                            </div>
                                            <div className="BookingItem_num">
                                                <h5 className="label">رقم الحجز</h5>
                                                <p>983872398</p>
                                            </div>
                                            <div className="BookingItem_type">
                                                <h5 className="label">نوع الحجز</h5>
                                                <p>ذهاب وعودة</p>
                                            </div>
                                            <div className="BookingItem_directions">
                                                <div className="directions">
                                                    <p>سفر من  القاهرة</p>
                                                    <div className="track">
                                                        <span className="line"></span>
                                                    </div>
                                                    <p> وصول إلي الغردقة</p>
                                                </div>
                                                <div className="details">
                                                    <p>سفر الأربعاء 16 يوليو 2018 والعودة الخميس 18 أغسطس 2018</p>
                                                </div>
                                            </div>
                                            <div className="BookingItem_details">
                                                <div className="base-details">
                                                    <h5 className="label">2  كراسي</h5>
                                                    <h4 className="price">720.00</h4>
                                                    <span className="booking-status temporary">حجز مؤقت</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="SignleBooking-content">
                                            <div className="full-trip-details">
                                                <div className="rout">
                                                    <h4><span className="icon-check"><i class="fas fa-check"></i></span>رحلة الذهاب</h4>
                                                    <p className="date">الأربعاء 17 ىوليو </p>
                                                    <p className="time"><span>03:00</span> مساءاً</p>
                                                    <p>سفر من: القاهرة - عبد المنعم رياض<br/>
                                                        وصول إلي : شرم الشيخ - دلتا<br/>
                                                        نوع الخدمة: إقتصادي<br/>
                                                        عدد المسافرين: 2
                                                    </p>
                                                    <ul className="seats-selected">
                                                        <li>45</li>
                                                        <li>46</li>
                                                    </ul>
                                                </div>
                                                <div className="rout">
                                                    <h4><span className="icon-check"><i class="fas fa-check"></i></span>رحلة العودة</h4>
                                                    <p className="date">الأربعاء 17 ىوليو </p>
                                                    <p className="time"><span>03:00</span> مساءاً</p>
                                                    <p>سفر من: القاهرة - عبد المنعم رياض<br/>
                                                        وصول إلي : شرم الشيخ - دلتا<br/>
                                                        نوع الخدمة: إقتصادي<br/>
                                                        عدد المسافرين: 2
                                                    </p>
                                                    <ul className="seats-selected">
                                                        <li>45</li>
                                                        <li>46</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="SignleBooking-footer">
                                            <div className="payment-method">
                                                <h4 className="label">طريقة الدفع</h4>
                                                <p className="icon-num mastercard">XXXX 8768</p>
                                                <p className="icon-num visa">XXXX 8768</p>
                                                <p className="icon-num fawry">762389327</p>
                                            </div>
                                            <div className="actions">
                                                <button>إلغاء جزئي للكراسي</button>
                                                <button>إلغاء الحجز بالكامل</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MainLayoutComponent>
        );
    }
}

export default MyBookings;