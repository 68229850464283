import React from 'react';
import FacebookLogin from 'react-facebook-login';
import { connect } from 'react-redux';


import {facebook_app_id} from '../../../Api/Base';
import ErrorComponent from '../../Utils/ErrorComponent';
import { Trans } from 'react-i18next';
import i18next from 'i18next';

const AuthOptionsForm = (props) => {
    let {toggleRegisterForm, toggleLoginForm, authWithFb, hasError, errorMessage} = props
    return(
        <div className="login-box active">
            <h4>
                {(hasError) &&
                    <ErrorComponent
                        errors={errorMessage}
                    />
                }
            </h4>
            <h3><Trans i18nKey="auth.login.title">بيانات صاحب الحجز</Trans></h3>
            
            <FacebookLogin
                // isMobile={true}
                disableMobileRedirect={true}
                appId={facebook_app_id}
                autoLoad={false}
                fields="name,email,picture"
                authType="rerequest"
                callback={authWithFb.bind(this)}
                cssClass="btn btn-fb"
                icon="fab fa-facebook-f"
                onClick={props.startLoader}
                textButton={i18next.t('auth.login.facebook_btn', { defaultValue: "إدخال البيانات عن طريق فيس بوك" })}
            />
            <span><Trans i18nKey="common.or">أو</Trans></span>
            <button
                className="btn btn-email"
                onClick={() => { toggleLoginForm();}} >
                <i className="material-icons">email</i>
                <Trans i18nKey="auth.login.email_btn">دخول بالأيميل</Trans>
            </button>
            <div className="login-foot">
                <button
                    className="btn btn-new-acc"
                    onClick={() => { toggleRegisterForm();}} >
                    <i className="material-icons">account_box</i>
                    <Trans i18nKey="auth.login.register_new">تسجيل حساب جديد بالإيميل</Trans>
            </button>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        locale: state.locale
    }
}

export default connect(mapStateToProps)(AuthOptionsForm)


