import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash"
import { bindActionCreators } from "redux";
import debug from "../../Helpers/DebugLog";

class RoutesLinks extends Component {
  
  renderRoutes = () => {
    let stations = this.props.search.stations;
    if(stations.length < 1) return null;

    let lastIndex = 0;
    let routes = [];
    let existingRegions = [];
    let filters = [9, 87, 61, 15]

    stations = stations.filter((val) => {
      if(existingRegions.includes(val.RegionNameA_Short)) return false;
      existingRegions.push(val.RegionNameA_Short);
      return true;
    });

    for(let x = 0; x < stations.length; x++) {
      for(let y = x; y < stations.length; y++) {
        let s1 = stations[x];
        let s2 = stations[y];
        
        if(s1.Code === s2.Code || s1.RegionNameE_Short === s2.RegionNameE_Short || filters.includes(Number(s1.Code)) || filters.includes(Number(s2.Code))) continue;

        routes.push(
          <a className="route-links" href={`/search?arrivalStation=${s2.Code}&departureStation=${s1.Code}`}>
            {s1.RegionNameA_Short} - {s2.RegionNameA_Short}
          </a>
        );
      }
    }
    
    debug('length', routes.length);

    return routes;
  }
  render() {
    return <React.Fragment>{this.renderRoutes()}</React.Fragment>;
  }
}

function mapStateToProps(store) {
  return {
    search: store.search
  };
}

function mapDispatchToProps(dispatch) {
  return {
    //userActions: bindActionCreators(UserActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoutesLinks);
