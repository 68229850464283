import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import qs from "query-string";
import EventEmitter from 'events';

// Actions
import * as SearchActions from "../../Actions/SearchActions";
import * as TripActions from "../../Actions/TripActions";

// Helpers
import { fullDateDigits } from "../../Helpers/TimeParser";

// Styles
import "react-day-picker/lib/style.css";

// Comonents
import { HomeViewComponent } from "./Views/HomeViewComponent";
import SearchViewComponent from "./Views/SearchViewComponent";
import Utils from "../../Helpers/Utils";
import { pushToDataLayer } from "../../Reducers/GTMReducer";

function mapStateToProps(store) {
  return {
    locale: store.locale,
    userStore: store.user,
    searchStore: store.search,
    tripStore: store.trips
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setDate: bindActionCreators(SearchActions.setDate, dispatch),
    searchActions: bindActionCreators(SearchActions, dispatch),
    tripActions: bindActionCreators(TripActions, dispatch),
    pushToDataLayer: bindActionCreators(pushToDataLayer, dispatch)
  };
}

export const searchEvents = new EventEmitter();

class SearchBoxComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      transportationType: this.props.searchStore.transportationType,
      tripType: this.props.searchStore.tripType,
      departureStation: this.props.searchStore.departureStation,
      arrivalStation: this.props.searchStore.arrivalStation,
      departureDate: this.props.searchStore.departureDate,
      arrivalDate: this.props.searchStore.arrivalDate,
      passengersNo: this.props.searchStore.passengersNo || 1,
      displayDepartureDatePicker: false,
      displayArrivalDatePicker: false,
      displaySeatSelector: false
    };

    // Methods
    this.changeTransportationType = this.changeTransportationType.bind(this);
    this.changeTripType = this.changeTripType.bind(this);
    this.selectDepartureStation = this.selectDepartureStation.bind(this);
    this.selectArrivalStation = this.selectArrivalStation.bind(this);
    this.chooseDepartureTime = this.chooseDepartureTime.bind(this);
    this.chooseArrivalTime = this.chooseArrivalTime.bind(this);
    this.search = this.search.bind(this);
    this.updateUrl = this.updateUrl.bind(this);
    this.handleMouseClick = this.handleMouseClick.bind(this);

    searchEvents.on('chooseArrivalTime', this.chooseArrivalTime);
    searchEvents.on('changeTripType', this.changeTripType);
    searchEvents.on('search', this.search);
  }

  /**
   * Get the Cities if it's not already there
   * @author Ezzat
   */
  componentDidMount() {
    document.addEventListener("mousedown", this.handleMouseClick);

    // if (this.props.searchStore.stations.length < 1) {
    this.props.searchActions.getStations();
    // }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleMouseClick);
  }

  /**
   * this is responible to sync the date in the date picker from the calendaer view
   * @param {object} props
   * @author Ezzat
   */
  componentWillReceiveProps(props) {
    this.setState({
      departureDate: props.searchStore.departureDate,
      arrivalDate: props.searchStore.arrivalDate
    });
  }

  /**
   * change transportation type [bus,ferry]
   * @param {string} transportationType
   * @author Ezzat
   */
  changeTransportationType(transportationType) {
    this.setState({
      transportationType
    });
  }

  /**
   * change trip type [oneWay, round]
   * @param {string} tripType
   * @author Ezzat
   * change the tripType for this Component (state), update  redux Store only when @search function is called
   */
  changeTripType(tripType) {
    let newState = {
      tripType
    };
    // check if trip type is oneWay, remove the arrivalStation to make it show on the departure stations
    if (tripType === "oneWay") {
      newState.arrivalDate = null;
    } else {
      newState.arrivalDate = moment(this.state.departureDate).add(1, "days");
    }

    this.setState(newState);
  }

  /**
   * Choose Departure city
   * @param {object} city
   * @author Ezzat
   * change the departure city for this Component (state), update  redux Store only when @search function is called
   */
  selectDepartureStation(departureStation) {
    let arrivalStation =
      this.state.arrivalStation || this.props.searchStore.arrivalStation;
    if (
        arrivalStation &&
        arrivalStation.TripRegion_Code === departureStation.TripRegion_Code
    ) {
        alert("وجهة الذهاب مماثلة لوجهة العودة");
    }
    this.setState({
      departureStation
    });
  }

  /**
   * Choose arrivalStation city
   * @param {object} city
   * @author Ezzat
   * change the departure city for this Component (state), update  redux Store only when @search function is called
   */
  selectArrivalStation(arrivalStation) {
    this.setState({ arrivalStation });
  }

  /**
   * Choose Departure Time
   * @param {object} time
   * @author Ezzat
   * change the departure time for this Component (state), update  redux Store only when @search function is called
   */
  chooseDepartureTime(departureDate, { disabled, selected }) {
    if (disabled) {
      return;
    }
    let new_date = moment(departureDate);
    let new_arrival_date = null;
    if (this.state.tripType === "round") {
      if (moment(this.state.arrivalDate).isSameOrBefore(new_date)) {
        new_arrival_date = moment(new_date).add(1, "days");
      } else {
        new_arrival_date = moment(this.state.arrivalDate);
      }
    }
    // let arrivalDate = moment(new_date).add(7, 'days')
    this.setState({
      departureDate: new_date,
      arrivalDate: new_arrival_date,
      displayArrivalDatePicker: false,
      displayDepartureDatePicker: false
    });
    this.props.setDate(new_date, 'departure');
    this.props.setDate(new_arrival_date, 'arrival');
  }

  /**
   * Choose Arrival  Time
   * @param {object} time
   * @author Ezzat
   * change the Arrival time for this Component (state), update  redux Store only when @search function is called
   */
  chooseArrivalTime(returnDate, { disabled, selected }) {
    if (disabled) {
      return;
    }
    let new_date = moment(returnDate);
    this.setState({
      arrivalDate: new_date,
      displayArrivalDatePicker: false,
      displayDepartureDatePicker: false
    });

    this.props.setDate(new_date, 'arrival');
  }

  /**
   * Open Departure Date Picker
   * @author Ezzat
   */
  openDepartureDatePicker = () => {
    this.setState({
      displayDepartureDatePicker: !this.state.displayDepartureDatePicker
    });
  };

  /**
   * open Arrival Date Picker
   * @author Ezzat
   */
  openArrivalDatePicker = () => {
    this.setState({ displayArrivalDatePicker: true });
  };

  /**
   * Close All Search Box Data
   * @author Ezzat
   * @todo Hide Passengr Number
   */
  closeSearchBoxModals() {
    if (this.state.displayDepartureDatePicker === true) {
      this.setState({
        displayDepartureDatePicker: false
      });
    }
    if (this.state.displayArrivalDatePicker === true) {
      this.setState({
        displayArrivalDatePicker: false
      });
    }
  }

  openSeatSelector = () => {
    this.setState({
      displaySeatSelector: true
    });
  };

  incrementPassengers = () => {
    if (Number(this.state.passengersNo) >= 8) return;
    this.setState({ passengersNo: Number(this.state.passengersNo) + 1 });
  };

  decrementPassengers = () => {
    if (Number(this.state.passengersNo) <= 1) return;

    // let count = --this.state.passengersNo;
    this.setState({ passengersNo: Number(this.state.passengersNo) - 1 });
  };

  /**
   * close (hide) daypicker and seat counter on clicking outside
   * @author Karem Diab
   */
  handleMouseClick(event) {
    if (
      this.state.displaySeatSelector |
      this.state.displayArrivalDatePicker |
      this.state.displayDepartureDatePicker
    ) {
      const path = event.path || (event.composedPath && event.composedPath());

      if (!path || !Array.isArray(path)) return;
      let selector = path.filter(path => {
        return (
          (path.className === "DayPicker") |
          (path.className === "input datepicker tarvel-date") |
          (path.className === "input datepicker return-date")
        );
      });

      if (selector.length === 0)
        this.setState({
          displaySeatSelector: false,
          displayDepartureDatePicker: false,
          displayArrivalDatePicker: false
        });
      // this.setState({ displaySeatSelector : !this.state.displaySeatSelector });
    }
  }

  /**
   * Search & make Search Request
   * @call updateUrl
   * @author Ezzat
   */
  search() {
    // updating the Store
    let departureStation = this.state.departureStation
      ? this.state.departureStation.Code
      : this.props.searchStore.departureStationId;
    let arrivalStation;

    /**
     * check if there is a default arrival station if not
     * fall back to arrival station if not
     * fall lastly to the arrival station set in search store
     */
    if (this.defaultStation) {
      arrivalStation = this.defaultStation.Code;
    } else if (this.state.arrivalStation) {
      arrivalStation = this.state.arrivalStation.Code;
    } else {
      arrivalStation = this.props.searchStore.arrivalStation.Code;
    }

    let search_store_data = {
      transportationType: this.state.transportationType,
      tripType: this.state.tripType || "oneWay",
      passengersNo: this.state.passengersNo || 1,
      departureStation,
      arrivalStation,
      departureDate: this.state.departureDate,
      arrivalDate: this.state.arrivalDate
    };

    this.props.searchActions.updateSearchParams(search_store_data);

    if (window.location.pathname === "/search") {
      this.props.tripActions.searchTrips(
        search_store_data,
        search_store_data.tripType
      );
    }

    this.props.tripActions.removeGoingTrip();
    // Update Url
    this.updateUrl();
  }

  /**
   * update the Current Url Or Redirect if on the homePage
   * @author Ezzat
   */
  updateUrl() {
    let departureStation = this.state.departureStation
      ? this.state.departureStation.Code
      : this.props.searchStore.departureStation.Code;
    let arrivalStation;

    // const lang = this.props.locale.lang;

    if (this.defaultStation) {
      arrivalStation = this.defaultStation.Code;
    } else if (this.state.arrivalStation) {
      arrivalStation = this.state.arrivalStation.Code;
    } else {
      arrivalStation = this.props.searchStore.arrivalStation.Code;
    }

    let url_params = {
      transportationType: this.state.transportationType,
      tripType: this.state.tripType || "oneWay",
      passengersNo: this.state.passengersNo || 1,
      departureStation,
      arrivalStation,
      departureDate: fullDateDigits(this.state.departureDate)
      // arrivalDate: fullDateDigits(this.state.arrivalDate),
    };

    // add language to the url params if its en
    // if (lang === 'en') {
    //   url_params.lang = lang;
    // }

    if (this.state.arrivalDate) {
      url_params.arrivalDate = fullDateDigits(this.state.arrivalDate);
    }

    url_params = qs.stringify(url_params, { encode: false });

    // redirect
    if (this.props.location.pathname === "/search") {
      window.history.pushState(
        {},
        document.title,
        window.location.pathname + "?" + url_params
      );
    } else {
      const location = {
        pathname: `/search`,
        search: url_params
      };

      this.props.history.push(location);
    }
  }

  render() {
    let busTapLinkClasses = `tablinks tab-bus  ${
      this.state.transportationType === "bus" ? "active" : null
    } `;
    let boatTapLinkClasses = `tablinks tab-boat ${
      this.state.transportationType === "boat" ? "active" : null
    } `;

    if (this.props.defaultDestination) {
      this.defaultStation = this.props.searchStore.stations.filter(
        s => s.Code === Number(this.props.defaultDestination)
      );
      if (this.defaultStation.length > 0) {
        this.defaultStation = this.defaultStation[0];
      } else {
        this.defaultStation = null;
      }
    }

    const { lang } = this.props.locale;
    const regionGroupKey = lang === 'ar'? "RegionNameA_Short": "RegionNameE_Short";

    // Get Default or selected station
    let departure_station =
      this.state.departureStation || this.props.searchStore.departureStation;
    let arrival_station =
      this.defaultStation ||
      this.state.arrivalStation ||
      this.props.searchStore.arrivalStation;

    // get Default Arrival and Departure Stations without the same stations in each field
    // ie: prevent [departure => cairo and arrivl => cairo]
    let departure_stations = this.props.searchStore.stations;

    let arrival_stations = null;

    if (
      !departure_stations.length < 1 &&
      departure_station &&
      arrival_station
    ) {
      arrival_stations = departure_stations.filter(
        s =>
          // removes stops from same region selected in departure
          s.TripRegion_Code !== departure_station.TripRegion_Code &&
          // removes current selected arrival stop from list
          s.Code !== arrival_station.Code &&

          // remove Sharm El Sheikh (Watanya) from arrival list
          s.Code !== 62
      );
      // arrival_stations.sort((a, b) =>
      //   a.TripRegion_Code < b.TripRegion_Code ? -1 : 1
      // );
	const sortKey = lang === 'ar'? 'NameA': 'NameE';
	arrival_stations.sort((a, b) => {
    const v1 = a[sortKey];
    const v2 = b[sortKey];
    if (!v1 || !v2) {
        return -1;
    }
		return v1.localeCompare(v2);
		// if (a[sortKey] < b[sortKey]) {
		// 	return -1;
		// }
		// if (a[sortKey] > b[sortKey]) {
		// 	return 1;
		// }
		// return 0;
	});
    }

    departure_stations = Utils.groupBy(departure_stations, regionGroupKey);
    // arrival_stations = Utils.groupBy(arrival_stations, "RegionNameA_Short");

    //handle if /Stations request catch a failuer
    if(arrival_stations === null || !departure_stations){
      arrival_station = null;
      departure_station = null;
    }

    // disables booking button if the condition is true
    let bookingAllowed =
      departure_station &&
      arrival_station &&
      departure_station.TripRegion_Code !== arrival_station.TripRegion_Code;

    if (this.props.page === "home") {
      return (
        <HomeViewComponent
          changeTransportationType={this.changeTransportationType}
          changeTripType={this.changeTripType}
          selectDepartureStation={this.selectDepartureStation}
          selectArrivalStation={this.selectArrivalStation}
          chooseDepartureTime={this.chooseDepartureTime}
          chooseArrivalTime={this.chooseArrivalTime}
          openDepartureDatePicker={this.openDepartureDatePicker}
          openArrivalDatePicker={this.openArrivalDatePicker}
          closeSearchBoxModals={this.closeSearchBoxModals}
          search={this.search}
          busTapLinkClasses={busTapLinkClasses}
          boatTapLinkClasses={boatTapLinkClasses}
          departure_station={departure_station}
          arrival_station={arrival_station}
          departure_stations={departure_stations}
          arrival_stations={arrival_stations}
          transportationType={this.state.transportationType}
          displayDepartureDatePicker={this.state.displayDepartureDatePicker}
          tripType={this.state.tripType}
          displayArrivalDatePicker={this.state.displayArrivalDatePicker}
          departureDate={this.state.departureDate}
          arrivalDate={this.state.arrivalDate}
          passengersNo={this.state.passengersNo}
          incrementPassengers={this.incrementPassengers}
          decrementPassengers={this.decrementPassengers}
          SeatSelector={this.SeatSelector}
          bookingAllowed={bookingAllowed}
        />
      );
    }

    if (this.props.page === "search") {
      return (
        <SearchViewComponent
          changeTransportationType={this.changeTransportationType}
          changeTripType={this.changeTripType}
          selectDepartureStation={this.selectDepartureStation}
          selectArrivalStation={this.selectArrivalStation}
          chooseDepartureTime={this.chooseDepartureTime}
          chooseArrivalTime={this.chooseArrivalTime}
          openDepartureDatePicker={this.openDepartureDatePicker}
          openArrivalDatePicker={this.openArrivalDatePicker}
          closeSearchBoxModals={this.closeSearchBoxModals}
          search={this.search}
          busTapLinkClasses={busTapLinkClasses}
          boatTapLinkClasses={boatTapLinkClasses}
          departure_station={departure_station}
          arrival_station={arrival_station}
          departure_stations={departure_stations}
          arrival_stations={arrival_stations}
          transportationType={this.state.transportationType}
          displayDepartureDatePicker={this.state.displayDepartureDatePicker}
          tripType={this.state.tripType}
          displayArrivalDatePicker={this.state.displayArrivalDatePicker}
          departureDate={this.state.departureDate}
          arrivalDate={this.state.arrivalDate}
          openSeatSelector={this.openSeatSelector}
          passengersNo={this.state.passengersNo}
          incrementPassengers={this.incrementPassengers}
          decrementPassengers={this.decrementPassengers}
          displaySeatSelector={this.state.displaySeatSelector}
          bookingAllowed={bookingAllowed}
        />
      );
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchBoxComponent);
