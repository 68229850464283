import React from 'react'
import ContentLoader from 'react-content-loader'

const FAQLoaderComponent = props => (
    <ContentLoader
        height={400}
        width={700}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
        {...props}
    >
        <rect x="490.41" y="21.27" rx="0" ry="0" width="167.59" height="22" />
        <rect x="276.23" y="57.27" rx="0" ry="0" width="381.17" height="13" />
        <rect x="539.23" y="105.27" rx="0" ry="0" width="114" height="21" />
        <rect x="195.23" y="138.27" rx="0" ry="0" width="455" height="10" />
        <rect x="194.23" y="156.27" rx="0" ry="0" width="456" height="10" />
        <rect x="522.23" y="219.27" rx="0" ry="0" width="128" height="20" />
        <rect x="259.23" y="250.27" rx="0" ry="0" width="387" height="9" />
        <rect x="257.23" y="270.27" rx="0" ry="0" width="389" height="9" />
        <rect x="253.23" y="288.27" rx="0" ry="0" width="394" height="9" />
        <rect x="273.23" y="306.27" rx="0" ry="0" width="374" height="8" />
    </ContentLoader>
)

export default FAQLoaderComponent;