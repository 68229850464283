import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import { Link, NavLink } from "react-router-dom";
import LoadingBar from "react-redux-loading-bar";

// Components
import AuthComponent from "./Auth/AuthComponent";


// Actions
import * as UserActions from "../Actions/UserActions";
import * as TripActions from "../Actions/TripActions";
import ProfileMenuComponent from "./Profile/ProfileMenuComponent";
import ProfileNavigationComponent from "./Profile/ProfileNavigationComponent";
import Utils from "../Helpers/Utils";
import { Trans } from 'react-i18next';
import { changeLocale } from '../Actions/LocaleActions';
import i18next from 'i18next';
import debug from '../Helpers/DebugLog';
import _ from "lodash";
import { InvalidPhoneComponent } from "./Profile/InvalidPhoneComponent";

function mapStateToProps(store) {
  return {
    app: store.app,
    locale: store.locale,
    userStore: store.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeLocale: bindActionCreators(changeLocale, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
    tripActions: bindActionCreators(TripActions, dispatch)
  };
}

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locale: i18next.language || 'ar',
      // isOnline: window.navigator.onLine,
      isActiveMobMenu: false,
      issuer: "main" // mobile nav menu issuer, used to select which menu to be shown [user menu, main menu]
    };

    this.subNavLinks = [
      { route: "/about", i18nKey: "header.subnav.about", displayName: "عن الشركة" },
      // { route: "/stations", displayName: "المحطات في الجمهورية"},
      { route: "/faq", i18nKey: "header.subnav.faq", displayName: "الأسئلة الشائعة" },
      { route: "/terms", i18nKey: "header.subnav.terms", displayName: "الشروط و الأحكام" },
      { route: "/policy", i18nKey: "header.subnav.policy", displayName: "سياسة الخصوصية" },
      { route: "/contact-us", i18nKey: "header.subnav.contact_us", displayName: "أتصل بنا" }
    ];

    this.navLinks = [
      { route: "/", i18nKey: "header.nav.home", displayName: "الصفحة الرئيسية" },
      { route: "/destinations", i18nKey: "header.nav.destinations", displayName: "وجهات السفر" },
      { route: "/stations", i18nKey: "header.nav.stations", displayName: "محطات جوباص" },
      // { route: "/", disabled: true, displayName: "حجز الأتوبيسات"},
      // { route: "/", disabled: true, displayName: "حجز العبارات"},
      { route: "/blog", i18nKey: "header.nav.news", displayName: "أخبار جوباص" }
    ];

    // methods
    // this.logout = this.logout.bind(this)
  }

  componentDidMount() {
    window.addEventListener('online', this.handleNetworkChange.bind(this, true));
    window.addEventListener('offline', this.handleNetworkChange.bind(this, false));

    const user = _.get(this, 'props.userStore.user.user', _.get(this, 'props.userStore.user'));
    this.checkUserPhone(user);
  }

  componentDidUpdate(prev, next) {
    const pUser = _.get(prev, 'userStore.user.user', _.get(prev, 'userStore.user'));
    const nUser = _.get(next, 'userStore.user.user', _.get(next, 'userStore.user'));
    
    if (_.isEmpty(pUser) && !_.isEmpty(nUser)) {
      this.checkUserPhone(nUser);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.handleNetworkChange.bind(this, true));
    window.removeEventListener('offline', this.handleNetworkChange.bind(this, false));
  }

  checkUserPhone(user) {
    if (!user) {
      return;
    }
    if (Object.keys(user).length === 0) {
      return;
    }
    if (!user.UserTelNo || user.UserTelNo.includes('Ù')) {
      this.props.userActions.setInvalidPhonePopUp(true);
    }
  }

  handleNetworkChange = (status) => this.setState({isOnline: status})

  toggleLocale = () => {
    const locale = this.props.locale.lang === 'en'? 'ar': 'en';
    this.props.changeLocale(locale);    
  }

  /**
   * Show Or Hide Auth PopUP
   * @author Ezzat
   */
  toggleAuthPopUp = () => {
    // make it to Store
    this.props.userActions.toggleAuthPopUp();
  };

  /**
   * Logout
   * @author Ezzat
   */
  // logout(){
  //   store2.remove('user')
  //   this.props.tripActions.removeGoingTrip()
  //   window.location.replace('/')

  // }

  // Ojja
  toggleClassesMobMenu = issuer => {
    if (!this.state.isActiveMobMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    this.setState({ isActiveMobMenu: !this.state.isActiveMobMenu, issuer });
  };

  handleMenuButtonClick = () => {
    this.toggleClassesMobMenu();
    Utils.scrollTo({ top: 0, behavior: "smooth" });
  };

  render() {
    let MobMenuClasses = this.state.isActiveMobMenu
      ? "nav-side active"
      : "nav-side";

    let headerclsx = "page-header nav-down";
    if (!this.props.app.isOnline) {
      headerclsx += " topmsg";
    }

    return (
      <div className="header-container">
        <header className={headerclsx}>
        {/* {!this.state.isOnline && 
          <div className="top-msg gray">
            <Trans i18nKey="header.no_internet_conn">لايوجد إتصال مع الإنترنت، الرجاء التأكد والمحاولة بعد قليل</Trans>
          </div>} */}
        
          <LoadingBar className="loadingtopBar" />
          <div
            className={
              this.props.page === "booking"
                ? "container booking-header"
                : "container"
            }
          >
            <div className="header-con">
              <div className="logo">
                {this.props.page !== "booking" && (
                  <div className="nav-mobile">
                    <button
                      className="nav-collabse"
                      onClick={() => {
                        this.toggleClassesMobMenu("main");
                      }}
                    >
                      {/* <i className="material-icons">menu</i> */}
                      <i className="fas fa-bars" />
                    </button>
                    <ul className={MobMenuClasses}>
                      <button
                        className="closeMenu"
                        onClick={() => {
                          this.toggleClassesMobMenu();
                        }}
                      >
                        <i className="material-icons">close</i>
                      </button>
                      <div className="supp-nav">
                        {this.props.userStore.auth &&
                          this.state.issuer === "user" && (
                            <React.Fragment>
                              <div className="user-profile">
                                <img
                                  src={
                                    this.props.userStore.user.Pic_Url ||
                                    "/images/img_avatar.png"
                                  }
                                  alt=""
                                />
                                <a href="#!">{this.props.userStore.user.UserName}</a>
                              </div>
                              <ProfileNavigationComponent
                                showPointsProgram
                                logout={() => {
                                  this.handleMenuButtonClick();
                                  this.props.userActions.logout(
                                    this.props.history,
                                    true
                                  );
                                }}
                                active={this.props.userStore.auth}
                              />
                            </React.Fragment>
                          )}

                        {this.state.issuer === "main" && (
                          <React.Fragment>
                            <div className="main-nav">
                              <ul>
                                {this.navLinks.map((link, i) => {
                                  if (this.props.locale.lang === 'en' && link.route === '/blog') return null;
                                  return (
                                    <li key={i}>
                                      <Link
                                        className={
                                          !link.disabled &&
                                          link.route === this.props.page
                                            ? "selected"
                                            : ""
                                        }
                                        onClick={() => {
                                          // enable scrolling after user clicks a link
                                          document.body.style.overflow = "unset"
                                          Utils.scrollTo({
                                            top: 0,
                                            behavior: "smooth"
                                          })
                                        }}
                                        to={link.route}
                                      >
                                        <Trans i18nKey={link.i18nKey}>{link.displayName}</Trans>
                                      </Link>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                            <ul>
                              {this.subNavLinks.map((link, i) => {
                                return (
                                  <li key={i}>
                                    <NavLink
                                      onClick={() => {
                                        // enable scrolling after user clicks a link
                                        document.body.style.overflow = "unset"
                                        
                                        Utils.scrollTo({
                                          top: 0,
                                          behavior: "smooth"
                                        })
                                      }}
                                      to={link.route}
                                    >
                                      <Trans i18nKey={link.i18nKey}>{link.displayName}</Trans>
                                    </NavLink>
                                  </li>
                                );
                              })}
                            </ul>
                          </React.Fragment>
                        )}
                      </div>
                      <button className="lang-switcher-btn" onClick={this.toggleLocale}>
                          {this.props.locale.lang === 'ar'
                            ? 'Go to english'
                            : 'الذهاب إلى العربية'}
                      </button>
                    </ul>
                    <div
                      className="overlay"
                      onClick={() => {
                        this.toggleClassesMobMenu();
                      }}
                    />
                  </div>
                )}
                <Link
                  to="/"
                  onClick={() => Utils.scrollTo({ top: 0, behavior: "smooth" })}
                >
                  <img src="/images/go-bus-dark.png" alt="logo" />
                </Link>
              </div>

              {this.props.page !== "booking" && (
                <div className="navbar">
                  <div className="top-nav">
                    <ul>
                      {this.subNavLinks.map((link, i) => {
                        return (
                          <li key={i}>
                            <Link
                              onClick={() =>
                                Utils.scrollTo({ top: 0, behavior: "smooth" })
                              }
                              to={link.route}
                            >
                              <Trans i18nKey={link.i18nKey}>{link.displayName}</Trans>
                            </Link>
                          </li>
                        );
                      })}
                      {
                        // (this.props.userStore.auth) &&
                        // <li>
                        //   <a onClick={this.props.userActions.logout}>
                        //     تسجيل الخروج
                        //   </a>
                        // </li>
                      }
                    </ul>

                    <div className="lang-switcher">
                      {!/\/blog/.test(this.props.location.pathname) && <button className="btn" onClick={this.toggleLocale}>
                          {this.props.locale.lang === 'ar'
                            ? 'Go to english'
                            : 'الذهاب إلى العربية'}
                      </button>}
                    </div>
                  </div>

                  <div className="main-nav">
                    <ul>
                      {this.navLinks.map((link, i) => {
                        if (this.props.locale.lang === 'en' && link.route === '/blog') return null;
                        return (
                          <li key={i}>
                            <Link
                              className={
                                !link.disabled && link.route === this.props.page
                                  ? "selected"
                                  : ""
                              }
                              onClick={() =>
                                Utils.scrollTo({ top: 0, behavior: "smooth" })
                              }
                              to={link.route}
                            >
                              <Trans i18nKey={link.i18nKey}>{link.displayName}</Trans>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              )}

              {this.props.page === "booking" ? (
                <>
                <i className="material-icons" onClick={this.props.goBack}>
                  arrow_back
                </i>
                </>
              ) : this.props.userStore.auth ? (
                <ProfileMenuComponent
                  profile_picture={this.props.userStore.user.Pic_Url}
                  username={this.props.userStore.user.UserName}
                  toggleMobileNavMenu={this.toggleClassesMobMenu}
                  logout={() => {
                    //this.handleMenuButtonClick();
                    this.props.userActions.logout(
                      this.props.history,
                      true
                    );
                  }}
                />
              ) : (
                <div className="header-btn">
                  <button
                    className="btn login-btn"
                    onClick={() => {
                      this.toggleAuthPopUp();
                    }}
                  >
                    <Trans i18nKey="header.login">
                      تسجيل الدخول
                    </Trans>
                  </button>
                </div>
              )}
            </div>
          </div>
        </header>

        {this.props.userStore.show_auth_popup && (
          <AuthComponent close={this.toggleAuthPopUp} />
        )}
        {this.props.userStore.show_invalid_phone_popup && (
          <InvalidPhoneComponent
            userActions={this.props.userActions}
            userStore={this.props.userStore}
            history={this.props.history}
          />
        )}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Header));
