import moment from "moment";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store2 from 'store2';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import pkg from '../package.json';

import { unregister } from "./registerServiceWorker";
import Routes from "./Routes";
import Store from "./Store";

import CMApi from "cm-mail-api";
import NodeAuth from "node-auth-api";
import { nodeauth_url, nodepayment_pw, nodepayment_un, sentryDsn, __ENV__ } from "./Api/Base";
import './Helpers/axiosInterceptor';
import Utils from "./Helpers/Utils";
import i18n from './i18n';

import { getPoints, usePoints } from './Actions/UserActions';
import { useCheckoutStore } from "./new-store/checkoutStore";

if (sentryDsn) {
  Sentry.init({
      dsn: sentryDsn,
      release: pkg.version,
      sampleRate: 0.05,
      tracesSampleRate: 0.05,
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 0.01,
      integrations: [new BrowserTracing(), new Sentry.Replay()]
  });
}

window.i18n = i18n;

NodeAuth.setUrl(nodeauth_url);

NodeAuth.Company.login(nodepayment_un, nodepayment_pw);
store2.set('env', __ENV__);
useCheckoutStore.getState().loadState();

if (process.env.REACT_APP_API === "production") {
  CMApi.setHost("https://go-bus.com:8000");
  // Utils.hijackAllLogs();
  Utils.hijackReduxDevTools();
} else {
  CMApi.setHost("http://go-bus.com:8001");
}

// global vars, helps for debugging.
window.WindowStore = Store;
window.WindowDate = moment;
window.NA = NodeAuth;
window.WindowUtils = Utils;

if (__ENV__ !== "production") {
  window.getPoints = () => Store.dispatch(getPoints());
  window.usePoints = (a) => Store.dispatch(usePoints(a));
}

ReactDOM.render(
  <Provider store={Store}>
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

if (NodeAuth.User.isLoggedIn()) {
  Store.dispatch(getPoints());
}

unregister();

