import FacebookPixel from "./FacebookPixelHelper";
import { FacebookConversionApi } from "../Api/FacebookConversionApi";
import { useCheckoutStore } from "../new-store/checkoutStore";
import _ from "lodash";
import Store from "../Store";

const __ENV__ = process.env.REACT_APP_API;
class FBBase {
    static isSafe() {
        return true;
    }

    static getUserEmail() {
        const state = Store.getState();
        return _.get(state, "user.user.UserEmail", Math.random());
    }

    static async getUserData() {
        const state = Store.getState();
        const user = _.get(state, "user.user", {});
        let phone = _.get(user, "UserTelNo", "");
        if (phone.startsWith("0")) {
            phone = "2" + phone;
        }
        if (false === phone.startsWith("20")) {
            phone = "20" + phone;
        }
        return {
            em: await FacebookConversionApi.sha256Hash(user.UserEmail),
            ph: await FacebookConversionApi.sha256Hash(phone),
            external_id: await FacebookConversionApi.sha256Hash(user.UserID)
        };
    }
}

class Custom extends FBBase {
    static async AddToCart({ id, value, from, to }) {
        if (__ENV__ !== "production") {
            return;
        }
        if (this.isSafe()) {
            const state = Store.getState();
            const user = _.get(state, "user.user") || {};
            if (!user.UserEmail) {
                return;
            }
            const event_id = Facebook.generateEventId();
            FacebookConversionApi.sendEvent(
                event_id,
                "CustomAddToCart",
                await this.getUserData(),
                {
                    id,
                    value,
                    from,
                    to
                }
            );
        }
    }
}

export default class Facebook extends FBBase {
    static Custom = Custom;

    static generateEventId() {
        return Date.now() + _.random(1, 10000, false);
    }

    static async InitiateCheckout(data) {
        if (__ENV__ !== "production") {
            return;
        }
        if (Facebook.isSafe()) {
            const event_id = Facebook.generateEventId();
            const { content_category, content_ids, value, from, to } = data;
            const state = Store.getState();
            const user = _.get(state, "user.user") || {};
            if (!user.UserEmail) {
                return;
            }
            const user_data = await this.getUserData();
            FacebookPixel.InitiateCheckout({ ...data, user_data, event_id });
            FacebookConversionApi.sendEvent(
                event_id,
                "InitiateCheckout",
                user_data,
                {
                    currency: "EGP",
                    content_type: "product",
                    content_category,
                    content_ids,
                    value,
                    from,
                    to
                }
            );
        }
    }

    /**
     *
     * @param {string} search_string
     * @param {string} from
     * @param {string} to
     */
    static async Search(search_string, from, to) {
        if (__ENV__ !== "production") {
            return;
        }
        if (Facebook.isSafe()) {
            const event_id = Facebook.generateEventId();
            const state = Store.getState();
            const user = _.get(state, "user.user") || {};
            if (!user.UserEmail) {
                return;
            }
            const user_data = await this.getUserData();
            FacebookPixel.Search(event_id, user_data, search_string, from, to);
            FacebookConversionApi.sendEvent(event_id, "Search", user_data, {
                search_string,
                from,
                to
            });
        }
    }

    static async AddToCart(data) {
        if (__ENV__ !== "production") {
            return;
        }
        if (Facebook.isSafe()) {
            const event_id = Facebook.generateEventId();
            const state = Store.getState();
            const user = _.get(state, "user.user") || {};
            if (!user.UserEmail) {
                return;
            }
            const user_data = await this.getUserData();
            const { content_ids, content_name, value, from, to } = data;
            FacebookPixel.AddToCart({ ...data, user_data, event_id });
            FacebookConversionApi.sendEvent(event_id, "AddToCart", user_data, {
                content_type: "product",
                currency: "EGP",
                content_name,
                content_ids,
                value,
                from,
                to
            });
        }
    }

    /**
     *
     * @param {number} value
     * @param {any} products
     * @param {"EGP"} currency
     */
    static async Purchase(value, contents) {
        if (__ENV__ !== "production") {
            return;
        }
        if (Facebook.isSafe()) {
            const event_id = Facebook.generateEventId();
            const state = Store.getState();
            const user = _.get(state, "user.user") || {};
            if (!user.UserEmail) {
                return;
            }
            const user_data = await this.getUserData();
            FacebookPixel.Purchase(event_id, user_data, value, contents);
            FacebookConversionApi.sendEvent(event_id, "Purchase", user_data, {
                value,
                currency: "EGP",
                contents: contents.map(c => ({
                    id: c.id,
                    quantity: c.quantity
                })),
                content_type: "product"
            });
        }
    }

    static sendAddToCartPixel() {
        const store = useCheckoutStore.getState();
        let CardID = store.cartId;

        let goingTrip = store.goingTripInfo;
        let returningTrip = store.returnTripInfo;

        if (goingTrip == null) {
            return;
        }

        let passengersNo = store.passengersNo;
        let totalPrice = 0,
            goingPrice = 0,
            returningPrice = 0;
        const content_ids = [String(goingTrip.TripSubData_Id)];

        if (returningTrip) {
            goingPrice = (passengersNo * goingTrip.TripPrice_GoCome) / 2;
            returningPrice =
                (passengersNo * returningTrip.TripPrice_GoCome) / 2;
            totalPrice = goingPrice + returningPrice;
            content_ids.push(String(returningTrip.TripSubData_Id));
        } else {
            goingPrice = passengersNo * goingTrip.TripPrice;
            totalPrice = passengersNo * goingTrip.TripPrice;
        }

        let cart = {
            id: CardID,
            value: totalPrice,
            content_ids: content_ids,
            from: goingTrip.TripOfficeFrom_NameE,
            to: goingTrip.TripOfficeTo_NameE
        };

        Facebook.AddToCart(cart);
    }

    static sendCheckoutPixel() {
        const store = useCheckoutStore.getState();
        //get final checkout data
        let CardID = store.cartId;
        let goingTrip = store.goingTripInfo;
        let returningTrip = store.returnTripInfo;

        if (!goingTrip) {
            return;
        }

        let passengersNo = store.passengersNo;
        let totalPrice = 0,
            goingPrice = 0,
            returningPrice = 0;
        let ticket_type;
        const content_ids = [String(goingTrip.TripSubData_Id)];

        if (returningTrip) {
            goingPrice = (passengersNo * goingTrip.TripPrice_GoCome) / 2;
            returningPrice =
                (passengersNo * returningTrip.TripPrice_GoCome) / 2;
            totalPrice = goingPrice + returningPrice;
            content_ids.push(String(returningTrip.TripSubData_Id));
            ticket_type = "going_returning_ticket";
        } else {
            goingPrice = passengersNo * goingTrip.TripPrice;
            totalPrice = passengersNo * goingTrip.TripPrice;
            ticket_type = "going_ticket";
        }

        let checkData = {
            id: CardID,
            value: totalPrice,
            content_ids: content_ids,
            content_category: ticket_type,
            from: goingTrip.TripOfficeFrom_NameE,
            to: goingTrip.TripOfficeTo_NameE
        };
        Facebook.InitiateCheckout(checkData);
    }
}
