import React, { Component } from 'react';
import ProfileNavigationComponent from '../../Profile/ProfileNavigationComponent';
import { Trans } from 'react-i18next';


class SideBar extends Component {

    render() {

        return (
            <div className="AccSideBar">
                <div className="user-data">
                    <img src={this.props.profile_picture || "/images/img_avatar.png"} alt="user avatar" className="avatar"/>
                    <h4 className="label"><Trans i18nKey="common.fullname">الأسم بالكامل</Trans></h4>
                    {/* <p>Malak Mikhail Saad</p> */}
                    <p>{this.props.username}</p>
                    <h4 className="label"><Trans i18nKey="common.email">البريد الإلكتروني</Trans></h4>
                    {/* <p>malak.mitch@gmail.com</p> */}
                    <p>{this.props.email}</p>
                    <h4 className="label"><Trans i18nKey="common.phone">رقم المحمول</Trans></h4>
                    {/* <p>01006510054</p> */}
                    <p>{this.props.phone}</p>
                </div>

                <ProfileNavigationComponent 
                    logout={this.props.logout}
                    active={true} />
            </div>
        );
    }
}

export default SideBar;