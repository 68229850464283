import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import moment from "moment";
import store2 from "store2";
import { bindActionCreators } from "redux";

import * as UserActions from "../../Actions/UserActions";
import MainLayoutComponent from "../MainLayoutComponent";
import {
    FullDateNoYear,
    getTimeDiff,
    getTimeDuration,
    tripTimeSegment
} from "../../Helpers/TimeParser";
import { Link } from "react-router-dom";
import TripInstructions from "../Booking/TripInstructions";
import { pushToDataLayer } from "../../Reducers/GTMReducer";
import {
    removeGoingTrip,
    removeReturningTrip
} from "../../Actions/TripActions";
import Utils, { isGoMini } from "../../Helpers/Utils";
import { Trans } from "react-i18next";
import Hint from "../Utils/Hint";
import i18next from "i18next";
import _ from "lodash";
import { useCheckoutStore } from "../../new-store/checkoutStore";
import { PaymentMethods } from "../Booking/PaymentComponent";
import { useRef } from "react";
import { useRequest } from "../../Hooks/useRequest";
import { baseUrl } from "../../Api/Base";
import Store from "../../Store";

function matchStateToProps(state) {
    return {
        locale: state.locale,
        user: state.user,
        trips: state.trips,
        search: state.search
    };
}

function mapDispatchToProps(dispatch) {
    return {
        pushToDataLayer: bindActionCreators(pushToDataLayer, dispatch),
        userActions: bindActionCreators(UserActions, dispatch),
        removeGoingTrip: bindActionCreators(removeGoingTrip, dispatch),
        removeReturningTrip: bindActionCreators(removeReturningTrip, dispatch)
    };
}

function purchaseEvent(ticket = {}, props) {
    if (ticket == null) {
        console.error("ticket is null");
        return;
    }

    const user = Store.getState().user.user;
    const email = _.get(user, "UserEmail", null);
    const phone = _.get(user, "UserTelNo", null);
    const { myTrips, CardID, CardTotalPrice } = ticket;

    let goingTrip = myTrips[0];
    let returnTrip = myTrips[1];

    const products = [];

    // going trip gtm data
    products.push({
        name: goingTrip.TripOfficeFrom_NameA,
        id: goingTrip.TripSubData_Id,
        price: goingTrip.TripPrice,
        quantity: goingTrip.TripSeatsCount
    });

    if (returnTrip != null) {
        products.push({
            name: returnTrip.TripOfficeFrom_NameA,
            id: returnTrip.TripSubData_Id,
            price: returnTrip.TripPrice,
            quantity: returnTrip.TripSeatsCount
        });
    }

    props.pushToDataLayer({
        ecommerce: {
            purchase: {
                actionField: {
                    id: CardID,
                    revenue: CardTotalPrice,
                    coupon: props.trips.promoCode.couponCode || ""
                },
                products
            }
        },
        email,
        phone,
        event: "Purchase"
    });
}

function dispatchGTMEvent(props) {
    const {
        cartId,
        goingTripInfo,
        returnTripInfo,
        pricing
    } = useCheckoutStore.getState();

    if (!goingTripInfo) {
        return;
    }

    const products = [];
    // going trip gtm data
    products.push({
        name: goingTripInfo.TripOfficeFrom_NameA,
        id: goingTripInfo.TripSubData_Id,
        price: goingTripInfo.TripPrice,
        quantity: props.search.passengersNo
    });

    if (returnTripInfo) {
        products.push({
            name: returnTripInfo.TripOfficeFrom_NameA,
            id: returnTripInfo.TripSubData_Id,
            price: returnTripInfo.TripPrice,
            quantity: props.search.passengersNo
        });
    }

    props.pushToDataLayer({
        ecommerce: {
            purchase: {
                actionField: {
                    id: cartId,
                    revenue: pricing.totalPrice,
                    coupon: props.trips.promoCode.couponCode || ""
                },
                products
            }
        },
        event: "trip_purchase"
    });
}

export const getCashAlertMessage = (ticket, lang = "ar") => {
    let departureTrip = _.get(ticket, "myTrips[0]", ticket);
    let actionDate = _.get(ticket, "ActionDate", moment());

    let isObsolete = false;
    let deadline = null;

    const now = moment();
    const tripTime = moment(departureTrip.TripDateTime)
        .set("minutes", 0)
        .set("seconds", 0);
    const bookTime = moment(actionDate)
        .set("minutes", 0)
        .set("seconds", 0);
    const beforeTripWindow = getTimeDiff(now, tripTime.subtract(3, "hours"));
    const afterBookingWindow = getTimeDiff(now, bookTime.add(3, "hours"));

    if (beforeTripWindow < 0 || afterBookingWindow < 0) {
        isObsolete = true;
    } else {
        const bookingWindow = Math.min(beforeTripWindow, afterBookingWindow);
        let duration = moment.duration(bookingWindow);

        if (duration.hours() > 0) {
            duration = duration.subtract(duration.minutes(), "minutes");
        }

        deadline = duration.locale(lang).humanize(false, { m: 60 });
    }

    if (isObsolete) {
        return `${i18next.t(
            "thanks_page.cash.alert_obsolete",
            "تمت انتهاء الصلاحية الخاصة بكود الحجز، برجاء إعادة الحجز مرة أخري"
        )}`;
    } else {
        return `${i18next.t(
            "thanks_page.cash.alert",
            "الحجز غير مؤكد حتى يتم الدفع في ماكينة أمان او فوري او بيى خلال {deadline} من الاَن",
            {
                deadline
            }
        )}`;
    }
};

const renderCashAlert = (ticket, lang = "ar") => {
    return (
        <React.Fragment>
            <h3 className="pending">
                <Trans i18nKey="thanks_page.cash.type_temporary">
                    حجز غير مؤكد (كود الدفع ){" "}
                    {{
                        job_id: store2.get("fawry_job_id")
                    }}
                    )
                </Trans>
            </h3>
            <div className="alert-msg">
                <div className="yellow-circle">
                    <i className="fas fa-exclamation-triangle"></i>
                </div>
                {getCashAlertMessage(ticket, lang)}
            </div>
        </React.Fragment>
    );
};

const Thanks = props => {
    const card_id = props.match.params.card_id;
    const state = useCheckoutStore();
    const request = useRequest(
        {
            url: `${baseUrl}/api/V3/GetCardData`,
            method: "GET",
            params: {
                cardID: card_id
            }
        },
        { loading: true }
    );

    const page = useRef(null);
    let timer = null;

    async function onMount() {
        Utils.scrollToElement(page.current);
        const ticket = await request.run();

        if (ticket == null || (ticket && ticket.CardID === 0)) {
            return;
            // timer = setTimeout(() => {
            //    window.location.replace(`/profile/tickets`);
            // }, 4000);
        }

        if (process.env.REACT_APP_API === "production") {
            if (process.env.REACT_APP_ENABLE_GOOGLE_ANALYTICS !== "false") {
                purchaseEvent(ticket, props);
            }
            // try {
            //     dispatchGTMEvent(props);
            // } catch (e) {
            //     console.error("GTMDISPATCHFAILED");
            //     console.error(e);
            // }
        }
    }

    async function redirectToBookings() {
        // if (timer != null) {
        //     clearTimeout(timer);
        // }

        window.location.replace(`/profile/tickets`);
    }

    useEffect(() => {
        onMount();
    }, []);

    if (state.__loading_state || request.loading) {
        return (
            <MainLayoutComponent page={props.location.pathname}>
                <div className="thanks-page" ref={ref => (page.current = ref)}>
                    <div className="content">
                        <div
                            className="component-loader"
                            style={{ height: "60vh" }}
                        />
                    </div>
                </div>
            </MainLayoutComponent>
        );
    }

    /**
     * Trip pricing with and without discount
     * * check for pricing.code if user used a promocode (eg, pricing: { code: 'websitev3' })
     */
    const pricing = store2.get("promoCode", {
        TotalPrice: state.pricing.totalPriceNoDiscount,
        Total_AfterDisc: state.pricing.displayPrice
    });
    const discount = pricing.TotalPrice - pricing.Total_AfterDisc;
    const no_discount = pricing.TotalPrice;
    const ticketNotFound =
        request.error != null ||
        request.result.data == null ||
        (request.result.data && request.result.data.CardID === 0);

    if (ticketNotFound === true) {
        console.error(
            `Error occured while fetching card data: `,
            request.error
        );
        return (
            <NoTicketFound
                history={props.history}
                redirectToBookings={redirectToBookings}
            />
        );
    }

    const user = props.user.user;
    const ticket = request.result.data;

    const { lang } = props.locale;

    let fromOfficeKey = "TripOfficeFrom_NameA";
    let toOfficeKey = "TripOfficeTo_NameA";
    let serviceKey = "TripServKind_NameA";

    if (lang === "en") {
        fromOfficeKey = "TripOfficeFrom_NameE";
        toOfficeKey = "TripOfficeTo_NameE";
        serviceKey = "TripServKind_NameE";
    }

    let departureTrip = ticket.myTrips[0];
    let arrivalTrip = ticket.myTrips[1];
    let departureSeats = departureTrip.Seats;

    let arrivalSeats = arrivalTrip ? arrivalTrip.Seats : [];

    let beneficiary = store2.get("beneficiary");

    let paymentMethod =
        ticket.CardTypeName === "Fawry"
            ? PaymentMethods.cash
            : PaymentMethods.mpgs; //store2.get("payment_method");

    let jobDate = store2.get("fawry_job_date");
    let jobTime = null;
    let jobDeadline = null;
    let remainingDuration = null;

    if (
        paymentMethod === PaymentMethods.mpgs ||
        paymentMethod === PaymentMethods.meeza
    ) {
        jobDate = moment();
        jobTime = moment()
            .locale("en")
            .format("hh:mm");
        jobTime += tripTimeSegment(moment(), null, lang); //.format("a");
        remainingDuration = getTimeDuration(
            departureTrip.TripDateTime,
            moment()
        );
    } else {
        if (jobDate) {
            jobTime =
                moment(jobDate)
                    .locale("en")
                    .format("hh:mm") +
                " " +
                tripTimeSegment(moment(jobDate), null, lang);
        }

        const tripDeadline = moment(departureTrip.TripDateTime).subtract(
            3,
            "hours"
        );
        jobDeadline = getTimeDuration(tripDeadline);
    }

    jobDate = FullDateNoYear(moment(jobDate), lang);

    if (!departureTrip) window.location.replace("/");

    let finalPayTimestamp = moment(departureTrip.TripDateTime).subtract(
        3,
        "hours"
    );
    let finalPayDate = FullDateNoYear(finalPayTimestamp, lang); //.format("dddd DD MMMM");
    let finalPayTime = moment(finalPayTimestamp)
        .locale("en")
        .format("hh:mm");
    let finalPayTimeSegment = tripTimeSegment(finalPayTimestamp, null, lang); //.format("a");

    const showHint =
        /(Nasr|نصر)/g.test(_.get(departureTrip, "TripOfficeFrom", "")) &&
        !isGoMini({
            serviceName: departureTrip.TripServKind_NameA
        });

    // const showAlert =
    //     state.goingTripInfo && state.goingTripInfo.TripOfficeFrom_Code === 364;
    return (
        <MainLayoutComponent page={props.location.pathname}>
            <div className="thanks-page">
                <div className="content">
                    <div className="row">
                        <div className="top-msg">
                            {paymentMethod === "mpgs" ||
                            paymentMethod === "meeza" ? (
                                <h3 className="confirm">
                                    <Trans i18nKey="thanks_page.credit_card.type_confirmed">
                                        حجز مؤكد رقم{" "}
                                        {{ booking_number: ticket.CardID }}
                                    </Trans>
                                </h3>
                            ) : (
                                renderCashAlert(ticket, lang)
                            )}
                            {/* {showAlert && (
                                <div className="search-component-hints">
                                    <Hint
                                        title={i18next.t(
                                            "thanks_page.hint.title",
                                            "الرجاء الإنتباه!"
                                        )}
                                        message={
                                            <>
                                                <Trans i18nKey="thanks_page.alert.message">
                                                    عميلنا العزيز، الوقت
                                                    والتاريخ غير مؤكدين للرحلة
                                                    ولكنه فقط سداد لرسوم الرحلة
                                                    وسيتم التواصل معكم خلال 24
                                                    ساعه لاستكمال باقى البيانات
                                                    من جانب حضراتكم.
                                                </Trans>
                                            </>
                                        }
                                    />
                                </div>
                            )} */}
                            {showHint && (
                                <div className="search-component-hints">
                                    <Hint
                                        title={i18next.t(
                                            "thanks_page.hint.title",
                                            "الرجاء الإنتباه!"
                                        )}
                                        message={
                                            <>
                                                <Trans i18nKey="thanks_page.hint.message">
                                                    برجاء العلم أن رحلات القاهرة
                                                    مدينة نصر تتحرك من
                                                </Trans>{" "}
                                                <a
                                                    href="https://www.google.com/maps/place/Go+Bus+main+station/@30.0464429,31.3184373,17z/data=!4m5!3m4!1s0x14583ff7276bf1ab:0x304b20824806314!8m2!3d30.0464349!4d31.3185799?hl=en&shorturl=1"
                                                    target="_blank"
                                                >
                                                    <Trans i18nKey="thanks_page.hint.link">
                                                        (228 شارع المخيم الدائم
                                                        الحي السادس مدينة نصر)
                                                    </Trans>
                                                </a>
                                            </>
                                        }
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="tickets-data">
                            <div className="trip-data">
                                <div className="row locations">
                                    <div className="single-location">
                                        <span>
                                            <Trans i18nKey="thanks_page.travel_from">
                                                سفر من
                                            </Trans>
                                        </span>
                                        <strong>
                                            {departureTrip[fromOfficeKey]}
                                        </strong>
                                    </div>
                                    <div className="single-location">
                                        <span>
                                            <Trans i18nKey="thanks_page.travel_to">
                                                وصول إلي
                                            </Trans>
                                        </span>
                                        <strong>
                                            {departureTrip[toOfficeKey]}
                                        </strong>
                                    </div>
                                    <div className="trip-type">
                                        <span>
                                            <Trans i18nKey="thanks_page.booking_type">
                                                نوع الحجز
                                            </Trans>
                                        </span>

                                        {arrivalTrip ? (
                                            <strong>
                                                <Trans i18nKey="thanks_page.round">
                                                    ذهاب وعودة
                                                </Trans>
                                            </strong>
                                        ) : (
                                            <strong>
                                                <Trans i18nKey="thanks_page.oneway">
                                                    ذهاب فقط
                                                </Trans>
                                            </strong>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="trip-details">
                                        <div className="item">
                                            <p className="title">
                                                <i class="fas fa-check" />
                                                <Trans i18nKey="thanks_page.trips.going_trip">
                                                    رحلة الذهاب
                                                </Trans>
                                            </p>
                                            <p className="date">
                                                {FullDateNoYear(
                                                    moment(
                                                        departureTrip.TripDateTime
                                                    ),
                                                    lang
                                                )}
                                            </p>
                                            <p className="time">
                                                <span>
                                                    {moment(
                                                        departureTrip.TripDateTime
                                                    )
                                                        .locale("en")
                                                        .format("hh:mm")}
                                                </span>
                                                {tripTimeSegment(
                                                    departureTrip.TripDateTime,
                                                    null,
                                                    lang
                                                )}
                                            </p>
                                            <p>
                                                <Trans i18nKey="thanks_page.travel_from">
                                                    سفر من
                                                </Trans>
                                                : {departureTrip[fromOfficeKey]}
                                                <br />
                                                <Trans i18nKey="thanks_page.travel_to">
                                                    وصول إلي
                                                </Trans>{" "}
                                                : {departureTrip[toOfficeKey]}
                                                <br />
                                                <Trans i18nKey="thanks_page.trips.class_type">
                                                    نوع الخدمة
                                                </Trans>
                                                : {departureTrip[serviceKey]}
                                                <br />
                                                <Trans i18nKey="common.passengers_no">
                                                    عدد المسافرين
                                                </Trans>
                                                : {departureSeats.length}
                                            </p>
                                            <ul className="seats-selected fl-row">
                                                {departureSeats.map(seat => {
                                                    return (
                                                        <li
                                                            key={seat}
                                                            className="seat"
                                                        >
                                                            {seat}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                        {arrivalTrip && (
                                            <div className="item">
                                                <React.Fragment>
                                                    <p className="title">
                                                        <i class="fas fa-check" />
                                                        <Trans i18nKey="thanks_page.trips.return_trip">
                                                            رحلة العودة
                                                        </Trans>
                                                    </p>
                                                    <p className="date">
                                                        {FullDateNoYear(
                                                            moment(
                                                                arrivalTrip.TripDateTime
                                                            ),
                                                            lang
                                                        )}
                                                    </p>
                                                    <p className="time">
                                                        <span>
                                                            {moment(
                                                                arrivalTrip.TripDateTime
                                                            )
                                                                .locale("en")
                                                                .format(
                                                                    "hh:mm"
                                                                )}
                                                        </span>
                                                        {tripTimeSegment(
                                                            arrivalTrip.TripDateTime,
                                                            null,
                                                            lang
                                                        )}
                                                    </p>
                                                    <p>
                                                        <Trans i18nKey="thanks_page.travel_from">
                                                            سفر من
                                                        </Trans>
                                                        :{" "}
                                                        {
                                                            arrivalTrip[
                                                                fromOfficeKey
                                                            ]
                                                        }
                                                        <br />
                                                        <Trans i18nKey="thanks_page.travel_to">
                                                            وصول إلي
                                                        </Trans>{" "}
                                                        :{" "}
                                                        {
                                                            arrivalTrip[
                                                                toOfficeKey
                                                            ]
                                                        }
                                                        <br />
                                                        <Trans i18nKey="thanks_page.trips.class_type">
                                                            نوع الخدمة
                                                        </Trans>
                                                        :{" "}
                                                        {
                                                            arrivalTrip[
                                                                serviceKey
                                                            ]
                                                        }
                                                        <br />
                                                        <Trans i18nKey="common.passengers_no">
                                                            عدد المسافرين
                                                        </Trans>
                                                        : {arrivalSeats.length}
                                                    </p>
                                                    <ul className="seats-selected fl-row">
                                                        {arrivalSeats.map(
                                                            seat => {
                                                                return (
                                                                    <li
                                                                        key={
                                                                            seat
                                                                        }
                                                                        className="seat"
                                                                    >
                                                                        {seat}
                                                                    </li>
                                                                );
                                                            }
                                                        )}
                                                    </ul>
                                                </React.Fragment>
                                            </div>
                                        )}
                                    </div>
                                    {/* .trip-details */}
                                </div>

                                <div className="row">
                                    <div className="price-details">
                                        <div className="price-go-return">
                                            <p>
                                                <Trans i18nKey="thanks_page.trips.going_trip_price">
                                                    سعر تذاكر الذهاب
                                                </Trans>
                                            </p>
                                            <div className="price fl-row">
                                                {departureSeats.length} x (
                                                <span className="value">
                                                    {
                                                        departureTrip.TripTotalPrice
                                                    }
                                                    ,00
                                                </span>
                                                <span className="currency">
                                                    <Trans i18nKey="common.currency">
                                                        جنيه
                                                    </Trans>
                                                </span>
                                                )
                                            </div>
                                        </div>
                                        {arrivalTrip && (
                                            <div className="price-go-return">
                                                <p>
                                                    <Trans i18nKey="thanks_page.trips.return_trip_price">
                                                        سعر تذاكر العودة
                                                    </Trans>
                                                </p>
                                                <div className="price fl-row">
                                                    {arrivalSeats.length} x (
                                                    <span className="value">
                                                        {
                                                            arrivalTrip.TripTotalPrice
                                                        }
                                                        ,00
                                                    </span>
                                                    <span className="currency">
                                                        <Trans i18nKey="common.currency">
                                                            جنيه
                                                        </Trans>
                                                    </span>
                                                    )
                                                </div>
                                            </div>
                                        )}
                                        {discount !== 0 && (
                                            <div className="price-go-return">
                                                <p>
                                                    <Trans i18nKey="thanks_page.discount">
                                                        الخصم
                                                    </Trans>
                                                </p>
                                                <div className="price fl-row">
                                                    <span className="value">
                                                        {discount}-
                                                    </span>
                                                    <span className="currency">
                                                        <Trans i18nKey="common.currency">
                                                            جنيه
                                                        </Trans>
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                        <div className="price-go-return">
                                            <div className="fl-row">
                                                <p class="total">
                                                    <Trans i18nKey="common.total">
                                                        الأجمالي
                                                    </Trans>
                                                    <span>
                                                        <Trans i18nKey="common.inc_tax_text">
                                                            جنيه شامل الضرائب
                                                        </Trans>
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="price fl-row">
                                                <span className="value">
                                                    {ticket.CardTotalPrice},00
                                                </span>
                                                <span className="currency">
                                                    <Trans i18nKey="common.currency">
                                                        جنيه
                                                    </Trans>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="user-data">
                                <div className="user-details">
                                    <img
                                        src={
                                            props.user.user.Pic_Url ||
                                            "/images/img_avatar.png"
                                        }
                                        alt=""
                                        className="user-img"
                                    />
                                    <div className="user-row">
                                        <div className="entry">
                                            <h5 className="label">
                                                <Trans i18nKey="common.fullname">
                                                    الأسم بالكامل
                                                </Trans>
                                            </h5>
                                            <p className="tp-text getting_name_data">
                                                {user.UserName}
                                            </p>
                                        </div>

                                        {beneficiary && (
                                            <div className="entry">
                                                <h5 className="label">
                                                    <Trans i18nKey="common.beneficiary_name">
                                                        إسم المستفيد
                                                    </Trans>
                                                </h5>
                                                <p className="tp-text">
                                                    {beneficiary.name}
                                                </p>
                                            </div>
                                        )}
                                    </div>

                                    <div className="user-row">
                                        <div className="entry">
                                            <h5 className="label">
                                                <Trans i18nKey="common.phone">
                                                    رقم المحمول
                                                </Trans>
                                            </h5>
                                            <p className="tp-text">
                                                {user.UserTelNo}
                                            </p>
                                        </div>
                                        {beneficiary && (
                                            <div className="entry">
                                                <h5 className="label">
                                                    <Trans i18nKey="common.beneficiary_phone">
                                                        رقم محمول المستفيد
                                                    </Trans>
                                                </h5>
                                                <p className="tp-text getting_phone_data">
                                                    {beneficiary.phone}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                    <div className="user-row">
                                        <div className="entry">
                                            <h5 className="label">
                                                <Trans i18nKey="common.email">
                                                    البريد الإلكتروني
                                                </Trans>
                                            </h5>
                                            <p className="tp-text getting_mail_data">
                                                {user.UserEmail}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="user-row">
                                        <div className="entry">
                                            <h5 className="label">
                                                <Trans i18nKey="thanks_page.booking_number">
                                                    رقم الحجز
                                                </Trans>
                                            </h5>
                                            <p className="tp-text">{card_id}</p>
                                        </div>
                                        <div className="entry">
                                            <h5 className="label">
                                                <Trans i18nKey="thanks_page.booking_date">
                                                    تاريخ اصدار الحجز
                                                </Trans>
                                            </h5>
                                            <p className="tp-text">
                                                {jobDate} {jobTime}
                                            </p>
                                        </div>
                                    </div>

                                    <PaymentMethod
                                        method={paymentMethod}
                                        totalPrice={ticket.CardTotalPrice}
                                    />

                                    <div className="protection">
                                        <p className="tp-text">
                                            <img
                                                src="/images/icons/protection.png"
                                                alt=""
                                            />
                                            {arrivalTrip ? (
                                                <Trans i18nKey="thanks_page.insurance_text_round">
                                                    الحجز يشمل تأمين كامل علي
                                                    رحلة الذهاب
                                                </Trans>
                                            ) : (
                                                <Trans i18nKey="thanks_page.insurance_text_oneway">
                                                    الحجز يشمل تأمين كامل علي
                                                    رحلة الذهاب و العودة
                                                </Trans>
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <TripInstructions paymentMethod={paymentMethod} />
                    </div>

                    <div className="row">
                        <div className="contact-info">
                            <h3>
                                <Trans i18nKey="thanks_page.complaints_inquiries.title">
                                    الأستعلام و الشكاوي
                                </Trans>
                            </h3>
                            <p>
                                <Trans i18nKey="thanks_page.complaints_inquiries.headline">
                                    يمكنا تقديم المساعدة والرد علي كل تسأولتكم
                                    من خلال موقع الشركة،
                                </Trans>
                                <Link to="/contact-us" target="_blank">
                                    <Trans i18nKey="thanks_page.complaints_inquiries.click_here">
                                        أظغط هنا.
                                    </Trans>
                                </Link>
                                <br />{" "}
                                <Trans i18nKey="thanks_page.complaints_inquiries.subline">
                                    أو الأتصال علي رقم ١٩٥٦٧
                                </Trans>
                            </p>
                            <div style={{ width: "100px" }}>
                                <img src="/images/go-bus-dark.png" alt="" />
                            </div>
                        </div>
                    </div>

                    <button className="printBtn" onClick={window.print}>
                        <Trans i18nKey="thanks_page.print_btn">طباعة</Trans>
                    </button>
                </div>
            </div>
        </MainLayoutComponent>
    );
};

function NoTicketFound(props) {
    return (
        <MainLayoutComponent>
            <div className="thanks-page">
                <div className="content">
                    <div
                        style={{
                            padding: "50px",
                            textAlign: "center",
                            height: "60vh",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column"
                        }}
                    >
                        <h4
                            style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                marginBottom: "20px"
                            }}
                        >
                            <Trans i18nKey="thanks_page.errors.verify_booking">
                                لم نتمكن من تأكيد هذا الحجز
                            </Trans>
                        </h4>
                        <p
                            style={{
                                fontSize: "15px",
                                fontWeight: "600",
                                marginBottom: "40px",
                                maxWidth: "500px"
                            }}
                        >
                            <Trans i18nKey="thanks_page.errors.not_confirmed_booking">
                                لم نتمكن من تاكيد تذكرتك ولذلك برجاء الضغط علي
                                زر "اعرض حجوزاتي" أدناه لمعرفة حالة حجزك او
                                الاتصال بخدمة العملاء علي 19567
                            </Trans>
                        </p>
                        <button
                            style={{ fontWeight: "bold" }}
                            className="btn btn-orange"
                            onClick={props.redirectToBookings}
                        >
                            <Trans i18nKey="thanks_page.back_button">
                                اعرض حجوزاتي
                            </Trans>
                        </button>
                    </div>
                </div>
            </div>
        </MainLayoutComponent>
    );
}

function NoTransaction(props) {
    return (
        <MainLayoutComponent>
            <div className="thanks-page">
                <div className="content">
                    <div
                        style={{
                            width: "80vw",
                            height: "60vh",
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            flexDirection: "column"
                        }}
                    >
                        <h4 style={{ fontSize: "20px", fontWeight: "bold" }}>
                            <Trans i18nKey="thanks_page.errors.incomplete_booking">
                                حجز غير مكتمل
                            </Trans>
                        </h4>
                        <button
                            style={{ fontWeight: "bold" }}
                            className="btn btn-orange"
                            onClick={props.redirectToBookings}
                        >
                            <Trans i18nKey="thanks_page.back_button">
                                الحجوزات
                            </Trans>
                        </button>
                    </div>
                </div>
            </div>
        </MainLayoutComponent>
    );
}

function PaymentMethod({ method, totalPrice }) {
    const cardNumber = useRef(store2.get("card_number"))
    const images = useRef({
        meeza: "/images/payments/meeza.png",
        mpgs: "/images/mastercard.png"
    });

    if (totalPrice === 0) {
        return null;
    }

    if (["mpgs", "meeza"].includes(method)) {
        return (
            <div className="user-row">
                <div className="entry">
                    <h5 className="label">
                        <Trans i18nKey="thanks_page.payment_method">
                            طريقة الدفع
                        </Trans>
                    </h5>
                    <p className="tp-text">
                        <img src={images.current[method]} alt="" />{" "}
                        <h4>{cardNumber.current}</h4>
                    </p>
                </div>
            </div>
        );
    }
    return (
        <div className="user-row">
            <div className="entry payment-methods-thx">
                <h5 className="label">
                    <Trans i18nKey="thanks_page.payment_method">
                        طريقة الدفع
                    </Trans>
                </h5>
                <p className="tp-text">
                    {method === "cash" ? (
                        <React.Fragment>
                            <img src="/images/aman.png" alt="imae" />{" "}
                            <img src="/images/fawry.png" alt="" />{" "}
                            <img
                                src="/images/payments/bee-logo.jpg"
                                alt=""
                            ></img>
                            <img
                                src="/images/payments/momken.png"
                                alt="momken"
                            />
                        </React.Fragment>
                    ) : (
                        <img src="/images/visa-mastercard" alt="" />
                    )}
                </p>
            </div>
            {method === "cash" && (
                <div className="entry">
                    <h5 className="label">
                        <Trans i18nKey="thanks_page.cash.payment_code">
                            كود الدفع
                        </Trans>
                    </h5>
                    <p className="tp-text">{store2.get("fawry_job_id")}</p>
                </div>
            )}
        </div>
    );
}

export default connect(matchStateToProps, mapDispatchToProps)(Thanks);
