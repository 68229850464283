import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getPoints, usePoints } from "../../Actions/UserActions";
import { Trans } from 'react-i18next';
import i18next from 'i18next';
import clsx from "clsx";
import store2 from "store2"

import * as TripActions from "../../Actions/TripActions";
import { useCheckoutStore } from "../../new-store/checkoutStore";
import { PaymentMethods } from "../Booking/PaymentComponent";


class PointsComponent extends Component {

	render() {
        const { paymentMethod, isRoundTrip, pricing } = useCheckoutStore.getState();
        // const canUsePoints = pricing.totalPrice >= 10;

        if (paymentMethod === PaymentMethods.cash || isRoundTrip) {
            return null;
        }

		return (
			<div
				className={
					this.props.applyingPromoCode
						? "points-component component-loader"
						: "points-component"
				}>
				{this.renderPoints()}
			</div>
		);
	}

	renderPoints = () => {
        const { points, usePoints, payment } = this.props;
        const classes = clsx(["tickets-component-con ticketItem", points.ploader && "component-loader"])
        if(payment === "Aman"){
            return (
				<div className="ticketItem">
					<div className="row">
						<p className="text-red"><Trans i18nKey="cart.points.not_allowed">لا يمكن استخدام النقاط مع وسائل الدفع الكاش</Trans></p>
					</div>
				</div>
			);
        }
        else{
            if (points.total < 100) {
                return (
                    <div className="ticketItem">
                        <div className="row">
                            <p><Trans i18nKey="cart.points.no_points">لا يوجد نقاط</Trans></p>
                        </div>
                    </div>
                );
            } else
            if (points.usable && !points.amount) {
              
                return (
                    <div className={classes}>
                        <div className="row">
                            <p className="text-bold"><Trans i18nKey="cart.points.points_balance">رصيد النقاط {{points: points.usable}}</Trans> <Trans i18nKey="cart.points.point">نقطة</Trans></p>
                            {/* <p><Trans i18nKey="cart.points.get_discount" values={{amount: (points.usable/100 * 5) + " " + i18next.t('common.currency', 'جنيه')}}>احصل على خصم {points.usable/ 100 * 5}</Trans></p> */}
                        </div>
                        <div className="row">
                            {/* <p className="text-bold text-green">{points.usable} <Trans i18nKey="cart.points.point">نقطة</Trans></p> */}
                            <p className="text-bold text-green"><Trans i18nKey="cart.points.get_discount" values={{amount: (points.usable/100 * 5) + " " + i18next.t('common.currency', 'جنيه')}}>احصل على خصم {points.usable/ 100 * 5}</Trans></p>
                            <button class="addCode" onClick={() => usePoints(points.usable)} style={{width: 'unset'}}>
                                <label><Trans i18nKey="cart.points.get_discount_btn">أخصم الاَن</Trans></label>
                            </button>
                        </div>
                    </div>
                )
            } else
            if (points.amount) {
                return (
                    <div className={classes}>
                        <div className="row">
                            <p className="text-bold text-green"><Trans i18nKey="cart.points.points_program_discount">خصم برنامج النقاط</Trans></p>
                            <div className="points-disc">
                                <p className="text-bold text-green">
                                    {points.amount/ 100 * 5} EGP 
                                </p>
                                <button class="close" onClick={() => usePoints(0)}>
                                    <i class="material-icons">close</i>
                                </button>
                            </div>
                        </div>
                    </div>
                )
            }
        }
        
	};
}

const mapStateToProps = state => ({
	points: state.user.points
});

const mapDispatchToProps = dispatch => ({
	getPoints: bindActionCreators(getPoints, dispatch),
	usePoints: bindActionCreators(usePoints, dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PointsComponent);
