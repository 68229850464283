import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

import { getRoutes } from "../../Actions/CorporateActions";
import { setStationIDs } from "../../Actions/SearchActions";
import { removeGoingTrip } from "../../Actions/TripActions";
import Utils from "../../Helpers/Utils";
import { Trans } from 'react-i18next';

class FeaturedRoutes extends Component {
  componentWillMount() {
    !this.props.routes && this.props.getRoutes();
  }
  
  getContent = (options = { defaultReturn: {} }) => {
    const routes = this.props.routes || {};
    const { lang } = this.props;
    // translated data & undefined safety object
    return routes[lang]? routes[lang]: routes['ar'] || options.defaultReturn;
  }

  render() {
    const { max, withFilters } = this.props;

    const routes = this.getContent();

    let filteredRoutes = [];
    if (!routes) return null;
    if (routes.length < 1) return null;

    if(withFilters) {
      routes.forEach(r => {
        if(r.acf.image)
          filteredRoutes.push(r);
      });
    } else {
      filteredRoutes = routes
    }

    if(!Array.isArray(filteredRoutes)) {
      return null;
    }

    return (
      <div className="featrured-routes">
        <div className="section-title">
            <h3><Trans i18nKey="home_page.featured_routes.headline">خطوط سفر أتوبيسات جوباص</Trans></h3>
            <p><Trans i18nKey="home_page.featured_routes.subline">أكثر من  20 خط لنقل الركاب في جمهورية مصر العربية</Trans></p>
        </div>
        <div className="routes-links">
            <div className="container">
                <div className="links">
                {filteredRoutes.map((route, count) => {
                  if (count >= max) return null;
                  return (
                    <Route
                      key={route.id}
                      onClick={() => {
                        this.props.removeSelectedTrips();
                        this.props.setStationIDs({
                          departure: route.acf.from,
                          arrival: route.acf.to,
                        });
                        Utils.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                      href={`/search?arrivalStation=${route.acf.to}&departureStation=${route.acf.from}`}
                      label={route.acf.label}
                    />
                  );
                })}
                </div>
            </div>
        </div>
      </div>
    );
  }
}

export const Route = props => {
  let { style, href, label, onClick } = props;
  return (
    <Link to={href} className="route-links" onClick={onClick}>
      {label}
    </Link>
    // <a className="route-links" onClick={onClick} href={href}>
    //     {label}
    // </a>
  );
};

function mapStateToProps(store) {
  return {
    lang: store.locale.lang,
    routes: store.corporate.routes
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getRoutes: bindActionCreators(getRoutes, dispatch),
    setStationIDs: bindActionCreators(setStationIDs, dispatch),
    removeSelectedTrips: bindActionCreators(removeGoingTrip, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeaturedRoutes);
