// Libraries
import React from 'react';

const SuccessComponent = (props) => {
    let {msg, page, state, animated} = props;

    if(page === "tickets" || animated) {
        return (
            <div className={state? "success-component" : "success-component height0"}>
                <p>{msg}</p>
            </div>
        )
    }

    return (
        <React.Fragment> 
            <div className="success-component">
                <p>{msg}</p>
            </div>
        </React.Fragment> 
    );
}

export default SuccessComponent;

