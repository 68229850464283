import InfoApi from '../Api/InfoApi';
import * as actionTypes from './ActionTypes';

import store2 from 'store2';
import Store from '../Store';
import { resetTripsState } from './TripActions';
import { usePoints } from './UserActions';

/***************************** Actions ********************** */

/**
 * Change Transporation type
 * @param {string} transportation 
 * @author Ezzat
 */
export function changeTransportationType(transportation){
    return {
        type:actionTypes.CHANGE_TRANSPORTATION_TYPE,
        transportation
    }
}

export function updateSearchParams(searchParams){
    Store.dispatch(resetTripsState());
    Store.dispatch(usePoints(0));
    return {
        type: actionTypes.UPDATE_SEARCH_PARAMS,
        searchParams
    }
}

export function setStationIDs({departure, arrival}) {
    return {
        type: actionTypes.SET_STATION_IDS,
        departureId: departure,
        arrivalId: arrival
    }
}

export function setDate(date, type) {
    const data = {};
    if (type === 'arrival') {
        data.arrivalDate = date;
    } else if (type === 'departure') {
        data.departureDate = date;
    } else return;
    
    return {
        type: actionTypes.SET_DATE,
        data
    }
}

function getStationMethod(dispatch){
   return InfoApi.stations().then( res => {
        let stations = res.map(s => {
            s.value = s.Code
            s.label = s.NameA
            return s
        })
        dispatch(handle_getting_statiosn(stations))
        // the order is important here, we alwayes check for the departure station
        // so when departure station data is set and we continue on 
        // arrival station is not set and causes crashes
        dispatch(handle_setting_arrival_station(stations)) 
        dispatch(handle_setting_departure_station(stations))
    }).catch(err => {
        console.log('[[[ERROR]]] - getStations throwing error');
        throw err;
    })
}
/**
 * get Stations
 * @author Ezzat
 */
export function getStations(){  
    return async (dispatch) => {
        return InfoApi.stations()
            .then(res => {
                let stations = res.map(s => {
                    s.value = s.Code;
                    s.label = s.NameA;
                    return s;
                });
                dispatch(handle_getting_statiosn(stations));
                // the order is important here, we alwayes check for the departure station
                // so when departure station data is set and we continue on
                // arrival station is not set and causes crashes
                dispatch(handle_setting_arrival_station(stations));
                dispatch(handle_setting_departure_station(stations));
            })
            .catch(err => {
                console.log("[[[ERROR]]] - getStations throwing error");
                console.log(err)
                // throw err;
            });
    }
}


/**************************** Handlers ******************************/
/**
 * Getting the stations and setting them
 * @param {array} stations 
 * @author Ezzat
 */
export function handle_getting_statiosn(stations) {
    return {
        type: actionTypes.GET_STATIONS,
        stations
    }
}


/**
 * Set the default departure Station
 * @author Ezzat
 */
export function handle_setting_departure_station(stations) {
    return {
        type: actionTypes.SET_DEFAULT_DEPARTURE_STATION,
        stations
    }
}

/**
 * Set the default arrival Station
 * @author Ezzat
 */

export function handle_setting_arrival_station(stations) {
    return {
        type: actionTypes.SET_DEFAULT_ARRIVAL_STATION,
        stations
    }
}
