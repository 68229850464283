import { scrollSpeed } from "../appConfig";
import qs from "query-string";
import _ from 'lodash';
import crypto from 'crypto';

let scroller = null;
if(process.title === "browser") {
  scroller = require('react-scroll-to-component');
}

export default class Utils {
  static encryption = {
    algorithm: 'aes-256-cbc',
    key: 'kGp4qrSujBuYEUMZR9C6YPVSZEEGFVxa'
  }

  static hijackAllLogs() {
    console.log=function(){return;}
    console.error=function(){return;}
    console.warn=function(){return;}
  }

  static hijackReduxDevTools() {
    if(window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__.disconnect) {
      window.__REDUX_DEVTOOLS_EXTENSION__.disconnect();
      window.__REDUX_DEVTOOLS_EXTENSION__.connect = function(){return;}
    }
  }

  static encodeUtf8(s) {
    return unescape(encodeURIComponent(s));
  }

  static decodeUtf8(s) {
    return decodeURIComponent(escape(s));
  }

  static htmlEntitiesFix(string) {
    return (string+"").replace(/&#\d+;/gm,function(s) {
      return String.fromCharCode(s.match(/\d+/gm)[0]);
    })
  }

  static encrypt(str) {
    const key = Utils.encryption.key;
    const iv = crypto.randomBytes(16);

    const cipher = crypto.createCipheriv(Utils.encryption.algorithm, Buffer.from(key), iv);
    let encrypted = cipher.update(typeof str === 'string'? str: JSON.stringify(str));
    encrypted = Buffer.concat([encrypted, cipher.final()]);
    return { iv: iv.toString('hex'), data: encrypted.toString('hex') }
  }

  static decrypt(data, iv) {
    const key = Utils.encryption.key;
    iv = Buffer.from(iv, 'hex');
    const encrypted = Buffer.from(data, 'hex');
    const decipher = crypto.createDecipheriv(Utils.encryption.algorithm, Buffer.from(key), iv);
    let decrypted = decipher.update(encrypted);
    decrypted = Buffer.concat([decrypted, decipher.final()]);

    try {
      decrypted = JSON.parse(decrypted);
      return decrypted;
    } catch (error) {
      console.error(error);
      return;
    }
  }

  static SessionStorage = {
      set: (key, value) => {
        if(window.sessionStorage) {
          let _val = null;
          if(typeof value === "object")
            _val = JSON.stringify(value);
          else if(typeof value === "string")
            _val = value;
          else console.error(`Type of value is not allowed ${value} typeof: ${typeof value}`)
          window.sessionStorage.setItem(key, _val);
        }
      },
      get: (key) => {
        return window.sessionStorage.getItem(key);
      }
  }
  /**
   *
   * @param {Array} a - first array.
   * @param {Array} b - second array.
   * @description compares a values to b
   */
  static compareArrays(a, b) {
    let similar = true;

    for (let val in a) {
      if (!b.includes(a[val])) {
        similar = false;
      }
    }

    return similar;
  }

  static URLParams = {
    remove: (key) => {
      const currentParams = qs.parse(window.location.search);
      if (key in currentParams) {
        // remove lang param from url
        delete currentParams[key];

        let newUrl = window.location.pathname;
        if (Object.keys(currentParams).length > 0) {
          newUrl += "?" + qs.stringify(currentParams);
        }
        window.history.replaceState(null, null, newUrl);
      }
    },
    add: (key, value) => {
      const currentParams = qs.parse(window.location.search);
      delete currentParams[key];
      currentParams[key] = value;
      const newUrl = window.location.pathname + "?" + qs.stringify(currentParams);
      window.history.replaceState(null, null, newUrl);
    }
  }
  
  /**
   * 
   * @param {React.KeyboardEvent<HTMLInputElement>} evt 
   */
  static isNumberKey(evt){
    if(isNaN(Number(evt.key))) evt.preventDefault()
  }

  /**
   * 
   * @param {String} symbol 
   * @param {String} str 
   */
  static sliceAfter(symbol, str) {
    return str.slice(str.lastIndexOf(symbol) + 1, str.length)
  }

  /**
   * 
   * @param {String} symbol 
   * @param {String} str 
   */
  static sliceBetween(symbol, str) {
    if(symbol.length !== 2) {
      return str;
    }

    let subsym = symbol.split('');
    return str.substring(str.indexOf(subsym[0]) + 1, str.indexOf(subsym[1]));
  }

  /**
   *
   * @param {String} groupParam A key to group the elements with its value
   * @description Groups an array of objects with specific key value
   * @returns Object of arrays
   */
  static groupBy(array, groupParam) {
    let groupedObject = {};

    array.forEach(el => {
      if (!groupedObject[el[groupParam]]) groupedObject[el[groupParam]] = [];

      groupedObject[el[groupParam]].push(el);
    });

    return groupedObject;
  }

  static isValidPhone(number) {
    //const re = /^0\d{2}\d{8}$/;
    const re = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/

    if (!number) return false;

    let result = number.match(re);

    if (result) {
      return result[0] && result[0].length === number.length;
    }

    return false;
  }

  static isValidEmail(email) {
    // const re = /([\w\d._-])*[@]([\w]*[.][\w]*)*$\b/;
    const re = /^\w+([\.-]+?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/;

    if (!email) return false;

    let matches = email.match(re);

    if (matches) {
      if (email.length === matches[0].length) return true;
      else return false;
    }

    return false;
  }


  /**
   * 
   * @param {ScrollToOptions} options 
   */
  static scrollTo(options, y) {
    // return if it's not a browser or scrollTo is not defined
    if(process.title !== "browser") return;

    let params = null;
    if(typeof y === "number" || typeof y === "string") {
      params = {
        top: options,
        left: y
      }
    } else {
      params = options;
    }

    try {
      window.scrollTo(params);
    } catch (err) {
      window.scrollTo(params.top, params.left || 0);
    }
  }

  /**
   * 
   * @param {string} str - source string
   * @param {string=} sym - optional symbol - defaults to space
   */
  static initialWord(str, sym) {
    if(!sym) sym = " ";
    let idx = str.indexOf(sym);
    return str.slice(0, idx > -1? idx : str.length)
  }

  static scrollToElement(element, alignment = 'top', offset = 0) {
    if (!element) return;

    if(scroller) {
      scroller(element, {
        align: alignment,
        offset,
        duration: scrollSpeed
      });
    } else {
      let scrollElement = null;
  
      if(document.scrollingElement) {
        scrollElement = document.scrollingElement;
      } else {
        scrollElement = document.querySelector('html');
      }

      const rect = element.getBoundingClientRect();
      const scrollOpts = { top: scrollElement.scrollTop + rect.top - 100, behavior: "smooth" }
      window.scroll(scrollOpts);
    }
  }

  static isPointInRect(px, py, rx, ry, rw, rh) {
    return rx <= px && px <= rx + rw &&
               ry <= py && py <= ry + rh;
  }

  /**
   * 
   * @param {any} retObj - data to return after timeout
   * @param {Number} after - returns retObj after this duration
   * @description Simulates a promise result
   */
  static simulate(retObj, after) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
          resolve(retObj)
      }, after);
    })
  }

  static delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }
}


export function isGoMini({ tripsStore, serviceName }) {
  const goMiniClasses = ["GoMini", "GoMini.", "MiniGo10", "GoMini14"];
  let goingClass;
  let returnClass;

  if (tripsStore) {
    goingClass = _.get(tripsStore, 'goingTrip.TripServKind_NameE');
    returnClass = _.get(tripsStore, 'returnTrip.TripServKind_NameE');
    return goMiniClasses.includes(goingClass) || goMiniClasses.includes(returnClass);
  } else
  if (serviceName) {
    return goMiniClasses.includes(serviceName);
  }
}
