import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import Utils from "../../Helpers/Utils";
import { Trans } from 'react-i18next';
import { connect } from 'react-redux'


class ProfileNavigationComponent extends Component {
  constructor(props) {
    super(props);

    this.navLinks = [
      { route: "/profile/edit", i18nKey: 'header.profile_menu.account_details', displayName: "بيانات حسابي" },
      {
        route: "/profile/tickets",
        i18nKey: 'header.profile_menu.bookings',
        subroute_regx: /\/SingleBooking\/\d+/g,
        displayName: "حجوزاتي السابقة"
      },
      { route: "/profile/change-password", i18nKey: 'header.profile_menu.change_password', displayName: "تعديل كلمة المرور" },
      { route: "/logout", i18nKey: 'header.profile_menu.logout', displayName: "خروج", onclick: props.logout }
    ];
  }

  checkActive = (route, subroute_regx, match, location) => {
    if (!location) return false;
    const { pathname } = location;
    if (!match && subroute_regx) return pathname.match(subroute_regx) !== null;
    return route === pathname;
  };

  render() {

    const { showPointsProgram } = this.props;
    return (
      <div
        className={
          this.props.active
            ? "profile-navigation-component active"
            : "profile-navigation-component"
        }
      >
        {this.props.arrowTop && <div className="square rotate45" />}
        {showPointsProgram && <ul class="points">
          <li class="">
            <span className="text"><Trans i18nKey="header.profile_menu.points_program_balance">رصيد برنامج النقاط</Trans></span>
            <span className="box">{this.props.totalPoints}</span>
          </li>
        </ul>}
        <ul>
          {this.navLinks.map(nav => {
            return (
              <li
                key={nav.route}
                className={
                  nav.route === this.props.currentPage ? "selected" : ""
                }
                onClick={nav.onclick}
              >
                {!nav.onclick ? (
                  <NavLink
                    to={nav.route}
                    isActive={(match, location) =>
                      this.checkActive(
                        nav.route,
                        nav.subroute_regx,
                        match,
                        location
                      )
                    }
                    onClick={() => {
                      // enable scrolling after user clicks a link
                      document.body.style.overflow = "unset"
                      Utils.scrollTo({ top: 0, behavior: "smooth" })
                    }}
                  >
                    <Trans i18nKey={nav.i18nKey}>{nav.displayName}</Trans>
                  </NavLink>
                ) : (
                  <a href="javascript:void(0)"><Trans i18nKey="header.profile_menu.logout">خروج</Trans></a>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: state.locale,
  totalPoints: state.user.points.total
})

export default connect(mapStateToProps)(withRouter(ProfileNavigationComponent));
