import axios from 'axios';
import { baseApi } from './Base';

import store2 from 'store2';
import apiErrorHandler from './apiErrorHandler';

const token = store2.get('token')
class InfoApi {

    static stations() {
        return axios.get(baseApi + '/Stations?companyID=1')
            .then(res => {
                return res.data;
            }).catch(err => {
                apiErrorHandler(err);
                throw err;
            });
    }

}
export default InfoApi;