import i18next from 'i18next';

const initialState = {
  lang: i18next.language || 'ar'
}

// initial locale classname
document.body.classList.add(initialState.lang === 'ar'? 'rtl': 'ltr');

export default function LocaleReducer(state = initialState, action) {
  switch(action.type) {
    case localeActions.changeLocale: 
      return {
        ...state,
        lang: action.data
      }

    default: return state;
  }
}

export const localeActions = {
  changeLocale: "locale/CHANGE_LOCALE"
}