import { useCheckoutStore } from "../new-store/checkoutStore";

const __ENV__ = process.env.REACT_APP_API;
class FBBase {
  static isSafe() {
    return typeof window.fbq === "function";
  }
}

class Custom extends FBBase {
  static AddToCart({ id, value, from, to }) {
    if (__ENV__ !== "production") {
      return;
    }
    if (this.isSafe()) {
      window.fbq("trackCustom", "CustomAddToCart", {
        id,
        value,
        from,
        to
      });
    }
  }
}

export default class FacebookPixel extends FBBase {
  static Custom = Custom;

  static InitiateCheckout({
    event_id,
    user_data,
    content_category,
    content_ids,
    value,
    from,
    to
  }) {
    if (__ENV__ !== "production") {
      return;
    }
    if (FacebookPixel.isSafe()) {
      window.fbq("track", "InitiateCheckout", {
        event_id,
        user_data,
        currency: "EGP",
        content_type: "product",
        content_category,
        content_ids,
        value,
        from,
        to
      });
    }
  }

  /**
   *
   * @param {string} search_string
   * @param {string} from
   * @param {string} to
   */
  static Search(event_id, user_data, search_string, from, to) {
    if (__ENV__ !== "production") {
      return;
    }
    if (FacebookPixel.isSafe()) {
      window.fbq("track", "Search", { event_id, user_data, search_string, from, to });
    }
  }

  static AddToCart({ content_ids, user_data, content_name, value, from, to }) {
    if (__ENV__ !== "production") {
      return;
    }
    if (FacebookPixel.isSafe()) {
      window.fbq("track", "AddToCart", {
        content_type: "product",
        currency: "EGP",
        content_name,
        user_data,
        content_ids,
        value,
        from,
        to
      });
    }
  }

  /**
   *
   * @param {number} value
   * @param {any} products
   * @param {"EGP"} currency
   */
  static Purchase(event_id, user_data, value, contents) {
    if (__ENV__ !== "production") {
      return;
    }
    if (FacebookPixel.isSafe()) {
      window.fbq("track", "Purchase", {
        event_id,
        user_data,
        value,
        currency: "EGP",
        contents,
        content_type: "product"
      });
    }
  }

  static sendAddToCartPixel() {
    const store = useCheckoutStore.getState();
    let CardID = store.cartId;

    let goingTrip = store.goingTripInfo;
    let returningTrip = store.returnTripInfo;

    let passengersNo = store.passengersNo;
    let totalPrice = 0,
      goingPrice = 0,
      returningPrice = 0;
    const content_ids = [String(goingTrip.TripSubData_Id)];

    if (returningTrip) {
      goingPrice = (passengersNo * goingTrip.TripPrice_GoCome) / 2;
      returningPrice =
        (passengersNo * returningTrip.TripPrice_GoCome) / 2;
      totalPrice = goingPrice + returningPrice;
      content_ids.push(String(returningTrip.TripSubData_Id));
    } else {
      goingPrice = passengersNo * goingTrip.TripPrice;
      totalPrice = passengersNo * goingTrip.TripPrice;
    }

    let cart = {
      id: CardID,
      value: totalPrice,
      content_ids: content_ids,
      from: goingTrip.TripOfficeFrom_NameE,
      to: goingTrip.TripOfficeTo_NameE
    };

    FacebookPixel.AddToCart(cart);
  }

  static sendCheckoutPixel() {
    const store = useCheckoutStore.getState();
    //get final checkout data
    let CardID = store.cartId;
    let goingTrip = store.goingTripInfo;
    let returningTrip = store.returnTripInfo;

    let passengersNo = store.passengersNo;
    let totalPrice = 0,
      goingPrice = 0,
      returningPrice = 0;
    let ticket_type;
    const content_ids = [String(goingTrip.TripSubData_Id)];

    if (returningTrip) {
      goingPrice = (passengersNo * goingTrip.TripPrice_GoCome) / 2;
      returningPrice =
        (passengersNo * returningTrip.TripPrice_GoCome) / 2;
      totalPrice = goingPrice + returningPrice;
      content_ids.push(String(returningTrip.TripSubData_Id));
      ticket_type = "going_returning_ticket";
    } else {
      goingPrice = passengersNo * goingTrip.TripPrice;
      totalPrice = passengersNo * goingTrip.TripPrice;
      ticket_type = "going_ticket";
    }

    let checkData = {
      id: CardID,
      value: totalPrice,
      content_ids: content_ids,
      content_category: ticket_type,
      from: goingTrip.TripOfficeFrom_NameE,
      to: goingTrip.TripOfficeTo_NameE
    };
    FacebookPixel.InitiateCheckout(checkData);
  }
}
