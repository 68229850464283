export default function debug(tag, msg) {
    if(process.env.REACT_APP_API === "production") return;
    let style = `
        background: #222;
        border-radius: 5px;
        padding-right: 5px;
        font-weight: bold;
        font-size: 12px;
        color: white;
    `;

    console.log("%c " + tag, style, msg);
}