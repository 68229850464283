import React, { Component } from "react";
import Helmet from "react-helmet";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SideBar from "../SideBar";
import * as CorporateActions from "../../Actions/CorporateActions";

// Componnets
import MainLayoutComponent from "../MainLayoutComponent";
import { buildYoastMetaTags } from "../../Helpers/Yoast";
import { Trans } from "react-i18next";
import Utils from "../../Helpers/Utils";

function mapStateToProps(store) {
  return {
    lang: store.locale.lang,
    corpStore: store.corporate
  };
}

function mapDispatchToProps(dispatch) {
  return {
    corporateActions: bindActionCreators(CorporateActions, dispatch)
  };
}

class NBEComponent extends Component {
  /**
   * get the nbe page if only not requsted before: minimize number of requests
   * @author Kareem Gehad
   * @revised Ezzat
   */
  componentWillMount() {
    !this.props.corpStore.nbe && this.props.corporateActions.getNBE();
  }

  getContent = (options = { defaultReturn: {} }) => {
    const nbe = this.props.corpStore.nbe || {};
    const { lang } = this.props;
    // translated data & undefined safety object
    return nbe[lang]? nbe[lang]: nbe['ar'] || options.defaultReturn;
  }

  onSubmitHandler = (e) => {
    e.preventDefault();
   this.props.history.push('/nbe-thanks')
   Utils.scrollTo({ top: 0, behavior: "smooth" })
  }
  renderNBE() {

    const content = this.getContent();

    let title = content.acf && content.acf.title;
    let description = content.acf
      ? content.acf.description
      : null;
    let DescktopBanner = content.acf.desktop_banner;
    let MobileBanner = content.acf.mobile_banner;
    let FormTitle = content.acf.form_title;
    let FormSteps = content.acf.form_steps;
    let TermsTitle = content.acf
      ? content.acf.terms_title
      : null;
    let Terms = content.acf.terms;
    return (
      <>
        <div className="page-content nbe-page">
            <div className="container">
                <h1>{title}</h1>
                <div
                  className="description"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
                <img src={DescktopBanner} alt="NBE GoBus Banner" className="banner hide-mob"/>
                <img src={MobileBanner} alt="NBE GoBus Banner" className="banner show-mob"/>
                <div className="form-box">
                    <div className="text">
                        <h2>{FormTitle}</h2>
                        <ul>
                            {FormSteps.map(step => <li>{step.item}</li>)}
                            <li>ادخل طريقة الدفع المناسبة لك <a href="/terms" target="_blank">تطبق الشروط والاحكام*</a></li>
                        </ul>
                    </div>
                    <form onSubmit={this.onSubmitHandler}>
                        <div className="input-field">
                            <label>الأسم</label>
                            <input
                            type="text"
                            name="name"
                            // onChange={setName.bind(this)}
                            />
                        </div>

                        <div className="input-field">
                            <label>البريد الإلكتروني</label>
                            <input 
                                type="email" 
                                name="email"
                                // onChange={setEmail.bind(this)}
                            />
                        </div>

                        <div className="input-field">
                            <label>رقم المحمول</label>
                            <input type="tel" name="phone"
                                // onChange={setMobile.bind(this)}
                            />
                        </div>
                        <button
                            type="submit"
                            className="btn btn-new-acc"
                        >
                            <Trans i18nKey="common.logging">تسجيل</Trans>
                        </button>
                    </form>
                </div>

                <div className="terms">
                    <h3>{TermsTitle}</h3>
                    <ul>
                        {Terms.map(term => <li>{term.item}</li>)}
                        {/* <li>العرض صالح حتى <strong>(تاريخ نهاية العرض)</strong> على أن يكون السفر قبل تاريخ <strong>(التاريخ ان وجد)</strong>.</li> */}
                    </ul>
                </div>
            </div>
        </div>
      </>
    );
  }

  render() {
    const nbe = this.getContent({ defaultReturn: { title: {} } });

    return (
      <MainLayoutComponent page={this.props.location.pathname}>
        <Helmet key={window.location.href} >
          <title>{nbe.title.rendered || "nbe | Go Bus"}</title>
          {this.props.corpStore.nbe &&
            buildYoastMetaTags(nbe.yoast_meta).map(tag => <meta {...tag} />)}
        </Helmet>
        {this.props.corpStore.nbe && this.renderNBE()}
      </MainLayoutComponent>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NBEComponent);
