import React from 'react';

// Components 
import ErrorComponent from '../../Utils/ErrorComponent'
import { Trans } from 'react-i18next';


const RegisterForm = (props) => {
    let {toggleLoginForm, setEmail, setPassword, setMobile, setName,register,  hasError,  errorMessage} = props

    return(
        <form className="sign-form active" onSubmit={register}>
            <h4><Trans i18nKey="auth.register.title">حساب مستخدم جديد</Trans></h4>

            <h4>
                {(hasError)   &&
                    <ErrorComponent 
                        errors={errorMessage}
                    />
                }    
            </h4>
            
            <div className="input-field">
                <label><Trans i18nKey="common.fullname">الأسم بالكامل</Trans></label>
                <input type="text" name="name" 
                    onChange={setName.bind(this)}
                />
            </div>

            <div className="input-field">
                <label><Trans i18nKey="common.phone">رقم المحمول</Trans></label>
                <input type="tel" name="phone"
                    onChange={setMobile.bind(this)}
                />
            </div>

            <div className="input-field">
                <label><Trans i18nKey="common.email">البريد الإلكتروني</Trans></label>
                <input type="email" name="email"
                    onChange={setEmail.bind(this)}
                 />
            </div>

            <div className="input-field">
                <label> <Trans i18nKey="common.password">كلمة السر</Trans></label>
                <input type="password"
                    onChange={setPassword.bind(this)}
                 />
            </div>
            <button type="button" className="btn btnSubmit" onClick={register} ><Trans i18nKey="auth.register.register_btn">إنشاء حساب جديد</Trans></button>
            <div className="login-foot">
                <button
                    type="button"
                    className="btn btn-new-acc"
                    onClick={() => { toggleLoginForm(); }} >
                    <i className="material-icons">account_box</i>
                    <Trans i18nKey="auth.register.login_btn">تسجيل الدخول</Trans>
                </button>
            </div>
        </form>
    )
}
export default RegisterForm


