import React, { Component } from "react";
import { Link } from "react-router-dom";

class FeaturedDiscount extends Component {
  copyToClipboard = () => {
    const el = document.createElement("textarea");
    el.value = this.coupon.innerText;
    document.body.appendChild(el);
    document.body.style.overflow = "hidden";
    el.select();
    document.body.style.overflow = "unset";
    document.execCommand("copy");
    document.body.removeChild(el);

    let span = document.createElement("span");
    span.classList.add("copy-span");
    this.couponContainer.appendChild(span);
    span.innerText = "COPIED!";
    setTimeout(() => {
      span.classList.add("active");
    }, 100);

    setTimeout(() => {
      this.couponContainer.removeChild(span);
    }, 1200);
  };

  render() {
    let {
      title,
      label,
      name,
      description,
      code,
      booking_button
    } = this.props;

    return (
      <div className="discound">
        <h4>{title}</h4>
        <div
          className="discound-component"
          ref={ref => (this.couponContainer = ref)}
        >
          {/* <i className="material-icons">bookmark</i> */}
          <i className="fas fa-bookmark"></i>
          <span>{label}</span>
          <h5>{name}</h5>
          <span>{description}</span>
          <span onClick={this.copyToClipboard}>COPY COUPON</span>
          {/* <span className="copy-span active">COPIED!</span> */}
          <h6 ref={ref => (this.coupon = ref)} onClick={this.copyToClipboard}>
            {code}
          </h6>
          {booking_button &&
          <Link className="btn" to='/'>إحجز الآن</Link>
          }
        </div>
      </div>
    );
  }
}

export default FeaturedDiscount;
