import React, { Component } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { withRouter } from "react-router-dom";

// Components
import SideBar from "../../Pages/Account/SideBar";
import MainLayoutComponent from "../../MainLayoutComponent";
import { getTickets, preLogin, logout, isLoggedIn } from "../../../Actions/UserActions";
import FullCancelTicket from "./FullCancelTicket";
import config from "../../../appConfig";
import { cancelSuccess, clearCancel } from "../../../Actions/TripActions";
import debug from "../../../Helpers/DebugLog";
import Utils from "../../../Helpers/Utils";
import { Trans } from "react-i18next";
import i18next from "i18next";
import { tripTimeSegment } from "../../../Helpers/TimeParser";
import CancelSeatsModal from "./CancelSeatsModal";
//import NA from "node-auth-api";
import Store from '../../../Store';
import { ApiCacher } from "../../../Api/apiCacher";


class SingleBooking extends Component {
	constructor(props) {
		super(props);

		this.cardId = this.props.match.params.id;
		this.state = {
			isActiveAction: false,
			isActiveFullCancellation: false,
			isActiveSeatCancellation: false
		};
	}

	componentDidMount() {
		// this.props.preLogin();
		Utils.scrollTo({ top: 0, behavior: "smooth" });
		if (process.title === "browser") {
			if (!this.props.tickets) {
				this.props
					.getTickets()
					.then(() => {
						setTimeout(() => {
							if (this.parent)
								this.parent.classList.add("active");
						}, 100);
					})
					.catch(err => { });
			} else {
				if (this.parent) this.parent.classList.add("active");
			}
		}
		// let ticket = this.props.tickets.filter(t => t.CardID === this.cardId);
		// debug('tcket', ticket);
	}

	toggleActionClasses = () => {
		this.setState({ isActiveAction: !this.state.isActiveAction });
	};
/*
	isLoggedIn = () => {
		return (NA.User.isLoggedIn("user") || process.title !== "browser");
	}*/
	toggleFullCancellation = () => {
		if(!isLoggedIn()){
      		alert('Your Session is Expired, Please Re-Login!');
      		Store.dispatch(logout(this.props.history));
		}else{
			//console.log('history',this.props.history)
			this.setState({
				isActiveFullCancellation: !this.state.isActiveFullCancellation,
				cancel_ticket_error: false,
				errMsg: []
			});
		}
	};
	toggleSeatCancellation = () => {
		if(!isLoggedIn()){
			alert('Your Session is Expired, Please Re-Loginnn!');
			Store.dispatch(logout(this.props.history));
		}else{
			this.setState({
				isActiveSeatCancellation: !this.state.isActiveSeatCancellation,
				cancel_ticket_error: false,
				errMsg: []
			});
		}
	};
	render() {
		let ActionsClasses = this.state.isActiveAction
			? "actions-box active"
			: "actions-box";

		let ticket = null;
		if (this.props.tickets && Array.isArray(this.props.tickets)) {
			ticket = this.props.tickets.filter(ticket => {
				return Number(ticket.CardID) === Number(this.cardId);
			});
		}
		if (ticket && ticket.length > 0) {
			ticket = ticket[0];
		} else {
			ticket = null;
		}

		let { UserName, UserEmail, UserTelNo, Pic_Url } = this.props.user || {};

		return (
			<MainLayoutComponent page={this.props.location.pathname}>
				<div className="page-content">
					<div className="AccPage">
						<div className="container">
							<div className="page-title">
								<h5>
									<Trans i18nKey="profile_page.bookings.headline">
										حسابي
									</Trans>
								</h5>
								<h1>
									<Trans i18nKey="profile_page.bookings.subline">
										حجوزاتي السابقة
									</Trans>
								</h1>
							</div>

							{ticket && (
								<FullCancelTicket
									ticket={ticket}
									isActive={
										this.state.isActiveFullCancellation
									}
									onSuccess={() => {
										// ApiCacher.invalidate('history_');
										this.props.cancelSuccess();
										this.props.history.replace(
											"/profile/tickets"
										);
									}}
									afterSuccess={() => {
										this.props.clearCancel();
										// let parent = document.querySelector(".account-content");
										// if (parent) parent.classList.remove("active");
										// this.props.getTickets().then(() => {
										//   setTimeout(() => {
										//     if (parent) parent.classList.add("active");
										//   }, 1000);
										// });
									}}
									toggle={this.toggleFullCancellation}
									duration={5000}
									history={this.props.history}
								// classes={FullCancellationClasses}
								/>
							)}

							{ticket && (
								<CancelSeatsModal
									ticket={ticket}
									isActive={
										this.state.isActiveSeatCancellation
									}
									onSuccess={() => {
										// ApiCacher.invalidate('history_');
										this.props.cancelSuccess();
										// TODO: refresh tickets
										this.props.getTickets()
										this.props.history.replace('/profile/tickets')
									}}
									afterSuccess={() => {
										this.props.clearCancel();
									}}
									toggle={this.toggleSeatCancellation}
									duration={5000}
									history={this.props.history}
								/>
							)}
							<div className="account-container">
								<SideBar
									username={UserName}
									email={UserEmail}
									phone={UserTelNo}
									profile_picture={Pic_Url}
									logout={() =>
										this.props.logout(this.props.history)
									}
								/>
								<div
									className="account-content"
									ref={ref => (this.parent = ref)}>
									{ticket && (
										<div className={`SignleBooking${this.props.getting_tickets ? ' component-loader' : ''}`}>
											<Ticket
												lang={this.props.locale.lang}
												ticket={ticket}
												toggleFullCancellation={
													this.toggleFullCancellation
												}
												toggleSeatCancellation={
													this.toggleSeatCancellation
												}
											/>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</MainLayoutComponent>
		);
	}
}

export class Ticket extends Component {
	BookingStatus = status => {
		switch (status) {
			case 3:
				return (
					<span className="booking-status temporary">
						<Trans i18nKey="profile_page.bookings.statuses.pending">
							حجز مؤقت
						</Trans>
					</span>
				);
			case 4:
				return (
					<span className="booking-status confirmed">
						<Trans i18nKey="profile_page.bookings.statuses.paid">
							حجز مدفوع ومؤكد
						</Trans>
					</span>
				);

			default:
				return (
					<span className="booking-status cancelled">حجز ملغي</span>
				);
		}
	};

	PaymentMethod = (id, identifier) => {
		switch (id) {
			case config.cardTypes.fawry_aman:
				return <p className="icon-num fawry">{identifier}</p>;
			case config.cardTypes.credit_card:
				return <p className="icon-num visa-mastercard"> </p>;
			default:
				return null;
		}
	};

	render() {
		let {
			ticket,
			lang,
			toggleFullCancellation,
			toggleSeatCancellation,
			viewOnly,
			GoingSideComponent,
			ReturnSideComponent
		} = this.props;

		const fromOfficeKey =
			lang === "ar" ? "TripOfficeFrom_NameA" : "TripOfficeFrom_NameE";
		const toOfficeKey =
			lang === "ar" ? "TripOfficeTo_NameA" : "TripOfficeTo_NameE";

		let showCancelCard = false;
		let showCancelSeats = false;

		let date = ticket.ActionDate;
		let number = ticket.CardID;
		let type =
			ticket.myTrips && ticket.myTrips.length === 1 ? "oneway" : "twoway";

		let from = ticket.myTrips[0][fromOfficeKey];
		let to = ticket.myTrips[0][toOfficeKey];
		let fromDateRaw = ticket.myTrips[0].TripDateTime;
		let toDateRaw =
			ticket.myTrips && ticket.myTrips.length > 1
				? ticket.myTrips[1].TripDateTime
				: null;

		let tripClass = ticket.myTrips[0].TripServKind_NameA;
		// let toClass = ticket.myTrips.length > 1 ? ticket.myTrips[1].TripServKind_NameA : null;

		let fromSeats = ticket.myTrips[0].mySeats;
		let toSeats = type !== "oneway" ? ticket.myTrips[1].Seats : null;
		let seatsCount = ticket.CardSeatsCount;
		let price = ticket.CardTotalPrice;
		let status = ticket.CardStatusID;
		let passengersNo = type !== "oneway" ? seatsCount / 2 : seatsCount;

		//convert date to the required format
		const format = "dddd DD MMMM YYYY";
		date = moment(date).format(format);
		let fromDate = moment(fromDateRaw).format(format);
		let toDate = toDateRaw ? moment(toDateRaw).format(format) : null;

		let fromTime = moment(fromDateRaw)
			.locale("en")
			.format("hh:mm");

		let fromTimeSegment = tripTimeSegment(fromDateRaw, null, lang);

		let toTime = toDateRaw ? moment(toDateRaw).format("hh:mm") : null;
		let toTimeSegment = toDateRaw
			? tripTimeSegment(toDateRaw, null, lang)
			: null;

		let arrivalFrom = null;
		let arrivalTo = null;
		let arrivalTripClass = null;
		let arrivalSeats = null;

		if (type === "twoway") {
			arrivalFrom = ticket.myTrips[1][fromOfficeKey];
			arrivalTo = ticket.myTrips[1][toOfficeKey];
			arrivalTripClass = ticket.myTrips[1].TripServKind_NameA;
			arrivalSeats = ticket.myTrips[1].mySeats;
		}

		const allSeats = [...fromSeats, ...(arrivalSeats || [])];
		showCancelCard = allSeats.every(seat => seat.SeatStatusID !== 5);
		showCancelSeats = allSeats.some(seat => seat.SeatStatusID !== 5);

		const GoingTripDetails = () => {
			return <div className="rout">
				<h4>
					<span className="icon-check">
						<i className="fas fa-check" />
					</span>
					<Trans i18nKey="profile_page.bookings.going_trip">
						رحلة الذهاب
				</Trans>
				</h4>
				<p className="date">{fromDate}</p>
				<p className="time">
					<span>{fromTime}</span> {fromTimeSegment}
				</p>
				<p>
					<Trans i18nKey="profile_page.bookings.travel_from">
						سفر من
				</Trans>
					: {from}
					<br />
					<Trans i18nKey="profile_page.bookings.travel_to">
						وصول إلي
				</Trans>
					: {to}
					<br />
					<Trans i18nKey="profile_page.bookings.booking_type">
						نوع الخدمة
				</Trans>
					: {tripClass}
					<br />
					<Trans i18nKey="common.passengers_no">
						عدد المسافرين
				</Trans>
					: {passengersNo}
				</p>
				{!viewOnly && <ul className="seats-selected">
					{fromSeats.map(seat => {
						return <li className={`seat seat-${seat.SeatStatusID === 5? 'cancelled': 'confirmed'}`} key={seat.SeatNo}>{seat.SeatNo}</li>;
					})}
				</ul>}
			</div>
		}

		const ReturnTripDetails = () => {
			return <div className="rout">
				<h4>
					<span className="icon-check">
						<i className="fas fa-check" />
					</span>
					<Trans i18nKey="profile_page.bookings.return_trip">
						رحلة العودة
				</Trans>
				</h4>
				<p className="date">{toDate}</p>
				<p className="time">
					<span>{toTime}</span> {toTimeSegment}
				</p>
				<p>
					<Trans i18nKey="profile_page.bookings.travel_from">
						سفر من
				</Trans>
					: {arrivalFrom}
					<br />
					<Trans i18nKey="profile_page.bookings.travel_to">
						وصول إلي
				</Trans>{" "}
					: {arrivalTo}
					<br />
					<Trans i18nKey="profile_page.bookings.booking_type">
						نوع الخدمة
				</Trans>
					: {arrivalTripClass}
					<br />
					<Trans i18nKey="common.passengers_no">
						عدد المسافرين
				</Trans>
					: {passengersNo}
				</p>
				{!viewOnly && <ul className="seats-selected">
					{arrivalSeats.map(seat => {
						return <li className={`seat seat-${seat.SeatStatusID === 5? 'cancelled': 'confirmed'}`} key={seat.SeatNo}>{seat.SeatNo}</li>;
					})}
				</ul>}
			</div>
		}
		//REGEX: getting the direction place without ()
		from = from.replace(/.*\((.*)\)/, '$1');
		to = to.replace(/.*\((.*)\)/, '$1');
		return (
			<React.Fragment>
				{!viewOnly && <div className="SignleBooking-header">
					<div className="BookingItem_date">
						<h5 className="label">
							<Trans i18nKey="profile_page.bookings.booking_date">
								تاريخ الحجز
							</Trans>
						</h5>
						<p>{date}</p>
					</div>
					{/*DONE: added new div with className */}
					<div className="SB_num_type">
					<div className="BookingItem_num">
						<h5 className="label">
							<Trans i18nKey="profile_page.bookings.booking_number">
								رقم الحجز
							</Trans>
						</h5>
						<p>{number}</p>
					</div>
					<div className="BookingItem_type">
						<h5 className="label">
							<Trans i18nKey="profile_page.bookings.booking_type">
								نوع الحجز
							</Trans>
						</h5>
						<p>
							{type === "oneway"
								? i18next.t("profile_page.bookings.oneway", {
									defaultValue: "ذهاب"
								})
								: i18next.t("profile_page.bookings.round", {
									defaultValue: "ذهاب وعودة"
								})}
						</p>
					</div>
					</div>
					<div className="BookingItem_directions">
						<div className="directions">
							<p>
								<Trans i18nKey="profile_page.bookings.travel_from">
									سفر من
								</Trans>{" "}
								{from.includes("-")
									? from.slice(0, from.indexOf("-") - 1)
									: from}
							</p>
							<div className="track">
								<span className="line" />
							</div>
							<p>
								{" "}
								<Trans i18nKey="profile_page.bookings.travel_to">
									وصول إلي
								</Trans>{" "}
								{to.includes("-")
									? to.slice(0, to.indexOf("-") - 1)
									: to}
							</p>
						</div>
						<div className="details">
							<p>
								<Trans i18nKey="profile_page.bookings.travel">
									سفر
								</Trans>{" "}
								{fromDate}{" "}
								{toDate
									? i18next.t(
										"profile_page.bookings.and_return",
										{ defaultValue: "والعودة " }
									) +
									" " +
									toDate
									: null}
							</p>
						</div>
					</div>
					<div className="BookingItem_details">
						<div className="base-details">
							<h5 className="label">
								{seatsCount}{" "}
								<Trans i18nKey="common.chairs">كراسي</Trans>
							</h5>
							<h4 className="price">
								{price}.00
								<span class="symbol">EGP</span> {/* TODO: back to check */}
							</h4>
							{this.BookingStatus(status)}
						</div>
					</div>
				</div>}
				<div className="SignleBooking-content">
					<div className="full-trip-details">
						{viewOnly ? (
							<div className="route-container">
								<GoingTripDetails />
								{GoingSideComponent && <div className="rout">
									<GoingSideComponent />
								</div>}
							</div>
						) : (<GoingTripDetails />)}
						{type === "twoway" ? (
							viewOnly ? (
								<div className="route-container">
									<ReturnTripDetails />
									{ReturnSideComponent && <div className="rout">
										<ReturnSideComponent />
									</div>}
								</div>
							) : (
									<ReturnTripDetails />
								)
						) : null}
					</div>
				</div>

				{!viewOnly && <div className="SignleBooking-footer">
					<div className="payment-method">
						<h4 className="label">
							<Trans i18nKey="profile_page.bookings.payment_method">
								طريقة الدفع
							</Trans>
						</h4>
						{this.PaymentMethod(ticket.CardTypeID, ticket.JobID)}
					</div>
					<div className="actions">
						{ticket.CardTypeID === config.cardTypes.credit_card && (
							<>
								{
									showCancelCard && <button onClick={toggleFullCancellation}>
										<Trans i18nKey="profile_page.bookings.cancel_ticket">
											إلغاء الحجز بالكامل
										</Trans>
									</button>
								}
								{
									showCancelSeats && <button onClick={toggleSeatCancellation}>
										<Trans i18nKey="profile_page.bookings.cancel_seats">
											إلغاء جزئي للكراسي
										</Trans>
									</button>
								}
							</>
						)}
					</div>
				</div>}
			</React.Fragment>
		);
	}
}

function mapStateToProps(store) {
	return {
		getting_tickets: store.user.getting_tickets,
		locale: store.locale,
		tickets: store.user.tickets,
		user: store.user.user
	};
}

function mapDispatchToProps(dispatch) {
	return {
		getTickets: bindActionCreators(getTickets, dispatch),
		preLogin: bindActionCreators(preLogin, dispatch),
		logout: bindActionCreators(logout, dispatch),
		cancelSuccess: bindActionCreators(cancelSuccess, dispatch),
		clearCancel: bindActionCreators(clearCancel, dispatch)
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(SingleBooking));
