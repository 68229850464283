import React, { Component } from 'react'

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import moment from "moment";

import * as UserActions from "../../../Actions/UserActions";
import ErrorComponent from '../../Utils/ErrorComponent';

import debug from '../../../Helpers/DebugLog';
import i18next from 'i18next';
import { Trans } from 'react-i18next';
import { tripTimeSegment } from '../../../Helpers/TimeParser';
import Store from '../../../Store';

function mapStateToProps(store) {
  return {
    locale: store.locale,
    userStore: store.user,
    tripsStore: store.trips
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(UserActions, dispatch)
  };
}

class FullCancelTicket extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cancel_ticket_error: false,
      cancelling: false,
      errMsg: []
    }
  }

  cancelTicket = () => {
    let checkbox = this.checkbox;
    // let checkbox = document.querySelector("#full-cancel-terms");
    if(!UserActions.isLoggedIn()){
      alert('Your Session is Expired, Please Re-Login!');
			Store.dispatch(UserActions.logout(this.props.history)); 
    }else{
      //console.log('history',this.props.history)
      if (!checkbox.checked) {
        this.setState({
          cancel_ticket_error: true,
          errMsg: [i18next.t('common.errors.accept_terms', 'من فضلك وافق على الشروط و الاحكام')]
        });
        return;
      }
  
      this.setState({cancelling: true});
      this.props.userActions
        .cancelTicket(this.props.ticket)
        .then(res => {
          if (res.data.success === true) {
            // cancel and refund success
            this.setState({
              cancel_ticket_error: false,
              isActiveFullCancellation: false,
              cancelling: false
            });
            this.props.toggle();
            this.props.onSuccess();
            setTimeout(() => {
              this.props.afterSuccess();
            }, this.props.duration);
          } else {
            debug('ERROR, ', res);
            // something else
            let error = i18next.t('profile_page.cancel_ticket.errors.unhandled_error', 'لم نتمكن من إلغاء الحجز');
  
            if (res.msg === 'invalid_refund_amount') {
              error = i18next.t('profile_page.cancel_ticket.errors.invalid_refund_amount', "لا يمكن إلغاء هذا الحجز مع العلم انه يتم خصم 15جنيها لكل كرسي بالرحلة");
            } else
            if (res.msg === 'mpgs_refund_failed') {
              error = i18next.t('profile_page.cancel_ticket.errors.mpgs_refund_failed', "تم إلغاء الحجز و لاكن فشل إرجاع المبلغ المستحق, سيتواصل معك احد ممثلي خدمة العملاء في اقرب وقت.");
            } else
            if (res.msg === 'gobus_cancel_time_error') {
              error = i18next.t('profile_page.cancel_ticket.errors.gobus_cancel_time_error', "لا يمكن الغاء الحجز في هذا الوقت.");
            } else
            if (res.msg === 'gobus_seats_error') {
              error = i18next.t('profile_page.cancel_ticket.errors.gobus_seats_error', "خطأ في الكراسي");
            }
  
            return this.setState({
              cancel_ticket_error: true,
              cancelling: false,
              errMsg: [error]
            });
          }
        })
        .catch(err => {
          console.error(err);
          this.setState({
            cancel_ticket_error: true,
            cancelling: false,
            errMsg: [i18next.t('common.errors.general', 'لقد حدث خطاً ما.')]
          });
        });
    }
   
  };

  resetState = () => {
    this.setState({
      cancel_ticket_error: false,
      cancelling: false,
      errMsg: []
    })
  }

  render() {
    let FullCancellationClasses = this.props.isActive
      ? "popup popup-cancel active"
      : "popup popup-cancel";

    const { lang } = this.props.locale;

    const servKey = lang === 'ar'? "TripServKind_NameA": "TripServKind_NameE";
    const fromOfficeKey = lang === 'ar'? "TripOfficeFrom_NameA": "TripOfficeFrom_NameE";
    const toOfficeKey = lang === 'ar'? "TripOfficeToKey": "TripOfficeTo_NameE";

    return (
      <div className={FullCancellationClasses}>
        <div className="tbl">
          <div className="tcl">
            <div className={this.state.cancelling? "popup__window component-loader" : "popup__window"}>
              <ErrorComponent
                page="tickets"
                errors={this.state.errMsg}
                state={this.state.cancel_ticket_error}
              />
              <i class="popup__close fas fa-times" onClick={() => {
                  this.resetState();
                  this.props.toggle();
                }}></i>

              <div className="popup-cancel-header">
                <h3><Trans i18nKey="profile_page.cancel_ticket.heading">تأكيد إلغاء الحجز بالكامل</Trans></h3>
              </div>
              <div className="popup-cancel-content">
                <div className="full-trip-details">
                  <div className="rout">
                    <h4>
                      <span className="icon-check">
                        <i class="fas fa-check" />
                      </span>
                      <Trans i18nKey="profile_page.bookings.going_trip">رحلة الذهاب</Trans>
                    </h4>
                    {/* الأربعاء 17 ىوليو */}
                    <p className="date">
                      {moment(this.props.ticket.myTrips[0].TripDateTime).format("dddd DD MMMM")}
                    </p>
                    <p className="time">
                      <span>
                        {moment(this.props.ticket.myTrips[0].TripDateTime)
                          .format("hh:mm")}
                      </span>
                      {tripTimeSegment(this.props.ticket.myTrips[0].TripDateTime, null, lang)}
                    </p>
                    <p>
                      <Trans i18nKey="profile_page.bookings.travel_from">سفر من</Trans>:{" "}
                      {this.props.ticket.myTrips[0][fromOfficeKey]}
                      <br />
                      <Trans i18nKey="profile_page.bookings.travel_to">وصول إلي</Trans>:{" "}
                      {this.props.ticket.myTrips[0][toOfficeKey]}
                      <br />
                      <Trans i18nKey="profile_page.bookings.booking_type">نوع الخدمة</Trans>:{" "}
                      {this.props.ticket.myTrips[0][servKey]}
                      <br />
                      <Trans i18nKey="common.passengers_no">عدد المسافرين</Trans>: {this.props.ticket.myTrips[0].Seats.length}
                    </p>
                    <ul className="seats-selected">
                      {this.props.ticket.myTrips[0].Seats.map(seat => {
                        return <li key={seat}>{seat}</li>;
                      })}
                    </ul>
                  </div>
                  {this.props.ticket.myTrips.length > 1 && (
                    <div className="rout">
                      <h4>
                        <span className="icon-check">
                          <i class="fas fa-check" />
                        </span>
                        <Trans i18nKey="profile_page.bookings.return_trip">رحلة العودة</Trans>
                      </h4>
                      <p className="date">
                        <span>
                          {moment(this.props.ticket.myTrips[1].TripDateTime).format("dddd DD MMMM")}
                        </span>
                      </p>
                      <p className="time">
                        {moment(this.props.ticket.myTrips[1].TripDateTime).format("hh:mm")}
                        {tripTimeSegment(this.props.ticket.myTrips[1].TripDateTime, null, lang)}
                      </p>
                      <p>
                        <Trans i18nKey="profile_page.bookings.travel_from">سفر من</Trans>:{" "}
                        {this.props.ticket.myTrips[1][fromOfficeKey]}
                        <br />
                        <Trans i18nKey="profile_page.bookings.travel_to">وصول إلي</Trans>:{" "}
                        {this.props.ticket.myTrips[1][toOfficeKey]}
                        <br />
                        <Trans i18nKey="profile_page.bookings.booking_type">نوع الخدمة</Trans>:{" "}
                        {this.props.ticket.myTrips[1][servKey]}
                        <br />
                        <Trans i18nKey="common.passengers_no">عدد المسافرين</Trans>:{" "}
                        {this.props.ticket.myTrips[1].Seats.length}
                      </p>
                      <ul className="seats-selected">
                        {this.props.ticket.myTrips[1].Seats.map(seat => {
                          return <li key={seat}>{seat}</li>;
                        })}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              <div className="popup-cancel-footer">
                <label htmlFor="full-cancel-terms" className="checkbox">
                  <input type="checkbox" id="full-cancel-terms" ref={ref => this.checkbox = ref} />
                  <span className="name"><Trans i18nKey="checkout_page.payment.accept_terms">أوافق علي كل</Trans>
                    <a href="/terms" target="_blank">
                      <Trans i18nKey="checkout_page.payment.terms_link_text">الشروط والأحكام</Trans>
                    </a>
                  </span>
                  <span className="check-icon" />
                </label>
                <div className="cancel-actions">
                  <a onClick={this.cancelTicket} className="btn btn-red">
                    <Trans i18nKey="profile_page.cancel_ticket.cancel_btn">إلغاء الحجز بالكامل</Trans>
                  </a>
                  <a
                    className="btn btn-gray"
                    onClick={() => {
                      this.resetState();
                      this.props.toggle();
                    }}
                  >
                    <Trans i18nKey="profile_page.cancel_ticket.exit_btn">خروج</Trans>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(FullCancelTicket);