import React, { Component, useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Sticky from "react-sticky-el";
import _ from "lodash";

// Actions
import * as SearchActions from "../../Actions/SearchActions";
import * as TripActions from "../../Actions/TripActions";

// Components
import MainLayoutComponent from "../MainLayoutComponent";
import CartComponent from "../Utils/CartComponent";

import UserInfoComponent from "./UserInfoComponent";
import TripCardComponent from "./TripCardComponent";
import PaymentComponent from "./PaymentComponent";
import EditSeatsComponent from "./EditSeatsComponent";
import { Trans } from "react-i18next";
import Helmet from "react-helmet";
import Hint from "../Utils/Hint";
import i18next from "i18next";
import { isGoMini } from "../../Helpers/Utils";

import NA from "node-auth-api";
import store2 from "store2";
import { logout, toggleAuthPopUp } from "../../Actions/UserActions";
import { useCheckoutStore } from "../../new-store/checkoutStore";
import i18n from "../../i18n";
import { Spinner } from "../Spinner/Spinner";
import { baseUrl } from "../../Api/Base";
import { useRequest } from "../../Hooks/useRequest";

function mapStateToProps(store) {
  return {
    locale: store.locale,
    userStore: store.user,
    searchStore: store.search,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    searchActions: bindActionCreators(SearchActions, dispatch),
    tripActions: bindActionCreators(TripActions, dispatch),
  };
}

const BookingComponent = (props) => {
  const [{ isActivePopupChange }, setState] = useState({
    isActivePopupChange: false,
  });
  const {
    cartId,
    showPayment,
    goingTripInfo,
    returnTripInfo,
    seats,
    holdSeats,
    cancelSeatModifications,
    __loading_state,
  } = useCheckoutStore();

    const request = useRequest({
      url: `${baseUrl}/api/V3/GetCardData`,
      method: 'GET',
      params: {
          cardID: cartId
      }
    }, { loading: true });

  async function checkTicketStatus(cartId) {
    const ticket = await request.run();
    if (ticket != null && ticket.CardID !== 0) {
      alert('Ticket is already booked, redirecting to thanks page...');
      window.location.replace(`/Thanks/${cartId}`);
    }
  }

  useEffect(() => {
    // checkTicketStatus(cartId);
  }, [cartId]);

  const holdError = seats.holdError;
  const holdingSeats = seats.holding;

  const holdSeatsMsg = i18n.t("checkout_page.edit_seats.seats_confirm_notice", {
      defaultValue: "سيتم تأكيد الكراسي المختارة قبل الدفع",
  });

  // Ojja
  const toggleClassesPopupChange = () => {
    if (!isActivePopupChange) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    setState({ isActivePopupChange: !isActivePopupChange });
  };

  const close = () => {
    document.body.style.overflow = "unset";
    setState({ isActivePopupChange: false });
  };

  if (__loading_state) {
    return null;
  }

  const departCode = _.get(props.searchStore, "departureStation.Code");
  const showHint = departCode === 27;
  //for Cairo - Amman route
  const showAlert = (goingTripInfo || {}).TripOfficeFrom_Code === 364;

  return (
    <MainLayoutComponent page="booking" goBack={props.history.goBack}>
      <Helmet>
        <title>Booking | Go Bus V3</title>
      </Helmet>
      <div className="page-content rout-booking user-data">
        <div className="single-routs">
          <div className="container">
            <div className="trip-route-component">
              <div className="trip-route-con">
                <div className="user-data-content">
                  {showHint && (
                    <div className="hints-wrapper">
                      <Hint
                        title={i18next.t(
                          "thanks_page.hint.title",
                          "الرجاء الإنتباه!"
                        )}
                        message={
                          <>
                            <Trans i18nKey="thanks_page.hint.message">
                              برجاء العلم أن رحلات القاهرة مدينة نصر تتحرك من
                            </Trans>{" "}
                            <a
                              href="https://www.google.com/maps/place/Go+Bus+main+station/@30.0464429,31.3184373,17z/data=!4m5!3m4!1s0x14583ff7276bf1ab:0x304b20824806314!8m2!3d30.0464349!4d31.3185799?hl=en&shorturl=1"
                              target="_blank"
                            >
                              <Trans i18nKey="thanks_page.hint.link">
                                (228 شارع المخيم الدائم الحي السادس مدينة نصر)
                              </Trans>
                            </a>
                          </>
                        }
                      />
                    </div>
                  )}
                  {showAlert && (
                    <div className="hints-wrapper">
                      <Hint
                        title={i18next.t(
                          "thanks_page.hint.title",
                          "الرجاء الإنتباه!"
                        )}
                        message={
                          <>
                            <Trans i18nKey="thanks_page.alert.message">
                              عميلنا العزيز، الوقت والتاريخ غير مؤكدين للرحلة
                              ولكنه فقط سداد لرسوم الرحلة وسيتم التواصل معكم
                              خلال 24 ساعه لاستكمال باقى البيانات من جانب
                              حضراتكم.
                            </Trans>
                          </>
                        }
                      />
                    </div>
                  )}
                  <UserInfoComponent />
                  <div className="user-widget">
                    <div className="TripCardItem">
                      <div className="trip-info"></div>
                    </div>
                  </div>
                  <div className="user-widget">
                    <div className="book-bus-container">
                      <TripCardComponent
                        editSeatsTrigger={toggleClassesPopupChange}
                        history={props.history}
                        tripType="going"
                      />
                      {returnTripInfo && (
                        <TripCardComponent
                          editSeatsTrigger={toggleClassesPopupChange}
                          history={props.history}
                          tripType="returning"
                        />
                      )}

                      <div className="chairs">
                        <div className="item yourchair">
                          <span></span>
                          <p> <Trans i18nKey="seats.selected">كرسيك</Trans></p>
                        </div>
                        <div className="item available">
                          <span></span>
                          <p> <Trans i18nKey="seats.available">متاح</Trans></p>
                        </div>
                        <div className="item not-available">
                          <span></span>
                          <p> <Trans i18nKey="seats.not_available">غير متاح</Trans> </p>
                        </div>

                      </div>
                    </div>
                    {!showPayment && (
                      <div className="widget-footer flex-column">
                        {!holdingSeats && (
                          <>
                            <h2 className={`${holdError ? "error" : ""}`}>
                              {holdError || holdSeatsMsg}
                            </h2>
                            {holdError && (
                              <h3>
                                <Trans i18nKey="checkout_page.edit_seats.retry">الرجاء أعادة المحاولة، أو الأستمرار بالأختيارات
                                الحالية.</Trans>
                              </h3>
                            )}
                            <div>
                              <button className="btn" onClick={holdSeats}>
                                <i className="material-icons">
                                  keyboard_arrow_right
                                </i>
                                <Trans i18nKey="checkout_page.edit_seats.next">تأكيد الكراسي</Trans>
                              </button>
                              {cartId ? (
                                <button
                                  className="btn red"
                                  onClick={cancelSeatModifications}
                                >
                                  <i className="material-icons">close</i>
                                  <Trans i18nKey="checkout_page.edit_seats.cancel">الرجوع للكراسي السابقة</Trans>
                                </button>
                              ) : null}
                            </div>
                          </>
                        )}
                        {holdingSeats && <Spinner />}
                      </div>
                    )}
                  </div>
                  <div className="user-widget user-widget-mob">
                    <div className="tickets-component">
                      {process.title === "browser" && (
                        <CartComponent history={props.history} />
                      )}
                    </div>
                  </div>
                  {showPayment && (
                    <PaymentComponent history={props.history} currency="EGP" />
                  )}
                </div>

                <div className="tickets-component">
                  <Sticky
                    topOffset={-110}
                    // bottomOffset={0}
                    boundaryElement=".tickets-component"
                    hideOnBoundaryHit={false}
                  >
                    {process.title === "browser" && (
                      <CartComponent history={props.history} />
                    )}
                  </Sticky>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayoutComponent>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingComponent);
