import axios from 'axios';
import apiErrorHandler from './apiErrorHandler';
import { baseApi } from './Base';
import { get } from 'lodash';
import { logout } from '../Actions/UserActions';
import Store from '../Store';

function delay(ms) {
	return new Promise((resolve) => {
		setTimeout(resolve, ms);
	})
}

class UserApi {

    static login(email, password) {
        let encodedPass = encodeURIComponent(password);
        return axios.get(`${baseApi}/Login?userEmail=${email}&userPassword=${encodedPass}`)
            .then(res => {
                return res.data;
            }).catch(err => {
                apiErrorHandler(err);
                return err;
            });
    }

    static fbLogin(socialId, email, accessToken) {
        return axios.get(`${baseApi}/Login?socialID=${socialId}&userEmail=${email}&facebookAccess_token=${accessToken}`)
            .then(res => {
                return res.data;
            }).catch(err => {
                apiErrorHandler(err);
                return err;
            })
    }

    static register(username, email, mobile, password) {
        return axios.get(`${baseApi}/Signup?userName=${username}&userEmail=${email}&userTelNo=${mobile}&userPassword=${password}`)
            .then(res => {
                return res.data;
            }).catch(err => {
                apiErrorHandler(err);
                return err;
            })
    }

    static fbSignup(username, email, mobile, password, pic, socialId) {
        return axios.get(`${baseApi}/Signup?userName=${username}&userEmail=${email}&userTelNo=${mobile}&userPassword=${password}&socialID=${socialId}&socialTypeID=1`)
            .then(res => {
                return res.data;
            }).catch(err => {
                apiErrorHandler(err);
                return err;
            })
    }

    static editProfile({ id, name, email, telNo }) {
        return axios.get(`${baseApi}/profileUpdate?userID=${id}&userEmail=${email}&userTelNo=${telNo}&userName=${name}`)
            .then(res => (res.data))
            .catch(err => {
                apiErrorHandler(err);
                return err;
            });
    }

    static changePassword({ id, old_password, new_password }) {
        return axios.get(`${baseApi}/profileUpdate?userID=${id}&passwordOnly=true&userPassword=${old_password}&userPasswordNew=${new_password}`)
            .then(res => (res.data))
            .catch(err => {
                apiErrorHandler(err);
                return err;
            });
    }

    static resetPassword(new_password, token) {
        let encodedPass = encodeURIComponent(new_password);
        return axios.post(`${baseApi}/ForgotPasswordResetPassword`, {
            resetToken: token,
            newUserPassword: encodedPass
        })
        .then(res => (res.data))
        .catch(err => {
            apiErrorHandler(err);
            return err;
        })
    }

    static async getTickets(userId, userToken) {
        return axios.get(`${baseApi}/MyHistory?userID=${userId}&userToken=${userToken}`)
            .then(res => {
                return res.data
            })
            .catch(err => {
                apiErrorHandler(err);
                if (get(err, 'response.status') === 401) {
                    alert('Unauthorized, Please Re-Login!');
                    Store.dispatch(logout());
                    window.location.replace("/");
                }
                return err;
            })
    }

    static async getCardData(cardID) {
        const result = await axios.get(`${baseApi}/GetCardData`, { params: { cardID } })
            .catch(err => {
                apiErrorHandler(err);
                return err;
            });
        return result.data;
    }

    static getPoints(token) {
        return axios.post(`${baseApi}/Points_Get`, {userToken: token})
            .then(res => res.data)
            .catch(err => {
                apiErrorHandler(err);
                return err;
            });
    }

    static getPointsHistory(token) {
        return axios.post(`${baseApi}/Points_GetHistory`, {userToken: token})
            .then(res => res.data)
            .catch(err => {
                apiErrorHandler(err);
                return err;
            });
    }
}
export default UserApi;
