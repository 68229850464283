import React, { Component, useState } from "react";
import { connect } from 'react-redux'

import TripClassComponent from "./TripClassComponent";
import { getServiceAsset } from "../../../Helpers/BusHelper";
import {
  tripPrice,
  tripTime,
  tripTimeSegment
} from "../../../Helpers/TimeParser";
import clsx from "clsx";
import debug from "../../../Helpers/DebugLog";
import ClassPreviewComponent from "./ClassPreviewComponent/ClassPreviewComponent";
import BusHelper from "../../../Helpers/BusHelper";
import { getClassi18nKey, getFeaturedClass } from '../../../appConfig';
import { Trans } from 'react-i18next';
import i18next from "i18next";
import { useCheckoutStore } from "../../../new-store/checkoutStore";
import moment from "moment";
import { Tooltip } from "../../Utils/Tooltip";

const TripComponent = (props) => {
  
    let {
        trip,
        index,
        seatsCount,
        tripType,
        selectedGoingTrip,
        selectedReturningTrip,
    } = props;

    const [{ shouldShowBusPreview, previewId }, setState] = useState({ shouldShowBusPreview: false, previewId: null });
    const { setOrUnsetTrip, goingTripInfo, goingTripId, returnTripId } = useCheckoutStore()

    if (tripType !== 'going' && goingTripInfo) {
        const goingTripDate = moment(goingTripInfo.TripDateTime);
        const currentTripDate = moment(trip.TripDateTime);
        const duration = moment.duration(currentTripDate.diff(goingTripDate));
        console.log({
            going: goingTripDate.toISOString(),
            current: currentTripDate.toISOString(),
            difference: duration.asHours()
        })
        if (duration.asHours() < 1) {
            return null;
        }
    }

  const showBusPreview = (id) => {
    setState({
      shouldShowBusPreview: true,
      previewId: id
    });
  }

  const hideBusPreview = () => {
    setState({
      showBusPreview: false,
      previewId: null
    });
  }

  let isSelected = trip.TripSubData_Id === goingTripId || trip.TripSubData_Id === returnTripId;

  const { lang } = props.locale;
  const officeFromKey = lang === 'ar' ? "TripOfficeFrom_NameA" : "TripOfficeFrom_NameE";
  const officeToKey = lang === 'ar' ? "TripOfficeTo_NameA" : "TripOfficeTo_NameE";

  const split = trip[officeFromKey].split("-");
  const region = split.length > 0 ? split[0] : null;
  const station = split.length > 1 ? split[1] : null;

  let imagePaths = BusHelper(trip.TripServKind_NameE);
  let fClass = getFeaturedClass(trip.TripServKind_Code);
  let services = [];

  if (fClass) {
    services = fClass.services;
  }

  return (
    <>
      <ClassPreviewComponent
        open={shouldShowBusPreview}
        onClose={hideBusPreview}
        images={imagePaths}
        classData={{
          TripServKind_Code: trip.TripServKind_Code,
          TripServKind_NameE: trip.TripServKind_NameE,
          TripServKind_NameA_Short: trip.TripServKind_NameA_Short
        }}
        tripClassComponent={() => {
          return <TripClassComponent
              i18nKey={getClassi18nKey(trip.TripServKind_Code)}
              TripServKind_Code={trip.TripServKind_Code}
              TripServKind_NameA={trip.TripServKind_NameA}
              TripServKind_NameE={trip.TripServKind_NameE}
              TripServKind_NameA_Short={trip.TripServKind_NameA_Short}
              onClick={imagePaths.length > 0 ? showBusPreview.bind(this, trip.TripServKind_Code) : () => { }}
            />
        }}
      />
      <div className="list" key={`trip-${index}`}>
        <div className="list-con">
          <div className="right-side">
            <div className="non-stop-trip">
              <p> <Trans i18nKey="searchbox.non-stop">رحلة مباشرة بدون توقف  </Trans></p>
            </div>
            <h4>
              <p>
                <Trans i18nKey="search_page.trips.depart_from">سفر من</Trans> {region} {station ? station : null}
              </p>

              {tripTime(trip.TripDateTime)}
              <span>{tripTimeSegment(trip.TripDateTime, undefined, props.locale.lang)}</span>
            </h4>
            <TripClassComponent
              i18nKey={getClassi18nKey(trip.TripServKind_Code)}
              TripServKind_Code={trip.TripServKind_Code}
              TripServKind_NameA={trip.TripServKind_NameA}
              TripServKind_NameE={trip.TripServKind_NameE}
              TripServKind_NameA_Short={trip.TripServKind_NameA_Short}
              onClick={imagePaths.length > 0 ? showBusPreview.bind(this, trip.TripServKind_Code) : () => { }}
            />
            <div className="bus-icon">
              <i className="material-icons">directions_bus</i>
              <span />
            </div>
            <Tooltip content={<Trans i18nKey="common.approx_detailed">تقريبي من اخر نقطه اقلاع</Trans>}>
              <h5>
                <p> <Trans i18nKey="search_page.trips.arrive_to">وصول إلي</Trans> {trip[officeToKey]}</p>
                <div  style={{ display: 'flex', gap: '4px' }}>
                  {tripTime(trip.TripDateTime, trip.TimeMinutes)}
                  <span>
                    {tripTimeSegment(trip.TripDateTime, trip.TimeMinutes, props.locale.lang)}
                  </span>
                  <span className="text-muted">(<Trans i18nKey="common.approx">تقريبياً</Trans>)</span>
                </div>
              </h5>
            </Tooltip>
          </div>
          <div className="left-side">
            <div className="btn-container">
              <button
                className={isSelected ? "btn red-button" : "btn"}
                style={{ position: 'relative' }}
                onClick={setOrUnsetTrip.bind(null, trip, tripType)}
              >
                {!isSelected ? (
                  <h6><Trans i18nKey="search_page.trips.select_trip">أختار الرحلة</Trans></h6>
                ) : (
                    <h6>
                      <Trans i18nKey="search_page.trips.remove_trip">حذف {{ seats_count: seatsCount }} كرسي</Trans>
                    </h6>
                  )}
              </button>
              {trip.RemainChairs === 1 && <span class="gb-warn"><Trans i18nKey="search_page.trips.one_seat_remaining">متبقي كرسي واحد</Trans></span>}
              {trip.RemainChairs === 2 && <span class="gb-warn"><Trans i18nKey="search_page.trips.two_seats_remaining">متبقي كرسيان</Trans></span>}
            </div>
            <h4>
		{trip.PriceBefor ? <span className="line-through" style={{fontSize: '13px'}}>{tripPrice(trip.PriceBefor)}</span> : null}
	    {tripPrice(trip.TripPrice)}
	    <span><Trans i18nKey="common.currency">جنيه</Trans> <Trans i18nKey="common.per_chair">للكرسي</Trans></span></h4>
          </div>
          <div className="services">
            <ul>
            {services.length > 0 &&
              services.map((svc, i) => {
                return (
                  <li key={i}>
                    <img
                      className="svc-img"
                      title={svc.name[lang]}
                      src={getServiceAsset(svc.name.en)}
                      alt="svcimg"
                    />
                    <span className="title">{svc.name[lang]}</span>
                  </li>
                );
              })}
          </ul>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  locale: state.locale
})

export default connect(mapStateToProps)(TripComponent);
