const iframe = document.createElement("iframe");

/**
 *
 * @param {*} element A dom element to append the iframe to.
 * @param {*} content html content to be loaded into the iframe
 */
export function loadIframeInto(element, content) {
  iframe.src = "data:text/html;charset=utf-8," + content.replace(/#/g, "%23");
  iframe.style.zIndex = "2";
  element.appendChild(iframe);
}

/**
 *
 * @param {*} element A dom element to compare scroll distance to.
 * @param {Number} closeTo percentage of body to trigger the callback when reached.
 * @param {Boolean} loadMore boolean to disable the callback when true.
 * @param {*} callback the event to trigger when contraints are met
 */
export function scrollingEvent(element, closeTo, loadMore, callback) {
  // if(!loadMore) return;
  let target = element.offsetTop + element.clientHeight;
  let currentScroll = document.scrollingElement.scrollTop + document.scrollingElement.clientHeight;

  if (currentScroll >= target * (closeTo / 100)) {
    callback();
  }
}

export function scrollToElement(element) {
  const rect = element.getBoundingClientRect();
  document.scrollingElement.scrollTo({top: document.scrollingElement.scrollTop + rect.top - 100, behavior: "smooth"})
}
