import React from 'react'
import ContentLoader from 'react-content-loader'

const SuggestedTripsComponent = props => {
    
    let  {trips}  = props
    let trips_keys  = Object.keys(trips)
    let random_trips = {}
    let x = 0
    while(x < 6) {
        let random_key  = trips_keys[x]
        random_trips[random_key] = trips[random_key]
        x++;
    }

    return(
        <div className="suggest-destinations">
            <ul>
                {   
                    Object.keys(random_trips).map(key  => {
                        return(
                            <li>
                                <h4>{key}</h4>
                                <p>أسعار تبدأ من </p>
                                <span>{random_trips[key].cheapest_trip.price}</span>
                                <a href="#" className="btn">أحجز الآن </a>
                            </li>            
                        )
                        
                    })
                }
            </ul>
        </div>
    )        

}
    

export default SuggestedTripsComponent;