import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import store2 from "store2";

import "react-datepicker/dist/react-datepicker.css";

import {
  getTimeDuration,
  tripFullDateNoYear
} from "../../Helpers/TimeParser";

// Actions
import * as TripActions from "../../Actions/TripActions";
import * as UserActions from "../../Actions/UserActions";

// Component
import moment from "moment";
import { Trans } from 'react-i18next';
import { isLoggedIn } from '../../Actions/UserActions';
import Utils from "../../Helpers/Utils";
import { useCheckoutStore } from "../../new-store/checkoutStore";
import Store from '../../Store';
import PointsComponent from './PointsComponent';
import PromoCodeComponent from "./PromoCodeComponent";
import DiscountLabel from "../Label/DiscountLabel";
import { NGalleryPopup } from "../Popups/Gallery-popup";

const scrollToReturnList = () => {
  let returnList = document.querySelectorAll('.list-component-con')[1];
  Utils.scrollToElement(returnList);
}

function mapStateToProps(store) {
  return {
    locale: store.locale,
    tripsStore: store.trips,
    searchStore: store.search,
    points: store.user.points,
    userStore: store.user
  };
} 

function mapDispatchToProps(dispatch) {
  return {
    tripActions: bindActionCreators(TripActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

const CartComponent = (props) => {
  const [{ isActiveTicketData }, setState] = useState({ isActiveTicketData: false });
  const [popupOpen, setPopupOpen] = useState(false);
  const { seats, goingTripInfo, returnTripInfo, pricing, promocode, points, updateSeats, localHoldSeats, cartId } = useCheckoutStore();

  useEffect(() => {
    if (props.tripsStore.seatsHoldTime && props.history.location.pathname.toLowerCase() === "/booking") {
      props.tripActions.startHeldSeatsCountdown();
    }

    return () => {
      if(props.history.location.pathname.toLowerCase() !== "/booking") {
        props.tripActions.clearHeldSeatsCountdown();
      }
    }
  }, []);

  const goToCheckoutPage = () => {
    Utils.scrollTo({top: 0, behavior: "smooth"})
    const location = {
      pathname: `/Booking`,
      // search: this.searchKeys,
    };
    props.history.push(location);
  }

  const preHoldSeats = useCallback(() => {

    const action = () => {
      const duration = getTimeDuration(goingTripInfo.TripDateTime, moment());
      if (duration.asHours() < 4) {
        setPopupOpen(true);
      } else {
        holdSeats();
      }
    }

    if (!isLoggedIn()) {
      Store.dispatch(UserActions.toggleAuthPopUp(action, null));
      return;
    }

    action();
  }, [goingTripInfo]);

  const closePopup = useCallback(() => {
    setPopupOpen(false);
  }, []);

  const holdSeats = useCallback(async () => {
    setPopupOpen(false);

    const action = async () => {
      await updateSeats();
      await localHoldSeats({ autoSelect: true });
      goToCheckoutPage();
    };

    if (!isLoggedIn()) {
      return Store.dispatch(UserActions.toggleAuthPopUp(action, null));
    }
    
    await action();
  }, []);

  const toggleTicketDataClasses = () => {
    setState(prev => ({ isActiveTicketData: !prev.isActiveTicketData }));
  };

    let TicketDataClasses = isActiveTicketData
      ? "ticketData active"
      : "ticketData";
      
    let passengersNo = props.searchStore.passengersNo;
    let goingTrip = goingTripInfo;
    let returningTrip = returnTripInfo;
    // let promoCode = props.tripsStore.promoCode;
    // let points = props.points;
    const totalPrice = pricing.displayPrice;
    // const goingPrice = pricing.goingPrice;
    // const returningPrice = pricing.returnPrice;
    const totalPriceNoDiscount = pricing.totalPriceNoDiscount;
    let pathname = props.history.location.pathname.toLowerCase();
    let seatsHoldTime = props.tripsStore.seatsHoldTime;

    let departureStation = props.searchStore.departureStation;
    let arrivalStation = props.searchStore.arrivalStation;

    const { lang } = props.locale;
    const regionKey = lang === 'ar'? "RegionNameA_Short": "RegionNameE_Short";
    const fromOfficeKey = lang === 'ar'? "TripOfficeFrom_NameA": "TripOfficeFrom_NameE";
    const toOfficeKey = lang === 'ar'? "TripOfficeTo_NameA": "TripOfficeTo_NameE";
    
    const paymentMethod = store2.get("payment_method");

    const isToPortSaid =
      arrivalStation != null &&
      arrivalStation.TripRegion_Code === 35 &&
      departureStation.TripRegion_Code === 2;
    
    // return if selected going trip is removed/empty
    if(!goingTrip) return (
      <div className={
        seats.fetching? "calc-ticket component-loader" : "calc-ticket"
      }>
        <div className={TicketDataClasses}>
          <div className="ticketData-head">
            <h4><Trans i18nKey="cart.title">تذاكري</Trans></h4>
          </div>
          <div className="empty-ticket">
              {/* <i></i> */}
              <i className="fas fa-ticket-alt"></i>
              <p><Trans i18nKey="cart.headline">إختار رحلتك من جدول رحلات الجانب الأيمن</Trans></p>
                      {/* <h5><Trans i18nKey="cart.subline">إحجز رحلة ذهاب و عودة"</Trans></h5> */}
                      {props.searchStore.tripType === "round" ?  <h5><Trans i18nKey="cart.subline">إحجز رحلة ذهاب و عودة</Trans></h5>
                               :<h5><Trans i18nKey="cart.subline_going">إحجز رحلة ذهاب  </Trans></h5>}

              {
                departureStation && arrivalStation &&
                <h5>{departureStation[regionKey]}  -  {arrivalStation[regionKey]}</h5>
              }

          </div>
        </div>
      </div>
    );

    const mSeatsHoldTime = moment(seatsHoldTime)
    const timerClasses = `countdown-timer${mSeatsHoldTime.isSameOrBefore(moment(120 * 1000))? " red": ""}`
    const formatedSeatsHoldTime = mSeatsHoldTime.format("mm:ss");

    const duration = getTimeDuration(goingTripInfo.TripDateTime, moment());

    return (
      <div className={seats.fetching? "calc-ticket component-loader" : "calc-ticket"}>
        <div className={TicketDataClasses}>
          <div className="ticketData-head">
            {/* <h4><Trans i18nKey="cart.title">تذاكري</Trans></h4> */}
            <div className="num-of-ticket">
              {passengersNo}{' '}
              {passengersNo > 1 ?
                <Trans i18nKey="common.passengers_no">مسافرين</Trans>
                :
                <Trans i18nKey="common.passenger_no">مسافر</Trans>
              }
                
            </div>
          </div>
          <div className="ticketItem">
            <div className="row">
              <div className="item">
                <p>{tripFullDateNoYear(goingTrip.TripDateTime, lang)}</p>
                <p className="gray">{goingTrip[fromOfficeKey]} - {goingTrip[toOfficeKey]}</p>
              </div>
              <div className="item">
                <p className="light">
                <Trans i18nKey="cart.ticket_price">سعر التذكرة</Trans>
                <br/>
                <span className="dir">{goingTrip.TripPrice} EGP</span>
                </p>
              </div>
            </div>
            {passengersNo > 1 &&(
              <div className="row">
                <div className="item">
                  <p><Trans i18nKey="cart.going_total">إجمالي الذهاب ({{passengers_no: passengersNo}} تذاكر)</Trans></p>
                </div>
                <div className="item">
                  <p className="bold">{(goingTrip.TripPrice * passengersNo) + ".00"}<span className="symbol"><Trans i18nKey="common.currency">جنيه</Trans></span></p>
                </div>
              </div>
            )}


            {pathname === "/booking" && (
                <div className="ticketItem-edit">
                  <a onClick={props.history.goBack}><Trans i18nKey="cart.edit">تعديل</Trans></a>
                </div>
              )}
          </div>

          {returningTrip && (
            <div className="ticketItem">
            <div className="row">
              <div className="item">
                <p>{tripFullDateNoYear(returningTrip.TripDateTime, lang)}</p>
                <p className="gray">{returningTrip[fromOfficeKey]} - {returningTrip[toOfficeKey]}</p>
              </div>
              <div className="item">
                <p className="light">
                <Trans i18nKey="cart.ticket_price">سعر التذكرة</Trans>
                <br/>
                <span className="dir">{returningTrip.TripPrice} EGP</span>
                </p>
              </div>
            </div>
            {passengersNo > 1 &&(
            <div className="row">
              <div className="item">
                <p><Trans i18nKey="cart.return_total">إجمالي العودة ({{passengers_no: passengersNo}} تذاكر)</Trans></p>
              </div>
              <div className="item">
                <p className="bold">{(returningTrip.TripPrice * passengersNo) + ".00"}<span className="symbol"><Trans i18nKey="common.currency">جنيه</Trans></span></p>
              </div>
            </div>
            )}

            {pathname === "/booking" && (
                <div className="ticketItem-edit">
                  <a onClick={props.history.goBack}><Trans i18nKey="cart.edit">تعديل</Trans></a>
                </div>
              )}
            </div>
          )}

          {
            ((cartId || null) && !points && props.history.location.pathname.toLowerCase() === "/booking")
              ? ( props.searchStore.tripType === "oneWay" || props.searchStore.tripType === "going")
                && goingTrip
                  ? <PromoCodeComponent history={props.history} />
                  : <PromoCodeComponent isRoundTrip history={props.history} />
              : ( goingTrip && returningTrip ) && <PromoCodeComponent isRoundTrip history={props.history} />
          }

          {/* Points component */}
          {
            ((cartId || null) && !promocode && props.history.location.pathname.toLowerCase() === "/booking")
              && <PointsComponent payment= {paymentMethod}/>
          }
         
        </div>

        <div className="total-cost">
          <button
            className="showTicket"
            onClick={() => {
              toggleTicketDataClasses();
            }}
          />
          <div className="tickets-component-con">
            <span><Trans i18nKey="common.total">الأجمالي</Trans>
            {totalPriceNoDiscount !== totalPrice &&(<DiscountLabel/> )}
            </span>
            <div className="price-txt">
              <p>{totalPrice + ".00"} EGP</p>
              {totalPriceNoDiscount !== totalPrice &&(
                <del>{totalPriceNoDiscount + ".00"} EGP</del>
                
              )}
            </div>
            <span className="price-tax"><Trans i18nKey="common.inc_tax_text">شاملة الضرائب</Trans></span>
            {pathname !== "/booking" 
              && ((props.searchStore.tripType === "round" && returnTripInfo)
              || (props.searchStore.tripType === "oneWay" || props.searchStore.tripType === "going"))
              ? (
                <a
                  // to="/booking"
                  onClick={preHoldSeats}
                  className="btn"
                >
                  <Trans i18nKey="cart.book_chairs">أحجز {{chairs: passengersNo}} كرسي</Trans>
                </a>
              )
              : pathname !== "/booking" && (
                  <a
                    href="#!"
                    // to="/booking"
                    className="btn disabled"
                    onClick={scrollToReturnList}
                  >
                    <Trans i18nKey="cart.select_return_trip">اختر رحلة العودة</Trans>
                  </a>
              )
            }
          </div>
        </div>

        {pathname === "/booking" && <div className="cart-notice">
          {isToPortSaid && <div className='list-notice'>
            <i className='fas fa-ticket-alt'></i>
            <Trans i18nKey='cart.alerts.port_said'>خصم خاص لرحلات الذهاب والعودة</Trans>
          </div>}
        </div>}

        {pathname === "/booking" && seatsHoldTime ? (
          <div className={timerClasses}> <i className="material-icons">timer</i>
            <Trans i18nKey="cart.timer">
              الكراسي محجوزة لمدة {{duration: formatedSeatsHoldTime }} دقائق
            </Trans>
          </div>
        ): null}

        {pathname !== "/booking" && duration.asHours() < 4 ? (
          <NGalleryPopup
              className="cart-short-alert"
              isOpen={popupOpen}
              onClose={closePopup}
              onOverlayClick={closePopup}
              bottomChildren={null}
              thirdChildren={null}
            >
              <p className="body">
                <Trans i18nKey="cart.alerts.book_within_4_hrs">
                  عزيزي العميل<br/> الحجز لا يمكن الغائه او تعديله لانه
                  باقي اقل من 4 ساعات علي موعد الرحلة، الرجاء التاكد
                  من كل تفاصيل الحجز قبل الدفع
                </Trans>
              </p>

              <button className="btn" onClick={holdSeats}>
                <Trans i18nKey="cart.alerts.book_within_4_hrs_btn">حسناً</Trans>
              </button>
            </NGalleryPopup>
        ) : null}
      </div>
      
    );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CartComponent);
