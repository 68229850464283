import axios from 'axios';
import { gobus_payUrl } from './Base';

import store2 from 'store2';
import apiErrorHandler from './apiErrorHandler';

const token = store2.get('token')
class EmailApi {
    static forgotPassword({ email, email_lang }) {
        return axios.post(gobus_payUrl + '/email/forgot-password', { email, email_lang })
            .catch(err => {
                apiErrorHandler(err);
                throw err;
            });
    }

}
export default EmailApi;
