import React, { Component } from "react";
import { connect } from "react-redux";
import i18next from "i18next";
import { bindActionCreators } from "redux";

import { cancelSeats, isLoggedIn, logout } from "../../../Actions/UserActions";
import ErrorComponent from "../../Utils/ErrorComponent";
import { Trans } from 'react-i18next';
import { Ticket } from "./SingleBooking";

import Store from '../../../Store';

class CancelSeatsModal extends Component {
	state = {
		errMsg: [],
		cancelling: false,
		cancel_seat_error: false
	};

	render() {
		const { errMsg, cancel_seat_error, cancelling } = this.state;

		const {
			isActive,
			ticket,
			lang
		} = this.props;

		let SeatCancellationClasses = isActive
			? "popup popup-cancel active"
			: "popup popup-cancel";

		// const { lang } = locale;

		// const servKey =
		// 	lang === "ar" ? "TripServKind_NameA" : "TripServKind_NameE";
		// const fromOfficeKey =
		// 	lang === "ar" ? "TripOfficeFrom_NameA" : "TripOfficeFrom_NameE";
		// const toOfficeKey =
		// 	lang === "ar" ? "TripOfficeToKey" : "TripOfficeTo_NameE";

		return (
      <div className={SeatCancellationClasses}>
        <div className="tbl">
          <div className="tcl">
            <div
              className={
                cancelling ? "popup__window component-loader" : "popup__window"
              }
            >
              <ErrorComponent
                page="tickets"
                errors={errMsg}
                state={cancel_seat_error}
              />
              <i
                className="popup__close material-icons"
                onClick={() => {
                  this.resetState();
                  this.props.toggle();
                }}
              >
                close
              </i>
              <div className="popup-cancel-header">
                <h3>
                  {" "}
                  <Trans i18nKey="profile_page.cancel_seats.heading">
                    إلغاء جزئي للكراسي
                  </Trans>
                </h3>
              </div>
              <div className="popup-cancel-content">
                <Ticket
                  viewOnly
                  lang={lang}
                  ticket={ticket}
                  GoingSideComponent={() => (
                    <Seats
                      seats={ticket.myTrips[0].Seats}
                      mySeats={ticket.myTrips[0].mySeats}
                      type="going"
                    />
                  )}
                  ReturnSideComponent={() =>
                    ticket.myTrips.length > 1 && (
                      <Seats seats={ticket.myTrips[1].Seats} mySeats={ticket.myTrips[1].mySeats} type="return" />
                    )
                  }
                />
                {/* <Seats seats={ticket.myTrips[0].Seats} type="going" /> */}
                {/* {ticket.myTrips.length > 1 && ( */}
                {/* <> */}
                {/* <Seats seats={ticket.myTrips[1].Seats} type="return" /> */}
                {/* <TripDetails lang={lang} trip={ticket.myTrips[1]} /> */}
                {/* </> */}
                {/* )} */}
              </div>
              <div className="popup-cancel-footer">
                <label htmlFor="seat-cancel-terms" className="checkbox">
                  <input
                    type="checkbox"
                    id="seat-cancel-terms"
                    ref={(ref) => (this.checkbox = ref)}
                  />
                  <span className="name">
                    <Trans i18nKey="checkout_page.payment.accept_terms">
                      أوافق علي كل
                    </Trans>
                    <a href="/terms" target="_blank">
                      <Trans i18nKey="checkout_page.payment.terms_link_text">
                        الشروط والأحكام
                      </Trans>
                    </a>
                  </span>
                  <span className="check-icon" />
                </label>
                <div className="cancel-actions">
                  <a className="btn btn-red" onClick={this.cancelSeats}>
                    <Trans i18nKey="profile_page.cancel_seats.cancel_btn">
                      إلغاء الحجز
                    </Trans>
                  </a>
                  <a
                    className="btn btn-gray"
                    onClick={() => {
                      this.resetState();
                      this.props.toggle();
                    }}
                  >
                    <Trans i18nKey="profile_page.cancel_seats.exit_btn">
                      خروج
                    </Trans>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
	}

	cancelSeats = () => {
		let terms = this.checkbox;
		if(!isLoggedIn()){
			alert('Your Session is Expired, Please Re-Login!');
			Store.dispatch(logout(this.props.history)); 
		}else{

			if (!terms.checked) {
				this.setState({
					cancel_seat_error: true,
					errMsg: [
						i18next.t(
							"common.errors.accept_terms",
							"من فضلك وافق على الشروط و الاحكام"
						)
					]
				});
				return;
			} else {
				this.setState({
					cancel_seat_error: false,
					errMsg: [],
					cancelling: true
				});
			}
			const { ticket, toggle, onSuccess, afterSuccess, duration, user } = this.props;

			let seats = [];

			ticket.myTrips.forEach((trip, index) => {
				trip.mySeats.forEach(seat => {
					let selector =
						index === 0
							? "#seatnum-" + seat.SeatNo
							: "#seatnum-ret-" + seat.SeatNo;
					let s = document.querySelector(selector);
					if (seat.SeatStatusID !== 5 && s && s.checked) {
						seats.push(seat);
					}
				});
			});
			if(seats.length === 0){
				this.setState({
					cancel_seat_error: true,
					errMsg: [
						i18next.t(
							"common.errors.check_seat",
							"برجاء اختيار الكرسي المراد إلغاءه"
						)
					],
					cancelling: false
				});
				return;
			}
			let data = {
				CardID: ticket.CardID,
				seats,
				cardToken: ticket.cardToken,
				user
			};
		
			this.props
				.cancelSeats(data)
				.then(cancelResult => {
					if (cancelResult.data.success === true) {
					this.setState({
						cancel_seat_error: false,
						isActiveFullCancellation: false,
						cancelling: false
					});
					toggle();
					onSuccess();
					setTimeout(() => {
						afterSuccess();
					}, duration);
				} else {
					console.error("ERROR: ", cancelResult);
					// something else
					let error = i18next.t(
						"profile_page.cancel_seats.errors.unhandled_error",
						"لم نتمكن من إلغاء الكراسي"
					);

					if (cancelResult.msg === "invalid_refund_amount") {
						error = i18next.t(
							"profile_page.cancel_seats.errors.invalid_refund_amount",
							"لا يمكن إلغاء الكراسي مع العلم انه يتم خصم 15جنيها لكل كرسي بالرحلة"
						);
					} else if (cancelResult.msg === "mpgs_refund_failed") {
						error = i18next.t(
							"profile_page.cancel_seats.errors.mpgs_refund_failed",
							"تم إلغاء الحجز و لاكن فشل إرجاع المبلغ المستحق, سيتواصل معك احد ممثلي خدمة العملاء في اقرب وقت."
						);
					} else if (cancelResult.msg === "gobus_seats_error") {
						error = i18next.t(
							"profile_page.cancel_seats.errors.gobus_seats_error",
							"خطأ في الكراسي"
						);
					} else if (cancelResult.msg === "gobus_cancel_time_error") {
						error = i18next.t(
							"profile_page.cancel_seats.errors.gobus_cancel_time_error",
							"لا يمكن الغاء الكراسي في هذا الوقت."
						)
					}

					return this.setState({
						cancel_seat_error: true,
						cancelling: false,
						errMsg: [error]
					});
				}
				})
				.catch(err => {
					console.error("Error Cancelling Seats: ");
					console.error(err);
				});
		}	
		
	};

	resetState = () => {
		this.setState({
			cancel_seat_error: false,
			cancelling: false,
			errMsg: []
		});
	};
}

function Seats({ seats, mySeats, type }) {
	const seatnumPrefix = type === "going"? "seatnum-": "seatnum-ret-";
	return (
		<div className="custom-seats">
			<div className="rout">
				{/* <Trans i18nKey="profile_page.bookings.going_trip">رحلة الذهاب</Trans> */}
				{mySeats.map(seat => {
					if (seat.SeatStatusID === 5) {
						return null;
					}
					
					return (
						<label
							htmlFor={seatnumPrefix + seat.SeatNo}
							className="checkbox">
							<input
								type="checkbox"
								id={seatnumPrefix + seat.SeatNo}
							/>
							<span className="seat-icon">
								{seat.SeatNo}
							</span>
							<span className="check-icon" />
						</label>
					);
				})}
			</div>
		</div>
	)
}

const mapStateToProps = state => ({
	lang: state.locale.lang,
	user: state.user.user
});

const mapDispatchToProps = dispatch => ({
	cancelSeats: bindActionCreators(cancelSeats, dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CancelSeatsModal);
