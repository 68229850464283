export const Types = {
  ADD_TO_DATALAYER: "analytics/ADD_TO_DATALAYER",
  PUSH_TO_DATALAYER: "analytics/PUSH_TO_DATALAYER",
  RESET_DATALAYER: "analytics/RESET_DATALAYER"
};

const defaultState = {
  dataLayer: null
};

export default function(state = defaultState, action = {}) {
  switch (action.type) {
    case Types.ADD_TO_DATALAYER:
      return {
        ...state,
        dataLayer: {
          ...state.dataLayer,
          ...action.data
        }
      };

    case Types.RESET_DATALAYER:
      return {
        ...state,
        dataLayer: null
      };

    default:
      return state;
  }
}

const addToDataLayer = (data = {}) => {
  return {
    type: Types.ADD_TO_DATALAYER,
    data
  };
};

export const pushToDataLayer = (data = {}) => (dispatch, getState) => {
  dispatch(addToDataLayer(data));
  return dispatch({
    type: Types.PUSH_TO_DATALAYER
  });
};

export const resetDataLayer = () => {
  return {
    type: Types.RESET_DATALAYER
  };
};

export const getDataLayer = state => {
  return state.gtm.dataLayer;
};
