import React from "react";
import { Trans } from 'react-i18next';

const DiscountLabel = () => {
  return (
    <div className="widget-lable discount-lable">
         <p> <Trans i18nKey="labels.discount">خصم خاص</Trans> </p>
        
     
    </div>
  );
};

export default DiscountLabel;
