import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'underscore';

// Actions 
import * as TripActions from '../../../Actions/TripActions'
// Components 
import {CheckBoxComponent} from './CheckBoxComponent'
import FiltersLoaderComponent from '../../Utils/Placeholders/FiltersPlaceholder'
import { translateSingleFilter } from '../../../Helpers/translations';
import { Trans } from 'react-i18next';
import i18next from 'i18next';
import { getClassi18nKey } from '../../../appConfig';

function mapStateToProps(store) {
    return {
        locale: store.locale,
        searchStore: store.search,
        tripsStore:store.trips
    }
}

function mapDispatchToProps(dispatch) {
    return {
        tripActions: bindActionCreators(TripActions, dispatch),
    }
}


class FilterComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showUnSelectAll:false,
            filtering:false,
            isActiveFilter: false,
        }

        this.filter = this.filter.bind(this)
        this.filterGoingTrips= this.filterGoingTrips.bind(this)
        this.filterReturningTrips= this.filterReturningTrips.bind(this)
        this.showAllTrips = this.showAllTrips.bind(this)


        this.choosenGoingDepartureSegment=[]
        this.choosenGoingArrivalSegment=[]
        this.choosenReturningDepartureSegment=[]
        this.choosenReturningArrivalSegment=[]
        this.choosenBuses=[]

    }


    toggleClasses = () => {
        this.setState({ isActiveFilter: !this.state.isActiveFilter });
    }
    
    /**
     * filter all trips ['going','returning'] by bus class
     * @param {string} bus 
     * @author Ezzat
     */
    filterBuses(bus){
        console.log({chosenBus: this.choosenBuses, bus});
        if (_.contains(this.choosenBuses, bus)) {
            let new_choosen_buses = this.choosenBuses.filter(i => {
                return i !== bus
            })
            this.choosenBuses = new_choosen_buses
        } else {
            this.choosenBuses.push(bus)
        }
        // if no bus choosen and no other filters applied
        if(this.choosenBuses.length < 1 && this.choosenGoingDepartureSegment.length < 1 && this.choosenGoingArrivalSegment.length < 1 && this.choosenReturningDepartureSegment.length < 1 && this.choosenReturningArrivalSegment.length < 1){
            this.showAllTrips()
        }else{
            this.filterGoingTrips()
            this.filterReturningTrips()
        }
    }

    /**
     * filter the going trips by departure time segment and update the store by this trips
     * @param {integer} segment [0,1,2,3]
     * @author Ezzat
     */
    filterGoingDepartureSegment(segment){
        // Check if the time segment Exist, if so remove it
        // if not exist add it
        if (_.contains(this.choosenGoingDepartureSegment, segment)) {
            let new_choosen_time_segments = this.choosenGoingDepartureSegment.filter(i => {
                return i !== segment
            })
            this.choosenGoingDepartureSegment = new_choosen_time_segments
        } else {
            this.choosenGoingDepartureSegment.push(segment)
        }
        this.filter('going')
    }
    
    /**
     * filter the going  trips by arrival time segment and update the store by this trips
     * @param {integer} segment [0,1,2,3]
     * @author Ezzat
     */
    filterGoingArrivalSegment(segment){
        // Check if the time segment Exist, if so remove it
        // if not exist add it
        if (_.contains(this.choosenGoingArrivalSegment, segment)) {
            let new_choosen_time_segments = this.choosenGoingArrivalSegment.filter(i => {
                return i !== segment
            })
            this.choosenGoingArrivalSegment = new_choosen_time_segments
        } else {
            this.choosenGoingArrivalSegment.push(segment)
        }
        
        this.filter('going')
    }

    /**
     * filter the returning trips by departure time segment and update the store by this trips
     * @param {integer} segment [0,1,2,3]
     * @author Ezzat
     */
    filterReturningDepartureSegment(segment){
        // Check if the time segment Exist, if so remove it
        // if not exist add it
        if (_.contains(this.choosenReturningDepartureSegment, segment)) {
            let new_choosen_time_segments = this.choosenReturningDepartureSegment.filter(i => {
                return i !== segment
            })
            this.choosenReturningDepartureSegment = new_choosen_time_segments
        } else {
            this.choosenReturningDepartureSegment.push(segment)
        }
        this.filter('returning')
    }
    
    /**
     * filter the returning trips by arrival time segment and update the store by this trips
     * @param {integer} segment [0,1,2,3]
     * @author Ezzat
     */
    filterReturningArrivalSegment(segment){
        // Check if the time segment Exist, if so remove it
        // if not exist add it
        if (_.contains(this.choosenReturningArrivalSegment, segment)) {
            let new_choosen_time_segments = this.choosenReturningArrivalSegment.filter(i => {
                return i !== segment
            })
            this.choosenReturningArrivalSegment = new_choosen_time_segments
        } else {
            this.choosenReturningArrivalSegment.push(segment)
        }
        
        this.filter('returning')
    }
    
    /**
     * trips filtering mapper
     * @param {string} tripType ['going','returning']
     * @author Ezzat
     */
    filter(tripsType) {
        this.setState({
            filtering: true
        }, () => {
            if(tripsType === 'going'){
                this.filterGoingTrips()
            }else{
                this.filterReturningTrips()
            }
            // If No Filters Applied
            if (this.choosenBuses.length < 1 && this.choosenGoingDepartureSegment.length < 1 && this.choosenGoingArrivalSegment.length < 1 && this.choosenReturningDepartureSegment.length < 1 && this.choosenReturningArrivalSegment.length < 1) {
                this.showAllTrips()
                this.setState({
                    showUnSelectAll: false,
                })
            } else {
                this.setState({
                    showUnSelectAll: true,
                })
            }

            setTimeout(() => {
                this.setState({
                    filtering: false
                }, () => {
                })
            }, 500);
        })
    }

    /**
     * Filter the going trips combined togther 
     * we check if we already have filtred data in the "new_filtered_trips", 
     * if not we make previous_trips the base trips so we have access to filter all trips
     * if so we make previous_trips  to be the filtered_trips and we start filter on them, so we make it combined
     * @author Ezzat
     */
    filterGoingTrips(){
        let new_filtered_trips = []; // the last trips to be returnrd
        let previous_trips = []; // to swap trips in it and make the filters combined
        let base_trips = this.props.tripsStore.goingTrips //the whole trips with no filters


        // filter by buses
        if (this.choosenBuses.length > 0) {
            (new_filtered_trips.length) ? previous_trips = new_filtered_trips : previous_trips = base_trips
            new_filtered_trips = []
            previous_trips.forEach(trip => {
                if (_.contains(this.choosenBuses, trip.TripServKind_Code)) {
                    new_filtered_trips.push(trip)
                }
            })
        }

        // Filter by Departure timeSegment
        if (this.choosenGoingDepartureSegment.length > 0) {
            (new_filtered_trips.length) ? previous_trips = new_filtered_trips : previous_trips = base_trips
            new_filtered_trips = []
            previous_trips.forEach(trip => {
                if (_.contains(this.choosenGoingDepartureSegment, trip.departureTimeSegment)) {
                    new_filtered_trips.push(trip)
                }
            })
        }
        // Filter by Arrival TimeSegment
        if (this.choosenGoingArrivalSegment.length > 0) {
            (new_filtered_trips.length) ? previous_trips = new_filtered_trips : previous_trips = base_trips
            new_filtered_trips = []
            previous_trips.forEach(trip => {
                if (_.contains(this.choosenGoingArrivalSegment, trip.arrivalTimeSegment)) {
                    new_filtered_trips.push(trip)
                }
            })
        }

        if (new_filtered_trips.length < 1) {
            new_filtered_trips = base_trips;
        }
        this.props.tripActions.updateDisplayedGoingTrips(new_filtered_trips)

    }

    /**
     * same logic as the @method filterGoingTrips
     * @author Ezzat
     */
    filterReturningTrips(){
        let new_filtered_trips = [];
        let previous_trips = [];
        let base_trips = this.props.tripsStore.returningTrips

        // filter by buses
        if (this.choosenBuses.length > 0) {
            (new_filtered_trips.length) ? previous_trips = new_filtered_trips : previous_trips = base_trips
            new_filtered_trips = []
            previous_trips.forEach(trip => {
                if (_.contains(this.choosenBuses, trip.TripServKind_Code)) {
                    new_filtered_trips.push(trip)
                }
            })
        }
        
        // Filter by Departure timeSegment 
        if (this.choosenReturningDepartureSegment.length > 0) {
            (new_filtered_trips.length) ? previous_trips = new_filtered_trips : previous_trips = base_trips
            new_filtered_trips = []
            previous_trips.forEach(trip => {
                if (_.contains(this.choosenReturningDepartureSegment, trip.departureTimeSegment)) {
                    new_filtered_trips.push(trip)
                }
            })
        }
        // Filter by Arrival TimeSegment
        if (this.choosenReturningArrivalSegment.length > 0) {
            (new_filtered_trips.length) ? previous_trips = new_filtered_trips : previous_trips = base_trips
            new_filtered_trips = []
            previous_trips.forEach(trip => {
                if (_.contains(this.choosenReturningArrivalSegment, trip.arrivalTimeSegment)) {
                    new_filtered_trips.push(trip)
                }
            })
        }
        
        if (new_filtered_trips.length < 1) {
            new_filtered_trips = base_trips;
        }
        
        this.props.tripActions.updateDisplayedReturningTrips(new_filtered_trips)
    }

    /**
     * show all Trips, act like removing all filters
     * @author Ezzat
     */
    showAllTrips(){
        // show all trips
        this.props.tripActions.updateDisplayedGoingTrips(this.props.tripsStore.goingTrips)
        this.props.tripActions.updateDisplayedReturningTrips(this.props.tripsStore.returningTrips)
    }

    render() {
        let searchStore = this.props.searchStore
        
        // This is to show the filters DATA only when ther'es a departure station 
        // prevent crashes in case that the stations APIs took so long 
        // or if the user tried to manipulte the search params
        if(!searchStore.departureStation){
            return (null)
        }
        // While Getting Trips
        if (this.props.tripsStore.fetchingGoingTrips) {
            // reset selected filters so it doesn't get messed up after loading new trips
            this.choosenBuses = [];
            this.choosenGoingArrivalSegment = []
            this.choosenGoingDepartureSegment = []
            this.choosenReturningArrivalSegment = []
            this.choosenReturningDepartureSegment = []
            return(
                <FiltersLoaderComponent />
            )
        }

        const { lang } = this.props.locale;
        const stationKey = lang === 'ar'? "NameA": "NameE";

        let FilterClasses = (this.state.isActiveFilter) ? "hideMob active" : "hideMob";
        return (
            <div className="filter-component">
                <form>
                    <div className={FilterClasses}>
                        {/* Bus Filters */}
                        {
                            (this.props.tripsStore.goingTripFilters.buses.length > 0)&& 
                            <ul className="list-filter-sec">
                                <li>
                                    <h4><Trans i18nKey="search_page.filters.bus_categories">فئة الأتوبيسات</Trans></h4>
                                </li>
                                {
                                    this.props.tripsStore.goingTripFilters.buses.map((bus,i) => {
                                        return (
                                            <li key={i}>
                                                <input type="checkbox" className="filled-in" id={`bus-${i}`} onClick={this.filterBuses.bind(this, bus.code)} />
                                                <label className="checkbox-label" htmlFor={`bus-${i}`} > {bus[lang]} </label>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        }
                        
                        <CheckBoxComponent
                            title={`${i18next.t('search_page.filters.departure_times', {defaultValue: "مواعيد القيام من"})} ${searchStore.departureStation[stationKey].includes("-") ? searchStore.departureStation[stationKey].slice(0, searchStore.departureStation[stationKey].indexOf("-")) : searchStore.departureStation[stationKey]}`}
                            type={`goingDepartureSegment`}
                            segments={this.props.tripsStore.goingTripFilters.departureTimeSegment}
                            lang={this.props.locale.lang}
                            clickHandler={this.filterGoingDepartureSegment.bind(this)}
                        />

                        {
                            (this.props.searchStore.tripType === 'round') && 
                            (
                                <CheckBoxComponent
                                    title={`${i18next.t('search_page.filters.departure_times', {defaultValue: "مواعيد القيام من"})} ${searchStore.arrivalStation[stationKey].includes("-")? searchStore.arrivalStation[stationKey].slice(0, searchStore.arrivalStation[stationKey].indexOf("-")): searchStore.arrivalStation[stationKey]}`}
                                    type={`returnDepartureSegment`}
                                    segments={this.props.tripsStore.returningTripsFilters.departureTimeSegment}
                                    lang={this.props.locale.lang}
                                    clickHandler={this.filterReturningDepartureSegment.bind(this)}
                                />
                            )
                        }
                        <div className="actions">
                            <span className="closeFilter" onClick={() => { this.toggleClasses() }}><i className="material-icons">close</i></span>
                            <div className="btnContainer">
                                <span className="submitForm" onClick={() => { this.toggleClasses() }}><Trans i18nKey="search_page.filters.filter_results">تصفية النتائج</Trans></span>
                            </div>
                        </div>
                    </div>
                    <div className="showMob">
                        <span className="openFilter" onClick={() => { this.toggleClasses() }}><Trans i18nKey="search_page.filters.filter_results">تصفية النتائج</Trans></span>
                    </div>
                </form>
            </div>
      )
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(FilterComponent)
