import React, { Component } from "react";
import { Trans } from 'react-i18next';

export default class SeatsCountComponent extends Component {
  /**
   * 
   * @param {object} props 
   * @param {number} props.passengersNo 
   */
  constructor(props) {
    super(props);

    this.state = {
      active: false,
      // passengersNo: props.passengersNo | 1
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleMouseClick);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleMouseClick);
  }

  toggle = () => {
    this.setState({
      active: !this.state.active
    })
  }

  //TODO: REFACTOR
  handleMouseClick = (event) => {
    if (this.state.active) {
      const path = event.path || (event.composedPath && event.composedPath());
      if(!path || !Array.isArray(path)) return;
      let selector = path.filter(path => {
        return path.className === 'seat-selector' |
          path.className === 'seat-btn'
      })

      if (selector.length === 0)
        this.setState({
          active: false
        })
    }
  }

  increment = () => {
    this.setState({
      passengersNo: this.state.passengersNo + 1
    })
  }

  decrement = () => {
    if (this.state.passengersNo === 1) return;
    this.setState({
      passengersNo: this.state.passengersNo - 1
    })
  }

  render() {
    return (
      <div className={"col people small seats-count-component " + this.props.className} >
        <div className="input-field">
          <label>
            <i className="material-icons">people</i> <Trans i18nKey="common.passengers_no">عدد المسافرين</Trans>
          </label>
          {/* <button className="seat-btn" type="button" onClick={this.toggle}>
            {this.props.passengersNo} كراسي
          </button>
          {this.state.active ? ( */}
            <div className={"seat-selector-default " + this.props.className}>
              <button
                type="button"
                className="seat-inc-btn"
                onClick={this.props.increment}
              >
                <i className="material-icons">add</i>
              </button>
              <span>{this.props.passengersNo}</span>
              <button
                type="button"
                className="seat-dec-btn"
                onClick={this.props.decrement}
              >
                <i className="material-icons">remove</i>
              </button>
            </div>
          {/* ) : null} */}
        </div>
      </div>
    );
  }
}
