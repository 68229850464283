
class GoogleAnalyticsTracker {
  _safe = false;

  init() {
    if (typeof window.gaData === 'object') {
      for (const key of Object.keys(window.gaData)) {
        const found = /UA-\d+-\d+/.test(key);
        if (found) {
          if (this.isSafe()) {
            window.ga('create', key, 'auto');
            this._safe = true;
          } else {
            return console.error('GA Error: Key found but ga creator is not defined')
          }
          return key;
        }
      }
      console.error('GA Error: Key not found.');
    } else {
      console.error('GA Error: gaData is not an object.')
    }
  }

  set(param, value) {
    this._safe && window.ga('set', param, value);
  }

  send(event) {
    this._safe && window.ga('send', event);
  }

  isSafe() {
    return typeof window.ga === 'function';
  }
}

export default new GoogleAnalyticsTracker();