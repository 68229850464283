import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Utils from '../../Helpers/Utils';
import GoogleAnalytics from '../../Helpers/GoogleAnalytics';


/**
 * Just to provide locale update to the url
 * and control over history and locations
 */
class LocationListenerComponent extends Component {
  componentDidMount() {
    // init google analytics tracker
    document.addEventListener('readystatechange', this.checkAndInitGA)

    this.props.history.listen((location, action) => {
      const lang = this.props.lang;
      let newPath = location.pathname + location.search;
      
      if (lang === "en") {
        Utils.URLParams.add("lang", lang);

        // check if newPath contains the lang param
        if (!/lang=/.test(newPath)) {
          newPath += location.search? "&lang=" + lang: "?lang=" + lang;
        }
      }

      /**
       * Timeout is added to give time for page title to update
       * if page titles return previous page visit in google analytics
       * increase timeout slightly.
       */
      setTimeout(() => {
        // set current ga page to the location user redirecting to
        GoogleAnalytics.set('page', newPath);
        
        // send pageview event to ga
        GoogleAnalytics.send('pageview');
      }, 500);
    });
  }

  checkAndInitGA() {
    if (document.readyState === 'complete') {
      GoogleAnalytics.init();
    }

    // remove onreadystate listener
    document.onreadystatechange = null;
  }

  render() {
    return this.props.children;
  }
}

const mapStateToProps = state => ({ lang: state.locale.lang });

// actual component after providing react router to in and redux
export default connect(mapStateToProps)(withRouter(LocationListenerComponent));