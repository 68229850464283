import React, { useCallback, useState } from "react";
import { Trans } from 'react-i18next';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import { featuredClassesArray } from "../../appConfig";
import BusHelper, { getServiceAsset } from "../../Helpers/BusHelper";
import TripClassComponent from "../Search/Trip/TripClassComponent";
import { connect } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import clsx from "clsx";

function GalleryPopup({
  view,
  locale,
  overrideImages,
  overrideShowPopup,
  overrideSetShowPopup,
  overrideTripClass,
  overrideFeaturedClass,
}) {
  const [showPopup, setShowPopup] = useState(false);
  const [featuredClass, setFeaturedClass] = useState(null);

  const togglePopup = useCallback(() => {
    if (overrideSetShowPopup) {
      overrideSetShowPopup();
      return;
    }

    setShowPopup(prev => !prev);
  }, []);

  const onFeaturedClassClick = useCallback((fClass) => {
    setFeaturedClass(fClass);
    setShowPopup(prev => !prev);
  }, []);

  return (
    <>
      {view !== 'trip-list' && <div className="bus-classes" >
        <div className="container">
          <div className="section-title">
            <h3><Trans i18nKey="home_page.bus_classes.headline">فئات جوباص</Trans></h3>
            <p><Trans i18nKey="home_page.bus_classes.subline">استكشف الرحلات الأكثر حجزا في مصر عبر جوباص</Trans></p>
          </div>
          <div className="bus-classes-con">
            {/* <Slider {...slidersettings}> */}
            {featuredClassesArray.map((fclass, i) => {
              return (
                <FeaturedClass
                  key={i}
                  className={fclass.className}
                  imagePath={fclass.image}
                  services={fclass.services}
                  code={fclass.code}
                  price={fclass.price}
                  i18nKey={fclass.i18nKey}
                  priceDepends={fclass.priceDepends}
                  gallery={fclass.gallery}
                  locale={locale}
                  onClick={() => onFeaturedClassClick(fclass)}
                />
              );
            })}
          </div>
        </div>
      </div>}
      <Popup
        isOpen={overrideShowPopup || showPopup}
        overrideImages={overrideImages}
        overrideTripClass={overrideTripClass}
        featuredClass={overrideFeaturedClass || featuredClass}
        locale={locale}
        onClose={togglePopup} />
      <Overlay
        isOpen={overrideShowPopup || showPopup}
        onClick={togglePopup} />
    </>
  );
}
const Popup = (props) => {
  const { featuredClass, overrideImages, overrideTripClass, isOpen, locale } = props;
  const { lang } = locale;

  let i18nKey;
  let code;
  let className;
  let price = {};
  let services = [];

  if (featuredClass) {
    i18nKey = featuredClass.i18nKey;
    code = featuredClass.code;
    className = featuredClass.className;
    price = featuredClass.price;
    services = featuredClass.services;
  }

  /**
   * Missing classes images
   *  - Go Limo
   *  - VIP
   */
  let images = overrideImages || BusHelper(className, { emptyOnDefault: true });
  images = images.map(img => ({ src: img }));

  return <AnimatePresence exitBeforeEnter>
    {isOpen ? <motion.div className="gallrypopup"
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      transition={{ duration: 0.3, type: "spring", stiffness: 90 }}
    >
      <Carousel
        images={images}
        hasMediaButton={false}
        hasSizeButton={false}
        isLoop={false}
      />
      {featuredClass && <div className="bottom-box">
        <div className="second">
          {(overrideTripClass && overrideTripClass()) || <TripClassComponent
            i18nKey={i18nKey}
            TripServKind_Code={code}
            TripServKind_NameA_Short={className}
          />}
        </div>

        <div className="third">
          <>
            <p><Trans i18nKey="home_page.bus_classes.avg_ticket_price">متوسط سعر التذكرة</Trans></p>
            <h4>
              {price.low} EGP - {price.high} EGP
            </h4>
          </>
        </div>


        <ul>
          {services.length > 0 &&
            services.map((svc, i) => {
              return (
                <li key={i}>
                  <p>{svc.name[lang]}</p>
                  <img
                    className="svc-img"
                    title={svc.name[lang]}
                    src={getServiceAsset(svc.name.en)}
                    alt="svcimg"
                  />
                </li>
              );
            })}
        </ul>
      </div>}
      {/* <GalleryWidget single gallery={BusHelper(featuredClass.className)} /> */}
      <button className="close-button" onClick={props.onClose}>
        <span class="material-symbols-outlined">
          close
        </span>
      </button>
    </motion.div> : null}
  </AnimatePresence>
}
const Overlay = (props) => {
  return <AnimatePresence exitBeforeEnter>
    {props.isOpen ? <motion.div
      className="overlay"
      onClick={props.onClick}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    ></motion.div> : null}
  </AnimatePresence>;
}

export const NGalleryPopup = ({
    className,
    isOpen,
    onOverlayClick,
    onClose,
    bottomChildren,
    thirdChildren,
    children,
    style
}) => {
    const classes = clsx([className, "gallrypopup"]);
    return (
        <>
            <AnimatePresence exitBeforeEnter>
                {isOpen ? (
                    <motion.div
                        className={classes}
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -50 }}
                        transition={{
                            duration: 0.3,
                            type: "spring",
                            stiffness: 90
                        }}
                        style={style}
                    >
                        {children && children}
                        {bottomChildren && (
                            <div className="bottom-box">{bottomChildren}</div>
                        )}
                        {thirdChildren && (
                            <div className="third">{thirdChildren}</div>
                        )}

                        <button className="close-button" onClick={onClose}>
                            <span className="material-symbols-outlined">
                                close
                            </span>
                        </button>
                    </motion.div>
                ) : null}
            </AnimatePresence>
            <AnimatePresence>
                {isOpen ? (
                    <motion.div
                        className="overlay"
                        onClick={onOverlayClick}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    ></motion.div>
                ) : null}
            </AnimatePresence>
        </>
    );
};

let FeaturedClass = props => {
  let { locale, price, className, imagePath, services, code, i18nKey, priceDepends, onClick } = props;
  const { lang } = locale;

  if (!price || !className || !imagePath) return null;

  return (
    <div className="bus-class-item" onClick={onClick}>
      <div className="bus-class">
        <TripClassComponent
          i18nKey={i18nKey}
          TripServKind_Code={code}
          TripServKind_NameA_Short={className}
          TripServKind_NameE={className}
        />
        {/* <span className="bus-class-name bus-class-name-royal">{className}</span> */}
        <ul>
          {services.length > 0 &&
            services.map((svc, i) => {
              return (
                <li key={i}>
                  <img
                    className="svc-img"
                    title={svc.name[lang]}
                    src={getServiceAsset(svc.name.en)}
                    alt="svcimg"
                  />
                  <span className="title">{svc.name[lang]}</span>
                </li>
              );
            })}
        </ul>

        <img
          src={imagePath}
          alt="cls-img"
          className="class-bus-image"
        />
        {!priceDepends && (
          <>
            <p><Trans i18nKey="home_page.bus_classes.avg_ticket_price">متوسط سعر التذكرة</Trans></p>
            <h4>
              {price.low} EGP - {price.high} EGP
            </h4>
          </>
        )}
        {priceDepends && (
          <>
            <p><Trans i18nKey="home_page.bus_classes.avg_ticket_price">متوسط سعر التذكرة</Trans></p>
            <h4 style={{ fontFamily: 'Cairo' }}><Trans i18nKey="home_page.bus_classes.priceDepends">على حسب الرحلة</Trans></h4>
          </>
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    locale: state.locale
  }
}

export default connect(mapStateToProps)(GalleryPopup);