import React, { Component } from "react";
import Helmet from "react-helmet";

//components
import MainLayoutComponent from "../MainLayoutComponent";

//redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as CorporateActions from "../../Actions/CorporateActions";
import BlogComponent from "../Corporate/BlogComponent";
import moment from "moment";
import { buildYoastMetaTags } from "../../Helpers/Yoast";
import Utils from "../../Helpers/Utils";

function mapStateToProps(store) {
  return {
    lang: store.locale.lang,
    corporates: store.corporate
  };
}

function mapDispatchToProps(dispatch) {
  return {
    corporateActions: bindActionCreators(CorporateActions, dispatch)
  };
}

class SingleNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blog_s: [],
      posts: []
    };
    
    this.page = null;
    this.slug = this.props.match.params.slug;
    this.singleNews = this.props.corporates.news.single_news;
  }

  componentWillMount() {
    if (this.singleNews && this.singleNews.slug === this.slug) return;
    this.props.corporateActions.getSingleNews(this.slug);
  }

  componentWillUpdate(nextProps, nextState) {
    if((this.props.match.params.slug === nextProps.match.params.slug) || (this.singleNews && this.singleNews.slug === this.slug)) {
      return;
    }

    this.slug = nextProps.match.params.slug;
    this.props.corporateActions.getSingleNews(this.slug);
    Utils.scrollToElement(this.page);
  }
  
  getContent = (options = { key: 'single_news', defaultReturn: {} }) => {
    const news = this.props.corporates.news[options.key] || {};
    const { lang } = this.props;
    // translated data & undefined safety object
    return news[lang]? news[lang]: news['ar'] || options.defaultReturn;
  }

  render() {
    let singleNews = this.getContent(); //this.props.corporates.news.single_news;

    // Single Blog Data
    let Title = singleNews.title ? singleNews.title.rendered : null;
    let Content = singleNews.content ? singleNews.content.rendered : null;
    let PublishDate = singleNews.date ? singleNews.date : null;
    let Image = singleNews.acf ? singleNews.acf.hero_image.url : null;
    let ImageMob = singleNews.acf ? singleNews.acf.hero_image_mob.url : null;

    return (
      <MainLayoutComponent page={this.props.location.pathname}>
        <Helmet key={window.location.href} >
          <title>{Title}</title>
          {buildYoastMetaTags(singleNews.yoast_meta).map(tag => (
            <meta {...tag} />
          ))}
        </Helmet>
        <div className="page-content single-news" ref={ref => this.page = ref}>
          <div className="main-title">
            <div className="container">
              {(singleNews.acf
                ? singleNews.acf.featured_big
                : null === true || singleNews.acf
                ? singleNews.acf.featured_small
                : null === true) && <strong>جديد</strong>}
              <h1>{Title}</h1>
              <span>{moment(PublishDate).format("ddd D MMMM  YYYY")}</span>
            </div>
          </div>
          <div className="single-news-content">
            <div className="container">
              {(singleNews.acf
                ? singleNews.acf.hero_image.url
                : null !== false) && <img src={Image} alt="Blog image" className='hide-mob'/>}
              {(singleNews.acf
                ? singleNews.acf.hero_image_mob.url
                : null !== false) && <img src={ImageMob} alt="Blog image" className='show-mob'/>}
              <div
                className="details"
                dangerouslySetInnerHTML={{ __html: Content }}
              />
            </div>
          </div>
          <BlogComponent count={3} title={'اقرأ المزيد من الأخبار'} noTitle={true} />

        </div>
      </MainLayoutComponent>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleNew);
