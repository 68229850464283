import axios from 'axios';
import { baseApi, gobus_payUrl } from './Base';

import i18next from 'i18next';
import store2 from 'store2';
import apiErrorHandler from './apiErrorHandler';
const token = store2.get('token')

class TripApi {

    static handleHoldResult(error, response) {
        
        if (error) {
            return i18next.t('search_page.errors.hold_fail_general', 'لم نتمكن من حجز كراسي, رجاءاً حاول مرة أخرى');
        }
        
        let errorMsg;

        if (response && response.Result.length > 0) {
            const errorsMap = {
                "user Token Not Found": i18next.t('search_page.errors.hold_fail_relogin', 'لم نتمكن من حجز كراسي, رجاءاً حاول تسجيل الدخول مرة أخرى'),
                "Going Trip Time Error": i18next.t('search_page.errors.hold_fail_invalid_date', 'لم نتمكن من حجز كراسي, موعد الرحلة غير صحيح. رجاءاً اعد البحث مرة اخرى'),
                'no_seats_available': i18next.t('search_page.errors.hold_fail_no_seats', 'للأسف! لا توجد كراسي متاحة، قد تم حجز الأتوبيس بالكامل'),
                "Go goMini Error:المقاعد محجوزة": i18next.t('search_page.errors.hold_fail_no_seats', 'للأسف! لا توجد كراسي متاحة، قد تم حجز الأتوبيس بالكامل'),
                "Some Coming Seats is not available Now": i18next.t('search_page.errors.some_coming_seats', 'بعض كراسي العودة غير متاحة حالياً'),
                "Some Going Seats is not available Now": i18next.t('search_page.errors.some_going_seats', 'بعض كراسي الذهاب غير متاحة حالياً')
            }

            errorMsg = errorsMap[response.Result];

            if (!errorMsg) {
                error = i18next.t('search_page.errors.hold_fail_general', 'لم نتمكن من حجز كراسي, رجاءاً حاول مرة أخرى');
            }
        }

        return error;
    }

    static trips(from, to, date) {
        return axios.get(`${baseApi}/GetTrips?From=${from}&To=${to}&tripDate=${date}`)
            .then(res => {
                return res.data;
            }).catch(err => {
                apiErrorHandler(err);
                return err;
            });
    }

    static async getTripsByCity(FromCityID, ToCityID, tripDate) {
        const result = await axios.get(`${baseApi}/GetTripsByCity`, {
            params: {
                FromCityID,
                ToCityID,
                tripDate,
                token: 'gobusV3'
            }
        }).catch(err => {
            apiErrorHandler(err);
            return err;
        });
        return result.data;
    }

    static async getTripInfo(tripSubData_Id) {
        const result = await axios.get(`${baseApi}/GetTrips?tripSubData_Id=${tripSubData_Id}`)
            .catch(err => {
                apiErrorHandler(err);
                return err;
            });
        return result.data;
    }

    static async seats(tripSubDataId) {
        const response = await axios.get(`${baseApi}/GetSeats?tripSubData_Id=${tripSubDataId}`)
            .catch(err => {
                apiErrorHandler(err);
                return err;
            });
        return response.data;
    }

    static holdSeats(t, userToken) {
        let url = `${baseApi}/HoldSeats?userID=${t.userID}&goSeats=${t.goSeats}&goTripSubData_Id=${t.goTripSubData_Id}&userToken=${userToken}`;

        if(t.comeSeats) {
            url += `&comeSeats=${t.comeSeats}`
        }

        if(t.comeTripSubData_Id) {
            url += `&comeTripSubData_Id=${t.comeTripSubData_Id}`
        }
        return axios.get(url)
            .then(res => {
                return [null, res.data];
            }).catch(err => {
                return [err, null];
            });
    }

    static cancelTicket(card) {
        return axios.post(`${gobus_payUrl}/cancelCard`, card)
            .then(res => res.data)
            .catch(err => {
                return err;
            });
    }

    static cancelSeats(data) {
        return axios.post(`${gobus_payUrl}/cancelSeats`, data)
            .then(res => res.data)
            .catch(err => {
                return err;
            })
    }

    /**
     * 
     * @param {*} data 
     * @returns {{Sms:"",Result:"",CardID:1147790,JobID:2040400}}
     */
    static bookWithAman(data) {
        return axios.put(`${gobus_payUrl}/payFawryAman`, data);
    }

    static bookTrip(data, payment_method) {
        return axios.post(`${gobus_payUrl}/payment`, { ...data, payment_method });
    }
    
    static applyPromoCode(p) {
        return axios.get(`${baseApi}/GetCouponsVal?couponCode=${p.couponCode}&cardId=${p.cardId}`)
            .then(res => {
                return res.data;
            }).catch(err => {
                apiErrorHandler(err);
                return err;
            });
    }

    /**
     * 
     * @param {Number} cardID 
     */
    static undoPromoCode(cardID) {
        return axios.post(`${baseApi}/CouponCancel`, { cardID })
            .then(res => [res.data, null]).catch(err => {
                apiErrorHandler(err);
                return [null, err];
            })
    }
}
export default TripApi;
