import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { setOnline } from '../../Actions/AppActions';

class OfflineListenerComponent extends Component {
  componentDidMount() {
    window.addEventListener('online', this.handleChange.bind(this, true));
    window.addEventListener('offline', this.handleChange.bind(this, false));
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.handleChange.bind(this, true));
    window.removeEventListener('offline', this.handleChange.bind(this, false));
  }

  handleChange = (state) => {
    this.props.setOnline(state);
  }

  render() {
    if (this.props.isOnline) {
      return this.props.children;
    } else {
      return (
        <>
          <div className="top-msg-header gray">
            <Trans i18nKey="header.no_internet_conn">لايوجد إتصال مع الإنترنت، الرجاء التأكد والمحاولة بعد قليل</Trans>
          </div>
          {this.props.children}
        </>
      );
    }
  }
}

const mapStateToProps = state => {
  return { isOnline: state.app.isOnline };
}

const mapDispatchToProps = dispatch => { 
  return {setOnline: bindActionCreators(setOnline, dispatch)}
}


export default connect(mapStateToProps, mapDispatchToProps)(OfflineListenerComponent)