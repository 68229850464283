import ContentLoader from "react-content-loader";
import React, { Component } from "react";
import debug from "./DebugLog";

export default class ImageLoader extends Component {
  
  constructor(props) {
    super();

    this.state = {
      loaded: false
    }
  }

  componentDidMount() {
    this.img = new Image();
    this.img.src = this.props.imageUrl

    this.img.onload = () => {
      this.setState({loaded: true})
    }
  }
  
  
  render() {
    return (
      this.state.loaded
      ? <img src={this.img.src} alt=""/>
      : <ContentLoader 
          height={this.props.height || 500}
          width={this.props.width || 500}
          speed={this.props.speed || 2}
          primaryColor={this.props.primaryColor || "#f3f3f3"}
          secondaryColor={this.props.secondaryColor || "#ecebeb"}
          style={{
            width: this.props.width,
            height: this.props.height
          }}
        >
          
        </ContentLoader>
    )
  }
}