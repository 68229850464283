import { Types, getDataLayer } from "../Reducers/GTMReducer";

const DATA_LAYER_CONTAINER = 'dataLayer';

export default ({ getState }) => {
  return next => action => {
    if(!window.google_tag_manager) return next(action);
    const _dataLayer = window[DATA_LAYER_CONTAINER] || [];
    // const _gtm = window.google_tag_manager['GTM-TNWWBM9'];
    const _gtm = window.google_tag_manager['GTM-KSTP7WP'];

    if (action.type === Types.PUSH_TO_DATALAYER) {
      _dataLayer.push(getDataLayer(getState()));
    } else if (action.type === Types.RESET_DATALAYER) {
      // _gtm[DATA_LAYER_CONTAINER].reset();
    }

    return next(action);
  };
};
