import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import store2 from "store2";
import { bindActionCreators } from "redux";

import * as UserActions from "../../Actions/UserActions";
import MainLayoutComponent from "../MainLayoutComponent";
import { FullDateNoYear, tripTimeSegment } from "../../Helpers/TimeParser";
import { Link } from "react-router-dom";
import TripInstructions from "../Booking/TripInstructions";
import { pushToDataLayer } from "../../Reducers/GTMReducer";
import {
    removeGoingTrip,
    removeReturningTrip
} from "../../Actions/TripActions";
import Utils, { isGoMini } from "../../Helpers/Utils";
import { Trans } from "react-i18next";
import Hint from "../Utils/Hint";
import i18next from "i18next";
import _ from "lodash";
import { useCheckoutStore } from "../../new-store/checkoutStore";
import { PaymentMethods } from "../Booking/PaymentComponent";
import { useRef } from "react";

function matchStateToProps(state) {
    return {
        locale: state.locale,
        user: state.user,
        trips: state.trips,
        search: state.search
    };
}

function mapDispatchToProps(dispatch) {
    return {
        pushToDataLayer: bindActionCreators(pushToDataLayer, dispatch),
        userActions: bindActionCreators(UserActions, dispatch),
        removeGoingTrip: bindActionCreators(removeGoingTrip, dispatch),
        removeReturningTrip: bindActionCreators(removeReturningTrip, dispatch)
    };
}

function dispatchGTMEvent(props) {
    const {
        cartId,
        goingTripInfo,
        returnTripInfo,
        pricing
    } = useCheckoutStore.getState();

    if (!goingTripInfo) {
        return;
    }

    const products = [];
    // going trip gtm data
    products.push({
        name: goingTripInfo.TripOfficeFrom_NameA,
        id: goingTripInfo.TripSubData_Id,
        price: goingTripInfo.TripPrice,
        quantity: props.search.passengersNo
    });

    if (returnTripInfo) {
        products.push({
            name: returnTripInfo.TripOfficeFrom_NameA,
            id: returnTripInfo.TripSubData_Id,
            price: returnTripInfo.TripPrice,
            quantity: props.search.passengersNo
        });
    }

    props.pushToDataLayer({
        ecommerce: {
            purchase: {
                actionField: {
                    id: cartId,
                    revenue: pricing.totalPrice,
                    coupon: props.trips.promoCode.couponCode || ""
                },
                products
            }
        },
        event: "trip_purchase"
    });
}

const Thanks = props => {
    const [{ loading, trials }, setState] = useState({
        loading: true,
        trials: 1
    });

    const state = useCheckoutStore();

    const page = useRef(null);

    async function onMount() {
        console.log({ trials });
        Utils.scrollToElement(page.current);

        // fetch user tickets
        try {
            const result = await props.userActions.getTickets();
            setState(prev => ({ ...prev, trials: 1, loading: false }));
        } catch (error) {
            console.error(
                error
                    ? error.message
                        ? error.message
                        : error
                    : "Error loading user tickets"
            );

            if (trials > 3) {
                setState(prev => ({ ...prev, loading: false }));
            } else {
                setTimeout(
                    () =>
                        setState(prev => ({ ...prev, trials: prev.trials++ })),
                    1000
                );
            }
        }

        if (process.env.REACT_APP_API === "production") {
            try {
                dispatchGTMEvent(props);
            } catch (e) {
                console.error("GTMDISPATCHFAILED");
                console.error(e);
            }
        }
    }

    useEffect(() => {
        onMount();
    }, [trials]);

    if (loading || state.__loading_state) {
        return (
            <MainLayoutComponent page={props.location.pathname}>
                <div className="thanks-page" ref={ref => (page.current = ref)}>
                    <div className="content">
                        <div
                            className="component-loader"
                            style={{ height: "60vh" }}
                        />
                    </div>
                </div>
            </MainLayoutComponent>
        );
    }

    let user = props.user.user;

    let tickets = props.user.tickets;

    if (!tickets) {
        return <NoTransaction history={props.history} />;
    }

    /**
     * Trip pricing with and without discount
     * * check for pricing.code if user used a promocode (eg, pricing: { code: 'websitev3' })
     */
    const pricing = store2.get("promoCode", {
        TotalPrice: state.pricing.totalPriceNoDiscount,
        Total_AfterDisc: state.pricing.displayPrice
    });
    const discount = pricing.TotalPrice - pricing.Total_AfterDisc;
    const no_discount = pricing.TotalPrice;

    // debug({ discount, no_discount, cid: state.cartId });
    let ticket = (tickets || []).find(
        t => String(t.CardID) === String(state.cartId)
    );
    console.log({ tickets, ticket, cartId: state.cartId });

    if (!ticket) {
        return <NoTicketFound history={props.history} />;
    }

    const { lang } = props.locale;

    let fromOfficeKey = "TripOfficeFrom_NameA";
    let toOfficeKey = "TripOfficeTo_NameA";
    let serviceKey = "TripServKind_NameA";

    if (lang === "en") {
        fromOfficeKey = "TripOfficeFrom_NameE";
        toOfficeKey = "TripOfficeTo_NameE";
        serviceKey = "TripServKind_NameE";
    }

    let departureTrip = ticket.myTrips[0];
    let arrivalTrip = ticket.myTrips[1];
    let departureSeats = departureTrip.Seats;

    let arrivalSeats = arrivalTrip ? arrivalTrip.Seats : [];

    let beneficiary = store2.get("beneficiary");

    let paymentMethod = store2.get("payment_method");
    let cardNumber = store2.get("card_number");

    let jobDate = store2.get("fawry_job_date");
    let jobTime = null;

    if (
        paymentMethod === PaymentMethods.mpgs ||
        paymentMethod === PaymentMethods.meeza
    ) {
        jobDate = moment();
        jobTime = moment()
            .locale("en")
            .format("hh:mm");
        jobTime += tripTimeSegment(moment(), null, lang); //.format("a");
    } else {
        if (jobDate) {
            jobTime =
                moment(jobDate)
                    .locale("en")
                    .format("hh:mm") +
                " " +
                tripTimeSegment(moment(jobDate), null, lang);
        }
    }

    jobDate = FullDateNoYear(moment(jobDate), lang);

    if (!departureTrip) window.location.replace("/");

    let finalPayTimestamp = moment(store2.get("fawry_job_date")).add(3, "hour");
    let finalPayDate = FullDateNoYear(finalPayTimestamp, lang); //.format("dddd DD MMMM");
    let finalPayTime = moment(finalPayTimestamp)
        .locale("en")
        .format("hh:mm");
    let finalPayTimeSegment = tripTimeSegment(finalPayTimestamp, null, lang); //.format("a");

    const showHint =
        /(Nasr|نصر)/g.test(_.get(departureTrip, "TripOfficeFrom", "")) &&
        !isGoMini({
            serviceName: departureTrip.TripServKind_NameA
        });

    const imgs = {
        meeza: "/images/payments/meeza.png",
        mpgs: "/images/mastercard.png"
    };

    const showAlert =
        state.goingTripInfo && state.goingTripInfo.TripOfficeFrom_Code === 364;
    return (
        <MainLayoutComponent page={props.location.pathname}>
            <div className="thanks-page">
                <div className="content">
                    <div className="row">
                        <div className="top-msg">
                            {paymentMethod === "mpgs" ||
                            paymentMethod === "meeza" ? (
                                <h3 className="confirm">
                                    <Trans i18nKey="thanks_page.credit_card.type_confirmed">
                                        حجز مؤكد رقم{" "}
                                        {{ booking_number: ticket.CardID }}
                                    </Trans>
                                </h3>
                            ) : (
                                <React.Fragment>
                                    <h3 className="pending">
                                        <Trans i18nKey="thanks_page.cash.type_temporary">
                                            حجز غير مؤكد (كود الدفع ){" "}
                                            {{
                                                job_id: store2.get(
                                                    "fawry_job_id"
                                                )
                                            }}
                                            )
                                        </Trans>
                                    </h3>
                                    <div className="alert-msg">
                                        <div className="yellow-circle">
                                            <i className="fas fa-exclamation-triangle"></i>
                                        </div>
                                        <Trans i18nKey="thanks_page.cash.alert">
                                            الحجز غير مؤكد حتى يتم الدفع في
                                            ماكينة أمان او فوري او بيى في مدة
                                            اقصاها ثلاثة ساعات من الاَن او قبل
                                            ميعاد الرحلة ب٤ ساعات
                                        </Trans>
                                    </div>
                                </React.Fragment>
                            )}
                            {showAlert && (
                                <div className="search-component-hints">
                                    <Hint
                                        title={i18next.t(
                                            "thanks_page.hint.title",
                                            "الرجاء الإنتباه!"
                                        )}
                                        message={
                                            <>
                                                <Trans i18nKey="thanks_page.alert.message">
                                                    عميلنا العزيز، الوقت
                                                    والتاريخ غير مؤكدين للرحلة
                                                    ولكنه فقط سداد لرسوم الرحلة
                                                    وسيتم التواصل معكم خلال 24
                                                    ساعه لاستكمال باقى البيانات
                                                    من جانب حضراتكم.
                                                </Trans>
                                            </>
                                        }
                                    />
                                </div>
                            )}
                            {showHint && (
                                <div className="search-component-hints">
                                    <Hint
                                        title={i18next.t(
                                            "thanks_page.hint.title",
                                            "الرجاء الإنتباه!"
                                        )}
                                        message={
                                            <>
                                                <Trans i18nKey="thanks_page.hint.message">
                                                    برجاء العلم أن رحلات القاهرة
                                                    مدينة نصر تتحرك من
                                                </Trans>{" "}
                                                <a
                                                    href="https://www.google.com/maps/place/Go+Bus+main+station/@30.0464429,31.3184373,17z/data=!4m5!3m4!1s0x14583ff7276bf1ab:0x304b20824806314!8m2!3d30.0464349!4d31.3185799?hl=en&shorturl=1"
                                                    target="_blank"
                                                >
                                                    <Trans i18nKey="thanks_page.hint.link">
                                                        (228 شارع المخيم الدائم
                                                        الحي السادس مدينة نصر)
                                                    </Trans>
                                                </a>
                                            </>
                                        }
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="tickets-data">
                            <div className="trip-data">
                                <div className="row locations">
                                    <div className="single-location">
                                        <span>
                                            <Trans i18nKey="thanks_page.travel_from">
                                                سفر من
                                            </Trans>
                                        </span>
                                        <strong>
                                            {departureTrip[fromOfficeKey]}
                                        </strong>
                                    </div>
                                    <div className="single-location">
                                        <span>
                                            <Trans i18nKey="thanks_page.travel_to">
                                                وصول إلي
                                            </Trans>
                                        </span>
                                        <strong>
                                            {departureTrip[toOfficeKey]}
                                        </strong>
                                    </div>
                                    <div className="trip-type">
                                        <span>
                                            <Trans i18nKey="thanks_page.booking_type">
                                                نوع الحجز
                                            </Trans>
                                        </span>

                                        {arrivalTrip ? (
                                            <strong>
                                                <Trans i18nKey="thanks_page.round">
                                                    ذهاب وعودة
                                                </Trans>
                                            </strong>
                                        ) : (
                                            <strong>
                                                <Trans i18nKey="thanks_page.oneway">
                                                    ذهاب فقط
                                                </Trans>
                                            </strong>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="trip-details">
                                        <div className="item">
                                            <p className="title">
                                                <i class="fas fa-check" />
                                                <Trans i18nKey="thanks_page.trips.going_trip">
                                                    رحلة الذهاب
                                                </Trans>
                                            </p>
                                            <p className="date">
                                                {FullDateNoYear(
                                                    moment(
                                                        departureTrip.TripDateTime
                                                    ),
                                                    lang
                                                )}
                                            </p>
                                            <p className="time">
                                                <span>
                                                    {moment(
                                                        departureTrip.TripDateTime
                                                    )
                                                        .locale("en")
                                                        .format("hh:mm")}
                                                </span>
                                                {tripTimeSegment(
                                                    departureTrip.TripDateTime,
                                                    null,
                                                    lang
                                                )}
                                            </p>
                                            <p>
                                                <Trans i18nKey="thanks_page.travel_from">
                                                    سفر من
                                                </Trans>
                                                : {departureTrip[fromOfficeKey]}
                                                <br />
                                                <Trans i18nKey="thanks_page.travel_to">
                                                    وصول إلي
                                                </Trans>{" "}
                                                : {departureTrip[toOfficeKey]}
                                                <br />
                                                <Trans i18nKey="thanks_page.trips.class_type">
                                                    نوع الخدمة
                                                </Trans>
                                                : {departureTrip[serviceKey]}
                                                <br />
                                                <Trans i18nKey="common.passengers_no">
                                                    عدد المسافرين
                                                </Trans>
                                                : {departureSeats.length}
                                            </p>
                                            <ul className="seats-selected fl-row">
                                                {departureSeats.map(seat => {
                                                    return (
                                                        <li
                                                            key={seat}
                                                            className="seat"
                                                        >
                                                            {seat}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                        {arrivalTrip && (
                                            <div className="item">
                                                <React.Fragment>
                                                    <p className="title">
                                                        <i class="fas fa-check" />
                                                        <Trans i18nKey="thanks_page.trips.return_trip">
                                                            رحلة العودة
                                                        </Trans>
                                                    </p>
                                                    <p className="date">
                                                        {FullDateNoYear(
                                                            moment(
                                                                arrivalTrip.TripDateTime
                                                            ),
                                                            lang
                                                        )}
                                                    </p>
                                                    <p className="time">
                                                        <span>
                                                            {moment(
                                                                arrivalTrip.TripDateTime
                                                            )
                                                                .locale("en")
                                                                .format(
                                                                    "hh:mm"
                                                                )}
                                                        </span>
                                                        {tripTimeSegment(
                                                            arrivalTrip.TripDateTime,
                                                            null,
                                                            lang
                                                        )}
                                                    </p>
                                                    <p>
                                                        <Trans i18nKey="thanks_page.travel_from">
                                                            سفر من
                                                        </Trans>
                                                        :{" "}
                                                        {
                                                            arrivalTrip[
                                                                fromOfficeKey
                                                            ]
                                                        }
                                                        <br />
                                                        <Trans i18nKey="thanks_page.travel_to">
                                                            وصول إلي
                                                        </Trans>{" "}
                                                        :{" "}
                                                        {
                                                            arrivalTrip[
                                                                toOfficeKey
                                                            ]
                                                        }
                                                        <br />
                                                        <Trans i18nKey="thanks_page.trips.class_type">
                                                            نوع الخدمة
                                                        </Trans>
                                                        :{" "}
                                                        {
                                                            arrivalTrip[
                                                                serviceKey
                                                            ]
                                                        }
                                                        <br />
                                                        <Trans i18nKey="common.passengers_no">
                                                            عدد المسافرين
                                                        </Trans>
                                                        : {arrivalSeats.length}
                                                    </p>
                                                    <ul className="seats-selected fl-row">
                                                        {arrivalSeats.map(
                                                            seat => {
                                                                return (
                                                                    <li
                                                                        key={
                                                                            seat
                                                                        }
                                                                        className="seat"
                                                                    >
                                                                        {seat}
                                                                    </li>
                                                                );
                                                            }
                                                        )}
                                                    </ul>
                                                </React.Fragment>
                                            </div>
                                        )}
                                    </div>
                                    {/* .trip-details */}
                                </div>

                                <div className="row">
                                    <div className="price-details">
                                        <div className="price-go-return">
                                            <p>
                                                <Trans i18nKey="thanks_page.trips.going_trip_price">
                                                    سعر تذاكر الذهاب
                                                </Trans>
                                            </p>
                                            <div className="price fl-row">
                                                {departureSeats.length} x (
                                                <span className="value">
                                                    {
                                                        state.goingTripInfo
                                                            .TripPrice
                                                    }
                                                    ,00
                                                </span>
                                                <span className="currency">
                                                    <Trans i18nKey="common.currency">
                                                        جنيه
                                                    </Trans>
                                                </span>
                                                )
                                            </div>
                                        </div>
                                        {arrivalTrip && (
                                            <div className="price-go-return">
                                                <p>
                                                    <Trans i18nKey="thanks_page.trips.return_trip_price">
                                                        سعر تذاكر العودة
                                                    </Trans>
                                                </p>
                                                <div className="price fl-row">
                                                    {arrivalSeats.length} x (
                                                    <span className="value">
                                                        {
                                                            state.returnTripInfo
                                                                .TripPrice
                                                        }
                                                        ,00
                                                    </span>
                                                    <span className="currency">
                                                        <Trans i18nKey="common.currency">
                                                            جنيه
                                                        </Trans>
                                                    </span>
                                                    )
                                                </div>
                                            </div>
                                        )}
                                        {discount !== 0 && (
                                            <div className="price-go-return">
                                                <p>
                                                    <Trans i18nKey="thanks_page.discount">
                                                        الخصم
                                                    </Trans>
                                                </p>
                                                <div className="price fl-row">
                                                    <span className="value">
                                                        {discount}-
                                                    </span>
                                                    <span className="currency">
                                                        <Trans i18nKey="common.currency">
                                                            جنيه
                                                        </Trans>
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                        <div className="price-go-return">
                                            <div className="fl-row">
                                                <p class="total">
                                                    <Trans i18nKey="common.total">
                                                        الأجمالي
                                                    </Trans>
                                                    <span>
                                                        <Trans i18nKey="common.inc_tax_text">
                                                            جنيه شامل الضرائب
                                                        </Trans>
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="price fl-row">
                                                <span className="value">
                                                    {ticket.CardTotalPrice},00
                                                </span>
                                                <span className="currency">
                                                    <Trans i18nKey="common.currency">
                                                        جنيه
                                                    </Trans>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="user-data">
                                <div className="user-details">
                                    <img
                                        src={
                                            props.user.user.Pic_Url ||
                                            "/images/img_avatar.png"
                                        }
                                        alt=""
                                        className="user-img"
                                    />
                                    <div className="user-row">
                                        <div className="entry">
                                            <h5 className="label">
                                                <Trans i18nKey="common.fullname">
                                                    الأسم بالكامل
                                                </Trans>
                                            </h5>
                                            <p className="tp-text getting_name_data">
                                                {user.UserName}
                                            </p>
                                        </div>

                                        {beneficiary && (
                                            <div className="entry">
                                                <h5 className="label">
                                                    <Trans i18nKey="common.beneficiary_name">
                                                        إسم المستفيد
                                                    </Trans>
                                                </h5>
                                                <p className="tp-text">
                                                    {beneficiary.name}
                                                </p>
                                            </div>
                                        )}
                                    </div>

                                    <div className="user-row">
                                        <div className="entry">
                                            <h5 className="label">
                                                <Trans i18nKey="common.phone">
                                                    رقم المحمول
                                                </Trans>
                                            </h5>
                                            <p className="tp-text getting_phone_data">
                                                {user.UserTelNo}
                                            </p>
                                        </div>
                                        {beneficiary && (
                                            <div className="entry">
                                                <h5 className="label">
                                                    <Trans i18nKey="common.beneficiary_phone">
                                                        رقم محمول المستفيد
                                                    </Trans>
                                                </h5>
                                                <p className="tp-text">
                                                    {beneficiary.phone}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                    <div className="user-row">
                                        <div className="entry">
                                            <h5 className="label">
                                                <Trans i18nKey="common.email">
                                                    البريد الإلكتروني
                                                </Trans>
                                            </h5>
                                            <p className="tp-text getting_mail_data">
                                                {user.UserEmail}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="user-row">
                                        <div className="entry">
                                            <h5 className="label">
                                                <Trans i18nKey="thanks_page.booking_number">
                                                    رقم الحجز
                                                </Trans>
                                            </h5>
                                            <p className="tp-text">
                                                {state.cartId}
                                            </p>
                                        </div>
                                        <div className="entry">
                                            <h5 className="label">
                                                <Trans i18nKey="thanks_page.booking_date">
                                                    تاريخ اصدار الحجز
                                                </Trans>
                                            </h5>
                                            <p className="tp-text">
                                                {jobDate} {jobTime}
                                            </p>
                                        </div>
                                    </div>

                                    {paymentMethod === "mpgs" ||
                                    paymentMethod === "meeza" ? (
                                        <div className="user-row">
                                            <div className="entry">
                                                <h5 className="label">
                                                    <Trans i18nKey="thanks_page.payment_method">
                                                        طريقة الدفع
                                                    </Trans>
                                                </h5>
                                                <p className="tp-text">
                                                    <img
                                                        src={
                                                            imgs[paymentMethod]
                                                        }
                                                        alt=""
                                                    />{" "}
                                                    <h4>{cardNumber}</h4>
                                                </p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="user-row">
                                            <div className="entry payment-methods-thx">
                                                <h5 className="label">
                                                    <Trans i18nKey="thanks_page.payment_method">
                                                        طريقة الدفع
                                                    </Trans>
                                                </h5>
                                                <p className="tp-text">
                                                    {paymentMethod ===
                                                    "cash" ? (
                                                        <React.Fragment>
                                                            <img
                                                                src="/images/aman.png"
                                                                alt="imae"
                                                            />{" "}
                                                            <img
                                                                src="/images/fawry.png"
                                                                alt=""
                                                            />{" "}
                                                            <img
                                                                src="/images/payments/bee-logo.jpg"
                                                                alt=""
                                                            ></img>
                                                            <img
                                                                src="/images/payments/momken.png"
                                                                alt="momken"
                                                            />
                                                        </React.Fragment>
                                                    ) : (
                                                        <img
                                                            src="/images/visa-mastercard"
                                                            alt=""
                                                        />
                                                    )}
                                                </p>
                                            </div>
                                            {paymentMethod === "cash" && (
                                                <React.Fragment>
                                                    <div className="entry">
                                                        <h5 className="label">
                                                            <Trans i18nKey="thanks_page.cash.payment_code">
                                                                كود الدفع
                                                            </Trans>
                                                        </h5>
                                                        <p className="tp-text">
                                                            {store2.get(
                                                                "fawry_job_id"
                                                            )}
                                                        </p>
                                                    </div>
                                                    <div className="entry">
                                                        <h5 className="label">
                                                            <Trans i18nKey="thanks_page.cash.last_pay_date">
                                                                اّخر ميعاد للدفع
                                                            </Trans>
                                                        </h5>
                                                        <p className="tp-text">
                                                            {finalPayDate}{" "}
                                                            {finalPayTime}{" "}
                                                            {
                                                                finalPayTimeSegment
                                                            }
                                                        </p>
                                                    </div>
                                                </React.Fragment>
                                            )}
                                        </div>
                                    )}

                                    <div className="protection">
                                        <p className="tp-text">
                                            <img
                                                src="/images/icons/protection.png"
                                                alt=""
                                            />
                                            {arrivalTrip ? (
                                                <Trans i18nKey="thanks_page.insurance_text_round">
                                                    الحجز يشمل تأمين كامل علي
                                                    رحلة الذهاب
                                                </Trans>
                                            ) : (
                                                <Trans i18nKey="thanks_page.insurance_text_oneway">
                                                    الحجز يشمل تأمين كامل علي
                                                    رحلة الذهاب و العودة
                                                </Trans>
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <TripInstructions paymentMethod={paymentMethod} />
                    </div>

                    <div className="row">
                        <div className="contact-info">
                            <h3>
                                <Trans i18nKey="thanks_page.complaints_inquiries.title">
                                    الأستعلام و الشكاوي
                                </Trans>
                            </h3>
                            <p>
                                <Trans i18nKey="thanks_page.complaints_inquiries.headline">
                                    يمكنا تقديم المساعدة والرد علي كل تسأولتكم
                                    من خلال موقع الشركة،
                                </Trans>
                                <Link to="/contact-us" target="_blank">
                                    <Trans i18nKey="thanks_page.complaints_inquiries.click_here">
                                        أظغط هنا.
                                    </Trans>
                                </Link>
                                <br />{" "}
                                <Trans i18nKey="thanks_page.complaints_inquiries.subline">
                                    أو الأتصال علي رقم ١٩٥٦٧
                                </Trans>
                            </p>
                            <img src="/images/go-bus-dark.png" alt="" />
                        </div>
                    </div>

                    <button className="printBtn" onClick={window.print}>
                        <Trans i18nKey="thanks_page.print_btn">طباعة</Trans>
                    </button>
                </div>
            </div>
        </MainLayoutComponent>
    );
};

function NoTicketFound(props) {
    return (
        <MainLayoutComponent>
            <div className="thanks-page">
                <div className="content">
                    <div
                        style={{
                            width: "80vw",
                            height: "60vh",
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            flexDirection: "column"
                        }}
                    >
                        <h4 style={{ fontSize: "20px", fontWeight: "bold" }}>
                            <Trans i18nKey="thanks_page.errors.verify_booking">
                                لم نتمكن من تأكيد هذا الحجز
                            </Trans>
                        </h4>
                        <button
                            style={{ fontWeight: "bold" }}
                            className="btn btn-orange"
                            onClick={props.history.goBack}
                        >
                            <Trans i18nKey="thanks_page.back_button">
                                عودة
                            </Trans>
                        </button>
                    </div>
                </div>
            </div>
        </MainLayoutComponent>
    );
}

function NoTransaction(props) {
    return (
        <MainLayoutComponent>
            <div className="thanks-page">
                <div className="content">
                    <div
                        style={{
                            width: "80vw",
                            height: "60vh",
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            flexDirection: "column"
                        }}
                    >
                        <h4 style={{ fontSize: "20px", fontWeight: "bold" }}>
                            <Trans i18nKey="thanks_page.errors.incomplete_booking">
                                حجز غير مكتمل
                            </Trans>
                        </h4>
                        <button
                            style={{ fontWeight: "bold" }}
                            className="btn btn-orange"
                            onClick={props.history.goBack}
                        >
                            <Trans i18nKey="thanks_page.back_button">
                                عودة
                            </Trans>
                        </button>
                    </div>
                </div>
            </div>
        </MainLayoutComponent>
    );
}

export default connect(matchStateToProps, mapDispatchToProps)(Thanks);
