import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import qs from "query-string";
import { connect } from 'react-redux';
import moment from 'moment';
// Actions 
import * as SearchActions from '../../../Actions/SearchActions'
import * as TripActions from '../../../Actions/TripActions'

// libs
import { FullDate, fullDateDigits,dayNamedAndNumbered } from '../../../Helpers/TimeParser'

function mapStateToProps(store) {
    return {
        locale: store.locale,
        searchStore: store.search,
        departureDate: store.search.departureDate,
        arrivalDate: store.search.arrivalDate,
        tripsStore:store.trips
    }
}

function mapDispatchToProps(dispatch) {
    return {
        searchActions: bindActionCreators(SearchActions, dispatch),
        tripActions: bindActionCreators(TripActions, dispatch),
    }
}

class NextAndPreviousDaySearchBox extends Component {
    constructor(props){
        super(props);

        //methods 
        this.getNextOrPreviousDayTrips = this.getNextOrPreviousDayTrips.bind(this)
        
        const today = props.listType === "going"? props.departureDate : props.arrivalDate
        this.state = {
            today: today,
            departureDate: props.departureDate,
            arrivalDate: props.arrivalDate
        }
    }

    getTodaysDate = () => {
        return this.props.listType === 'going'
            ? this.state.departureDate
            : this.state.arrivalDate;
    }

    // componentWillReceiveProps(props){
    //     if(props.listType === 'going'){
    //         this.setState({
    //             today:props.departureDate
    //         })
    //     }else{
    //         this.setState({
    //             today:props.arrivalDate //the date of arrival fro, the going trip
    //         })
    //     }
    // }
    
    /**
     * responsible for getting next or previous day trips
     * @param {string} action [add, subtract] => VERY IMPRTANT AS IT"S MOMENT METHODS 
     * @author Ezzat
     */
    getNextOrPreviousDayTrips(action){
        // get the search data
        let search_store_data = {};
        const today = this.getTodaysDate();

        Object.assign(search_store_data, this.props.searchStore);

        // update data for search and for date storage in store
        search_store_data.departureStation = search_store_data.departureStation && search_store_data.departureStation.Code
        search_store_data.arrivalStation = search_store_data.arrivalStation && search_store_data.arrivalStation.Code

        // update Departure or Arrival Date
        if(this.props.listType === 'going'){
           search_store_data.departureDate  = today.clone()[action](1, 'days')
        }else{
           search_store_data.arrivalDate  = today.clone()[action](1, 'days')
        }
        
        this.props.searchActions.updateSearchParams(search_store_data)

        if( this.props.listType === 'going') {
            this.props.tripActions.searchTrips(search_store_data, 'going');
            this.props.tripActions.searchTrips(search_store_data, 'returning');
        } else {
            if( moment(search_store_data.arrivalDate).isBefore(moment(search_store_data.departureDate)) )
                return;
                
            this.props.tripActions.searchTrips(search_store_data, 'returning');
        }

        let params = qs.parseUrl( window.location.href );

        if( search_store_data.departureDate ) {
            params.query.departureDate = search_store_data.departureDate.format('D/M/Y');
        } if( search_store_data.arrivalDate ) {
            params.query.arrivalDate = search_store_data.arrivalDate.format('D/M/Y');
        }
        window.history.pushState( null, null, params.url + "?" + qs.stringify( params.query ) );

        // update today 
        this.setState({
            today: today.clone()[action](1, 'days')
        })

    }
    
    // update Store Departure and Arrival Date to REFLECT ON THE SEARCH BOX ==> very imprtant


    render(){
        let next_arrow_style= 'calnder-arrow'
        let previous_arrow_style = 'calnder-arrow';
        const today = this.getTodaysDate();
        const { departureDate } = this.props.searchStore;
        let canGetPreviousDay = false;
        let canGetNextDay = moment(today).isAfter(moment().clone().add(6,'months'), 'day');

        // check if today is equal to the searchDate
        if(this.props.listType === 'going'){
            if( moment(today).isSame(moment(), 'day') ){
                previous_arrow_style = 'calnder-arrow-disabled'
            }
            if( moment(today).isAfter(moment().clone().add(6,'months'), 'day') ){
                next_arrow_style = 'calnder-arrow-disabled'
            }

            canGetPreviousDay = !moment(today).isSameOrBefore(moment(), 'day');
        }else{
            // in case of the returning trips 
            let base_departure_date = this.state.departureDate
            if( moment(today).isSame(base_departure_date, 'day') ){
                previous_arrow_style = 'calnder-arrow-disabled'
            }
            if( moment(today).isAfter(base_departure_date.clone().add(6,'months'), 'day') ){
                next_arrow_style = 'calnder-arrow-disabled'
            }
            canGetPreviousDay = !moment(today).isSame(moment(departureDate), 'day')
        }
        
        return(
            <div className="list-calendar">
                <div className="list-calendar-con">
                    <span className={previous_arrow_style}>
                        <i className="material-icons" onClick={this.getNextOrPreviousDayTrips.bind(this,'subtract')} >keyboard_arrow_right</i>
                    </span>
                    <div className="calnder-content">
                        <span onClick={canGetPreviousDay? this.getNextOrPreviousDayTrips.bind(this, 'subtract'): undefined}>
                        {dayNamedAndNumbered(today.clone().subtract(1,'days'), this.props.locale.lang)}
                        </span>
                        <span className="active">
                            {dayNamedAndNumbered(today, this.props.locale.lang)}
                        </span>
                        <span onClick={canGetNextDay? this.getNextOrPreviousDayTrips.bind(this, 'add'): undefined}>
                        {dayNamedAndNumbered(today.clone().add(1,'days'), this.props.locale.lang)}
                        </span>
                    </div>
                    <span className={next_arrow_style} onClick={this.getNextOrPreviousDayTrips.bind(this,'add')}>
                        <i className="material-icons">keyboard_arrow_left</i>
                    </span>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NextAndPreviousDaySearchBox)
