import React, { Component } from "react";
import Helmet from "react-helmet";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SideBar from "../SideBar";
import * as CorporateActions from "../../Actions/CorporateActions";

// Componnets
import MainLayoutComponent from "../MainLayoutComponent";
import FAQLoaderComponent from "../Utils/Placeholders/FAQPlaceholder";
import { buildYoastMetaTags } from "../../Helpers/Yoast";
import Utils from "../../Helpers/Utils";

function matchStateToProps(store) {
    // debugger;
  return {
    lang: store.locale.lang, 
    station: store.corporate.station,
    fetching: store.corporate.fetching,
    stationCategories: store.corporate.stationCategories,
  };
}

function matchDispatchToProps(dispatch) {
  return {
    corporateActions: bindActionCreators(CorporateActions, dispatch)
  };
}

class Stations extends Component {
  componentDidMount() {
    !this.props.station && this.props.corporateActions.getStation();
  }

  getContent = (options = { corporateKey: 'station', defaultReturn: {} }) => {
    const content = this.props[options.corporateKey] || {};
    const { lang } = this.props;
    // translated data & undefined safety object
    return content[lang]? content[lang]: content['ar'] || options.defaultReturn;
  }


  renderStations = () => {
    const station = this.getContent({ corporateKey: 'station', defaultReturn: [] });
    const stationCategories = this.getContent({ corporateKey: 'stationCategories', defaultReturn: [] });
    console.log('station>',station)
    console.log('asasas',this.props.lang)

    return stationCategories.map((category, index) => {
      return (
        category.count > 0 && (
        <div className="stations-list-con" key={index}>
            {this.props.lang === 'ar' ?
            <h2>{category.name}</h2>
            :
            <h2>{category.acf.city_en?category.acf.city_en:category.name}</h2>
            }
            {station.map(item => {
            return (
                item.city.includes(category.id) && (
                    <div class="station-list">
                        <div class="station-details">
                            {this.props.lang === 'ar' ?
                            <>
                            <h3>{Utils.htmlEntitiesFix(item.title.rendered)}</h3>
                            <span>{item.acf.area}</span>
                            <p>{item.acf.address}</p>
                            <a href={item.acf.map} target="_blank">اظهر علي الخريطه</a>
                            </>
                            :
                            <>
                            <h3>{item.acf.title_en?item.acf.title_en:Utils.htmlEntitiesFix(item.title.rendered)}</h3>
                            <span>{item.acf.area_en}</span>
                            <p>{item.acf.address_en}</p>
                            <a href={item.acf.map} target="_blank">Show on map</a>
                            </>
                            }
                        </div>
                    </div>
                )
            )})}
        </div>
        )
      );
    });
  };

  renderStationsLayout() {
    return (
        <div className="page-content">
            <div className="page-title stations-title">
                <div className="container">
                {this.props.lang === 'ar' ?
                    <>
                    <h1>محطات ومؤاني جوباص</h1>
                    <p>محطات ومؤاني جوباص</p>
                    </>
                    :
                    <>
                    <h1>GoBus Stations</h1>
                    <p>GoBus Stations</p>
                    </>
                    }
                </div>
            </div>
            <div className="stations-page">
                <div className="container">
                    {this.renderStations()}
                </div>
            </div>
        </div>
    );
  }

  render() {
    return (
      <MainLayoutComponent page={this.props.location.pathname}>
        <Helmet key={window.location.href} >
          <title>
            {"محطات ومؤاني جو باص | Go Bus"}
          </title>
        </Helmet>
        {this.renderStationsLayout()}
      </MainLayoutComponent>
    );
  }
}

export default connect(
  matchStateToProps,
  matchDispatchToProps
)(Stations);