import React, { Component } from "react";
import {
  Redirect, Route,
  // Link,
  Switch
} from "react-router-dom";

import NoMatch from "./Components/Pages/NoMatch";

// Main App
import BookingComponent from "./Components/Booking/BookingComponent";
import Home from "./Components/Pages/Home";
import HomeTemp from "./Components/Pages/HomeTemp";
import SearchResultWrapperComponent from "./Components/Search/SearchResultComponent";

// Account
import ChangePasswordComponent from "./Components/Profile/ChangePasswordComponent";
import EditProfileComponent from "./Components/Profile/EditProfileComponent";
import SingleTicketComponent from "./Components/Profile/SingleTicketComponent";

// Corp
import AboutComponent from "./Components/Corporate/AboutComponent";
import BlogComponent from "./Components/Corporate/BlogComponent";
import ContactUs from "./Components/Corporate/ContactUs/ContactUsComponent";
import DestinationsComponent from "./Components/Corporate/DestinationComponent";
import FAQs from "./Components/Corporate/FAQComponent";
import SingleStationComponent from "./Components/Corporate/SingleStationComponent";
import StationsListComponent from "./Components/Corporate/StationsListComponent";
import TermsComponent from "./Components/Corporate/TermsComponent";
// Html
import SingleDestination from "./Components/Pages/SingleDestination";
import SingleNews from "./Components/Pages/SingleNews";

// HTML
import MyBookings from "./Components/Pages/Account/MyBookings";
import SingleBooking from "./Components/Pages/Account/SingleBooking";
import NewThanks from "./Components/Pages/NewThanks";
import Thanks from "./Components/Pages/Thanks";

import ResetPasswordComponent from "./Components/Profile/ResetPasswordComponent";

import { isLoggedIn, logout } from './Actions/UserActions';
import NBEComponent from "./Components/Corporate/NBEComponent";
import NBEThanksComponent from "./Components/Corporate/NBEThanksComponent";
import PolicyComponent from "./Components/Corporate/PolicyComponent";
import Stations from "./Components/Corporate/Stations";
import MainLayoutComponent from "./Components/MainLayoutComponent";
import Rating from "./Components/RatingComponent/RatingComponent";
import LocationListenerComponent from './Components/Utils/LocationListenerComponent';
import OfflineListenerComponent from './Components/Utils/OfflineListenerComponent';
import debug from "./Helpers/DebugLog";
import Store from './Store';

function handleNotLoggedIn(props){
  Store.dispatch(logout(props.history))
  return (
    <Redirect
      to={{
        pathname: "/",
        state: { from: props.location }
      }}
    />
  )
}
/**
 * @param component props
 * @return [requiredCompnent, notAuthorizedComponent, LoginRoute]
 */
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      // check if user is looged IN
      isLoggedIn() ? (
        <Component {...props} />
      ) : (
        // Not loggedin, redirect to LoginRoute.
        <>
        {handleNotLoggedIn(props)}
        </>
      )
    }
  />
);

class ErrorBoundary extends Component {
  state = {
    hasError: false
  };
  
  /**
   * 
   * @param {Error} error 
   * @param {*} errorInfo 
   */
  componentDidCatch(error, errorInfo) {
    if (error.name === "NotFoundError") {
      console.warn('[ErrorBoundary]', error);
      return;
    }
    debug("[ErrorBoundary]", error);
    debug("[ErrorBoundary][INF]", errorInfo);
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <MainLayoutComponent>
          <div className="cu_success">Something went wrong!</div>
        </MainLayoutComponent>
      );
    }
    return this.props.children;
  }
}


class Routes extends Component {
  render() {
    return (
      <div>
        <ErrorBoundary>
          <OfflineListenerComponent>
            <LocationListenerComponent>
              <Switch>
                {/* Main App */}
                <Route exact path="/" component={Home} />
                <Route
                  exact
                  path="/search"
                  component={SearchResultWrapperComponent}
                />
                <PrivateRoute
                  exact
                  path="/booking"
                  component={BookingComponent}
                />

                {/* Account Pages */}
                <PrivateRoute
                  exact
                  path="/profile/edit"
                  component={EditProfileComponent}
                />
                <PrivateRoute
                  exact
                  path="/profile/change-password"
                  component={ChangePasswordComponent}
                />
                <PrivateRoute
                  exact
                  path="/profile/tickets"
                  component={MyBookings}
                />
                <PrivateRoute
                  exact
                  path="/profile/tickets/:ticketId"
                  component={SingleTicketComponent}
                />

                {/* corp   */}
                <Route exact path="/about" component={AboutComponent} />
                <Route exact path="/nbe" component={NBEComponent} />
                <Route exact path="/nbe-thanks" component={NBEThanksComponent} />
                <Route exact path="/blog" component={BlogComponent} />
                <Route path="/blog/:slug" component={SingleNews} />
                <Route exact path="/faq" component={FAQs} />
                <Route
                  exact
                  path="/destinations"
                  component={DestinationsComponent}
                />
                <Route path="/destination/:id" component={SingleDestination} />
                <Route
                  exact
                  path="/stations/"
                  component={Stations}
                />
                <Route
                  exact
                  path="/stations/"
                  component={StationsListComponent}
                />
                <Route
                  exact
                  path="/stations/:id"
                  component={SingleStationComponent}
                />
                <Route exact path="/terms" component={TermsComponent} />
                <Route exact path="/policy" component={PolicyComponent} />

                {/* HTML */}

                <Route exact path="/contact-us" component={ContactUs} />
                <Route
                  exact
                  path="/reset-password/:token"
                  component={ResetPasswordComponent}
                />
                <PrivateRoute
                  exact
                  path="/SingleBooking/:id"
                  component={SingleBooking}
                />
                <Route exact path="/Thanks" component={Thanks} />
                <Route exact path="/Thanks/:card_id" component={NewThanks} />

                <Route exact path="/temp-route" component={HomeTemp} />
                <Route exact path="/Rating/:trip_id/:trip_sub_id" component={Rating} />
                {/* 404 */}
                <Route component={NoMatch} />
              </Switch>
            </LocationListenerComponent>
          </OfflineListenerComponent>
        </ErrorBoundary>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default Routes;
