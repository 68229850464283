import React, { useCallback, useState } from "react";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import DatePicker, { registerLocale } from "react-datepicker";
import ar from 'date-fns/esm/locale/ar'
import moment from "moment";
import { searchEvents } from "../SearchBox/SearchBoxComponent";

registerLocale('ar', ar);

const TwoWayOfferComponent = (props) => {
    const { locale, searchStore } = props;
    const { departureDate } = searchStore;
    let initialDate;

    // Eval initialDate either today + 1 or departureDate + 1
    if (moment(departureDate).isSame(moment(), 'day')) {
        initialDate = moment().add(1, 'day').toDate();
    } else {
        initialDate = moment(departureDate).add(1, 'day').toDate();
    }
    
    const [active, setActive] = useState(false);
    const [show, setShow] = useState(false);

    const handleClick = event => {
      // 👇️ toggle isActive state on click
      setShow(current => !current);
    };
    const [date, setDate] = useState(initialDate);

    const toggleActive = useCallback(() => {
        setActive((prev) => !prev);
        searchEvents.emit('changeTripType', 'round');
        searchEvents.emit('chooseArrivalTime', date, {});
    }, []);
    
    const onDateChange = useCallback((date) => {
        setDate(date);
        searchEvents.emit('chooseArrivalTime', date, {});
    }, []);
    
    const search = useCallback(() => {
        searchEvents.emit('search');
    } , []);

    if (searchStore.tripType === "round") {
        return null;
    }

    return <div className={`blue-card ${ show? "hide" : ""}`}>
        <span className="flag">
            <img src="/images/flag.png" alt="flag" />
        </span>
        <button className="close-button"   onClick={handleClick}>
                <span class="material-symbols-outlined">
                    close
                    </span>
            </button>
        <div className={`stepone ${ active ? "" : "active"  } `}>
            
            <div className="top">
                <img src="/images/percent.png" alt="percent" />
                <p>
                    <Trans i18nKey="cart.oneway-bluecard">
                        أحجز رحلة العودة
                        وأحصل علي خصم خاص
                    </Trans>
                </p>
            </div>
            <div className="bottom">
                <button
                    onClick={toggleActive} >
                    <Trans i18nKey="cart.bluecard-button">
                        اختار رحلة العودة
                    </Trans>
                </button>
            </div>
        </div>

        <div className={`steptwo ${ active ? "active" : ""  }`}>
            <p>
                <Trans i18nKey="cart.stepTwo_title">
                    تاريخ العودة
                </Trans>
            </p>
            <div className="datepicker">
                <DatePicker
                    selected={date}
                    onChange={onDateChange}
                    excludeDateIntervals={[
                        {
                            start: new Date(1970, 0, 1),
                            end: moment(departureDate).subtract(1, 'day').toDate(),
                        },
                        {
                            start: moment().add(2, 'years').toDate(),
                            end: moment().add(1000, 'years').toDate(),
                        }
                    ]}
                    dateFormat="EEEE dd MMMM"
                    locale={locale.lang}
                />
            </div>

            <button onClick={search}>
                <Trans i18nKey="cart.stepTwo_button">
                    أعرض الرحلات
                </Trans>
            </button>
        </div>
    </div>
}

const mapStateToProps = (store) => {
    return {
        locale: store.locale,
        tripsStore: store.trips,
        searchStore: store.search
    };
}

export default connect(mapStateToProps)(TwoWayOfferComponent);
