import React, { Component } from "react";
import Helmet from "react-helmet";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SideBar from "../SideBar";
import * as CorporateActions from "../../Actions/CorporateActions";

// Componnets
import MainLayoutComponent from "../MainLayoutComponent";
import FAQLoaderComponent from "../Utils/Placeholders/FAQPlaceholder";
import { buildYoastMetaTags } from "../../Helpers/Yoast";
import Utils from "../../Helpers/Utils";

function matchStateToProps(store) {
  return {
    lang: store.locale.lang, 
    // corpStore: store.corporate,
    faq: store.corporate.faq,
    faqPage: store.corporate.faqPage,
    fetching: store.corporate.fetching,
    faqCategories: store.corporate.faqCategories,
  };
}

function matchDispatchToProps(dispatch) {
  return {
    corporateActions: bindActionCreators(CorporateActions, dispatch)
  };
}

class FAQs extends Component {
  componentDidMount() {
    !this.props.faqPage && this.props.corporateActions.getFAQsPage();
    !this.props.faq && this.props.corporateActions.getFAQs();
  }

  getContent = (options = { corporateKey: 'faq', defaultReturn: {} }) => {
    const content = this.props[options.corporateKey] || {};
    const { lang } = this.props;
    // translated data & undefined safety object
    return content[lang]? content[lang]: content['ar'] || options.defaultReturn;
  }

  toggleClass = itemId => {
    let question = this.refs[itemId].childNodes[0];
    let answer = this.refs[itemId].childNodes[1];

    if (question.className === "ques-head active") {
      question.className = "ques-head";
      answer.className = "answer";
    } else {
      question.className = "ques-head active";
      answer.className = "answer active";
    }
  };

  renderFAQs = () => {
    const faq = this.getContent({ corporateKey: 'faq', defaultReturn: [] });
    const faqCategories = this.getContent({ corporateKey: 'faqCategories', defaultReturn: [] });

    console.log("FAQ: ", faq);
    
    return faqCategories.map((category, index) => {
      return (
        <div className="faq-content" key={index}>
          <h2>{category.name}</h2>
          {faq.map(item => {
            return (
              item.faq_category.includes(category.id) && (
                <div
                  className="question"
                  ref={item.id + "" + category.id}
                  key={item.id}
                >
                  <div
                    className="ques-head"
                    onClick={() => {
                      this.toggleClass(item.id + "" + category.id);
                    }}
                  >
                    <h3>{Utils.htmlEntitiesFix(item.title.rendered)}</h3>
                    <span>
                      <i className="material-icons">keyboard_arrow_left</i>
                    </span>
                  </div>
                  <div
                    className="answer"
                    dangerouslySetInnerHTML={{ __html: item.content.rendered }}
                  />
                </div>
              )
            );
          })}
        </div>
      );
    });
  };

  renderFAQLayout(faqPage) {
    return (
      <div className="page-content faq-page">
        <div className="container">
          <div className="page-con">
            <SideBar />
            <div className="content-widget">
              <div className="title">
                <h1>{faqPage.acf && (faqPage.acf.heading || "أسئلة شائعة")}</h1>
                {faqPage.content && (<div dangerouslySetInnerHTML={{ __html: faqPage.content.rendered}} /> || <p>لديك تسأول أو أستفسار عن حجز أتوبيس من جوباص؟</p>)}
              </div>
              {this.renderFAQs()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    
    let faqPage = this.getContent({ corporateKey: 'faqPage', defaultReturn: { title: {} } })

    return (
      <MainLayoutComponent page={this.props.location.pathname}>
        <Helmet key={window.location.href} >
          <title>
            {faqPage.title.rendered
              ? Utils.htmlEntitiesFix(faqPage.title.rendered)
              : "أسئلة شائعه لعملاء جو باص | Go Bus"}
          </title>
          {buildYoastMetaTags(faqPage.yoast_meta).map(tag => (
            <meta {...tag} />
          ))}
        </Helmet>
        {this.props.fetching && <FAQLoaderComponent />}
        {this.props.faq && this.renderFAQLayout(faqPage)}
      </MainLayoutComponent>
    );
  }
}

export default connect(
  matchStateToProps,
  matchDispatchToProps
)(FAQs);
