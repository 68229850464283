import React, { Component } from "react";
import { connect } from "react-redux";

import SeatsCountComponent from "../SeatsCountComponent";
import BrowseSelectComponent from "../BrowseSelectComponent";
import DatePicker from "../DatePicker";
import { Trans } from "react-i18next";
import i18n from "../../../i18n";
import Hint from "../../Utils/Hint";
import i18next from "i18next";
import { useCheckoutStore } from "../../../new-store/checkoutStore";

class SearchViewComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActiveSearchMob: false,
      isActiveSearcDesktop: false
    };
  }

  // Ojja
  toggleClassesSearch = () => {
    this.setState({ isActiveSearchMob: !this.state.isActiveSearchMob });
  };
  toggleSearchDesktop = () => {
    this.setState({
      isActiveSearcDesktop: !this.state.isActiveSearcDesktop
    });
  };

  render() {
    let {
      changeTransportationType,
      changeTripType,
      selectDepartureStation,
      selectArrivalStation,
      chooseDepartureTime,
      chooseArrivalTime,
      openDepartureDatePicker,
      openArrivalDatePicker,
      closeSearchBoxModals,
      search,
      busTapLinkClasses,
      boatTapLinkClasses,
      departure_station,
      arrival_station,
      departure_stations,
      arrival_stations,
      transportationType,
      displayDepartureDatePicker,
      tripType,
      displayArrivalDatePicker,
      departureDate,
      arrivalDate,
      SeatSelector,
      passengersNo,
      bookingAllowed
    } = this.props;

    let SearchComponentClasses = this.state.isActiveSearchMob
      ? "search-component active"
      : "search-component";

    let SearchComponentConClasses = this.state.isActiveSearchMob
      ? "search-component-con active"
      : "search-component-con";

    let defaultClassesData = this.state.isActiveSearcDesktop
      ? "data-show-desktop not-active"
      : "data-show-desktop";

    let SearchComponentConClassesDesktop = this.state.isActiveSearcDesktop
      ? "search-component-con show"
      : "search-component-con";

    const { lang } = this.props.locale;
    const stationKey = lang === "ar" ? "NameA" : "NameE";

    const bookingButtonClasses = `btn${!bookingAllowed ? " disabled" : ""}`;


    return (
      <div className="search-component-parent">
        <div className="search-component">
          <div className={defaultClassesData}>
            <div className="col">
              <h5>
                <i className="material-icons">pin_drop</i>
                <Trans i18nKey="searchbox.from">سفر من</Trans>
              </h5>
              <p>{departure_station && departure_station[stationKey]}</p>
            </div>
            <div className="col">
              <h5>
                <i className="material-icons">pin_drop</i>
                <Trans i18nKey="searchbox.to">سفر من</Trans>
              </h5>
              <p>{arrival_station && arrival_station[stationKey]}</p>
            </div>
            <div className="col">
              <h5>
                <i className="material-icons">date_range</i>
                <Trans i18nKey="searchbox.from_date">تاريخ السفر</Trans>
              </h5>
              <p>{departureDate.locale(lang).format("dddd D MMMM")}</p>
            </div>
            {tripType === "round" && (
              <div className="col">
                <h5>
                  <i className="material-icons">date_range</i>
                  <Trans i18nKey="searchbox.to_date">تاريخ العودة</Trans>
                </h5>
                <p>{arrivalDate.locale(lang).format("dddd D MMMM")}</p>
              </div>
            )}
            <div className="col">
              <h5>
                <i className="material-icons">people</i>
                <Trans i18nKey="common.passengers_no">عدد المسافرين</Trans>
              </h5>
              <p>{passengersNo}</p>
            </div>
            <button
              className="openSearch"
              onClick={() => {
                this.toggleSearchDesktop();
              }}
            >
              <Trans i18nKey="searchbox.modify_search">تعديل البحث</Trans>
            </button>
          </div>
          {/* .data-show-desktop */}
          <div className="data-show-mob">
            <div className="col">
              <h5>
                <Trans i18nKey="searchbox.from_date">تاريخ السفر</Trans>
              </h5>
              <p>{departureDate.locale(lang).format("dddd D MMMM")}</p>
            </div>
            {tripType === "round" && (
              <div className="col">
                <h5>
                  <Trans i18nKey="searchbox.to_date">تاريخ العودة</Trans>
                </h5>
                <p>{arrivalDate.locale(lang).format("dddd D MMMM")}</p>
              </div>
            )}
            <div className="col">
              <h5>
                <Trans i18nKey="common.passengers_no">عدد المسافرين</Trans>
              </h5>
              <p>{passengersNo}</p>
            </div>
            <button
              className="col openSearch"
              onClick={() => {
                this.toggleClassesSearch();
              }}
            >
              <i class="material-icons">search</i>
              <Trans i18nKey="searchbox.modify_search">تعديل البحث</Trans>
            </button>
          </div>
          {/* .data-show-mob */}

          <div
            className={
              SearchComponentConClasses + " " + SearchComponentConClassesDesktop
            }
          >
            <button
              className="closeSearch"
              onClick={() => {
                this.toggleClassesSearch();
              }}
            >
              <i className="material-icons">close</i>
            </button>
            <form action="POST">
              <div className="inputs-container">
                <div className="col big">
                  <div className="input-field">
                    <label>
                      <i className="material-icons">pin_drop</i>{" "}
                      <Trans i18nKey="searchbox.from">سفر من</Trans>
                    </label>
                    <BrowseSelectComponent
                      border="1px solid #bbc9d9"
                      default={departure_station}
                      type={BrowseSelectComponent.TYPE.departure}
                      onChange={selectDepartureStation}
                      options={departure_stations}
                    />
                  </div>
                </div>
                <div className="col big">
                  <div className="input-field">
                    <label>
                      <i className="material-icons">pin_drop</i>
                      <Trans i18nKey="searchbox.to">وصول إلي</Trans>
                    </label>
                    <BrowseSelectComponent
                      border="1px solid #bbc9d9"
                      default={arrival_station}
                      type={BrowseSelectComponent.TYPE.return}
                      onChange={selectArrivalStation}
                      options={arrival_stations}
                    />
                  </div>
                </div>
                <div
                  className={
                    tripType === "oneWay"
                      ? "datepickercom departure"
                      : "datepickercom return"
                  }
                >
                  <DatePicker
                    from={departureDate}
                    to={arrivalDate}
                    type={tripType}
                    changeFrom={chooseDepartureTime}
                    changeTo={chooseArrivalTime}
                    changeType={changeTripType}
                    page="search"
                  />
                </div>
                <SeatsCountComponent
                  className="search"
                  passengersNo={this.props.passengersNo}
                  increment={this.props.incrementPassengers}
                  decrement={this.props.decrementPassengers}
                />
              </div>
              <input
                type="button"
                value={i18n.t("searchbox.search")}
                className="btn"
                onClick={() => {
                  if (!bookingAllowed) return;
                  this.toggleClassesSearch();
                  search();
                }}
                className={bookingButtonClasses}
              />
              <i
                onClick={this.toggleSearchDesktop}
                className="material-icons closeBtn"
              >
                close
              </i>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  locale: state.locale
});

export default connect(mapStateToProps)(SearchViewComponent);
