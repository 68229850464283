import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Helmet from "react-helmet"

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as CorporateActions from '../../Actions/CorporateActions'

// Components 
import MainLayoutComponent from '../MainLayoutComponent'
import DestinationPlaceholder from '../Utils/Placeholders/DestinationPlaceholder';
import Utils from '../../Helpers/Utils';
import is from "is_js";
import { Trans } from 'react-i18next';

function matchStateToProps(store) {
    return {
        lang: store.locale.lang,
        corpStore: store.corporate
    }
}

function matchDispatchToProps(dispatch) {
    return {
        corporateActions: bindActionCreators(CorporateActions, dispatch)
    }
}


class DestinationsComponent extends Component {

    /**
     * get the Destination page if only not requsted before: minimize number of requests
     * @author Kareem Gehad
     * @revised Ezzat
     */
    componentDidMount() {
        !this.props.corpStore.destinations && this.props.corporateActions.getDestinations();

    }

    scrollTop = () => {
        Utils.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    getContent = (options = { defaultReturn: {} }) => {
        const destinations = this.props.corpStore.destinations || {};
        const { lang } = this.props;
        // translated data & undefined safety object
        return destinations[lang]? destinations[lang]: destinations['ar'] || options.defaultReturn;
      }

    SliderOrGrid = ({destinations, id}) => {
        // if(is.mobile()) {
        //     return (
        //         <div className="destination_group">
        //             {
        //                 destinations.map((destination, index) => {
        //                     let imgUrl =
        //                         (destination.better_featured_image !== null) && (
        //                             destination.better_featured_image.source_url
        //                         )
        //                     let DestinationImg = {
        //                         backgroundImage: `url(${imgUrl})`
        //                     };
        //                     return (
        //                         <Link className="feature" style={DestinationImg} key={index} onClick={this.scrollTop}  to={`destination/${destination.slug}`}>
        //                             <div className="hidden-box">
        //                                 <h3>
        //                                     {destination.acf && destination.acf.display_name}
        //                                     <span></span>
        //                                 </h3>
        //                             </div>
        //                         </Link>
        //                     )
        //                 })
        //             }
        //         </div>
        //     )
        // } else {
            return destinations.map((destination, index) => {
                let imgUrl =
                    (destination.better_featured_image !== null) && (
                        destination.better_featured_image.source_url
                    )
                let DestinationImg = {
                    backgroundImage: `url(${imgUrl})`
                };
                return (
                    <Link className="feature" style={DestinationImg} key={index} onClick={this.scrollTop}  to={`destination/${destination.slug}`}>
                        <div className="hidden-box">
                            <h3>
                                {destination.acf && destination.acf.display_name}
                                <span></span>
                            </h3>
                        </div>
                    </Link>
                )
            })
        // }
    }

    renderDestinations = () => {
        let destinations = this.getContent({ defaultReturn: [] });
        return <this.SliderOrGrid destinations={destinations} />
    }
    renderDestLayout() {
        return (
            <div className="page-content routs-page">
                <div className="featrured-routes">
                    <div className="container">
                        <h2><Trans i18nKey="header.nav.destinations">وجهات السفر</Trans></h2>
                        <div className="featrured-routes-parent">
                            <div className="featrured-routes-con destinations">
                                {this.renderDestinations()}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <MainLayoutComponent page={this.props.location.pathname}>
                <Helmet key={window.location.href} >
                    <title>Destinations | Go Bus</title>
                </Helmet>
                {this.props.corpStore.destinations && this.renderDestLayout() }
                {this.props.corpStore.fetching && <DestinationPlaceholder /> }
            </MainLayoutComponent>
        );
    }
}

export default connect(matchStateToProps, matchDispatchToProps)(DestinationsComponent);