import React, { Component } from "react";
import Utils from "../../Helpers/Utils";
import { ClickAwayListener } from "@material-ui/core";
import { connect } from 'react-redux'


class BrowseSelectComponent extends Component {
  static TYPE = {
    departure: 0,
    return: 1
  };
  constructor(props) {
    super(props);

    this.state = {
      active: false,
      subgroupActive: false,
      subgroupOptions: []
    };
  }

  componentDidMount() {
    // window.addEventListener("mousedown", this.handleMouseClick);
  }

  componentWillUnmount() {
    // window.removeEventListener("mousedown", this.handleMouseClick);
  }

  toggle = () => {
    this.setState({
      active: !this.state.active,
      subgroupActive: false
    });
  };

  close = () => {
    if(!this.state.active) return;
    this.setState({
      active: false,
      subgroupActive: false
    })
  }

  setSubgroupOptions(options) {
    this.setState({
      subgroupOptions: options,
      subgroupActive: true
    });
  }

  hideSubGroup = () => {
    this.setState({
      subgroupActive: false
    });
  };

  setValue = (value, option) => {
    this.setState({
      subgroupActive: false,
      active: false
    });
    
    this.props.onChange(value);
  };

  renderDepartureStations = () => {
    const { lang } = this.props.locale;
    const stationKey = lang === 'ar'? "NameA": "NameE";
    
    return (
      <React.Fragment>
        <ul
          className="browse-select-options"
          ref={ref => (this.subnode = ref)}
          style={{ border: this.props.border }}>
          {this.props.options &&
            Object.keys(this.props.options).map(option => {
              if (this.props.options[option].length > 1) {
                return (
                  <li
                    key={option}
                    className="browse-group-btn"
                    onClick={() =>
                      this.setSubgroupOptions(this.props.options[option])
                    }>
                    {option}
                    <img
                      className="rotate180"
                      src="/images/back.png"
                      alt="back"
                    />
                  </li>
                );
              } else {
                option = this.props.options[option];
                return (
                  <li key={option[0].Code} onClick={() => this.setValue(option[0])}>
                    {option[0][stationKey]}
                  </li>
                );
              }
            })}
        </ul>
        <ul
          className={
            this.state.subgroupActive
              ? "browse-select-options subgroup active"
              : "browse-select-options subgroup"
          }
          style={{ border: this.props.border }}>
          <li onClick={this.hideSubGroup}>
            <img src="/images/back-arrow.png" alt="back-arrow" />
          </li>
          {this.state.subgroupOptions.map(opt => {
            const value = (opt[stationKey] ? opt[stationKey] : opt['NameA']) || "";
            return (
              <li key={opt.Code} onClick={() => this.setValue(opt)}>
                {value.slice(
                  value.indexOf("-") + 1,
                  value.length
                ).trim()}
              </li>
            );
          })}
        </ul>
      </React.Fragment>
    );
  };

  renderReturnStations = () => {
    const { lang } = this.props.locale;
    const stationKey = lang === 'ar'? "NameA": "NameE";

    return (
      <ul
        className="browse-select-options"
        ref={ref => (this.subnode = ref)}
        style={{ border: this.props.border }}>
        {this.props.options &&
          Object.keys(this.props.options).map(option => {
              option = this.props.options[option];
              return (
                <li key={option.Code} onClick={() => this.setValue(option)}>
                  {option[stationKey]}
                </li>
              );
          })}
      </ul>
    );
  };

  renderStations = () => {
    const { lang } = this.props.locale;
    const stationKey = lang === 'ar'? "NameA": "NameE";

    return (
      <React.Fragment>
        <ul
          className="browse-select-options"
          ref={ref => (this.subnode = ref)}
          style={{ border: this.props.border }}>
          {this.props.options &&
            Object.keys(this.props.options).map(option => {
              if (this.props.options[option].length > 1) {
                return (
                  <li
                    className="browse-group-btn"
                    onClick={() =>
                      this.setSubgroupOptions(this.props.options[option])
                    }>
                    {option}
                    <img
                      className="rotate180"
                      src="/images/back.png"
                      alt="back"
                    />
                  </li>
                );
              } else {
                option = this.props.options[option];
                return (
                  <li onClick={() => this.setValue(option[0])}>
                    {option[0][stationKey]}
                  </li>
                );
              }
            })}
        </ul>
        <ul
          className={
            this.state.subgroupActive
              ? "browse-select-options subgroup active"
              : "browse-select-options subgroup"
          }
          style={{ border: this.props.border }}>
          <li onClick={this.hideSubGroup}>
            <img src="/images/back-arrow.png" alt="back-arrow" />
          </li>
          {this.state.subgroupOptions.map(opt => {
            return (
              <li onClick={() => this.setValue(opt)}>
                {opt[stationKey].slice(
                  opt[stationKey].indexOf("-") + 1,
                  opt[stationKey].length
                ).trim()}
              </li>
            );
          })}
        </ul>
      </React.Fragment>
    );
  }

  handleMouseClick = event => {
    if (!this.subnode || !this.node) return;
    const btnRect = this.node.getBoundingClientRect();
    const menuRect = this.subnode.getBoundingClientRect();
    const path = event.path || (event.composedPath && event.composedPath());

    if(!path || !Array.isArray(path)) return;

    let isButton = Utils.isPointInRect(
      event.pageX,
      event.pageY,
      btnRect.x,
      btnRect.y,
      btnRect.width,
      btnRect.height
    );
    let isMenu = Utils.isPointInRect(
      event.pageX,
      event.pageY,
      menuRect.x,
      menuRect.y,
      menuRect.width,
      menuRect.height
    );

    let selector = path.filter(p => p === this.node || p === this.subnode);

    if (isButton && selector.length > 0) {
      this.toggle();
    }

    if (!isButton && !isMenu && selector.length === 0) {
      this.setState({
        subgroupActive: false,
        active: false
      });
    }
  };

  render() {
    if (typeof this.props.options !== "object") {
      return null;
    }
    const { lang } = this.props.locale;
    const stationKey = lang === 'ar'? "NameA": "NameE";

    return (
      //onClick={this.toggle}
      <ClickAwayListener onClickAway={this.close}>
        <div style={{ position: "relative" }}>
          <div className="browse-select-component" onClick={this.toggle} ref={ref => (this.node = ref)}>
            {this.props.default && <span>{this.props.default[stationKey]}</span>}
          </div>
          <div
            className={
              this.state.active ? "browse-select-div active" : "browse-select-div"
            }>
            {
              // this.renderStations()
              this.props.type === BrowseSelectComponent.TYPE.departure
                ? this.renderDepartureStations()
                : this.renderReturnStations()
            }
          </div>
        </div>
      </ClickAwayListener>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: state.locale
})

export default connect(mapStateToProps)(BrowseSelectComponent);
