import { __ENV__ } from './Api/Base';

const bus_images_base = "/images/new-bus-classes";

export default {
  "cardTypes": {
    "credit_card": 3,
    "fawry_aman": 2
  }
}

export const CancelledCardStatus = {
  FULL_CANCEL: 1,
  PARTIAL_CANCEL: 2,
  NOT_CANCELLED: 0
}

export const wpPostIdMap = {
	about: {
		ar: 24,
		en: 697
	},
	nbe: {
		ar: 1328,
		en: 1328
	},
	terms: {
		ar: 255,
		en: 711
	},
	policy: {
		ar: 1622,
		en: 1627
	},
	faq: {
		ar: 51,
		en: 709
	},
	home: {
		ar: 5,
		en: 708
	},
	blogs: {
		ar: 79,
		en: 710
  },
  contact_us: {
    ar: 189,
    en: 712
  }
}

export const scrollSpeed = 1000;

const services = {
  WiFi: {
    name: {
      en: "WiFi",
      ar: "واي فاي"
    }
  },
  TV: {
    name: {
      en: "TV",
      ar: 'تلفزيون'
    }
  },
  AC: {
    name: {
      en: "Conditioner",
      ar: 'مكيف'
    }
  },
  Food: {
    name: {
      en: "Food",
      ar: 'طعام'
    }
  },
  Drink: {
    name: {
      en: "Drink",
      ar: 'مشاريب'
    }
  },
  DVD: {
    name: {
      en: "DVD",
      ar: 'دي في دي'
    }
  }
}
/**
 * * this array contains home page bus classes,
 * * services contained are titles for the image element and 
 * * converted to lowercase to match the image file name in filesystem.
 */
export const featuredClassesArray = [
  {
    i18nKey: "home_page.bus_classes.royal_plus",
    className: "رويال بلس",
    image: "",
    services: [
      services.TV,
      services.AC,
      services.Food,
      services.Drink,
    ],
    price: {
      high: 265,
      low: 265
    }
  },
  {
    code: 5,
    i18nKey: "home_page.bus_classes.deluxe_plus",
    className: "دي لوكس بلس",
    image: bus_images_base + "/deluxe-plus/1.png",
    services: [
      services.TV,
      services.AC,
    ],
    price: {
      high: 265,
      low: 180
    }
  },
  {
    code: 20,
    i18nKey: "home_page.bus_classes.elite_double",
    className: "Elite D.D",
    image: bus_images_base + "/elite-DD/1.png",
    services: [
      services.TV,
      services.AC,
      services.DVD,
    ],
    price: {
      high: 445,
      low: 165
    }
  },
  {
    code: 15,
    i18nKey: "home_page.bus_classes.elite_plus",
    className: "إيليت بلس",
    image: bus_images_base + "/elite-plus/1.png",
    services: [
      services.TV,
      services.AC,
      services.Food,
      services.Drink,
      services.DVD,
    ],
    price: {
      high: 385,
      low: 385
    }
  },
  {
    code: 18,
    i18nKey: "home_page.bus_classes.go_mini",
    className: "GoMini",
    image: bus_images_base + "/go-mini/1.jpg",
    services: [],
    price: {
      high: 230,
      low: 45
    }
  },
  {
    code: 19,
    i18nKey: "home_page.bus_classes.new_delux",
    className: "نيو ديلوكس",
    image: bus_images_base + "/new-deluxe/1.png",
    services: [
      services.TV,
      services.AC,
    ],
    price: {
      high: 265,
      low: 225
    }
  },
  {
    code: 11,
    i18nKey: "home_page.bus_classes.economic",
    className: "اقتصادي",
    image: bus_images_base + "/economic/1.png",
    services: [
      services.TV,
      services.AC,
    ],
    price: {
      high: 180,
      low: 100
    }
  },
  {
    code: 13,
    i18nKey: "home_page.bus_classes.elite_plus+",
    className: "إيليت بلس +",
    image: bus_images_base + "/elite-plus-plus/1.png",
    services: [
      services.TV,
      services.AC,
      services.Food,
      services.Drink,
    ],
    price: {
      high: 440,
      low: 130
    }
  },
  {
    i18nKey: "home_page.bus_classes.vip",
    className: "في اي بي",
    image: "",
    services: [],
    price: {
      high: 100,
      low: 100
    }
  },
  { 
    code: 23,
    i18nKey: "home_page.bus_classes.aero",
    className: "Aero",
    image: bus_images_base + "/aero-class/1.png",
    services: [
      services.TV,
      services.AC,
      services.Food,
      services.Drink,
      services.DVD,
    ],
    price: {
      high: 545,
      low: 545
    }
  },
  // { 
  //   code: 701,
  //   i18nKey: "home_page.bus_classes.limo",
  //   className: "Go Limo",
  //   image: bus_images_base + "/go-limo/1.jpg",
  //   services: [
  //     services.AC,
  //   ],
  //   price: {
  //     high: 0,
  //     low: 0
  //   },
  //   priceDepends: true
  // }
]

export const busClassNameMap = {
  'aero': 'Aero First Class',
  'elite plus': 'Elite Business M',
  'elite plus plus': 'Elite Business V',
  'elite dd': 'Business Class DD',
  'elite d.d': 'Business Class DD',
  'deluxe plus': 'Super Go D',
  'classic': 'Economy'
};

export const getClassi18nKey = (code) => (featuredClassesArray.find(c => c.code === code) || {}).i18nKey
export const getFeaturedClass = (code) => (featuredClassesArray.find(c => c.code === code));
