import React, { Component } from "react";
import { connect } from "react-redux";

import * as UserActions from "../../../Actions/UserActions";

// Components
import ErrorComponent from "../../Utils/ErrorComponent";
import { bindActionCreators } from "redux";
import Utils from "../../../Helpers/Utils";
import { Trans } from 'react-i18next';

class FBSignupForm extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            hasError: false,
            errorMessage: [],
            phoneNumber: null,
            email: null
        }
    }

    setPhoneNumber = (e) => {
        this.setState({
          hasError: false,
          errorMessage: [],
          phoneNumber: e.target.value
        });
    }

    setEmail = (e) => {
      this.setState({
        hasError: false,
        errorMessage: [],
        email: e.target.value
      })
    }

    fbSignup = () => {

        if(!this.props.userStore.fbResponse && !Utils.isValidEmail(this.state.email)) {
            this.setState({
                hasError: true,
                errorMessage: ["البريد الإلكتروني خاطئ"]
            })
            return;
        }

        if(!Utils.isValidPhone(this.state.phoneNumber)) {
            this.setState({
                hasError: true,
                errorMessage: ["رقم الهاتف خاطئ"]
            })
            return;
        }

        const userStore = this.props.userStore;
        const fbResponse = userStore.fbResponse;
        const name = fbResponse.name;
        const email = fbResponse.email || this.state.email;
        const picture = fbResponse.picture ? fbResponse.picture.data.url : undefined;
        
        this.props.userActions.fbSignup(name, email, this.state.phoneNumber, fbResponse.userID, picture || "/images/img_avatar.png", fbResponse.userID);
    }
  render() {
    const userStore = this.props.userStore;
    const hasError = userStore.hasError;
    const errorMessage = userStore.errorMessage;
    const fbResponse = userStore.fbResponse;
    if(!fbResponse) return null;

    const name = fbResponse.name;
    const email = fbResponse.email;
    const picture = fbResponse.picture ? fbResponse.picture.data.url : undefined;

    return (
      <form className="sign-form fb active">

        <h4>{hasError && <ErrorComponent errors={errorMessage} />}</h4>
        <h4>{this.state.hasError && <ErrorComponent errors={this.state.errorMessage} />}</h4>

        <div className="avatar">
            <img src={picture || "/images/img_avatar.png"} alt=""/>
            <input type="text" value={name} />
        </div>
        
        <div className="input-field">
          <label><Trans i18nKey="common.email">البريد الإلكتروني</Trans></label>
          {
            email
              ? <input type="email" defaultValue={email} readOnly />
              : <input type="email" defaultValue={email} onChange={this.setEmail} />
          }
          
        </div>

        <div className="input-field">
          <label><Trans i18nKey="common.phone">رقم المحمول</Trans></label>
          <input type="text" value={this.state.phoneNumber} onChange={this.setPhoneNumber} />
        </div>

        <button type="button" className="btn btnSubmit" onClick={this.fbSignup}>
          <Trans i18nKey="auth.register.register_btn">انشاء حساب جديد</Trans>
        </button>
      </form>
    );
  }
}

function mapStateToProps(store) {
	return {
		userStore: store.user
	}
}

function mapDispatchToProps(dispatch) {
	return {
		userActions: bindActionCreators(UserActions, dispatch),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FBSignupForm);
