import { showLoading, hideLoading } from 'react-redux-loading-bar'

import * as actionTypes from './ActionTypes'
import corpApi from '../Api/CorporateApi'

import _ from "lodash";

import Utils from '../Helpers/Utils';
import i18next from 'i18next';
import debug from '../Helpers/DebugLog';

export function getAbout() {
    return function (dispatch) {
        dispatch(handle_fetching_data())
        corpApi.getAbout().then(res => {
            dispatch(handle_getting_about(res))
        }).catch(err => {
            console.error('CorpAction: Error', err)
        })
    }
}

export function getNBE() {
    return function (dispatch) {
        dispatch(handle_fetching_data())
        corpApi.getNBE().then(res => {
            dispatch(handle_getting_nbe(res))
        }).catch(err => {
            console.error('CorpAction: Error', err)
        })
    }
}

export function getTerms() {
    return function (dispatch) {
        dispatch(handle_fetching_data())
        corpApi.getTerms().then(res => {
            dispatch(handle_getting_terms(res))
        }).catch(err => {
            console.error('CorpAction: Error', err)
        })
    }
}


export function getPolicy() {
    return function (dispatch) {
        dispatch(handle_fetching_data())
        corpApi.getPolicy().then(res => {
            dispatch(handle_getting_policy(res))
        }).catch(err => {
            console.error('CorpAction: Error', err)
        })
    }
}

export function getStation() {
    return function (dispatch) {
        dispatch(handle_fetching_data())
        corpApi.getStation()
            .then(res => {
                dispatch(handle_getting_station(res.station, res.stationCategories))
            })
            .catch(err => {
                console.error('CorpAction: Error', err)
            }) 
    }
}

export function getFAQs() {
    return function (dispatch) {
        dispatch(handle_fetching_data())
        corpApi.getFAQs()
            .then(res => {
                dispatch(handle_getting_faq(res.faq, res.faqCategories))
            })
            .catch(err => {
                console.error('CorpAction: Error', err)
            }) 
    }
}

export function getFAQsPage() {
    return function (dispatch, getState) {
        let faq = getState().corporate.faq;
        if(faq && faq.page) {
            return;
        }
        corpApi.getFAQsPage()
            .then(res => {
                dispatch(handle_getting_faq_page(res))
            })
            .catch(err => {
                console.error('CorpAction: Error', err);
            }) 
    }
}

export function getContactUs() {
    return function (dispatch) {
        dispatch(handle_fetching_data());
        corpApi.getContactUs()
            .then(res => {
                dispatch(handle_getting_contact_us(res));
            })
            .catch(err => {
                console.error('CorpAction: Error', err)
            })
    }
}

export function getDestinations() {
    return function (dispatch) {
        dispatch(handle_fetching_data())
        corpApi.getDestinations()
            .then(res => {
                dispatch(handle_getting_destinations(res));
            })
            .catch(err => {
                console.error('CorpAction: Error', err)
            })
    }
}

export function getSingleDestination(id) {
    return function (dispatch) {
        dispatch(handle_fetching_data())
        corpApi.getSingleDestination(id)
            .then(res => {
                dispatch(handle_getting_single_destination(res));
            })
            .catch(err => {
                console.error('CorpAction: Error', err)
            })
    }
}

export function getHomePage() {
    return function (dispatch) {
        corpApi.getHomePage()
            .then(res => {
                let { tagline, hero_image,hero_image_en, title } = res.ar.acf;
                Utils.SessionStorage.set("hero_image", hero_image);
                Utils.SessionStorage.set("hero_image_en", hero_image_en);
                Utils.SessionStorage.set("hero_title", title);
                Utils.SessionStorage.set("hero_tagline", tagline);

                dispatch({
                    type: actionTypes.GET_HOMEPAGE,
                    homepage: res
                })
            })
            .catch(err => {
                console.error('CorpAction: Error', err)
            })
    }
}

export function getBlogs() {
    return function (dispatch) {
        dispatch(handle_fetching_data())
        corpApi.getBlogs()
            .then(res => {
                dispatch(handle_getting_blogs(res));
            })
            .catch(err => {
                console.error('CorpAction: Error', err)
            })
    }
}

export function getBlogsPage() {
    return (dispatch, getState) => {
        if(getState().corporate.news.page) {
            return;
        }
        corpApi.getBlogsPage()
            .then(res => {
                dispatch(handle_getting_blogs_page(res));
            })
            .catch(err => {
                console.error('CorpAction: Error', err)
            })
    }
}

export function getRoutes() {
    return function (dispatch, getState) {
        return corpApi.getRoutes()
            .then(res => {
                dispatch({
                    type: actionTypes.GET_ROUTES_SUCCESS,
                    routes: res
                })

                let search = getState().search;
                if(search.arrivalStation && search.departureStation) {
                    dispatch(updateCurrentRoute(search.departureStation.Code, search.arrivalStation.Code))
                }

                return res
            })
            .catch(err => {
                console.error('Failed to get featured routes.');
                console.error('CorpAction: Error', err)
            })
    }
}

export function updateCurrentRoute(departId, arrivId) {
    return async function (dispatch, getState) {
        let routes = getState().corporate.routes;
        // debug('routes', routes);
        // debug('lang', i18next.language);
        if(!routes) {
            // dispatch(await getRoutes());
            // routes = getState().corporate.routes;
            return;
        }

        if(Array.isArray(routes['en']) && Array.isArray(routes['ar'])) {
            const routeFinder = r => {
                let from = _.get(r, 'acf.from');
                let to = _.get(r, 'acf.to');
                return Number(from) === Number(departId) && Number(to) === Number(arrivId)
            };

            const enRoute = routes['en'].find(routeFinder);
            const arRoute = routes['ar'].find(routeFinder);

            const data = {};
            if (enRoute && arRoute) {
                data['en'] = enRoute;
                data['ar'] = arRoute;
            }

            dispatch({
                type: actionTypes.UPDATE_CURRENT_ROUTE,
                data
            })
        }
    }
}

// News actions

/**
 * @param {Number} per_page number of news per iteration
 * @param {Number} page page number to be fetched
 * @returns news: Array, max_pages: Number
 */
export function getMoreNews(per_page, page) {
    return function (dispatch) {
        dispatch(handle_fetching_data());
        corpApi.getMoreNews(per_page, page)
            .then(res => {
                res.news = {
                    ar: res.ar,
                    en: res.en
                }

                res.max_pages = Number(res['x-wp-totalpages']);
                delete res.ar;
                delete res.en;
                delete res['x-wp-totalpages'];

                dispatch(handle_getting_news(res, page));
            })
            .catch(err => {
                // Dispatch error
                console.error('CorpAction: Error', err)
            })
    }
}

/**
 * 
 * @param {Number} slug news reference slug.
 */
export function getHotNews() {
    return function (dispatch) {
        dispatch(handle_fetching_data());

        corpApi.getHotNews()
            .then(res => {
                dispatch(handle_getting_hot_news(res));
            })
            .catch(err => {
                // Dispatch error
                console.error('CorpAction: Error', err)
            })
    }
}


/**
 * 
 * @param {Number} slug news reference slug.
 */
export function getSingleNews(slug) {
    return function (dispatch) {
        dispatch(handle_fetching_data());

        corpApi.getSingleNews(slug)
            .then(res => {
                dispatch(handle_getting_single_news(res));
            })
            .catch(err => {
                // Dispatch error
                console.error('CorpAction: Error', err)
            })
    }
}

export function gotMaxNews() {
    return function (dispatch) {
        dispatch(handle_got_max_news());
    }
}

/**
 * Extract the Cheapest Trip in each City trips
 * change city from an Array to Object with two Keys 1-cheapest_trip and 2-Trips
 * @author Ezzat
 */
function getCityCheapestTrip(cityTrips){
    let going_cities = cityTrips.going_cities // object of arrays of objects {[{}, {}, {}] ,[] , []}
    let returning_cities = cityTrips.returning_cities // object of arrays of objects {[{}, {}, {}] ,[] , []}

    let new_going_cities = {}    ;
    let new_returning_cities = {}    ;

    // Get City Cheapest Trip
    Object.keys(going_cities).forEach(city => {
        let temp_city = {}
        temp_city.trips = going_cities[city]
        temp_city.cheapest_trip = temp_city.trips.reduce((prev, curr) => {
            return prev.price < curr.price ? prev : curr;
        });
        new_going_cities[city] = temp_city
    })

    // Get City Cheapest Trip
    Object.keys(returning_cities).forEach(city => {
        let temp_city = {}
        temp_city.trips = returning_cities[city]
        temp_city.cheapest_trip = temp_city.trips.reduce((prev, curr) => {
            return prev.price < curr.price ? prev : curr;
        });
        new_returning_cities[city] = temp_city
    })

    return {
        station: cityTrips.station,
        going_cities: new_going_cities,
        returning_cities: new_returning_cities
    }
}


/**************************************** Handlers **************************************/
export function handle_getting_about(about) {
    return {
        type: actionTypes.GET_ABOUT,
        about
    }
}
export function handle_getting_nbe(nbe) {
    return {
        type: actionTypes.GET_NBE,
        nbe
    }
}
export function handle_getting_terms(terms) {
    return {
        type: actionTypes.GET_TERMS,
        terms
    }
}

export function handle_getting_policy(policy) {
    return {
        type: actionTypes.GET_POLICY,
        policy
    }
}

export function handle_getting_station(station, categories) {
    return {
        type: actionTypes.GET_STATION,
        station,
        stationCategories: categories
    }
}

export function handle_getting_faq(faq, categories) {
    return {
        type: actionTypes.GET_FAQS,
        faq,
        faqCategories: categories
    }
}

export function handle_getting_contact_us(data) {
    return {
        type: actionTypes.GET_CONTACT_US,
        data
    }
}

export function handle_getting_faq_page(page) {
    return {
        type: actionTypes.GET_FAQS_PAGE,
        page
    }
}

export function handle_getting_destinations(destinations) {
    return {
        type: actionTypes.GET_DESTINATIONS,
        destinations
    }
}

export function handle_getting_single_destination(single_destination) {
    return {
        type: actionTypes.GET_SINGLE_DESTINATION,
        data: single_destination
    }
}

export function handle_getting_blogs(blogs) {
    return {
        type: actionTypes.GET_BLOGS,
        blogs
    }
}

export function handle_getting_blogs_page(page) {
    return {
        type: actionTypes.GET_BLOGS_PAGE,
        page
    }
}

export function handle_getting_stations(stations) {
    return {
        type: actionTypes.GET_STATIONS,
        stations
    }
}

export function handle_getting_single_station(station) {
    return {
        type: actionTypes.GET_SINGLE_STATION,
        station
    }
}

export function handle_getting_news(data, page) {
    return {
        type: actionTypes.GET_NEWS,
        news: data.news,
        page,
        max_pages: data.max_pages
    }
}

export function handle_getting_hot_news(data) {
    return {
        type: actionTypes.GET_HOT_NEWS,
        hot_news: data
    }
}

export function handle_getting_single_news(data) {
    return {
        type: actionTypes.GET_SINGLE_NEWS,
        single_news: data
    }
}

function handle_got_max_news() {
    return {
        type: actionTypes.GOT_MAX_NEWS
    }
}

export function handle_fetching_data() {
    return {
        type: actionTypes.FETCHING_CORPORATE_DATA
    }
}