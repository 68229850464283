class InputHandler {
    
    KEYS = {
        Escape: 27
    }

    constructor() {
        this.listeners = [];
        
        this.listen = this.listen.bind(this);
        this.dispatch = this.dispatch.bind(this);

        window.addEventListener('keydown', this.dispatch);
    }

    
    /**
     * 
     * @param {React.KeyboardEvent} e 
     */
    dispatch (e) {
        this.listeners.forEach(fn => fn(e.keyCode));
    }

    /**
     * 
     * @param {Function} fn 
     */
    listen (fn) {
        this.listeners.push(fn);
    }
}

export default new InputHandler();