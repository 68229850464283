import React, { Component } from "react";
import { bindActionCreators } from "redux";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import qs from "query-string";
import Sticky from 'react-sticky-el';
import GalleryPopup from "../Popups/Gallery-popup";


// Actions
import * as SearchActions from "../../Actions/SearchActions";
import * as TripActions from "../../Actions/TripActions";

// Componnets
import MainLayoutComponent from "../MainLayoutComponent";
import SearchBoxComponent from "../SearchBox/SearchBoxComponent";
import CartComponent from "../Utils/CartComponent";

import TripListComponent from "./Trip/TripListComponent";
import FilterComponent from "./Filter/FilterComponent";
import Facebook from "../../Helpers/FacebookHelper";
import { ToastContainer } from "react-toastify";
import { updateCurrentRoute, getRoutes } from "../../Actions/CorporateActions";
import { buildYoastMetaTags } from "../../Helpers/Yoast";
import Utils from "../../Helpers/Utils";
import debug from '../../Helpers/DebugLog';
import { TiktokPixelHelper } from "../../Helpers";
import { Trans } from "react-i18next";
import moment from "moment";
import TwoWayOfferComponent from "../Utils/TwoWayOfferComponent";

function mapStateToProps(store) {
  return {
    locale: store.locale,
    userStore: store.user,
    searchStore: store.search,
    tripsStore: store.trips,
    currentRoute: store.corporate.currentRoute,
    routes: store.corporate.routes,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    searchActions: bindActionCreators(SearchActions, dispatch),
    tripActions: bindActionCreators(TripActions, dispatch),
    updateCurrentRoute: bindActionCreators(updateCurrentRoute, dispatch),
    getRoutes: bindActionCreators(getRoutes, dispatch)
  };
}

class SearchResultComponent extends Component {

  componentWillMount() {
    !this.props.routes && this.props.getRoutes();
    this.loadSearch();
  }

  /**
   * responsible for getting the trips when the component mounts
   * @author Ezzat
   */
  componentDidMount() {
    // let search_store_data = {
    //   transportationType: this.props.searchStore.transportationType,
    //   tripType: this.props.searchStore.tripType || "oneWay",
    //   passengersNo: this.props.searchStore.passengersNo || 1,
    //   departureStation: this.props.searchStore.departureStationId,
    //   arrivalStation: this.props.searchStore.arrivalStationId,
    //   departureDate: this.props.searchStore.departureDate,
    //   arrivalDate: this.props.searchStore.arrivalDate
    // };

    if (
      this.props.searchStore.departureStation &&
      this.props.searchStore.arrivalStation
    ) {
      Facebook.Search(
        window.location.href,
        this.props.searchStore.departureStation.NameE,
        this.props.searchStore.arrivalStation.NameE
      );
      TiktokPixelHelper.Search(
        window.location.href,
        this.props.searchStore.departureStation.NameE,
        this.props.searchStore.arrivalStation.NameE
      );
    }

    // this.props.tripActions.searchTrips(
    //   search_store_data,
    //   search_store_data.tripType
    // );
  }

  loadSearch = () => {
    let search_store_data = {
      transportationType: this.props.searchStore.transportationType,
      tripType: this.props.searchStore.tripType || "oneWay",
      passengersNo: this.props.searchStore.passengersNo || 1,
      departureStation: this.props.searchStore.departureStationId,
      arrivalStation: this.props.searchStore.arrivalStationId,
      departureDate: this.props.searchStore.departureDate,
      arrivalDate: this.props.searchStore.arrivalDate
    };

    let urlParams = qs.parse(this.props.history.location.search);
    search_store_data.arrivalStation = Number(urlParams.arrivalStation);
    search_store_data.departureStation = Number(urlParams.departureStation);

    this.props.searchActions.updateSearchParams(search_store_data);
    this.props.updateCurrentRoute(search_store_data.departureStation, search_store_data.arrivalStation);

    this.props.tripActions.searchTrips(
      search_store_data,
      search_store_data.tripType
    );
  }

  getContent = (options = { defaultReturn: {} }) => {
    const currentRoute = this.props.currentRoute || {};
    const { lang } = this.props.locale;
    // translated data & undefined safety object
    return currentRoute[lang]? currentRoute[lang]: currentRoute['ar'] || options.defaultReturn;
  }

  returnTripSameDayAlert = () => {
    const departureDate = this.props.searchStore.departureDate;
    const arrivalDate = this.props.searchStore.arrivalDate;

    if (moment(arrivalDate).day() !== moment(departureDate).day()) {
        return null;
    }

    return <div className="alert-msg">
        <div className="yellow-circle">
            <i className="fas fa-exclamation-triangle"></i>
        </div>
        <Trans i18nKey="search_page.alerts.round_trip_same_date">تم اختيار تاريخ رحلات الذهاب والعودة في نفس اليوم</Trans>
    </div>
  }

  render() {
    const currentRoute = this.getContent();

    return (
      <MainLayoutComponent page={this.props.location.pathname}>
        <Helmet key={window.location.href} >
          <title>
            {currentRoute.title
              ? Utils.htmlEntitiesFix(currentRoute.title.rendered)
              : "Search | Go Bus V3"}
          </title>
          {buildYoastMetaTags(currentRoute.yoast_meta).map(tag => (
            <meta {...tag} />
          ))}
        </Helmet>
        <h1 style={{display: 'none'}}>{currentRoute.title? currentRoute.title.rendered: null}</h1>
        <div className="page-content single-routs">
          <ToastContainer style={{ top: "6em" }} />
          <div className="container">
            <SearchBoxComponent
              page="search"
              locale={this.props.locale}
              location={this.props.location}
              history={this.props.history}
              match={this.props.match}
            />
            <div className="trip-route-component">
                {this.returnTripSameDayAlert()}
              <div className="trip-route-con">
                <FilterComponent />

                {process.title === "browser" && (
                  <div className="list-component">
                    <TripListComponent
                      listType="going"
                      history={this.props.history}
                    />
                    {this.props.searchStore.tripType === "round" && (
                      <TripListComponent
                        listType="returning"
                        history={this.props.history}
                      />
                    )}
                  </div>
                )}

                <div className= {`tickets-component ${ GalleryPopup ? "" : "active"  } `}>
                <Sticky
                  style={{ zIndex: 999 }}
                  ref={(ref) => {
                    if (ref && ref.wrapperEl) {
                      ref.wrapperEl.style.transform = null;
                    }
                  }}
                >
                  {process.title === "browser" &&
                    ((this.props.tripsStore.displayedGoingTrips.length > 0 ||
                      this.props.tripsStore.displayedReturningTrips.length >
                        0) && <div className="ticket-side">
                          <CartComponent history={this.props.history} />
                          <TwoWayOfferComponent />
                        </div>)}
                  </Sticky>   
                </div>
              </div>
            </div>
            {/* <OtherLinesComponent /> */}
          </div>
        </div>
      </MainLayoutComponent>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchResultComponent);
