import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MainLayoutComponent from '../MainLayoutComponent';
import { connect } from 'react-redux'
import { Trans } from 'react-i18next';

class NoMatch extends Component {
    
    render() {
        return (
			<MainLayoutComponent>
				<div className="not-found">
					<h1><Trans i18nKey="404_page.heading">عفواً لم نجد هذه الصفحة</Trans></h1>
					<h2><Trans i18nKey="404_page.subheading">من فضلك أختار صفحة أخري من القائمة الرئيسية أو أضغط أدناه للرجوع إلي الصفحة الرئيسية</Trans></h2>
					<img src="/images/404.png" alt=""/>
					<Link to="/"><Trans i18nKey="404_page.back_to_home">العودة إلى الصفحة الرئيسية</Trans></Link>
				</div>
			</MainLayoutComponent>
        );
    }
}

const mapStateToProps = (state) => ({
	locale: state.locale
})


export default connect(mapStateToProps)(NoMatch);