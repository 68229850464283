import React, { Component } from "react";
import i18next from "i18next";
import * as UserActions from '../../Actions/UserActions'
import Utils from "../../Helpers/Utils";
import Store from "../../Store";
import ErrorComponent from "../Utils/ErrorComponent";
import SuccessComponent from "../Utils/SuccessComponent";
import { Trans } from "react-i18next";
import LoaderComponent from "../Utils/LoaderComponent";

export class InvalidPhoneComponent extends Component {
    constructor() {
        super();
        this.state = {
            errorMessage: null,
            phone: ""
        };
        this.field = {
            name: "phone-number",
            label: "رقم المحمول",
            i18nKey: "common.phone",
            type: "tel",
            value: this.state.phone
        };
    }

    isFormValid = () => {
        let errorMessage = null;

        // validate phone number is valid
        if (!Utils.isValidPhone(this.state.phone)) {
            errorMessage = i18next.t(
                "common.errors.invalid_phone_format",
                "من فضلك ادخل رقم الهاتف صحيح"
            );
        }

        if (errorMessage != null) {
            this.setState({ errorMessage });
        } else {
            this.setState({ errorMessage: null });
            return true;
        }
    };

    handlePhoneChange = e => {
        const value = e.target.value;
        this.setState({ phone: value });
    };

    editProfile = () => {
        const { userActions, userStore } = this.props;
        const { user } = userStore;
        
        if (!UserActions.isLoggedIn()) {
            alert("Your session expired, Please Re-Login!");
            Store.dispatch(UserActions.logout(this.props.history));
            //return
        } else {
            if (this.isFormValid()) {
                userActions.editProfile({
                    id: user.UserID,
                    name: user.UserName,
                    email: user.UserEmail,
                    telNo: this.state.phone
                });
            }
        }
    };

    render() {
        const { edit_profile_result, editing_profile } = this.props.userStore;
        console.log(this.props.userStore);
        return (
            <div className="popup popup-login active">
                <div className="tbl">
                    <div className="tcl">
                        <div
                            className={
                                this.props.userStore.loading
                                    ? "popup__window component-loader"
                                    : "popup__window"
                            }
                        >
                            <div className="container">
                                {this.state.errorMessage &&
                                this.state.errorMessage.length > 0 ? (
                                    <ErrorComponent
                                        errors={this.state.errorMessage}
                                    />
                                ) : null}

                                {edit_profile_result === "success" && (
                                    <SuccessComponent
                                        msg={i18next.t(
                                            "profile_page.edit_profile.success.data_updated",
                                            "تم تعديل البيانات بنجاح."
                                        )}
                                    />
                                )}
                                <form className="login-form active">
                                    <div className="input-field">
                                        <h3
                                            style={{
                                                fontSize: "20px",
                                                color: "#FF0000"
                                            }}
                                        >
                                            <Trans i18nKey="common.invalid_phone_alert">
                                                رقم المحمول غير صحيح
                                            </Trans>
                                        </h3>
                                        <h3 style={{ fontSize: "14px" }}>
                                            <Trans i18nKey="common.invalid_phone_text">
                                                يجب تحديث رقم الهاتف لضمان عملية
                                                حجز ناجحة
                                            </Trans>
                                        </h3>
                                    </div>
                                    <div className="input-field">
                                        <label>
                                            <Trans i18nKey={this.field.i18nKey}>
                                                {this.field.label}
                                            </Trans>
                                        </label>
                                        <input
                                            name={this.field.name}
                                            type={this.field.type}
                                            value={this.state[this.field.name]}
                                            onChange={this.handlePhoneChange}
                                        />
                                    </div>
                                    <div
                                        className="input-field"
                                        style={{ marginBottom: "15px" }}
                                    >
                                        <button
                                            type="button"
                                            className="btn btn-orange"
                                            onClick={this.editProfile}
                                        >
                                            {editing_profile ||
                                            edit_profile_result ===
                                                "success" ? (
                                                <LoaderComponent
                                                    background-color="#fc9900"
                                                    foreground-color="#ffffff"
                                                />
                                            ) : (
                                                <span>
                                                    <Trans i18nKey="profile_page.edit_btn">
                                                        تعديل
                                                    </Trans>
                                                </span>
                                            )}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
