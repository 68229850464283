const base_path = "/images/new-bus-classes";
const bus_svc_base = "/images/bus-services";

export default function(NameE, options = { emptyOnDefault: false }) {
  switch (NameE) {
    /*case "Business":
      return [
        base_path + "/business-class/1.jpg",
        base_path + "/business-class/2.jpg",
        base_path + "/business-class/3.jpg",
        base_path + "/business-class/4.jpg",
        base_path + "/business-class/5.jpg",
        base_path + "/business-class/6.jpg",
        base_path + "/business-class/7.jpg",
        base_path + "/business-class/8.jpg",
        base_path + "/business-class/9.jpg",
        base_path + "/business-class/10.jpg",
        base_path + "/business-class/11.jpg",
        base_path + "/business-class/12.jpg",
      ];*/
    case "Classic":
    case "اقتصادي":
      return [
        base_path + "/economic/2.jpg",
        base_path + "/economic/3.jpg",
        base_path + "/economic/4.png",
      ];
    case "Elite D.D":
      return [
        base_path + "/elite-DD/2.jpg",
        base_path + "/elite-DD/3.jpg",
        base_path + "/elite-DD/4.png",
      ];
    case "Elite Plus":
    case "إيليت بلس":
      return [
        base_path + "/elite-plus/2.jpg",
        base_path + "/elite-plus/3.jpg",
        base_path + "/elite-plus/4.png",
      ];
    case "Elite Plus +":
    case "إيليت بلس +":
      return [
        base_path + "/elite-plus-plus/2.jpg",
        base_path + "/elite-plus-plus/3.jpg",
        base_path + "/elite-plus-plus/4.png",
      ]; 
    case "GoMini":
      return [
        base_path + "/go-mini/2.jpg",
      ];
    case "New Deluxe":
    case "نيو ديلوكس":
      return [
        base_path + "/new-deluxe/2.jpg",
        base_path + "/new-deluxe/3.jpg",
        base_path + "/new-deluxe/4.png",
      ];
    case "Deluxe Plus":
    case "دي لوكس بلس":
      return [
        base_path + "/deluxe-plus/2.jpg",
        base_path + "/deluxe-plus/3.jpg",
        base_path + "/deluxe-plus/4.png",
      ];  
    case "Aero":
      return [
        base_path + "/aero-class/2.jpg",
        base_path + "/aero-class/3.jpg",
        base_path + "/aero-class/4.png",
      ];
    case "Royal Plus":
    case "رويال بلس":
      return [
        base_path + "/royal-plus/1.jpg",
        base_path + "/royal-plus/2.jpg",
        base_path + "/royal-plus/3.jpg",
        base_path + "/royal-plus/4.jpg",
        base_path + "/royal-plus/5.jpg",
        base_path + "/royal-plus/6.jpg",
        base_path + "/royal-plus/7.jpg",
        base_path + "/royal-plus/8.jpg",
        base_path + "/royal-plus/9.jpg",
        base_path + "/royal-plus/10.jpg",
        base_path + "/royal-plus/11.jpg",
        base_path + "/royal-plus/12.jpg",
        base_path + "/royal-plus/13.jpg",
        base_path + "/royal-plus/14.jpg",
        base_path + "/royal-plus/15.jpg",
        base_path + "/royal-plus/16.jpg",
      ];
    default:
      if (options.emptyOnDefault) {
        return [];
      }
      return [
        base_path + "/royal-plus/16.jpg",
        base_path + "/royal-plus/16.jpg",
        base_path + "/royal-plus/16.jpg",
      ];
  }
}

export function getServiceAsset(svcName) {
  return bus_svc_base + "/" + svcName.toLowerCase() + ".png"
}
