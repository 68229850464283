import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Trans } from 'react-i18next';

class SideBar extends Component {
  render() {
    return (
      <div className="menu-widget">
        <ul>
          <li>
            <NavLink to="/about"><Trans i18nKey="header.subnav.about">عن شركة جوباص</Trans></NavLink>
          </li>
          <li>
            <NavLink to="/faq"><Trans i18nKey="header.subnav.faq">أسئلة شائعة</Trans></NavLink>
          </li>
          <li>
            <NavLink to="/policy"><Trans i18nKey="header.subnav.policy">سياسة الخصوصية</Trans></NavLink>
          </li>
          <li>
            <NavLink to="/terms"><Trans i18nKey="header.subnav.terms">الشروط والأحكام</Trans></NavLink>
          </li>
          {/* <li><NavLink to="/destinations">وجهات سفر</NavLink></li> */}
          {/* <li><NavLink to="/">خدمات متن الرحلة</NavLink></li> */}
          {/* <li><NavLink to="/">إلغاء التذاكر</NavLink></li> */}
          {/* <li><NavLink to="/">الشحن السريع</NavLink></li> */}
          {/* <li><NavLink to="/">حقائب المسافرين</NavLink></li> */}
          {/* <li><NavLink to="/">تحميل التطبيق</NavLink></li> */}
          <li>
            <NavLink to="/contact-us"><Trans i18nKey="header.subnav.contact_us">أتصل بنا</Trans></NavLink>
          </li>
        </ul>
      </div>
    );
  }
}

export default SideBar;
