import React from 'react';
import ContentLoader, { BulletList} from 'react-content-loader'


let filters_number = [1,2,3,4] 
const FiltersLoaderComponent = props => (
    <div className="filter-component">
        <form>
            <div>
                
                    {
                        filters_number.map((n) => {
                            return(
                                <ul className="list-filter-sec" key={n}>
                                    <ContentLoader
                                        height={320}
                                        width={400}
                                        speed={2}
                                        primaryColor="#f3f3f3"
                                        secondaryColor="#ecebeb"
                                        {...props}
                                    >
                                        <circle cx="366.28" cy="265.3" r="11.28" />
                                        <rect x="191" y="142.05" rx="5" ry="5" width="160.6" height="24.5" />
                                        <circle cx="368.8" cy="148.82000000000002" r="10.8" />
                                        <rect x="189" y="199" rx="5" ry="5" width="158.4" height="25.5" />
                                        <circle cx="368.04" cy="214.56" r="11.04" />
                                        <rect x="190" y="253.05" rx="5" ry="5" width="156.2" height="26.400000000000002" />
                                        <rect x="369" y="68.05" rx="0" ry="0" width="0" height="0" />
                                        <rect x="380" y="99.05" rx="0" ry="0" width="0" height="0" />
                                        <rect x="380" y="99.05" rx="0" ry="0" width="0" height="0" />
                                        <rect x="380" y="99.05" rx="0" ry="0" width="0" height="0" />
                                        <rect x="130" y="81.05" rx="0" ry="0" width="242.54000000000002" height="21" />
                                        <rect x="172" y="88.05" rx="0" ry="0" width="0" height="0" />
                                    </ContentLoader>
                                </ul>
                            )
                        })
                    }

                
            </div>
        </form>
    </div>
)

export default FiltersLoaderComponent