import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

import qs from 'query-string';
import store from './Store';
import store2 from 'store2';
import { changeLocale } from './Actions/LocaleActions';
import { __ENV__ } from './Api/Base';


// add loading state on page load before language is set
document.body.classList.add('site_loader');

const params = qs.parse(window.location.search);
let lng = 'ar';

if (params.lang) {
  lng = params.lang;  
} else {
  lng = store2.get('lang', 'ar');
}


i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'ar',
    lng, // initial language
    debug: __ENV__ !== 'production',
    react: {
      useSuspense: false
    },
    preload: ['en', 'ar'],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  }).then(() => store.dispatch(changeLocale(lng)));



export default i18n;