import React, { Component } from 'react'
import { Modal, Paper } from "@material-ui/core";

import styles from "./GlobalModal.module.css";

export default class GlobalModal extends Component {
  render() {
    let {
      open,
      onClose,
      children,
      withPaper
    } = this.props;

    return (
      <Modal className={styles.global_modal} open={open} onClose={onClose}>
        {
          withPaper
            ? (
              <Paper className={styles.paper}>
                {children}
              </Paper>
            ) : (children)
        }
      </Modal>
    )
  }
}
