import moment from "moment";
import store2 from 'store2';
import qs from "query-string";
import { localeActions } from '../Reducers/LocaleReducer';
import i18n from '../i18n';
import { globalLoaderDelay } from '../Api/Base';
import Utils from '../Helpers/Utils';
import { useCheckoutStore } from "../new-store/checkoutStore";

export function changeLocale(locale) {
  return async dispatch => {
    // global loader
    document.body.classList.add('site_loader');

    // restricted localization pages
    const isBlogsPage = /\/blog/g.test(window.location.pathname);
    // set locale to default (arabic) if its a restricted page
    if (isBlogsPage) locale = 'ar';
    
    await i18n.changeLanguage(locale);

    // remove loader
    setTimeout(() => {
      if (!useCheckoutStore.getState().__loading_state) {
        document.body.classList.remove('site_loader');
      }
    }, globalLoaderDelay);

    // set moment global locale
    moment.locale(locale);

    store2.set('lang', locale);

    switch (locale) {
      case 'en':
        document.body.classList.remove('rtl');    
        document.body.classList.add('ltr');    

        // add language param to url
        Utils.URLParams.add('lang', locale);
        break;
      case 'ar':
        // switch body direction
        document.body.classList.remove('ltr');    
        document.body.classList.add('rtl');

        // remove language param from url
        Utils.URLParams.remove('lang');
        break;
    
      default:
        break;
    }
    
    dispatch(_changeLocale(locale));
  }
}

function _changeLocale(locale) {
  return {
    type: localeActions.changeLocale,
    data: locale
  }
}