import React from 'react'
import ContentLoader from 'react-content-loader'

const StationPlaceholder = props => (
    <ContentLoader
        height={500}
        width={700}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
        {...props}
    >
        <rect x="426.41" y="24.27" rx="0" ry="0" width="246.59" height="12" />
        <rect x="72.23" y="49.27" rx="0" ry="0" width="596.17" height="10" />
        <rect x="74.23" y="71.27" rx="0" ry="0" width="593" height="9" />
        <rect x="96.23" y="91.27" rx="0" ry="0" width="573" height="10" />
        <rect x="14.23" y="120.27" rx="0" ry="0" width="654" height="275" />
        <rect x="296.23" y="421.27" rx="0" ry="0" width="373" height="15" />
    </ContentLoader>
)

export default StationPlaceholder;