export const FETCHING_DATA = 'FETCHING_DATA'
export const RESET = 'RESET'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'

export const TOGGLE_AUTH_POPUP = 'TOGGLE_AUTH_POPUP'
export const TOGGLE_INVALID_PHONE_POPUP = 'TOGGLE_INVALID_PHONE_POPUP'



/********Search  Actions *****************************/
export const CHANGE_TRANSPORTATION_TYPE = 'CHANGE_TRANSPORTATION_TYPE'
export const UPDATE_SEARCH_PARAMS = 'UPDATE_SEARCH_PARAMS'
export const GET_STATIONS = 'GET_STATIONS'
export const SET_DATE = 'SET_DATE'

export const SET_DEFAULT_DEPARTURE_STATION = 'SET_DEFAULT_DEPARTURE_STATION'
export const SET_DEFAULT_ARRIVAL_STATION = 'SET_DEFAULT_ARRIVAL_STATION'

export const SET_STATION_IDS = "SET_STATION_IDS";


/******** Trip  Actions *****************************/
export const FETCHING_GOING_TRIPS = 'FETCHING_GOING_TRIPS'
export const FETCHING_RETURNING_TRIPS = 'FETCHING_RETURNING_TRIPS'

export const GET_GOING_TRIPS_SUCCESS = 'GET_GOING_TRIPS_SUCCESS'
export const GET_RETURNING_TRIPS_SUCCESS = 'GET_RETURNING_TRIPS_SUCCESS'

export const SEARCH_TRIPS = 'SEARCH_TRIPS'
export const SET_GOING_FILTERS = 'SET_GOING_FILTERS'
export const SET_RETURNING_FILTERS = 'SET_RETURNING_FILTERS'

export const UPDATE_DISPLAYED_GOING_TRIPS = 'UPDATE_DISPLAYED_GOING_TRIPS'
export const UPDATE_DISPLAYED_RETURNING_TRIPS = 'UPDATE_DISPLAYED_RETURNING_TRIPS'

export const SELECT_GOING_TRIP = 'SELECT_GOING_TRIP'
export const SELECT_RETURNING_TRIP = 'SELECT_RETURNING_TRIP'

export const REMOVE_GOING_TRIP = 'REMOVE_GOING_TRIP'
export const REMOVE_RETURNING_TRIP = 'REMOVE_RETURNING_TRIP'

export const SET_CC_NUMBER = "SET_CC_NUMBER"

export const BOOKING_AMAN = "BOOKING_AMAN";
export const BOOKING_RESULT_AMAN = "BOOKING_RESULT_AMAN";

export const TRIP_LOADER_STATE = "TRIP_LOADER_STATE";

export const RESET_TRIPS_STATE = "RESET_TRIPS_STATE";

export const SET_HOLDING_SEATS_ERROR = 'SET_HOLDING_SEATS_ERROR';

// ticket cancellation
export const CANCEL_SUCCESS = "CANCEL_SUCCESS";
export const CLEAR_CANCEL = "CLEAR_CANCEL";

/******** Seats  Actions *****************************/
export const FETCHING_GOING_SEATS = 'FETCHING_GOING_SEATS'
export const FETCHING_RETURNING_SEATS = 'FETCHING_RETURNING_SEATS'

export const SET_GOING_SEATS = 'SET_GOING_SEATS'
export const SET_RETURNING_SEATS = 'SET_RETURNING_SEATS'

export const HOLDING_SEATS = 'HOLDING_SEATS'
export const HOLDING_SEATS_SUCCESSFULLY = 'HOLDING_SEATS_SUCCESSFULLY'

export const UPDATE_GBCARD = "UPDATE_GBCARD";

export const SET_HOLD_GOING_SEATS = 'SET_HOLD_GOING_SEATS'
export const SET_HOLD_RETURNING_SEATS = 'SET_HOLD_RETURNING_SEATS'

export const UN_HOLD_GOING_SEATS = 'UN_HOLD_GOING_SEATS'
export const UN_HOLD_RETURNING_SEATS = 'UN_HOLD_RETURNING_SEATS'

export const HOLD_SINGLE_GOING_SEAT = 'HOLD_SINGLE_GOING_SEAT'
export const HOLD_SINGLE_RETURNING_SEAT = 'HOLD_SINGLE_RETURNING_SEAT'

export const RESET_GOING_SEAT = 'RESET_GOING_SEAT'
export const RESET_RETURNING_SEAT = 'RESET_RETURNING_SEAT'

export const UPDATE_SEATS_HOLD_TIME = "UPDATE_SEATS_HOLD_TIME";
export const HELD_SEATS_TIMEOUT = "HELD_SEATS_TIMEOUT";
export const CLEAR_COUNTDOWN_TIMER = "CLEAR_COUNTDOWN_TIMER";

/******** Other Passenger  Actions *****************************/
export const SET_OTHER_PASSENGER_DATA = 'SET_OTHER_PASSENGER_DATA'
export const OTHER_PASSENGER_ERROR = 'OTHER_PASSENGER_ERROR'
export const SET_OTHER_PASSENGER_SELECTED = 'SET_OTHER_PASSENGER_SELECTED'

/******** promo Code  Actions *****************************/
export const APPLYING_PROMO_CODE = 'APPLYING_PROMO_CODE'
export const APPLYING_PROMO_CODE_ERROR = 'APPLYING_PROMO_CODE_ERROR'
export const APPLYING_PROMO_CODE_SUCCESS = 'APPLYING_PROMO_CODE_SUCCESS'
export const CLEAR_PROMOCODE_ERR = "CLEAR_PROMOCODE_ERR";
export const UNDO_PROMOCODE = "UNDO_PROMOCODE";



/******** Corporate  Actions *****************************/
export const FETCHING_CORPORATE_DATA = 'FETCHING_CORPORATE_DATA'
export const GET_ABOUT = 'GET_ABOUT'
export const GET_NBE = 'GET_NBE'
export const GET_TERMS = 'GET_TERMS'
export const GET_POLICY = 'GET_POLICY'
export const GET_FAQS = 'GET_FAQS'
export const GET_STATION = 'GET_STATION'
export const GET_FAQS_PAGE = 'GET_FAQS_PAGE'
export const GET_CONTACT_US = 'GET_CONTACT_US';
export const GET_DESTINATIONS = 'GET_DESTINATIONS';
export const GET_SINGLE_DESTINATION = 'GET_SINGLE_DESTINATION';
export const GET_HOMEPAGE = 'GET_HOMEPAGE'
export const GET_BLOGS = 'GET_BLOGS'
export const GET_BLOGS_PAGE = 'GET_BLOGS_PAGE'
export const GET_HOT_NEWS = 'GET_HOT_NEWS'

export const GET_CORP_STATIONS = 'GET_CORP_STATIONS'
export const GET_SINGLE_STATION = 'GET_SINGLE_STATION'
export const GET_NEWS = 'GET_NEWS'
export const GET_SINGLE_NEWS = 'GET_SINGLE_NEWS'
export const GOT_MAX_NEWS = 'GOT_MAX_NEWS';
export const GET_ROUTES_SUCCESS = "GET_ROUTES_SUCCESS";
export const UPDATE_CURRENT_ROUTE = "UPDATE_CURRENT_ROUTE";


/******** User  Actions *****************************/
export const USER_LOGIN = 'USER_LOGIN'
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR'

export const USER_REGISTER = 'USER_REGISTER'
export const USER_REGISTER_ERROR = 'USER_REGISTER_ERROR'

export const LOADING_USER = "LOADING_USER"
export const FB_USER_NOT_FOUND = "FB_USER_NOT_FOUND";

export const EDITING_PROFILE = 'EDITING_PROFILE';
export const EDIT_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS';
export const EDIT_PROFILE_FAILED = 'EDIT_PROFILE_FAILED';

export const GETTING_TICKETS = "GETTING_TICKETS";
export const GET_TICKETS_SUCCESS = "GET_TICKETS_SUCCESS";
export const GET_TICKETS_FAILED = "GET_TICKETS_FAILED";
export const CANCELLING_TICKET = "CANCELLING_TICKET";
export const CANCEL_TICKET_RESULT = "CANCEL_TICKET_RESULT";

export const UPDATE_POINTS_TO_USE = "UPDATE_POINTS_TO_USE";
export const UPDATE_USER_POINTS = "UPDATE_USER_POINTS";

export const USER_LOGOUT = "USER_LOGOUT"
export const USER_SOFT_LOGOUT = "USER_SOFT_LOGOUT"
export const CLEAR_POST_LOGIN = "CLEAR_POST_LOGIN";
export const USER_DATA_RESET = "USER_DATA_RESET";

export const RESET_PWD_EMAIL_SENT = "RESET_PWD_EMAIL_SENT";
export const RESET_PWD_SUCCESS = "RESET_PWD_SUCCESS";
export const RESET_PWD_FAIL = "RESET_PWD_FAIL";

export const APPLY_POINT_PROMO = "APPLY_POINT_PROMO";