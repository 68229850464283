import React from 'react'
import { Field, reduxForm } from 'redux-form'
import Utils from '../../../Helpers/Utils';

const validate = (...params) => {
    const values = params[0];
    const uiErrors = params[1].uiErrors;

    const errors = {}

    if (!values.name) {
        errors.name = uiErrors.name_required_error;
    }

    if (!values.email) {
        errors.email = uiErrors.email_required_error;
    }

    if (!values.msgTitle) {
        errors.msgTitle = uiErrors.message_title_required_error;
    }

    if (!values.msgContent) {
        errors.msgContent = uiErrors.message_content_required_error;
    }

    return errors;
}

const renderField = ({
    input, label, type, tag, onKeyPress, meta: { touched, error }
}) => (
        <div className="input-field" >
            <label htmlFor="">{label}</label>
            <input {...input} type={type} onKeyPress={onKeyPress} />
            {touched &&
                ((error && <span style={{ color: 'red' }}>{error}*</span>))}
        </div>
    )

let ContactForm = props => {
    const { handleSubmit, uiLabels } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="input-field" >
                <label htmlFor="">{uiLabels.fullname_label}</label>
                <Field type="text" component={renderField} name="name" id="" />
            </div>
            <div className="input-field">
                <label htmlFor="">{uiLabels.email_label}</label>
                <Field type="email" component={renderField} name="email" id="" />
            </div>
            <div className="input-field">
                <label htmlFor="">{uiLabels.phone_label}</label>
                <Field type="tel" component={renderField} onKeyPress={e => (Utils.isNumberKey(e))} minLength="11" maxLength="11" name="phone" id="" />
            </div>
            <div className="input-field">
                <label htmlFor="">{uiLabels.booking_number_label}</label>
                <Field type="text" component={renderField} onKeyPress={e => (Utils.isNumberKey(e))} name="bookingId" id="" />
            </div>
            <div className="input-field">
                <label htmlFor="">{uiLabels.message_title_label}</label>
                <Field type="text" component={renderField} name="msgTitle" id="" />
            </div>
            <div className="input-field">
                <label htmlFor="">{uiLabels.message_content_label}</label>
                <Field name="msgContent" component={renderField} ></Field>
            </div>

            <button className="btn" >{uiLabels.send_message_label}</button>
        </form>
    )
}

ContactForm = reduxForm({
    form: 'contact-form',
    validate
})(ContactForm);


export default ContactForm;