import React from "react";

import SeatsCountComponent from "../SeatsCountComponent";
import BrowseSelectComponent from "../BrowseSelectComponent";
import DatePicker from "../DatePicker";
import { Trans } from 'react-i18next';
import DiscountLabel from "../../Label/DiscountLabel";

export const HomeViewComponent = props => {
  let {
    changeTransportationType,
    changeTripType,
    selectDepartureStation,
    selectArrivalStation,
    chooseDepartureTime,
    chooseArrivalTime,
    openDepartureDatePicker,
    openArrivalDatePicker,
    closeSearchBoxModals,
    search,
    busTapLinkClasses,
    boatTapLinkClasses,
    departure_station,
    arrival_station,
    departure_stations,
    arrival_stations,
    transportationType,
    displayDepartureDatePicker,
    tripType,
    displayArrivalDatePicker,
    departureDate,
    arrivalDate,
    bookingAllowed
  } = props;

  const bookingButtonClasses = `btn${!bookingAllowed? " disabled" : ""}`
  return (
    <form action="single-routs.html" method="post">
      <ul className="tabs">
        <li>
          <a
            id="defaultOpen"
            onClick={changeTransportationType.bind(this, "bus")}
            className={busTapLinkClasses}
          >
            <i className="material-icons">directions_bus</i>حجز أتوبيس
          </a>
        </li>
        {/* <li>
          <a
            id="boat-tab"
            onClick={changeTransportationType.bind(this, "boat")}
            className={boatTapLinkClasses}
          >
            <i className="material-icons">directions_boat</i>حجز عبارات
          </a>
        </li> */}
      </ul>
      {/* Buses  */}
      <div id="bus" className="tab-content active">
        {transportationType === "bus" ? (
          <h2><Trans i18nKey="searchbox.title">احجز أتوبيس جوباص</Trans></h2>
        ) : (
          <h2>أحجز عبارات سريعة</h2>
        )}
        {
                    (tripType === 'round') ?
                        <div className="tripType-switcher">
                            <input onClick={changeTripType.bind(this, 'oneWay')} type="radio" name="way1" id="way-1"></input>
                            <label htmlFor="way-1" className="way first"><Trans i18nKey="searchbox.oneway">أتجاة واحد</Trans></label>
                            <input onClick={changeTripType.bind(this, 'round')} checked="checked" type="radio" name="way1" id="way-2"></input>
                            <label htmlFor="way-2" className="way"><Trans i18nKey="searchbox.round">ذهاب وعودة </Trans></label>
                        </div>
                        :
                        <div className="tripType-switcher">
                            <input onClick={changeTripType.bind(this, 'oneWay')} checked="checked" type="radio" name="way1" id="way-1"></input>
                            <label htmlFor="way-1" className="way first"><Trans i18nKey="searchbox.oneway">أتجاة واحد</Trans></label>
                            <input onClick={changeTripType.bind(this, 'round')} type="radio" name="way1" id="way-2"></input>
                            <label htmlFor="way-2" className="way double"><Trans i18nKey="searchbox.round">ذهاب وعودة </Trans> <DiscountLabel/></label>
                        </div>
                        
                }

        <div className="trip-choise-con">
          <div className="choose-trip">
            <label>
              {/* <i className="material-icons">location_on</i> سفر من */}
              <i className="fas fa-map-marker-alt"></i> <Trans i18nKey="searchbox.from">سفر من</Trans>
            </label>
            <BrowseSelectComponent
              type={BrowseSelectComponent.TYPE.departure}
              default={departure_station}
              onChange={selectDepartureStation}
              options={departure_stations}
            />
          </div>

          <div className="choose-trip">
            <label>
              {/* <i className="material-icons">location_on</i> سفر إلى */}
              <i className="fas fa-map-marker-alt"></i> <Trans i18nKey="searchbox.to">سفر إلى</Trans>
            </label>
            <BrowseSelectComponent
              type={BrowseSelectComponent.TYPE.return}
              default={arrival_station}
              onChange={selectArrivalStation}
              options={arrival_stations}
            />
          </div>
        </div>
        <div className="trip-choise-con">
          <div className={tripType === "oneWay"? "datepicker departure" : "datepicker return"}>
            <DatePicker 
              from={departureDate}
              to={arrivalDate}
              type={tripType}
              changeFrom={chooseDepartureTime}
              changeTo={chooseArrivalTime}
              changeType={changeTripType}
              />
            </div>
        </div>
        <SeatsCountComponent
          className="home"
          passengersNo={props.passengersNo}
          increment={props.incrementPassengers}
          decrement={props.decrementPassengers}
        />
      </div>
      {/* <Modal open={true}> */}
        {/* <div>test</div> */}
      {/* </Modal> */}
      {/* <div className="trip-choise-con">
      </div> */}
      <button type="button" onClick={() => {
        if(!bookingAllowed) return;
        search()
      }} className={bookingButtonClasses}>
        <Trans i18nKey="searchbox.book">أعرض الرحلات</Trans>
      </button>
    </form>
  );
};
