import React from 'react';
import { translateSingleFilter } from '../../../Helpers/translations';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux'
import _ from 'lodash';
import { busClassNameMap } from '../../../appConfig';


/**
 * Class ==> Code 
 * 
 * Deluxe Plus ==> 5
 * New Deluxe ==> 19
 * Elite Plus ==> 15
 * Elite DD ==> 20
 * Classic==> 11   //Economic has been changed to (Classic)
 * Royal plus ==> 14
 * VIP+  ==> 
 * Business ==> 
 */


const TripClassComponent = (props) => {
    let { TripServKind_Code, TripServKind_NameA, TripServKind_NameE, TripServKind_NameA_Short, i18nKey, onClick } = props;
    let className = null;
    let displayName = TripServKind_NameA;
    let localeKey = i18nKey;

    if (TripServKind_NameE) {
        displayName = _.get(
            busClassNameMap,
            TripServKind_NameE.trim().toLowerCase()
        );

        if (!displayName && TripServKind_NameA_Short) {
            displayName = _.get(
                busClassNameMap,
                TripServKind_NameA_Short.trim().toLowerCase()
            );
        }

        if (!displayName && TripServKind_NameA) {
            displayName = _.get(
                busClassNameMap,
                TripServKind_NameA.trim().toLowerCase()
            );
        }

        if (!displayName) {
            displayName = TripServKind_NameA
        } else {
            localeKey = null;
        }
    }

    switch(TripServKind_Code) {
        // Deluxe Plus
        // New Deluxe Plus
        case 5: 
        case 19: 
            className = "bus-class-name bus-class-name-deluxe"
            break;
        
        // Elite Plus 
        // Elite DD
        case 15: 
        case 20: 
            className = "bus-class-name bus-class-name-elite"
            break;

        // Deluxe
        case 10:
            className = "bus-class-name bus-class-name-deluxe"
            break;

        // Classic
        case 11:
            className = "bus-class-name bus-class-name-classic"
            break;

        case 12:
            className = "bus-class-name bus-class-name-business"
            break;

        // Royal Plus
        case 14:
            className = "bus-class-name bus-class-name-royal";
            break;

        // Royal Plus
        case 701:
            className = "bus-class-name bus-class-name-limo";
            break;

        // VIP +
        // Business
        default:
            className = "bus-class-name bus-class-name-royal"
            break;
    }

    // return <strong className={className} onClick={onClick}>{translateSingleFilter(TripServKind_NameA_Short, 'ar')}</strong>
    return <strong className={className} onClick={onClick}><Trans i18nKey={localeKey}>{displayName}</Trans></strong>

    // Deluxe Plus
    if(TripServKind_Code === 5 ){
        return(
            <strong className="bus-class-name bus-class-name-deluxe">{translateSingleFilter(TripServKind_NameA_Short, 'ar')}</strong>
        )
    }
    
    // New Deluxe Class
    if (TripServKind_Code === 19) {
        return (
            <strong className="bus-class-name bus-class-name-deluxe">{TripServKind_NameA_Short}</strong>
        )
    }
    
    // Elite Plus 
    if (TripServKind_Code === 15 ) {
        return (
            <strong className="bus-class-name bus-class-name-elite">{TripServKind_NameA_Short}</strong>
        )
    }

    // Elite DD
    if (TripServKind_Code === 20) {
        return (
            <strong className="bus-class-name bus-class-name-elite">{TripServKind_NameA_Short}</strong>
        )
    }
    
    // Ecnomic
    if (TripServKind_Code === 11) {
        return (
            <strong className="bus-class-name bus-class-name-classic">{TripServKind_NameA_Short}</strong>
        )
    }
    
    // Royal Plus
    if (TripServKind_Code === 14) {
        return (
            <strong className="bus-class-name bus-class-name-royal">{TripServKind_NameA_Short}</strong>
        )
    }
    
    // VIP + 
    if (TripServKind_Code === 14) {
        return (
            <strong className="bus-class-name bus-class-name-royal">{TripServKind_NameA_Short}</strong>
        )
    }

    // Business
    if (TripServKind_Code === 14) {
        return (
            <strong className="bus-class-name bus-class-name-business">{TripServKind_NameA_Short}</strong>
        )
    }

    // Business
    if (TripServKind_Code === 701) {
        return (
            <strong className="bus-class-name bus-class-name-limo">{TripServKind_NameA_Short}</strong>
        )
    }

    return (
        <strong>{TripServKind_NameA_Short}</strong>        
    )

}

const mapStateToProps = (state) => ({
locale: state.locale  
})

export default connect(mapStateToProps)(TripClassComponent)