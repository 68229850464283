import axios from "axios";
import Store from "../Store";
import { gobus_payUrl } from "./Base";

/**
 * 
 * @param {import("axios").AxiosError} error 
 */
export default function apiErrorHandler(error) {
    if (error == null) {
        return;
    }
    if (error.config == null) {
        return;
    }

    if (error.message === 'Network Error') {
        return;
    }
    
    const log = {
        type: 'error',
        source: 'api',
        user: Store.getState().user.user,
        data: {
            name: error.name,
            message: error.message,
            stack: error.stack,
	    stack: error.stack,
            config: {
                url: error.config.url,
                method: error.config.method,
                data: error.config.data,
                params: error.config.params,
                headers: error.config.headers
            }
        }
    };

    if (error.response) {
        log.data.response = {
            data: error.response.data,
            status: error.response.status,
            statusText: error.response.statusText,
        };
    }

    // const logString = btoa(JSON.stringify(log));
    // axios.post(`${gobus_payUrl}/log`, { log: logString })
    //     .catch((e) => {});
}
