import axios from "axios";
import _ from "lodash";
import { gobus_payUrl } from "./Base";
export class FacebookConversionApi {
    /**
     *
     * @param {'Purchase'|'AddToCart'|'InitiateCheckout'|'CustomAddToCart'} event_name
     * @param {{ em: string; ph: string }} user_data
     * @param {any} custom_data
     */
    static async sendEvent(event_id, event_name, user_data, custom_data) {
        if (process.env.REACT_APP_ENABLE_FACEBOOK_EVENTS === "false") {
            return;
        }
        if (crypto.subtle == null) {
            return;
        }
        const url = `${gobus_payUrl}/fb`;
        const date = new Date();
        const dateUTCInSeconds = new Date(date.getTime() + (date.getTimezoneOffset() * 60 * 1000)).getTime() / 1000;
        return await axios.post(url, {
            data: [
                {
                    event_id,
                    event_time: Math.round(dateUTCInSeconds),
                    event_name,
                    user_data,
                    custom_data
                }
            ]
        });
    }

    static async sha256Hash(str) {
        if (!str) {
            return '';
        }
        const msgBuffer = new TextEncoder().encode(str);
        const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray
          .map(b => ("00" + b.toString(16)).slice(-2))
          .join("");

        return hashHex;
    }

    static async sha256ObjectContent(object) {
        const newObject = {};

        for (const key of Object.keys(object)) {
            // encode as UTF-8
            const msgBuffer = new TextEncoder().encode(object[key]);

            // hash the message
            const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);

            // convert ArrayBuffer to Array
            const hashArray = Array.from(new Uint8Array(hashBuffer));

            // convert bytes to hex string
            const hashHex = hashArray
                .map(b => ("00" + b.toString(16)).slice(-2))
                .join("");
            newObject[key] = hashHex;
        }

        return newObject;
    }
}
