import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { corpUrl } from "../Api/Base";
import debug from "../Helpers/DebugLog";
import { bindActionCreators } from "redux";
import { getHomePage } from "../Actions/CorporateActions";
import Utils from "../Helpers/Utils";
import _ from "lodash";
import { Trans } from 'react-i18next';

function mapStateToProps(state) {
  return {
    locale: state.locale,
    homepage: state.corporate.homepage
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getHomepage: bindActionCreators(getHomePage, dispatch)
  };
}

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      home: []
    };

    this.navLinks = [
      { route: "/about", i18nKey: "footer.nav.about", displayName: "عن الشركة" },
      { route: "/destinations", i18nKey: "footer.nav.destinations", displayName: "وجهات السفر" },
      { route: "/faq", i18nKey: "footer.nav.faq", displayName: "الأسئلة الشائعة" },
      { route: "/blog", i18nKey: "footer.nav.news", displayName: "أخبار جوباص" },
      { route: "/terms", i18nKey: "footer.nav.terms", displayName: "الشروط و الأحكام" },
      { route: "/contact-us", i18nKey: "footer.nav.contact_us", displayName: "أتصل بنا" },
      { route: "/", i18nKey: "footer.nav.book_bus", displayName: "حجز اتوبيس" },
    ];
  }

  componentDidMount() {
    if (!this.props.homepage) this.props.getHomepage();
  }

  /**
   * @param {React.SyntheticEvent} e
   */
  subscribeFormSubmit = () => {
    let formElement = document.querySelector("#subForm");
    if (!formElement) return;

    if (!Utils.isValidEmail(formElement.elements["cm-oqudlt-oqudlt"].value)) {
      return alert("Invalid email address");
    }

    this.submit();
  };

  // resetSubmitButton = () => {
  //   this.submitButton.onclick = this.subscribeFormSubmit;
  //   this.submitButton.type = "button";
  // }
  submit = () => {
    this.submitButton.onclick = null;
    this.submitButton.type = "submit";
    this.submitButton.click();
  };

  render() {
    let GooglePlayLink = _.get(this.props.homepage, "acf.google_play_link");
    let AppStoreLink = _.get(this.props.homepage, "acf.app_store_link");

    return (
      <footer className="page-footer">
        <div className="container">
          <div className="top-part">
            <div className="part-con">
              {/* {this.props.page && this.props.page.toLowerCase() !== "booking" && (
                <div className="subscribe-feild">
                  <h3>إشترك للحصول علي أحدث العروض </h3>
                  <form
                    id="subForm"
                    
                    className="js-cm-form input-field"
                    action="https://www.createsend.com/t/subscribeerror?description="
                    method="post"
                    data-id="5B5E7037DA78A748374AD499497E309E5C07480E8B8D6BC543592524367DF0D10B88FE1439C1568527A55737C97390B71BD2A3F331BBAAD41A3ED6CB6E76DA61">
                    <label htmlFor="fieldEmail">Email</label>
                    <input
                      id="fieldEmail"
                      name="cm-oqudlt-oqudlt"
                      type="email"
                      className="js-cm-email-input"
                      required
                    />
                    <button
                      className="js-cm-submit-button btn subscribe-btn"
                      ref={ref => this.submitButton = ref}
                      onClick={this.subscribeFormSubmit}
                      type="button">
                      إشترك
                    </button>
                  </form>
                </div> */}
              <div className="footer-links">
                <div className="footer-logo">
                  <Link
                    onClick={() =>
                      Utils.scrollTo({ top: 0, behavior: "smooth" })
                    }
                    to="/"
                  >
                    <img src="/images/go-bus.png" alt="GoBus-logo" />
                  </Link>
                </div>
                <ul>
                  {this.navLinks.map((link, i) => {
                    return (
                      <li key={i}>
                        <Link
                          onClick={() =>
                            Utils.scrollTo({
                              top: 0,
                              behavior: "smooth"
                            })
                          }
                          to={link.route}
                        >
                          <Trans i18nKey={link.i18nKey}>{link.displayName}</Trans>
                        </Link>
                      </li>
                    );
                  })}
                </ul>   
              </div>
              <div className="footer-icon">
                <a href="https://bit.ly/2t93GIw" target="_blank" className="fb">
                  <img
                      width="25"
                      editable
                      src="/images/whatsapp.png"
                      alt="whatsapp icon"
                  />
                </a>
                <a href="https://www.youtube.com/c/GoBusEgypt1" target="_blank" className="fb">
                  <img
                      width="25"
                      editable
                      src="/images/youtube.png"
                      alt="youtube icon"
                  />
                </a>
                <a href="https://www.linkedin.com/company/gobusegypt/?viewAsMember=true" target="_blank" className="fb">
                  <img
                      width="25"
                      editable
                      src="/images/linkedin.png"
                      alt="linkedin icon"
                  />
                </a>
                <a href="https://www.instagram.com/gobusegypt/" target="_blank" className="fb">
                  <img
                      width="25"
                      editable
                      src="/images/instagram.png"
                      alt="instagram icon"
                  />
                </a>
                <a href="https://www.facebook.com/GoBusEgy/" target="_blank" className="fb">
                  <img
                      width="25"
                      editable
                      src="/images/fb.png"
                      alt="facebook icon"
                  />
                </a>
              </div> 
            </div>
          </div>
          <div className="bottom-part">
            <div className="part-con">
              <div className="copyright">
                <p>
                  <Trans i18nKey="footer.copyright">جميع حقوق التصميم والملكية الفكرية مسجلة ومحفوظة لشركة جوباص © {{year: new Date().getFullYear()}}</Trans>
                </p>
                {/* <p>© {new Date().getFullYear()} Go Bus. All Rights Reserved</p> */}
                {/* page doesnt end with / */}
                {/*!/^\/$/g.test(this.props.page) && <a
                  href="https://www.mitchdesigns.com/"
                  title="Mitch Designs | Web Design - Development Company Egypt | Web Agency Cairo"
                  target="_blank"
                  rel="noopener noreferrer">
                  Website Powered by MitchDesigns
                </a>*/}
              </div>
              <ul>
                {
                  GooglePlayLink &&
                    <li>
                      <a
                        href={GooglePlayLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src="/images/mobile-app/link-google.png"
                          alt="Google play Logo"
                        />
                        <div />
                      </a>
                    </li>
                }
                {
                  AppStoreLink &&
                    <li>
                      <a
                        href={AppStoreLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src="/images/mobile-app/link-apple.png"
                          alt="App Store Logo"
                        />
                        <div />
                      </a>
                    </li>
                }
              </ul>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Footer);
