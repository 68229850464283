import moment from 'moment';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import store2 from 'store2';

import CMApi from "cm-mail-api";
import i18next from 'i18next';
import _ from 'lodash';
import NodeAuth from "node-auth-api";
import * as actionTypes from './ActionTypes';

import { defaultLoaderDelay, nodepayment_pw, nodepayment_un } from '../Api/Base';
import TripApi from '../Api/TripApi';
import EmailApi from '../Api/EmailApi';
import userApi from '../Api/UserApi';
import debug from '../Helpers/DebugLog';
import Utils from '../Helpers/Utils';
import { removeGoingTrip } from './TripActions';

import UserApi from '../Api/UserApi';
import { TiktokPixelHelper } from '../Helpers';
import { identify } from '../Helpers/FullStory';
import { useCheckoutStore } from '../new-store/checkoutStore';


/**************************************** Auth **************************************/
/**
 * Toggle Auth COmponent
 * @author Ezzat
 */
 export function toggleAuthPopUp(postLoginAction, data){
    return {
        type:actionTypes.TOGGLE_AUTH_POPUP,
        postLoginAction,
        postLoginData: data
    }
 }

 export function setInvalidPhonePopUp(payload){
    return {
        type:actionTypes.TOGGLE_INVALID_PHONE_POPUP,
        payload
    }
 }

 export function isLoggedIn(){
    console.log('isLoggedIn from: ', process.title);
    console.log('result: ', NodeAuth.User.isLoggedIn('user') || process.title !== "browser");
    return NodeAuth.User.isLoggedIn("user") || process.title !== "browser";
 }
/**
 * Handle Login 
 * @param {string, string} email, password
 * @author Ezzat
 */
export function login(email, password, lastAttempt, options = { force: false, callback: () => {} }) {
    return function (dispatch, state) {
        let errors = [];
        let encodedPass = encodeURIComponent(password);
        let usrCred = {email, encodedPass, type: "classic"};

        if(!email) {
            errors.push(i18next.t('common.errors.email_missing', 'من فضلك ادخل البريد الإلكتروني'))
        }

        if(!password) {
            errors.push(i18next.t('common.errors.password_missing', 'من فضلك ادخل كلمة السر'))
        }

        if(errors.length > 0) {
            return dispatch(handle_user_login_error(errors));
        }

        dispatch(showLoading());
        dispatch(loading());      
        state = state();

        return NodeAuth.User.login(email, encodedPass, {forceLogin: options.force})
            .then(res => {
                if(res.success) {
                    let user = NodeAuth.User.getTokenPayload('user');

                    if(!user) {
                        dispatch(handle_user_login_error([i18next.t('common.errors.general', 'لقد حدث خطاً ما.')]))
                        localStorage.removeItem('user_token');
                        localStorage.removeItem('company_token');
                        NodeAuth.Company.login(nodepayment_un, nodepayment_pw, true);
                    }
                    console.log('[[USER_LOGIN_RESPONSE]:', user);
                    //[ENCRYPTION] - encrypt user password and email
                    const encrypted = Utils.encrypt(usrCred);
                    store2.set("_t", encrypted);

                    let { name, email, avatar, phone, extras } = user;
                    // console.error('extras', extras)
                    store2.set("user_avatar", avatar || "");
                    setTimeout(async () => {
                        dispatch(handle_user_login({ UserName: name /*Utils.decodeUtf8(name)*/, UserEmail: email, UserTelNo: phone, Pic_Url: avatar, UserID: extras.UserID, UserToken: extras.UserToken }, dispatch));
                        dispatch(getPoints());
                        options.callback();
                        if(state.user.postLoginAction) {
                            await state.user.postLoginAction(state.user.postLoginData);
                            dispatch(clearPostLoginAction())
                        }
                    }, options.force? 0: defaultLoaderDelay)
                } else {
                    dispatch(hideLoading())
                    debug('[[[login response]]]:', res);
                    try{
                        if(!lastAttempt && typeof _.get(res, 'data.message') === "object") {
                            if(res.data.message.code && res.data.message.code === "invalid_token") {
                                return NodeAuth.Company.login(nodepayment_un, nodepayment_pw, true)
                                    .then(() => dispatch(login(email, encodedPass, true)))
                            }
                        }
    
                        if(res.data.errors) {
                            return dispatch(handle_user_login_error(res.data.errors))
                        }
                        let message = res.data.message;

                        if(typeof message === "string") {
                            if(message === "Invalid password") {
                                return dispatch(handle_user_login_error([i18next.t('common.errors.invalid_password', 'خطأ في كلمة السر')]))
                            }
    
                            if(message === "User not found") {
                                return dispatch(handle_user_login_error([i18next.t('common.errors.email_not_found', 'خطأ في البريد الالكتروني')]))
                            }
                        }

                        if(typeof message === "object") {
                            if(message.Result && typeof message.Result === "string") {
                                if(message.Result === "Not Found ") {
                                    return dispatch(handle_user_login_error([i18next.t('common.errors.invalid_email_or_pass', 'خطأ في البريد الإلكتروني او كلمة السر')]))
                                }
                            }
                        }
                    
                        return dispatch(handle_user_login_error([i18next.t('common.errors.general', 'لقد حدث خطاً ما.')]))
                    }catch(err){
                        console.log('[[USER-LOGIN]]- Catching Error:', err.message, '!!Check Requesting URL!!')
                        return dispatch(handle_user_login_error([i18next.t('common.errors.general', 'لقد حدث خطاً ما.')]));
                    }
                }
                dispatch(hideLoading())
                return res;
            })
    }
}

/**
 * Handle Register  
 * @param {string, string} email, password
 * @author Ezzat
 */
export function register(username, email, mobile, password) {
    return function (dispatch, state) {
        let errors = [];
        let usrCred = {email, password, type: "classic"}; //[AYA]

        // missing fields validation
        if(!username) errors.push(i18next.t('common.errors.username_missing', 'من فضلك ادخل الاسم'));
        if(!email) errors.push(i18next.t('common.errors.email_missing', 'من فضلك ادخل البريد الإلكتروني'));
        if(!mobile) errors.push(i18next.t('common.errors.phone_number_missing', 'من فضلك ادخل رقم الهاتف'));
        if (!password) errors.push(i18next.t('common.errors.password_missing', 'من فضلك ادخل كلمة السر'));
        
        if(errors.length > 0) {
            return dispatch(handle_user_register_error(errors));
        }

        // invalid fields validation
        if(!Utils.isValidEmail(email)) errors.push(i18next.t('common.errors.invalid_email_format', 'من فضلك ادخل بريد إلكتروني صحيح'));
        if(!Utils.isValidPhone(mobile)) errors.push(i18next.t('common.errors.invalid_phone_format', 'من فضلك أدخل رقم الهاتف صحيح'));

        if (errors.length > 0) {
            return dispatch(handle_user_register_error(errors));
        }
        
        dispatch(showLoading());
        dispatch(loading());  
        state = state();

        NodeAuth.User.register({name: username, email, phone: mobile, password})
            .then(res => {
                console.log(res);
                if(res.success) {
                    let user = NodeAuth.User.getTokenPayload('user');

                    if(!user) {
                        dispatch(handle_user_register_error([i18next.t('common.errors.general', 'لقد حدث خطاً ما.')]))
                        localStorage.removeItem("user_token");
                    }
                    //[ENCRYPTION] - encrypt user password and email
                    const encrypted = Utils.encrypt(usrCred);
                    store2.set("_t", encrypted);
                    
                    let { name, email, avatar, phone, extras } = user;
                    // console.error('extras', extras)
                    const lang = state.locale.lang;

                    store2.set("user_avatar", avatar);
                    CMApi.send("welcome", [`${name} <${email}>`], {
                        name: name,
                        email: email,
                        phone: phone,
                        email_lang: lang
                    });

                    setTimeout(() => {
                        dispatch(handle_user_login({ UserName: name, /*Utils.decodeUtf8(name)*/ UserEmail: email, UserTelNo: phone, Pic_Url: avatar, UserID: extras.UserID, UserToken: extras.UserToken }, dispatch));
                        dispatch(getPoints());
                        if(state.user.postLoginAction) {
                            state.user.postLoginAction(state.user.postLoginData);
                            dispatch(clearPostLoginAction())
                        }
                    }, defaultLoaderDelay)
                } else {
                    let { message } = res.data;
                    let error = i18next.t('common.errors.general', 'لقد حدث خطاً ما.');

                    if(typeof message === "string") {
                        if(message === "the provided email already exists") {
                            error = i18next.t('common.errors.email_exists', 'البريد الإلكتروني مسجل مسبقاََ');
                        }
                    }

                    if(typeof message === "object") {
                        if(message.Result && typeof message.Result === "string") {
                            if(message.Result === "This Mobile Have Account Befor") {
                                error = i18next.t('common.errors.phone_number_exists', 'رقم الهاتف مسجل مسبقاََ');
                            }

                            if(message.Result === "This Email Have Account Befor") {
                                error = i18next.t('common.errors.email_exists', 'البريد الإلكتروني مسجل مسبقاََ');
                            }
                        }
                    }
                    dispatch(handle_user_register_error([error]))
                }
                dispatch(hideLoading());
            })
            .catch(err => {
                console.error(err);
                dispatch(handle_user_register_error([i18next.t('common.errors.general', 'لقد حدث خطاً ما.')]))
                dispatch(hideLoading());
            })              
    }
}


export function fbLogin(fbResponse, lastAttempt, options = {force: false, callback: () => {}}) {
    return function (dispatch, state) {
        dispatch(showLoading())
        dispatch(loading());
        state = state();

        const usrCred = { 
            picture: fbResponse.picture,
            accessToken: fbResponse.accessToken, 
            type: "facebook" 
        };

        return NodeAuth.User.login_facebook(fbResponse.accessToken, { forceLogin: options.force })
            .then(res => {
                debug('[[fb login resp]]', res)
                dispatch(hideLoading());
                if(!res.success) {
                    debug('FB_LOGIN Res: ', res);
                    if(res.data && res.data.message && res.data.message.Result) {
                    }
                    if(res.data && res.data.message && res.data.message.Result && res.data.message.Result.trim() === "Not Found ".trim()) {
                        dispatch(fb_user_not_found(fbResponse));
                        return;
                    }
                    if(!lastAttempt && res.data && res.data.message.code === "invalid_token") {
                        return NodeAuth.Company.login(nodepayment_un, nodepayment_pw, true)
                            .then(() => dispatch(fbLogin(fbResponse, true)))                            
                    }

                    let error = "";

                    if(res.data && typeof res.data.message === "string") {
                        error = res.data.message;
                    }

                    if(res.data && typeof res.data.message === "object") {
                        error = res.data.message.errors;
                    }

                    return dispatch(handle_user_login_error([error || i18next.t('common.errors.general', 'لقد حدث خطاً ما.')]))
                }

                let user = NodeAuth.User.getTokenPayload('user');

                if(!user) {
                    dispatch(handle_user_register_error([i18next.t('common.errors.general', 'لقد حدث خطاً ما.')]))
                    localStorage.removeItem("user_token");
                }

                // auto login credentials
                const encrypted = Utils.encrypt(usrCred);
                store2.set("_t", encrypted);

                let { name, email, phone, extras } = user;

                // console.error('extras', extras)
                let avatar = "";

                if(fbResponse.picture) {
                    avatar = fbResponse.picture.data.url;
                } else {
                    avatar = user.avatar;
                }

                store2.set("user_avatar", avatar);

                setTimeout(() => {
                    dispatch(handle_user_login({ UserName: name, /*Utils.decodeUtf8(name)*/ UserEmail: email, UserTelNo: phone, Pic_Url: avatar, UserID: extras.UserID, UserToken: extras.UserToken }, dispatch));
                    dispatch(getPoints());
                    options.callback();
                    if(state.user.postLoginAction) {
                        state.user.postLoginAction(state.user.postLoginData);
                        dispatch(clearPostLoginAction())
                    }

                }, defaultLoaderDelay)
                dispatch(hideLoading());
                return res;
            })
            .catch(err => {
                console.error('Facebook Login: Error', err);
                dispatch(handle_user_register_error([i18next.t('common.errors.general', 'لقد حدث خطاً ما.')]))
                dispatch(hideLoading());
            })
    }
}


export function fbSignup(username, email, phone, password, pic, socialId) {
    return function (dispatch, getState) {
        dispatch(showLoading());
        dispatch(loading());
        userApi.fbSignup(username, email, phone, password, pic, socialId).then(res => {
            debug('fb signup result: ', res);
            if (res.Result.length === 0) {
                dispatch(fbLogin(getState().user.fbResponse))
            } else {
                debug('fb signup failed')
                let error = res.Result;

                if(error === "This Mobile Have Account Befor") {
                    error = i18next.t('common.errors.phone_number_exists', 'رقم الهاتف مسجل مسبقاََ');
                }

                if(error === "This Email Have Account Befor") {
                    error = i18next.t('common.errors.email_exists', 'البريد الإلكتروني مسجل مسبقاً');
                }

                dispatch(handle_user_login_error([error]))
            } 
            dispatch(hideLoading())
        }).catch(err => {
            dispatch(hideLoading())
            dispatch(handle_user_register_error([i18next.t('common.errors.general', 'لقد حدث خطاً ما.')]))
        })
    }
}

export function forgotPassword(email) {
    return (dispatch, getState) => {
        dispatch(clearErrors());
        dispatch(loading());
        if (!email) return dispatch(handle_user_login_error([i18next.t('common.errors.email_missing')]));
        if (!Utils.isValidEmail(email)) return dispatch(handle_user_login_error([i18next.t('common.invalid_email_format', 'من فضلك ادخل البريد الإلكتروني صحيح')]))

        setTimeout(() => {
            const lang = getState().locale.lang;
            // EmailApi.forgotPassword({ email, email_lang: lang })
            //     .then(res => {
            //         debug('forgot password mail: ', res);
            //         dispatch({
            //             type: actionTypes.RESET_PWD_EMAIL_SENT
            //         });
            //         dispatch(loading(false));
            //     })
            //     .catch(err => {
            //         debug('forgot password mail error: ', err);
            //         dispatch(loading(false))
            //     })
            CMApi.send("forgot-password", [email], {email, email_lang: lang})
                .then(res => {
                    debug('forgot password mail: ', res);
                    if (res.success) {
                        dispatch({
                            type: actionTypes.RESET_PWD_EMAIL_SENT
                        })
                    }
                    dispatch(loading(false))
                })
                .catch(err => {
                    debug('forgot password mail error: ', err);
                    dispatch(loading(false))
                })
        }, defaultLoaderDelay);

    }
}

/**
 * 
 * @param {Object} fields - form fields
 * @param {String} fields.new_password - new password chosen by user.
 * @param {String} fields.confirm_new_password - confirmed new password.
 * @param {*} token 
 */
export function resetPassword(fields, token) {
    return dispatch => {
        dispatch(loading());
        setTimeout(() => {
            userApi.resetPassword(fields.new_password, token)
            .then(res => {
                dispatch(loading(false));
                // validate reset password success
                if(res.IsSuccess) {
                    // dispatch password reset success
                    dispatch(handle_reset_password(true))

                    return setTimeout(() => {
                        // redirect to home page
                        window.location.replace("/");
                    }, defaultLoaderDelay * 3);
                }
                
                // reset password fail
                // dispatch password reset failed
                dispatch(handle_reset_password(false, [i18next.t('common.errors.general', 'لقد حدث خطاً ما.')]));
            })
            .catch(err => {
                // dispatch general error
                dispatch(loading(false));
                dispatch(handle_reset_password(false, [i18next.t('common.errors.general', 'لقد حدث خطاً ما.')]))
    
            })
        } , defaultLoaderDelay);
    }
}

/**
 * 
 * @param {Boolean} success reset password success | fail
 */
function handle_reset_password(success, errors) {
    let action = {
        type: actionTypes.RESET_PWD_FAIL,
        errors
    };

    if(success) {
        action.type = actionTypes.RESET_PWD_SUCCESS;
        delete action.errors;
    }

    return action;
}



/**
 * Clear the errors
 */
export function clearErrors(){
    return {
        type:actionTypes.CLEAR_ERRORS
    }
}


/**************************************** Account Management **************************************/
export function editProfile(user){
    return function (dispatch) {
        dispatch({type: actionTypes.EDITING_PROFILE})

        NodeAuth.User.update({
            name: user.name,
            email: user.email,
            phone: user.telNo,
            extras: {
                UserID: user.id
            }
        })
        .then(res => {
            debug('edit profile result: ', res);
            if(res.success) {
                let { name, email, avatar, phone, extras } = res.data.user;
                // console.error('extras', extras)
                dispatch({
                    type: actionTypes.EDIT_PROFILE_SUCCESS,
                    user: { 
                        UserName: name,//Utils.decodeUtf8(name),
                        UserEmail: email,
                        UserTelNo: phone,
                        Pic_Url: avatar,
                        UserID: extras.UserID,
                        UserToken: extras.UserToken
                    }
                });
                setTimeout(() => {
                    dispatch(setInvalidPhonePopUp(false));
                }, 3000);
            } else {
                let msg = i18next.t('common.errors.general', 'لقد حدث خطاً ما.');

                if(typeof res.data.message === "string") {
                    msg = res.data.message;
                } else if (typeof res.data.message === "object") {
                    if (res.data.message.Result) {
                        msg = res.data.message.Result
                        
                        if (msg === "This Mobil Have Account Befor") {
                            msg = i18next.t('common.phone_number_exists', 'رقم الهاتف مسجل مسبقاً')
                        }
                    }
                }

                dispatch({
                    type: actionTypes.EDIT_PROFILE_FAILED,
                    error: [msg]
                })
            }
        })
        .catch(err => {
            console.error(err)
            dispatch({
                type: actionTypes.EDIT_PROFILE_FAILED,
            })
        })
    }
}

/**
 * 
 * @param {{old_password: string, new_password: string}} user 
 */
export function changePassword(user) {
    return function (dispatch) {
        dispatch({type: actionTypes.EDITING_PROFILE});

        if (user.old_password === user.new_password) {
            return dispatch({
                type: actionTypes.EDIT_PROFILE_FAILED,
                error: i18next.t('common.errors.old_and_new_password_similar', 'من فضلك ادخل كلمة سر أخرى')
            });
        }

        NodeAuth.User.changePassword({
            old_password: encodeURIComponent(user.old_password),
            new_password: encodeURIComponent(user.new_password),
            extras: {
                UserID: user.id
            }
        })
        .then(res => {
            if(!res) {
                dispatch({
                    type: actionTypes.EDIT_PROFILE_FAILED,
                    error: i18next.t('common.errors.general', 'لقد حدث خطاً ما.')
                })
                return;   
            }

            debug('change password result: ', res);
            if(res.success) {
                let { name, email, avatar, phone, extras } = res.data.user;
                // console.error('extras', extras)
                dispatch({
                    type: actionTypes.EDIT_PROFILE_SUCCESS,
                    user: { 
                        UserName: name, //Utils.decodeUtf8(name),
                        UserEmail: email,
                        UserTelNo: phone,
                        Pic_Url: avatar,
                        UserID: extras.UserID,
                        UserToken: extras.UserToken
                    }
                })
            } else {
                if(res.data.message === "password didn't match") {
                    return dispatch({
                        type: actionTypes.EDIT_PROFILE_FAILED,
                        error: i18next.t('common.invalid_password', 'خطأ في كلمة السر')
                    })
                }

                if(typeof res.data.message === "string") {
                    return dispatch({
                        type: actionTypes.EDIT_PROFILE_FAILED,
                        error: res.data.message
                    })
                } 
                
                if(typeof res.data.message === "object") {
                    if(res.data.message.Result) {
                        if(res.data.message.Result === "old password don't match") {
                            return dispatch({
                                type: actionTypes.EDIT_PROFILE_FAILED,
                                error: [i18next.t('common.errors.invalid_password', 'خطأ في كلمة السر')]
                            })
                        }

                        return dispatch({
                            type: actionTypes.EDIT_PROFILE_FAILED,
                            error: [res.data.message.Result]
                        })
                    }
                }

                if (Array.isArray(res.data.errors)) {
                    const errors = [];
                    res.data.errors.forEach(error => {
                        if (error === 'old_password field is required') {
                            errors.push(i18next.t('common.errors.old_password_missing', 'من فضلك ادخل كلمة السر الحالية'));
                        } else
                        if (error === 'new_password field is required') {
                            errors.push(i18next.t('common.errors.new_password_missing', 'من فضلك ادخل كلمة السر الجديدة'));
                        }
                    })

                    return dispatch({
                        type: actionTypes.EDIT_PROFILE_FAILED,
                        error: errors
                    })
                }

                return dispatch({
                    type: actionTypes.EDIT_PROFILE_FAILED,
                    error: i18next.t('common.errors.general', 'لقد حدث خطاً ما.')
                })
            }
        })
        .catch(err => {
            console.error(err)
            dispatch({
                type: actionTypes.EDIT_PROFILE_FAILED,
                error: i18next.t('common.errors.general', 'لقد حدث خطاً ما.')
            })
        })
    }
}

export function getTickets(onetime = false) {
    return function (dispatch, getState) {
        const state = getState();
        dispatch({type: actionTypes.GETTING_TICKETS})
        console.log('[[USER_STATE]]- Getting USER Tickets:', state);
        return userApi.getTickets(state.user.user.UserID, state.user.user.UserToken)
            .then(async tickets => {
                // if tickets empty, try logging in again and repeat once.
                if (Array.isArray(tickets) && !tickets.length && !onetime) {
                    // encrypted credentials
                    const encrypted = store2.get("_t", {});
                    // actual credentials
                    let cred = {};
                    try {
                        cred = Utils.decrypt(encrypted.data, encrypted.iv);
                    } catch (error) {
                        console.error(error);
                    }
                    // check if email and password exists (user logged in once and has storage enabled).
                    if (cred.type === "classic" && cred.email && cred.password) {
                        // attempt to login
                        try {
                            await login(
                                cred.email,
                                cred.password,
                                undefined, 
                                {force: true, callback: () => dispatch(getTickets(true))}
                            )(dispatch, getState);
                        } catch (error) {
                            console.error(error)
                        }
                    } else
                    if (cred.type === "facebook" && cred.accessToken) {
                        try {
                            await fbLogin({accessToken: cred.accessToken, picture: cred.picture}, undefined, {
                                force: true,
                                callback: () => dispatch(getTickets(true))
                            })(dispatch, getState);
                        } catch (error) {
                            console.error(error);
                        }
                    } else {
                        console.error("Tickets procedure failed!");
                        dispatch({
                            type: actionTypes.GET_TICKETS_SUCCESS,
                            tickets
                        })
                    }
                } else {
                    dispatch({
                        type: actionTypes.GET_TICKETS_SUCCESS,
                        tickets
                    })
                }
            })
            .catch(err => {
                dispatch({
                    type: actionTypes.GET_TICKETS_FAILED
                })
            })
    }
}

export function cancelTicket(ticket) {
    return function (dispatch, state) {
        state = state();

        const data = {
            name: state.user.user.UserName,
            email: state.user.user.UserEmail,
            phone: state.user.user.UserTelNo
        }

        ticket.user = state.user.user;
        ticket.UserID = state.user.user.UserID;
        ticket.userToken = state.user.user.UserToken;
        ticket.email = {
            email_lang: state.locale.lang
        }

        return TripApi.cancelTicket(ticket)
            .then((res) => {
                return res;
            })
    }
}

export function cancelSeats(data) {
    return function (dispatch) {
        return TripApi.cancelSeats(data)
            .then(res => {
                return res;
            })
    }
}

export function getPoints() {
    return async function (dispatch, getState) {
      const token = getState().user.user.UserToken;
  
      const points = await UserApi.getPoints(token);
      dispatch({ type: actionTypes.UPDATE_USER_POINTS, data: {
        total: points.Points,
        usable: points.PointsToUse,
        value: points.Points_Value,
        amount: 0,
        _fetched: true
      } })
    }
  }
  
  export function usePoints(amount) {
    return function (dispatch, getState) {
      const points = getState().user.points;
      let total = useCheckoutStore.getState().pricing.totalPrice;
      let allPoints = amount;
      let consumedPoints = 0;
      
      if (amount % 100 !== 0) {
        return console.error('You can only use multiples of 100 points');
      }

      if (points.PointsToUse < amount) {
        return console.error("You don't have this much points!");
      }

      while (allPoints > 0) {
        const newTotal = total - 5
        if (newTotal > 0) {
          consumedPoints += 100;
          allPoints -= 100;
          total = newTotal
        } else {
          break;
        }
      }

      dispatch(applyPromoPoints('ploader', true));
      dispatch({ type: actionTypes.UPDATE_POINTS_TO_USE, data: consumedPoints });
        useCheckoutStore.getState().setPoints(consumedPoints);
        //set ploader: false again
        dispatch(applyPromoPoints('ploader',false));
  
    }
  }

function clearPostLoginAction() {
    return {
        type: actionTypes.CLEAR_POST_LOGIN
    }
}

export function storeUser(user){
    /**
        * THIS IS TEMPROAORY until go bus makes an endpoint to get user data from token
    */
        user.ttl = moment().add(2,'hours')
        store2.set('user', user)
        store2.set('token', user.UserToken)

}

export function soft_logout() {
    return function (dispatch) {
        dispatch({ type: actionTypes.USER_SOFT_LOGOUT });
    }
}

export function logout(navigator, isLogout) {
    return function (dispatch) {
        dispatch(loading());
        NodeAuth.User.logout();
        store2.remove('user');
        store2.remove('token');
        store2.remove('_t');
        store2.remove("GBCard");
        dispatch(removeGoingTrip());
        //dispatch(clearHeldSeatsCountdown());
        debug('TEST', "User Logged Out");  
        setTimeout(() => {
            dispatch({type: actionTypes.USER_LOGOUT})
            if(window.location.pathname !== "/search" && window.location.pathname !== "/")
                if (navigator) {
                    navigator.push('/');
                }
                // window.location.replace('/')
            if(!isLogout){
                console.log('Force Logout!')
                dispatch(toggleAuthPopUp());
            }
        }, defaultLoaderDelay)
    }
}

export function preLogin() {
    return (dispatch, getState) => {
        if(NodeAuth.User.isLoggedIn('user')) {
            let user = null;
        
            let temp = NodeAuth.User.getTokenPayload('user');
            
            if(!temp) {
                localStorage.removeItem("user_token");
            }

            let avatar = store2.get("user_avatar", temp.avatar);
            user = {
                UserID: temp.extras? temp.extras.UserID: null,
                UserToken: temp.extras? temp.extras.UserToken: null,
                UserName: temp.name, //Utils.decodeUtf8(temp.name),
                UserEmail: temp.email,
                UserTelNo: temp.phone,
                Pic_Url: avatar
            }
            dispatch(handle_user_login(user, dispatch))

            if (!getState().user.points._fetched) {
                dispatch(getPoints())
            }
          }
    }
}

export function reset() {
    return function (dispatch) {
        dispatch({type: actionTypes.USER_DATA_RESET})
    }
}


export function fbEmailMissing(fbResponse) {
    return dispatch => {
        dispatch(fb_user_not_found(fbResponse))
    }
}


/**************************************** Handlers **************************************/
export function handle_user_login(user, dispatch) {
    // fullstory identify user
    identify(user.UserID, user.UserName, user.UserEmail);
    TiktokPixelHelper.identify({ email: user.UserEmail });
    
    return {
        type: actionTypes.USER_LOGIN,
        user
    }
}

export function handle_user_login_error(error) {
    return {
        type: actionTypes.USER_LOGIN_ERROR,
        error
    }
}


export function handle_user_register(user) {
    return {
        type: actionTypes.USER_REGISTER,
        user
    }
}

export function handle_user_register_error(error) {
    return {
        type: actionTypes.USER_REGISTER_ERROR,
        error
    }
}

function fb_user_not_found(fbResponse) {
    return {
        type: actionTypes.FB_USER_NOT_FOUND,
        fbResponse
    }
}
//aya
export function applyPromoPoints(key, load){
    return {
        type: actionTypes.APPLY_POINT_PROMO,
        key,
        load
    }
}

export function dispatchLoading(bool) {
    return function (dispatch) {
        dispatch(loading(bool));
    }
}

function loading(bool) {
    return {
        type: actionTypes.LOADING_USER,
        bool
    }
}
