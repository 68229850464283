import React, { Component } from "react";
import Helmet from "react-helmet";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
// Actions
import * as UserActions from "../../Actions/UserActions";

// Components
import MainLayoutComponent from "../MainLayoutComponent";
import SideBar from "../Pages/Account/SideBar";
import LoaderComponent from "../Utils/LoaderComponent";
import ErrorComponent from "../Utils/ErrorComponent";
import SuccessComponent from "../Utils/SuccessComponent";
import { Trans } from 'react-i18next';
import i18next from 'i18next';
import NA from "node-auth-api";
import Store from '../../Store';
import { logout, isLoggedIn } from '../../Actions/UserActions';

function mapStateToProps(store) {
  return {
    locale: store.locale,
    userStore: store.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(UserActions, dispatch)
  };
}

/**
 * @typedef {object} Props
 * @prop {UserActions} userActions
 *
 * @extends {Component<Props>}
 */
class ChangePasswordComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isActiveAction: false,
      userId: props.userStore.user.UserID,
      old_password: "",
      new_password: "",
      verify_password: "",
      errors: []
    };
  }

  componentDidMount() {
    this.props.userActions.reset();

    setTimeout(() => {
      if (this.parent) this.parent.classList.add("active");
    }, 100);
  }

  toggleActionClasses = () => {
    this.setState({ isActiveAction: !this.state.isActiveAction });
  };

  handleChange = e => {
    let newState = {
      ...this.state
    };

    newState[e.target.name] = e.target.value;
    this.setState(newState);
  };

  changePassword() {
    if(!isLoggedIn()){
      alert('Your session expired, Please Re-Login!');
      Store.dispatch(logout(this.props.history));
      //return
    }else{
      const errors = [];

      if (!this.state.old_password) {
        errors.push(i18next.t('common.errors.old_password_missing', 'من فضلك ادخل كلمة السر الحالية'))
      }
  
      if (!this.state.new_password) {
        errors.push(i18next.t('common.errors.new_password_missing', 'من فضلك ادخل كلمة السر الجديدة'))
      }
  
      if (!this.state.verify_password) {
        errors.push(i18next.t('common.errors.verify_password_missing', 'من فضلك ادخل تأكيد كلمة السر'))
      }
  
      if (errors.length > 0) {
        return this.setState({ errors });
      }
  
      if (this.state.new_password === this.state.verify_password) {
        this.setState({ errors: [] });
        this.props.userActions.changePassword({
          id: this.state.userId,
          old_password: this.state.old_password,
          new_password: this.state.new_password
        });
      } else {
        this.setState({
          errors: [i18next.t('change_password_page.errors.confirm_password_invalid', 'تأكيد كلمة المرور غير متوافق')]
        });
      }
    }

  }

  render() {
    if (!this.props.userStore.auth) return null;

    this.fields = [
      {
        name: "old_password",
        label: i18next.t('change_password_page.current_password_label', 'كلمة المرور الحالية'),
        type: "password"
      },
      {
        name: "new_password",
        label: i18next.t('change_password_page.new_password_label', 'كلمة مرور جديدة'),
        type: "password"
      },
      {
        name: "verify_password",
        label: i18next.t('change_password_page.verify_password_label', 'تأكيد كلمة المرور الجديدة'),
        type: "password"
      }
    ];
    
    let ActionsClasses = this.state.isActiveAction
      ? "actions-box active"
      : "actions-box";

    let {
      editing_profile,
      edit_profile_result,
      errorMessage
    } = this.props.userStore;
    let { UserName, UserEmail, UserTelNo, Pic_Url } = this.props.userStore.user;

    return (
      <MainLayoutComponent page={this.props.location.pathname}>
        <Helmet key={window.location.href} >
          <title>Change Password | Go Bus</title>
          <meta
            name="description"
            content="تغير كلمة السر الخاصة بك في الموقع الرسمي من جو باص"
          />
        </Helmet>
        <div className="page-content">
          <div className="AccPage">
            <div className="container">
              <div className="page-title">
                <h5><Trans i18nKey="change_password_page.heading">حسابي</Trans></h5>
                <h1><Trans i18nKey="change_password_page.subheading">تعديل كلمة المرور</Trans></h1>
              </div>
              <div className="account-container">
                <SideBar
                  username={UserName}
                  email={UserEmail}
                  phone={UserTelNo}
                  profile_picture={Pic_Url}
                  logout={() =>
                    this.props.userActions.logout(this.props.history, true)
                  }
                  // currentPage={this.props.history.location.pathname}
                />
                <div
                  className="account-content"
                  ref={ref => (this.parent = ref)}
                >
                  <div className="edit-account">
                    {this.state.errors.length > 0 && (
                      <ErrorComponent errors={this.state.errors} />
                    )}
                    {edit_profile_result === "fail" && (
                      <ErrorComponent errors={errorMessage} />
                    )}
                    {edit_profile_result === "success" && (
                      <SuccessComponent msg={i18next.t('change_password_page.success.password_changed', 'تم تعديل كلمة السر بنجاح.')} />
                    )}
                    <h3><Trans i18nKey="change_password_page.subheading">تعديل كلمة المرور</Trans></h3>
                    <form>
                      {this.fields.map(field => {
                        return (
                          <div key={field.name} className="input-field">
                            <label htmlFor={field.name}>{field.label}</label>
                            <input
                              name={field.name}
                              type={field.type}
                              value={this.state[field.name]}
                              onChange={this.handleChange}
                            />
                          </div>
                        );
                      })}
                      <div className="input-field">
                        <button
                          type="button"
                          className="btn btn-orange"
                          onClick={() => {
                            !editing_profile && this.changePassword();
                          }}
                        >
                          {editing_profile ? (
                            <LoaderComponent
                              background-color="#fc9900"
                              foreground-color="#ffffff"
                            />
                          ) : (
                            <span><Trans i18nKey="change_password_page.edit">تعديل</Trans></span>
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainLayoutComponent>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordComponent);
