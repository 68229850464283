import React from "react";

// Components
import ErrorComponent from "../../Utils/ErrorComponent";
import { Trans } from 'react-i18next';

const ForgotPasswordForm = props => {
  let {
    toggleLoginForm,
    setEmail,
    forgotPassword,
    resetPasswordSent,
    login,
    hasError,
    errorMessage
  } = props;

  return (
    <form
      className="login-form active"
      name="user-auth-form"
      autocomplete="on"
      onSubmit={forgotPassword}
    >
      {resetPasswordSent ? (
        <h4><Trans i18nKey="auth.login_email.forgot_password.success.email_sent">تم ارسال رسالة على بريدك الإلكتروني.</Trans></h4>
      ) : (
        <React.Fragment>
          <h4><Trans i18nKey="auth.login_email.forgot_password.heading">نسيت كلمة السر</Trans></h4>
          <h4>{hasError && <ErrorComponent errors={errorMessage} />}</h4>

          <div className="input-field">
            <label><Trans i18nKey="common.email">البريد الإلكتروني</Trans></label>
            <input
              type="email"
              name="user-email"
              autoComplete="on"
              onChange={setEmail.bind(this)}
            />
          </div>
          <button
            type="button"
            className="btn btnSubmit"
            onClick={forgotPassword}
          >
            <Trans i18nKey="auth.login_email.forgot_password.forgot_btn">نسيت كلمة السر</Trans>
          </button>

          <div className="login-foot">
            <button
              type="button"
              className="btn btn-new-acc"
              onClick={() => {
                toggleLoginForm();
              }}
            >
              <i className="material-icons">account_box</i>
              <Trans i18nKey="auth.register.login_btn">تسجيل الدخول</Trans>
            </button>
          </div>
        </React.Fragment>
      )}
    </form>
  );
};
export default ForgotPasswordForm;
