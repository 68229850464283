import React, { Component } from "react";
import { bindActionCreators } from "redux";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import SideBar from "../SideBar";
import * as CorporateActions from "../../Actions/CorporateActions";

// Componnets
import MainLayoutComponent from "../MainLayoutComponent";
import TermsPlaceholder from "../Utils/Placeholders/TermsPlaceholder";
import { buildYoastMetaTags } from "../../Helpers/Yoast";
import Utils from "../../Helpers/Utils";

function mapStateToProps(store) {
  return {
    lang: store.locale.lang,
    corpStore: store.corporate
  };
}

function mapDispatchToProps(dispatch) {
  return {
    corporateActions: bindActionCreators(CorporateActions, dispatch)
  };
}

class TermsComponent extends Component {

  /**
   * get the terms page if only not requsted before: minimize number of requests
   * @author Kareem Gehad
   * @revised Ezzat
   */
  componentDidMount() {
    !this.props.corpStore.terms && this.props.corporateActions.getTerms();
  }

  getContent = (options = { defaultReturn: {} }) => {
    const terms = this.props.corpStore.terms || {};
    const { lang } = this.props;
    // translated data & undefined safety object
    return terms[lang]? terms[lang]: terms['ar'] || options.defaultReturn;
  }

  renderTerms() {
    const terms = this.getContent();
    // let Title = (this.props.corpStore.terms.title) ? this.props.corpStore.terms.title.rendered : null;
    let Content = terms.content
      ? terms.content.rendered
      : null;

    return (
      <div className="page-content about-page">
        <div className="container">
          <div className="page-con">
            <SideBar />
            <div className="content-widget">
              <div className="title">
                <h1>{terms.acf && terms.acf.heading}</h1>
              </div>
              <div
                className="wysiwyg"
                dangerouslySetInnerHTML={{ __html: Content }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const terms = this.getContent();

    return (
      <MainLayoutComponent page={this.props.location.pathname}>
        <Helmet key={window.location.href} >
          <title>
            {terms.title
              ? Utils.htmlEntitiesFix(terms.title.rendered)
              : "Terms & Conditions | Go Bus"}
          </title>
          {terms &&
            buildYoastMetaTags(terms.yoast_meta).map(
              tag => <meta {...tag} />
            )}
        </Helmet>
        {this.props.corpStore.fetching && <TermsPlaceholder />}
        {terms && this.renderTerms()}
      </MainLayoutComponent>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TermsComponent);