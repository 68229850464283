const initialState = {
  isOnline: window.navigator.onLine
}

export default function AppReducer(state = initialState, action) {
  switch (action.type) {
    case appActions.setOnline:
      return {
        ...state,
        isOnline: action.data
      }
  
    default: return state;
  }
}

export const appActions = {
  setOnline: 'app/SET_ONLINE'
}