import React, { Component } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as CorporateActions from "../../Actions/CorporateActions";
import * as SearchActions from "../../Actions/SearchActions";
import * as TripActions from "../../Actions/TripActions";

import moment from "moment";
import qs from "query-string";

import { Trans } from 'react-i18next';
import { corpUrl, tazhubUrl } from "../../Api/Base";
import { buildYoastMetaTags } from "../../Helpers/Yoast";
import FeaturedDiscount from "../FeaturedDiscount";
import MainLayoutComponent from "../MainLayoutComponent";
import SearchBoxComponent from "../SearchBox/SearchBoxComponent";

function matchStateToProps(store) {
  return {
    lang: store.locale.lang,
    corpStore: store.corporate
  };
}

function matchDispatchToProps(dispatch) {
  return {
    corporateActions: bindActionCreators(CorporateActions, dispatch),
    tripActions: bindActionCreators(TripActions, dispatch),
    searchActions: bindActionCreators(SearchActions, dispatch)
  };
}

class SingleDestination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      destination_s: [0],
      routes_by_price: [],
      pages: []
    };
    this.destination_id = this.props.match.params.id;
    // console.log("destination > ", this.destination_id);
  }

  componentDidMount() {
    !this.props.corpStore.destinations &&
      this.props.corporateActions.getDestinations();

    
    // console.log("Destination: ", destination);
    // console.log()
    // this.setState({ destination_s: [destination] });
    

    // const prefix = this.props.lang === 'ar'? corpUrl: corpUrlEn;
    // let url = `${prefix}/destination?slug=${this.destination_id}`;

    // fetch(url)
    //   .then(response => response.json())
    //   .then(response => {
    //     // debug('single-destination', response);
    //     this.setState({
    //       destination_s: response[0]
    //     });
    //     // this.fetchLowestTrips(response[0].acf.station_id);
    //   });

    let baseurl = `${corpUrl}/pages/5`;
    fetch(baseurl)
      .then(response => response.json())
      .then(response => {
        this.setState({
          pages: response
        });
      });
  }

  getContent = (options = { defaultReturn: {}, forceLang: null }) => {
    const destinations = this.props.corpStore.destinations || options.defaultReturn;
    const lang = options.forceLang? options.forceLang: this.props.lang;
    // translated data & undefined safety object
    return destinations[lang]? destinations[lang]: destinations['ar'] || options.defaultReturn;
  }

  fetchLowestTrips(station_id) {
    let dest_by_price_url = `${tazhubUrl}/trips/${station_id}/price`;
    // debug('id',station_id)
    fetch(dest_by_price_url)
      .then(response => response.json())
      .then(response => {
        // debug('routes', response);
        this.setState({
          routes_by_price: response.data
        });
      })
      .catch(err => {
        console.error(err);
      });
  }

  search(arrival_station_id) {
    let params = {
      departureDate: moment(new Date()), // - 20/12/2018
      arrivalStation: arrival_station_id,
      departureStation: this.state.destination_s.acf.station_id,
      passengersNo: 1,
      transportationType: "bus",
      tripType: "oneWay"
    };

    this.props.searchActions.updateSearchParams(params);
    // this.props.tripActions.searchTrips(params, params.tripType);

    // params.departureDate = params.departureDate.format("D/M/YYYY");
    params = qs.stringify(params);
    const location = {
      pathname: `/search`,
      search: params
    };
    this.props.history.push(location);
  }

  render() {
    // find destination with specified slug
    const content = this.getContent({ defaultReturn: [] });

    let destination_s = content.find(c => decodeURI(c.slug) === this.destination_id) || {};

    // destination not found, try find it in other language then use its acf.station_id to match
    if (!Object.keys(destination_s).length) {
      const otherContent = this.getContent({ forceLang: this.props.lang === 'ar'? 'en': 'ar', defaultReturn: [] });
      const otherDestination = otherContent.find(c => decodeURI(c.slug) === this.destination_id);

      if (otherDestination && otherDestination.acf) {
        destination_s = content.find(c => c.acf && c.acf.station_id === otherDestination.acf.station_id) || {};
      }
    }

    // Destination Name
    let DestinationName = destination_s.acf
      ? destination_s.acf.display_name
      : null;

    let HeroImageURL = destination_s.acf
      ? destination_s.acf.hero_image
      : null;
    let HeroImage = {
      backgroundImage: `url(${HeroImageURL})`
    };

    let CouponName;
    let CouponLabel;
    let CouponBigTitle;
    let CouponDescription;
    let CouponCode;
    let CouponBtnURL;
    let CouponBtnName;
    let StationId;
    let heading;
    let subheading;

    let yoast_meta = destination_s.yoast_meta;

    if (destination_s.acf) {
      heading = destination_s.acf && destination_s.acf.hero_heading;
      subheading = destination_s.acf && destination_s.acf.hero_subheading;
      // Coupon
      CouponName = destination_s.acf
        ? destination_s.acf.coupon_name
        : null;
      CouponLabel = destination_s.acf
        ? destination_s.acf.coupon_label
        : null;
      CouponBigTitle = destination_s.acf
        ? destination_s.acf.coupon_big_title
        : null;
      CouponDescription = destination_s.acf
        ? destination_s.acf.coupon_description
        : null;
      CouponCode = destination_s.acf
        ? destination_s.acf.coupon_code
        : null;
      CouponBtnURL = destination_s.acf
        ? destination_s.acf.button_url
        : null;
      CouponBtnName = destination_s.acf
        ? destination_s.acf.button_name
        : null;

      StationId = destination_s.acf.station_id;
    }

    // General Information
    let GeneralInfoAboutCity = destination_s.acf
      ? destination_s.acf.general_information_about_city
      : null;
    let GeneralInfoImg = destination_s.acf
      ? destination_s.acf.general_information_image
      : null;
    let GeneralInfoDescription = destination_s.acf
      ? destination_s.acf.general_information_description
      : null;

    // Doing in City
    let WhatWeDoIn = destination_s.acf
      ? destination_s.acf.what_we_do
      : null;
      
    let Doing = destination_s.acf
      ? destination_s.acf.doing_in_city.map((doing, index) => {
          return (
            <div className="distination-con" key={index}>
              <div className="image-widget">
                <img src={doing.image} alt="" />
              </div>
              <div className="content-widget">
                <h4>{doing.title}</h4>
                <p>{doing.description}</p>
              </div>
            </div>
          );
        })
      : null;

    // App
    let AppImage = this.state.pages.acf
      ? this.state.pages.acf.app_image.sizes.large
      : null;
    let AppTitle = this.state.pages.acf ? this.state.pages.acf.app_title : null;
    let AppTagline = this.state.pages.acf
      ? this.state.pages.acf.app_tagline
      : null;
    let AppSmallTitle = this.state.pages.acf
      ? this.state.pages.acf.app_small_title
      : null;
    let GooglePlayLink = this.state.pages.acf
      ? this.state.pages.acf.google_play_link
      : null;
    let AppStoreLink = this.state.pages.acf
      ? this.state.pages.acf.app_store_link
      : null;

    return (
      <MainLayoutComponent page={this.props.location.pathname}>
        <Helmet key={window.location.href} >
          <title>{(destination_s.title && destination_s.title.rendered) || "Destination | Go Bus"}</title>
          {buildYoastMetaTags(yoast_meta).map(tag => (
            <meta {...tag} />
          ))}
        </Helmet>
        <div className="page-content destination-page">
          <div className="hero destination-hero" style={HeroImage}>
            <div className="container">
              <div className="hero-con">
                <SearchBoxComponent
                  page="home"
                  location={this.props.location}
                  history={this.props.history}
                  match={this.props.match}
                  defaultDestination={StationId}
                />
                <div className="hero-content">
                  <h2>{heading || "أحجز رحلة أتوبيس إلي"}</h2>
                  {
                    this.props.lang === 'ar'
                      ? <h1>{subheading || "مدينة"} {DestinationName}</h1>
                      : <h1>{DestinationName} {subheading || "مدينة"}</h1>
                  }
                </div>
              </div>
            </div>
          </div>
          {/* <div className="container">
                    <div className="destinations">
                        <div className="destinations-con">
                            <div className="station-destination">
                                <h3>وجهات السفر</h3>
                                <p>من و إلي محطة {DestinationName}</p>
                            </div>
                            <div className="suggest-destinations">
                                <ul>
                                    {this.state.routes_by_price.map((dest, index) => {
                                        return (
                                            <li key={index}>
                                                <h4>{dest.arrival_city}</h4>
                                                <p>أسعار تبدأ من&nbsp;</p>
                                                <span>{dest.price}</span>
                                                <button className="btn" onClick={this.search.bind(this, dest.station_id)}>أحجز الآن </button>
                                            </li>
                                        )    
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> */}

          <div className="distination-info">
            <div className="distination-main-info">
              <div className="container">
                <h3>{GeneralInfoAboutCity} {DestinationName}</h3>
                <div className="distination-info-content-con">
                  <div className="distination-con full-width">
                    <div className="image-widget">
                      <img src={GeneralInfoImg} alt="" />
                    </div>
                    <div
                      className="content-widget"
                      dangerouslySetInnerHTML={{
                        __html: GeneralInfoDescription
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="distination-info-content">
              <div className="container">
                <h3>{WhatWeDoIn} {DestinationName}</h3>
                <div className="distination-info-content-con">{Doing}</div>
              </div>
            </div>
          </div>

          {CouponCode && (
            <FeaturedDiscount
              title={CouponBigTitle}
              label={CouponLabel}
              name={CouponName}
              description={CouponDescription}
              code={CouponCode}
            />
          )}

          <div className="mobile-app">
            <div className="app-con">
              <div className="app-image">
                <img src={AppImage} alt="" />
              </div>

              <div className="app-content">
                  <span><Trans i18nKey="home_page.mobile_apps.mini_heading">حمل و إحجز الآن</Trans></span>
                  <h3><Trans i18nKey="home_page.mobile_apps.headline">تطبيق جوباص على الموبايل</Trans></h3>
                  <p><Trans i18nKey="home_page.mobile_apps.subline">تطبيق جوباص للموبايل يتيح لك الحجز مباشرة من موبايلك – وباستخدام التذكرة الالكترونية يمكنك الحجز بدون الحاجة للذهاب لمكتب الحجز</Trans></p>
                  <ul>
                    <li>
                      <a
                        href={GooglePlayLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src="/images/mobile-app/link-google.png"
                          alt="Google play Logo"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href={AppStoreLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src="/images/mobile-app/link-apple.png"
                          alt="App Store Logo"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
            </div>
          </div>
        </div>
      </MainLayoutComponent>
    );
  }
}

export default connect(
  matchStateToProps,
  matchDispatchToProps
)(SingleDestination);
