import React, {Component} from 'react';

// Componnets 
import Header from './Header'
import Footer from './Footer'
import debug from '../Helpers/DebugLog';



class MainLayoutComponent extends Component {

    constructor(props) {
        super(props)   
    }

    
    render(){
        return(
            <div>
                <Header page={this.props.page} goBack={this.props.goBack} />
                    {this.props.children}
                <Footer page={this.props.page}/>
            </div>
        )
    }
}

export default MainLayoutComponent ;