export const __ENV__ = process.env.REACT_APP_API;
export const __DEV__ = process.env.REACT_APP_API !== 'production';
// Staging
let baseApi = "https://gobus.me/api/V3";
let baseUrl = "https://gobus.me";
let tazhubUrl = "http://176.58.105.55/gobus/api";
let wpUrl = 'https://go-bus.com/corporate';
let corpUrl = wpUrl + "/wp-json/wp/v2";
let corpUrlEn = wpUrl + "/en/wp-json/wp/v2";
let gobus_payUrl = "http://178.79.170.228:3003/gobus";
let gobus_notifyUrl = "http://178.79.170.228:3003/notify";
let nodepayment_url = "http://172.105.71.126/payment";
let nodepayment_un = "test303030";
let nodepayment_pw = "test303030";

let  nodeauth_url = "http://172.105.71.126/auth";
let nodenotify_url = "http://172.105.71.126/notification";

let facebook_app_id = "2192844677597965"; //GBusTest => can be used for staging
const sentryDsn = process.env.REACT_APP_SENTRY_DSN;

if (__ENV__ === "local") {
  nodeauth_url = "http://localhost:3007";
  nodepayment_url = "http://localhost:3005";
  baseApi = "http://moveholidays.com/api/V3";
  baseUrl = "http://moveholidays.com";
  gobus_payUrl = "http://localhost:3003/gobus";
  nodepayment_pw = "123456";
}

if (__ENV__ === "testing") {
  baseApi = "https://gobus.me/api/V3";
  baseUrl = "https://gobus.me";
  corpUrl = wpUrl + "/wp-json/wp/v2";
  tazhubUrl = "https://tazhub.com/gobus/api";
  gobus_notifyUrl = "https://go-bus.com/notify";
  nodeauth_url = "https://mdmicroservices.com/auth";
  nodenotify_url = "https://mdmicroservices.com/notification";
  nodepayment_url = "https://mdmicroservices.com/payment";
  nodepayment_un = "gobus";
  nodepayment_pw = "gobus_1315!";
  facebook_app_id = "1781211382109361";
}

if (__ENV__ === "production") {
  // Production
  baseApi = "https://gobus.me/api/V3";
  baseUrl = "https://gobus.me";
  corpUrl = wpUrl + "/wp-json/wp/v2";
  tazhubUrl = "https://tazhub.com/gobus/api";
  gobus_payUrl = "https://go-bus.com/gobus";
  gobus_notifyUrl = "https://go-bus.com/notify";
  nodeauth_url = "https://mdmicroservices.com/auth";
  nodenotify_url = "https://mdmicroservices.com/notification";
  nodepayment_url = "https://mdmicroservices.com/payment";
  nodepayment_un = "gobus";
  nodepayment_pw = "gobus_1315!";
  facebook_app_id = "1781211382109361";
}
export const defaultLoaderDelay =
  __ENV__ === "production" ? 1200 : 0;

export const globalLoaderDelay = 1200;

if (process.env.REACT_APP_CONFIG_USE_ENV === "true") {
  baseUrl = process.env.REACT_APP_GOBUS_HOST;
  baseApi = process.env.REACT_APP_GOBUS_API;

  wpUrl = process.env.REACT_APP_WP_HOST;
  corpUrl = wpUrl + "/wp-json/wp/v2";

  gobus_payUrl = process.env.REACT_APP_PAYMENT_URL;
  gobus_notifyUrl = process.env.REACT_APP_NOTIFY_URL;

  facebook_app_id = process.env.REACT_APP_FB_APP_ID;

  nodepayment_un = process.env.REACT_APP_PAYMENT_USERNAME;
  nodepayment_pw = process.env.REACT_APP_PAYMENT_PASSWORD;
  nodepayment_url = process.env.REACT_APP_PAYMENT_HOST;
  nodeauth_url = process.env.REACT_APP_AUTH_HOST;
  nodenotify_url = process.env.REACT_APP_NOTIFICATION_HOST;
}

export {
  baseApi,
  baseUrl,
  wpUrl,
  corpUrl,
  corpUrlEn,
  tazhubUrl,
  gobus_payUrl,
  gobus_notifyUrl,
  nodeauth_url,
  nodenotify_url,
  nodepayment_url,
  nodepayment_un,
  nodepayment_pw,
  facebook_app_id,
  sentryDsn
};
