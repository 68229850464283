import React, { Component } from "react";
import Helmet from "react-helmet";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SideBar from "../SideBar";
import * as CorporateActions from "../../Actions/CorporateActions";

// Componnets
import MainLayoutComponent from "../MainLayoutComponent";
import AboutPlaceholder from "../Utils/Placeholders/AboutPlaceholder";
import { buildYoastMetaTags } from "../../Helpers/Yoast";

function mapStateToProps(store) {
  return {
    lang: store.locale.lang,
    corpStore: store.corporate
  };
}

function mapDispatchToProps(dispatch) {
  return {
    corporateActions: bindActionCreators(CorporateActions, dispatch)
  };
}

class AboutComponent extends Component {
  /**
   * get the about page if only not requsted before: minimize number of requests
   * @author Kareem Gehad
   * @revised Ezzat
   */
  componentWillMount() {
    !this.props.corpStore.about && this.props.corporateActions.getAbout();
  }

  getContent = (options = { defaultReturn: {} }) => {
    const about = this.props.corpStore.about || {};
    const { lang } = this.props;
    // translated data & undefined safety object
    return about[lang]? about[lang]: about['ar'] || options.defaultReturn;
  }

  renderAbout() {

    const content = this.getContent();

    let Title = content.acf && content.acf.title;
    let Content = content.content
      ? content.content.rendered
      : null;
    let HeroImage = content.better_featured_image
      ? content.better_featured_image.source_url
      : null;
    let HeroImageAlt = content.better_featured_image
      ? content.better_featured_image.alt_text
      : null;
    let Features = content.acf
      ? content.acf.features
      : null;
    let ValuesHeading = content.acf && (content.acf.values_heading || "قيم جوباص")
    let Values = content.acf
      ? content.acf.values.map((value, index) => {
          return (
            <div className="valuable-con" key={index}>
              <div className="valuable-image">
                <img src={value.image.url} alt='value' />
              </div>
              <div className="valuable-content">
                <h3>{value.title}</h3>
                <div dangerouslySetInnerHTML={{ __html: value.description }} />
              </div>
            </div>
          );
        })
      : null;

    return (
      <>
        <div className="page-content about-page">
          <div className="container">
            <div className="page-con">
              <SideBar />
              <div className="content-widget">
                <div className="title">
                  <h1>{Title}</h1>
                  <div
                    className="about-features"
                    dangerouslySetInnerHTML={{ __html: Content }}
                  />
                </div>
                <img src={HeroImage} alt="hero" />
                <div
                  className="about-features"
                  dangerouslySetInnerHTML={{ __html: Features }}
                />
                <div className="valuable">
                  <h2>{ValuesHeading}</h2>
                  {Values}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  render() {
    const about = this.getContent({ defaultReturn: { title: {} } });

    return (
      <MainLayoutComponent page={this.props.location.pathname}>
        <Helmet key={window.location.href} >
          <title>{about.title.rendered || "About | Go Bus"}</title>
          {this.props.corpStore.about &&
            buildYoastMetaTags(about.yoast_meta).map(tag => <meta {...tag} />)}
        </Helmet>
        {this.props.corpStore.fetching && <AboutPlaceholder />}
        {this.props.corpStore.about && this.renderAbout()}
      </MainLayoutComponent>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AboutComponent);
