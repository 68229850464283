import React, { Component } from 'react'
import { connect } from 'react-redux'

import busStyles from "./Bus.module.css";
import Seat from './Seat';
import debug from '../../../Helpers/DebugLog';
import clsx from 'clsx';

class Bus extends Component {
  constructor(props) {
    super(props);

    this.leftsMap = {};
    this.leftSizes = [];

    this.preCalcHeight(props.seats);
  }

  updateRowsCount = (leftSize) => {
    // add one to the current left position per seat and add to the lefts map
    if(this.leftSizes.includes(leftSize)) return this.leftsMap[leftSize] = (this.leftsMap[leftSize] || 3) + 1;
    this.leftSizes.push(leftSize);
  }
  
  preCalcHeight(seats) {
    seats.forEach(seat => {
      this.updateRowsCount(seat.PosX);
    })
  }

  getSeatX(posX) {
    return posX/ 1.8 //* 0.6;
  }

  getSeatY(posY) {
    return posY/ 1.1079 //* 0.9;
  }

  render() {
    let { isMobile, seats, heldSeats, holdSeat, unHoldSeat, loading, additionalHeight, lang } = this.props;

    let customStyles = {};

    /** update layout height with (rows count * height of each seat + margin-bottoms)  */

    // count rows based on repetitions of left index
    let lefts = this.leftSizes.sort(((a, b) => a < b));
    let smallestLeft = this.leftsMap[lefts[0]];
    let spaceFactor = 30 //+ (lefts[1] - lefts[0]);

    // add multiply rows by seat height + margins + paddings
    if (isMobile) {
      customStyles.height = (smallestLeft * spaceFactor) + 80 + (additionalHeight() || 0) + "px";
    }
    // customStyles.position = 'absolute';
    // customStyles.transform = 'rotate(-90deg) translate(83px, 50%)';

    const className = lang === "en"? busStyles.bus_en: busStyles.bus

    const classNames = clsx({
      ...this.props.classes,
      loading: loading
    }, className)
    return (
      <div className={classNames} style={customStyles}>
        {seats.map(seat => {
          return (
            <Seat 
              key={seat.SeatNo}
              id={seat.SeatNo}
              x={this.getSeatX(seat.PosX)}
              y={this.getSeatY(seat.PosY)}
              updateRowsCount={this.updateRowsCount}
              hold={holdSeat}
              unhold={unHoldSeat}
              heldSeats={heldSeats}
              baseSeats={this.props.baseSeats}
              offsetLeft={30}
              status={seat.Status}
            />
          );
        })}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  lang: state.locale.lang
})

export default connect(mapStateToProps)(Bus)