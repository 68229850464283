import React, { Component } from 'react';
import { Link } from 'react-router-dom';


class SideBarComponent extends Component {

    render() {

        return (
            <div className="menu-widget">
                <ul>
                    <li><Link to="/about">عن شركة جوباص</Link></li>
                    <li><Link to="/faq">أسئلة شائعة</Link></li>
                    <li><Link to="/">خدمات متن الرحلة</Link></li>
                    <li><Link to="/">إلغاء التذاكر</Link></li>
                    <li><Link to="/">الشحن السريع</Link></li>
                    <li><Link to="/">حقائب المسافرين</Link></li>
                    <li><Link to="/">تحميل التطبيق</Link></li>
                    <li><Link to="/">أتصل بنا</Link></li>
                </ul>
            </div>
        );
    }
}

export default SideBarComponent;