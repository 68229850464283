import axios from "axios";
import React, { Component } from "react";
import Helmet from "react-helmet";
import CMApi from "cm-mail-api";
import ContactForm from "./ContactForm";

import SideBar from "../../SideBar";
// Componnets
import i18next from 'i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getContactUs } from '../../../Actions/CorporateActions';
import { defaultLoaderDelay, wpUrl } from "../../../Api/Base";
import Utils from "../../../Helpers/Utils";
import { buildYoastMetaTags } from '../../../Helpers/Yoast';
import MainLayoutComponent from "../../MainLayoutComponent";

const BCC_LIST = [];
const MAIL_LIST = [
  "Info@Go-Bus.com",
];


class Contact extends Component {
  constructor() {
    super();
    this.state = {
      errors: [],
      sucess: false,
      loading: false
    };

    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    !this.props.contactUs && this.props.getContactUs();
  }

  getContent = (options = { defaultReturn: {} }) => {
    const contactUs = this.props.contactUs || {};
    const { lang } = this.props;
    // translated data & undefined safety object
    return contactUs[lang]? contactUs[lang]: contactUs['ar'] || options.defaultReturn;
  }

  getCfVerify = () => {
    return axios
        .get(`https://go-bus.com/corporate/cf-api/CF5b9fc55323b6e/`)
        .then(formHtmlResponse => {
            return formHtmlResponse.data;
        })
        .then(formHtml => {
            let cfVerify = "";
            const cfVerifyMatch = formHtml.match(/_cf_verify" value="(\w*)"/);

            if (cfVerifyMatch) {
                cfVerify = cfVerifyMatch[1];
            }
            return cfVerify;
        });
  }

  sendMessage = async (values) => {
    const url = `${wpUrl}/cf-api/CF5b9fc55323b6e`;
    let cfVerify = await this.getCfVerify();

    let data = new FormData();

    data.set("_cf_frm_id", "CF5b9fc55323b6e");
    data.set("_cf_frm_ct", 1);
    data.set("_cf_verify", cfVerify);
    data.set("_cf_cr_pst", 1420);
    data.set("_wp_http_referer", "/cf-api/CF5b9fc55323b6e/");

    data.set("cfajax", "CF5b9fc55323b6e");
    data.set("loadClass", "cf_processing");
    data.set("loadElement", "_parent");
    data.set("customCallback", "slug_post_form_submit");
    data.set("action", "cf_process_ajax_submit");
    data.set("template", "#cfajax_CF5b9fc55323b6e-tmpl");

    data.set("fld_185917", values.name); //name
    data.set("fld_2254781", values.phone); //mobile
    data.set("fld_7900587", values.email); //email
    data.set("fld_4448736", values.bookingId); //order id
    data.set("fld_6382141", values.msgTitle); //title
    data.set("fld_4089741", values.msgContent); //msg
    data.set("fld_7167496", "Submit");

    return axios.post(url, data, { headers: { 'Content-Type': 'multipart/form-data' } });
  }

  submit = (submit_error_message, values) => {
    let inputFields = document.querySelectorAll("input");

    if (inputFields.length > 2) {
      Utils.scrollToElement(inputFields[0]);
    }

    this.setState({
      loading: true
    });

    let errors = [];
    if (!Utils.isValidPhone(values.phone)) {
      errors.push(i18next.t('common.errors.invalid_phone_format', 'رقم الهاتف غير صحيح'));
    }

    if (!Utils.isValidEmail(values.email)) {
      errors.push(i18next.t('common.errors.invalid_email.format', 'البريد الالكتروني غير صحيح'));
    }

    setTimeout(() => {
      if (errors.length > 0) {
        return this.setState({
          errors,
          loading: false
        });
      }

      this.sendMessage(values)
        .then(res => {
          if (res.data && res.data.success === true) {
            this.setState({ success: true, loading: false });
            CMApi.sendAny(
                "fba851d5-875c-4229-82ca-e43a0848aabd",
                MAIL_LIST,
                {
                    name: values.name,
                    phone: values.phone,
                    email: values.email,
                    bookingNo: values.bookingId,
                    message: values.msgTitle,
                    content: values.msgContent
                },
                [],
                BCC_LIST
            );
          } else if (res.data.success === false) {
            this.setState({
              errors: [submit_error_message || "فشل ارسال رسالتكم"],
              loading: false
            });
          } else {
            this.setState({
              errors: [i18next.t('common.errors.general', 'لقد حدث خطاً ما.')],
              loading: false
            });
          }
        })
        .catch(err => {
          this.setState({
            errors: [i18next.t('common.errors.general', 'لقد حدث خطاً ما.')],
            loading: false
          });
        });
    }, defaultLoaderDelay);
  };

  renderSuccess(success_message) {
    Utils.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    return <div className="cu_success">{success_message}</div>;
  }

  renderErrors() {
    if (this.state.errors.length > 0) {
      const target = document.querySelector('sh-before');
      Utils.scrollToElement(target, 'top', 100);
      let style = { color: "red" };

      return (
        <div className="cu_error">
          {this.state.errors.map((error, index) => {
            return (
              <label key={index} style={style}>
                * {error}
              </label>
            );
          })}
        </div>
      );
    }
  }

  render() {

    const contactUs = this.getContent();

    const title = contactUs.title && contactUs.title.rendered;
    const content = contactUs.content && contactUs.content.rendered;
    
    let page_heading = null;
    let page_subheading = null;
    let heading = null;
    let subheading = null;
    let booking_number_label = null;
    let email_label = null;
    let fullname_label = null;
    let phone_label = null;
    let send_message_label = null;
    let message_title_label = null;
    let message_content_label = null;
    let ending_title = null;
    let ending_subtitle = null;
    let ending_email = null;
    let complaints = null;
    let whatsapp = null;

    let name_required_error = null;
    let email_required_error = null;
    let message_title_required_error = null;
    let message_content_required_error = null;

    let submit_success_message = null;
    let submit_error_message = null;
    
    if (contactUs && contactUs.acf) {
      page_heading = contactUs.acf.page_heading;
      page_subheading = contactUs.acf.page_subheading;
      heading = contactUs.acf.heading;
      subheading = contactUs.acf.subheading;
      booking_number_label = contactUs.acf.booking_number_label;
      email_label = contactUs.acf.email_label;
      fullname_label = contactUs.acf.fullname_label;
      phone_label = contactUs.acf.phone_label;
      send_message_label = contactUs.acf.send_message_label;
      message_title_label = contactUs.acf.message_title_label;
      message_content_label = contactUs.acf.message_content_label;
      ending_title = contactUs.acf.ending_title;
      ending_subtitle = contactUs.acf.ending_subtitle;
      ending_email = contactUs.acf.ending_email;
      name_required_error = contactUs.acf.name_required_error;
      email_required_error = contactUs.acf.email_required_error;
      message_title_required_error = contactUs.acf.message_title_required_error;
      message_content_required_error = contactUs.acf.message_content_required_error;
      submit_success_message = contactUs.acf.submit_success_message;
      submit_error_message = contactUs.acf.submit_error_message;
      complaints = contactUs.acf.Complaints;
      whatsapp = contactUs.acf.whatsapp;
    }

    if (this.state.success) {
      return <MainLayoutComponent>{this.renderSuccess(submit_success_message)}</MainLayoutComponent>;
    }

    return (
      <MainLayoutComponent page={this.props.location.pathname}>
        <Helmet key={window.location.href} >
          <title>{title || "أتصل بنا | Go Bus"}</title>
          {contactUs.yoast_meta &&
            buildYoastMetaTags(contactUs.yoast_meta).map(tag => <meta {...tag} />)}
        </Helmet>
        <div className="page-content contact-page">
          <div className="container">
            <div className="page-con">
              <SideBar />
              <div
                className={
                  this.state.loading
                    ? "content-widget component-loader"
                    : "content-widget"
                }
              >
                <div className="title">
                  <h1>{page_heading}</h1>
                  <p>{page_subheading}</p>
                </div>

                <div className="contact-content">
                  <div dangerouslySetInnerHTML={{__html: content}}></div>
                  <h2>{heading}</h2>
                  <p className="sh-before"> {subheading}</p>
                  {this.state.errors ? this.renderErrors() : null}
                  {contactUs.acf && <ContactForm 
                    onChange={() => console.log('TEST')}
                    uiErrors={{
                      name_required_error,
                      email_required_error,
                      message_title_required_error,
                      message_content_required_error
                    }}
                    uiLabels={{booking_number_label,
                      email_label,
                      fullname_label,
                      phone_label,
                      send_message_label,
                      message_title_label,
                      message_content_label}}
                    onSubmit={this.submit.bind(this, submit_error_message)} />}
                  <h2>{ending_title}</h2>
                  <span> {ending_subtitle}</span>
                  <span>{ending_email}</span>
                  <span>{complaints}</span>
                  <span>{whatsapp}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainLayoutComponent>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.locale.lang,
  contactUs: state.corporate.contactUs
})

const mapDispatchToProps = dispatch => ({
  getContactUs: bindActionCreators(getContactUs, dispatch)
})


export default connect(mapStateToProps, mapDispatchToProps)(Contact);
