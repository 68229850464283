import * as actionTypes from "../Actions/ActionTypes";

export const default_corporate_state = {
  // faq state
  faq: undefined,
  faqCategories: undefined,

  station: undefined,
  stationCategories: undefined,

  homepage: {},

  // destinations state
  destinations: undefined,
  single_destination: undefined,

  // routes state
  routes: undefined,
  currentRoute: {},

  //about
  about: undefined,

  // news state
  news: {
    data: { ar: [], en: []},
    hot_news: {},
    max_pages: undefined,
    available_pages: [],
    single_news: {
      ar: undefined,
      en: undefined
    },
    got_max_news: false
  },

  // global corporate state
  fetching: false
};

export function CorporateReducer(state = default_corporate_state, action) {
  switch (action.type) {
    case actionTypes.FETCHING_CORPORATE_DATA:
      state = {
        ...state,
        fetching: true
      };
      break;
    case actionTypes.GET_ABOUT:
      state = {
        ...state,
        about: action.about,
        fetching: false
      };
      break;
    case actionTypes.GET_NBE:
      state = {
        ...state,
        nbe: action.nbe,
        fetching: false
      };
      break;

    case actionTypes.GET_TERMS:
      state = {
        ...state,
        terms: action.terms,
        fetching: false
      };
      break;

    case actionTypes.GET_POLICY:
      state = {
        ...state,
        policy: action.policy,
        fetching: false
      };
      break;

    case actionTypes.GET_STATION:
      let station_items = [];
      action.station.ar.forEach(item => {
        station_items.push({
          id: item.id,
          active: false
        });
      });

      state = {
        ...state,
        station: action.station,
        stationCategories: action.stationCategories,
        // items: items,
        fetching: false
      };
      break;

    case actionTypes.GET_FAQS:
      let items = [];
      action.faq.ar.forEach(item => {
        items.push({
          id: item.id,
          active: false
        });
      });

      state = {
        ...state,
        faq: action.faq,
        faqCategories: action.faqCategories,
        // items: items,
        fetching: false
      };
      break;
    
    case actionTypes.GET_CONTACT_US: {
      return {
        ...state,
        contactUs: action.data
      }
    }
    
    case actionTypes.GET_FAQS_PAGE:

      return {
        ...state,
        faqPage: action.page
      }

    case actionTypes.GET_DESTINATIONS:
      state = {
        ...state,
        destinations: action.destinations,
        fetching: false
      };
      break;

    case actionTypes.GET_SINGLE_DESTINATION:
      return {
        ...state,
        single_destination: action.data,
        fetching: false
      }

    case actionTypes.GET_ROUTES_SUCCESS:
      return {
        ...state,
        routes: action.routes
      };
    case actionTypes.UPDATE_CURRENT_ROUTE:
      state = {
        ...state,
        currentRoute: action.data
      };
      break;
    case actionTypes.GET_BLOGS: {
      state = {
        ...state,
        blogs: action.blogs,
        fetching: false
      };
      break;
    }
    case actionTypes.GET_BLOGS_PAGE: {
      let news = state.news;
      news.page = action.page;

      return {
        ...state,
        news: {
          ...news
        }
      }
    }

    case actionTypes.GET_SINGLE_STATION: {
      state = {
        ...state,
        station: action.station,
        fetching: false
      };
      break;
    }

    case actionTypes.GET_NEWS:
      let news = state.news;

      news.available_pages.push(action.page);
      news.data.ar = news.data.ar.concat(action.news.ar);
      news.data.en = news.data.en.concat(action.news.en);

      news.max_pages = action.max_pages;

      state = {
        ...state,
        news,
        fetching: false
      };

      break;

    case actionTypes.GET_HOT_NEWS:
      state.news.hot_news = action.hot_news;
      state = {
        ...state,
        fetching: false
      };
      break;

    case actionTypes.GET_SINGLE_NEWS:
      state.news.single_news.ar = action.single_news.ar[0];
      state.news.single_news.en = action.single_news.en[0];
      state = {
        ...state,
        fetching: false
      };
      break;

    case actionTypes.GOT_MAX_NEWS:
      state.news.got_max_news = true;

      state = {
        ...state,
        fetching: false
      };
      break;

    case actionTypes.GET_HOMEPAGE: {
      state = {
        ...state,
        homepage: action.homepage
      };
      break;
    }
    
    default: return state;
  }
  return state;
}

export default CorporateReducer;
