import { __ENV__ } from "../Api/Base";
import { useCheckoutStore } from "../new-store/checkoutStore";

let addToCartData = null;

function random_string(length) {
    return Math.round(
        Math.pow(36, length + 1) - Math.random() * Math.pow(36, length)
    )
        .toString(36)
        .slice(1);
}

function sendTiktokEvent(event, params) {
    if (__ENV__ !== "production") {
        return console.warn(
            `[WARN] non production environment, disabling tiktok events`
        );
    }

    const isTiktokPixelLoaded =
        window.ttq && typeof window.ttq.track === "function";
    if (isTiktokPixelLoaded) {
        window.ttq.track(event, { ...params, content_id: random_string(10) });
    } else {
        console.warn(`[WARN] tiktok pixel code not loaded`);
    }
}

function identifyUser(user) {
    if (__ENV__ !== "production") {
        return console.warn(
            `[WARN] non production environment, disabling tiktok events`
        );
    }

    const isTiktokPixelLoaded =
        window.ttq && typeof window.ttq.track === "function";

    if (isTiktokPixelLoaded) {
        window.ttq.identify(user);
    } else {
        console.warn(`[WARN] tiktok pixel code not loaded`);
    }
}

export function identify({ email }) {
    identifyUser({ email });
}

export function InitiateCheckout({
    content_category,
    content_ids,
    value,
    from,
    to
}) {
    sendTiktokEvent("InitiateCheckout", {
        currency: "EGP",
        content_type: "product",
        content_category,
        content_ids,
        value,
        from,
        to
    });
}

/**
 *
 * @param {string} search_string
 * @param {string} from
 * @param {string} to
 */
export function Search(search_string, from, to) {
    sendTiktokEvent("Search", { search_string, from, to });
}

export function AddToCart(params) {
    addToCartData = params;
    sendTiktokEvent("AddToCart", params);
}

/**
 *
 * @param {number} value
 * @param {any} products
 * @param {"EGP"} currency
 */
export function Purchase(value, contents) {
    // contents.forEach(item => {
    //     item.content_id = item.id;
    // });
    // sendTiktokEvent("Purchase", {
    //     value,
    //     currency: "EGP",
    //     contents,
    //     content_type: "product"
    // });
    if (!addToCartData) {
        console.error(
            `[Error] failed to send purchase event to tiktok, cart data are empty`
        );
        return;
    }
    sendTiktokEvent("Purchase", { ...addToCartData });
    addToCartData = null;
}

export function sendAddToCartPixel() {
    const store = useCheckoutStore.getState();
    let CardID = store.cartId;

    let goingTrip = store.goingTripInfo;
    let returningTrip = store.returnTripInfo;

    if (!goingTrip) {
        return;
    }

    let passengersNo = store.passengersNo;
    let totalPrice = 0,
        goingPrice = 0,
        returningPrice = 0;
    const content_ids = [String(goingTrip.TripSubData_Id)];

    if (returningTrip) {
        goingPrice = (passengersNo * goingTrip.TripPrice_GoCome) / 2;
        returningPrice = (passengersNo * returningTrip.TripPrice_GoCome) / 2;
        totalPrice = goingPrice + returningPrice;
        content_ids.push(String(returningTrip.TripSubData_Id));
    } else {
        goingPrice = passengersNo * goingTrip.TripPrice;
        totalPrice = passengersNo * goingTrip.TripPrice;
    }

    const contents = [
        {
            content_id: CardID,
            content_name: `${goingTrip.TripOfficeFrom_NameE} - ${goingTrip.TripOfficeTo_NameE}`,
            content_type: "product",
            price: goingPrice
        }
    ];
    // value: totalPrice,
    if (returningTrip) {
        contents.push({
            content_id: CardID,
            content_name: `${returningTrip.TripOfficeFrom_NameE} - ${returningTrip.TripOfficeTo_NameE}`,
            content_type: "product",
            price: returningPrice
        });
    }
    let data = {
        value: totalPrice,
        currency: "EGP"
    };

    if (contents.length === 1) {
        data = {
            ...data,
            ...contents[0]
        };
    } else if (contents.length === 2) {
        data.contents = contents;
    }

    AddToCart(data);
}

export function sendCheckoutPixel() {
    const store = useCheckoutStore.getState();
    //get final checkout data
    let CardID = store.cartId;
    let goingTrip = store.goingTripInfo;
    let returningTrip = store.returnTripInfo;

    let passengersNo = store.passengersNo;
    let totalPrice = 0,
        goingPrice = 0,
        returningPrice = 0;
    let ticket_type;
    let isRoundTrip = false;

    if (!goingTrip) {
        return;
    }

    const content_ids = [String(goingTrip.TripSubData_Id)];

    if (returningTrip) {
        goingPrice = (passengersNo * goingTrip.TripPrice_GoCome) / 2;
        returningPrice = (passengersNo * returningTrip.TripPrice_GoCome) / 2;
        totalPrice = goingPrice + returningPrice;
        content_ids.push(String(returningTrip.TripSubData_Id));
        ticket_type = "going_returning_ticket";
        isRoundTrip = true;
    } else {
        goingPrice = passengersNo * goingTrip.TripPrice;
        totalPrice = passengersNo * goingTrip.TripPrice;
        ticket_type = "going_ticket";
    }

    const contents = [
        {
            content_id: CardID,
            content_name: `${goingTrip.TripOfficeFrom_NameE} - ${goingTrip.TripOfficeTo_NameE}`,
            content_type: "product",
            price: goingPrice
        }
    ];

    if (returningTrip) {
        contents.push({
            content_id: CardID,
            content_name: `${returningTrip.TripOfficeFrom_NameE} - ${returningTrip.TripOfficeTo_NameE}`,
            content_type: "product",
            price: returningPrice
        });
    }
    let data = {
        value: totalPrice,
        currency: "EGP"
    };

    if (contents.length === 1) {
        data = {
            ...data,
            ...contents[0]
        };
    } else if (contents.length === 2) {
        data.contents = contents;
    }
    InitiateCheckout(data);
}
