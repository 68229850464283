import store2 from 'store2'

import * as actionTypes from '../Actions/ActionTypes'
import Utils from '../Helpers/Utils';
import i18next from 'i18next';
import { ApiCacher } from '../Api/apiCacher';

let selectedGoingTrip = store2.has('selectedGoingTrip') ? store2.get('selectedGoingTrip') :null 
let selectedReturningTrip = store2.has('selectedReturningTrip') ? store2.get('selectedReturningTrip') : null

let GBCard = store2.has('GBCard') ? store2.get('GBCard')  :  {}
let promoCode = store2.has('promoCode') ? store2.get('promoCode')  :  {}
// getting the hold Seats
let selectedGoingSeats = []
if(GBCard.selectedGoingSeats){
    selectedGoingSeats = GBCard.selectedGoingSeats
}
let selectedReturningSeats = []
if(GBCard.selectedReturningSeats){
    selectedReturningSeats = GBCard.selectedReturningSeats
}

// to prevent checking in each component if the filter has data or not
let filters_structure={
    buses:[],
    'departureTimeSegment':[],
    'arrivalTimeSegment':[],
}
export function TripReducer(state = {
    trips: [],
    currentlyDisplayedTrips: null,
    
    fetchingGoingTrips:false,
    isGoingSuggestion: false,
    isReturnSuggestion: false,
    goingTrips: [],
    displayedGoingTrips: [],
    selectedGoingTrip: selectedGoingTrip,
    goingTripFilters:filters_structure,

    fetchingReturningTrips: false,
    returningTrips: [],
    displayedReturningTrips:[],
    selectedReturningTrip: selectedReturningTrip,
    returningTripsFilters:filters_structure,

    //============ Seats ===================
    holdingSeats:false,

    fetchingGoingSeats:false,
    goingTripSeats: [],
    seatsHoldTime: store2.get("seats_hold_time", 0),
    goingSeatsChanged: false,
    returningSeatsChanged: false,

    // the first random Seats from backEnd, also the seats the user choose from is here
    selectedGoingSeats:selectedGoingSeats,
    // this is the default seats, npt changed unless we hol form the backEnd
    originalGoingSeats:selectedGoingSeats, 

    
    fetchingReturningSeats:false,    
    returningTripSeats: [],
    
    // the first random Seats from backEnd, also the seats the user choose from is here
    selectedReturningSeats:selectedReturningSeats,
    // this is the default seats, npt changed unless we hol form the backEnd
    originalReturningSeats:selectedReturningSeats,

    holdingSeatsError: null,
    
    GBCard:GBCard,
    otherPassenger:{},
    otherPassengerErrors: [],
    otherPassengerSelected: false,

    //============ Cancel Card ===================
    cancel_card_success: false,

    //============ Promo Code ===================
    applyingPromoCode:false,
    promoCodeError:"",
    promoCode: promoCode,


    booking: false,
    amanErrors: [],

    loading: false


}, action) {

    switch (action.type) {
        case actionTypes.FETCHING_GOING_TRIPS: {
            state = {
                ...state,
                fetchingGoingTrips: true,
                goingTrips: [],
                displayedGoingTrips: [],
                goingTripSeats: [],
                goingTripFilters: filters_structure,
            }
            break;
        }

        case actionTypes.GET_GOING_TRIPS_SUCCESS: {
            state = {
                ...state,
                isGoingSuggestion: false,
                fetchingGoingTrips: false,
                goingTrips: action.trips,
                displayedGoingTrips: action.trips,
                isGoingSuggestion: action.isGoingSuggestion
            }
            break;
        }

        case actionTypes.FETCHING_RETURNING_TRIPS: {
            state = {
                ...state,
                isReturnSuggestion: false,
                fetchingReturningTrips: true,
                returningTrips: [],
                displayedReturningTrips: [],
                returningTripSeats: [],
                returningTripsFilters: filters_structure,
            }
            break;
        }
        
        case actionTypes.GET_RETURNING_TRIPS_SUCCESS: {
            state = {
                ...state,
                fetchingReturningTrips: false,
                returningTrips: action.trips,
                displayedReturningTrips: action.trips,
                isReturnSuggestion: action.isReturnSuggestion
            }
            break;
        }

        case actionTypes.SET_GOING_FILTERS: {
            state = {
                ...state,
                goingTripFilters: action.filters,
            }
            break;
        }

        case actionTypes.SET_RETURNING_FILTERS: {
            state = {
                ...state,
                returningTripsFilters: action.filters,
            }
            break;
        }

        case actionTypes.UPDATE_DISPLAYED_GOING_TRIPS: {
            state = {
                ...state, 
                displayedGoingTrips:action.trips
            }
            break;
        }
        
        case actionTypes.UPDATE_DISPLAYED_RETURNING_TRIPS: {
            state = {
                ...state, 
                displayedReturningTrips:action.trips
            }
            break;
        }


        case actionTypes.SELECT_GOING_TRIP: {
            state = {
                ...state, 
                selectedGoingTrip: action.trip   
            }
            break;
        }

        case actionTypes.SELECT_RETURNING_TRIP: {
            state = {
                ...state, 
                selectedReturningTrip: action.trip   
            }
            break;
        }


        case actionTypes.REMOVE_GOING_TRIP: {
            state = {
                ...state, 
                selectedGoingTrip: null,
                selectedReturningTrip: null,

                otherPassenger: {},
                otherPassengerErrors: [],
                otherPassengerSelected: false,
                
                // returningTripsFilters: filters_structure,
                promoCode: {}
            }
            break;
        }

        case actionTypes.REMOVE_RETURNING_TRIP: {
            state = {
                ...state,
                // returningTripsFilters: filters_structure,
                selectedReturningTrip: null
            }
            break;
        }

        case actionTypes.SET_OTHER_PASSENGER_SELECTED: {
            return {
                ...state,
                otherPassengerSelected: action.otherPassengerSelected,
                otherPassenger: {}
            }
        }
        case actionTypes.SET_OTHER_PASSENGER_DATA: {
            state = {
                ...state, 
                otherPassenger: action.otherPassenger,
                otherPassengerErrors: []
            }
            break;
        }

        case actionTypes.OTHER_PASSENGER_ERROR: {
            return {
                ...state,
                otherPassengerErrors: action.errors
            }
        }

        //================= Seats  =======================
        case actionTypes.FETCHING_GOING_SEATS: {
            state = {
                ...state,
                fetchingGoingSeats: true,
            }
            break;
        }

        case actionTypes.SET_GOING_SEATS: {
            state = {
                ...state,
                goingTripSeats: action.seats,
                fetchingGoingSeats: false
            }
            break;
        }


        case actionTypes.FETCHING_RETURNING_SEATS: {
            state = {
                ...state,
                fetchingReturningSeats: true,
            }
            break;
        }

        case actionTypes.SET_RETURNING_SEATS: {
            state = {
                ...state,
                returningTripSeats: action.seats,
                fetchingReturningSeats: false
            }
            break;
        }


        case actionTypes.HOLDING_SEATS: {
            state = {
                ...state,
                holdingSeats:true
            }
            break;
        }

        case actionTypes.SET_HOLDING_SEATS_ERROR: {
            return {
                ...state,
                holdingSeatsError: action.error,
                holdingSeats: false
            }
        }

        case actionTypes.HOLDING_SEATS_SUCCESSFULLY: {
            const isEqualGoing = Utils.compareArrays(state.selectedGoingSeats, state.originalGoingSeats);
            const isEqualReturning = Utils.compareArrays(state.selectedReturningSeats, state.originalReturningSeats);
            state = {
                ...state,
                holdingSeats:false,
                GBCard: action.GBCard,
                seatsHoldTime: action.seatsHoldTime,
                goingSeatsChanged: !isEqualGoing,
                returningSeatsChanged: !isEqualReturning,
                bookingSuccess: false
            }
            break;
        }

        case actionTypes.UPDATE_GBCARD: {
            return {
                ...state,
                GBCard: action.GBCard
            }
        }

        case actionTypes.CLEAR_COUNTDOWN_TIMER: {
            return {
                ...state,
                seatsHoldTime: 0
            }
        }


        case actionTypes.SET_HOLD_GOING_SEATS: {
            state = {
                ...state,
                holdingSeats:false,
                selectedGoingSeats: action.seats,
                originalGoingSeats: action.seats,

            }
            break;
        }

        case actionTypes.SET_HOLD_RETURNING_SEATS: {
            state = {
                ...state,
                holdingSeats:false,
                selectedReturningSeats: action.seats,
                originalReturningSeats: action.seats,

            }
            break;
        }

        case actionTypes.UN_HOLD_GOING_SEATS: {
            state = {
                ...state,
                holdingSeats:false,
                selectedGoingSeats: action.newSeats,

            }
            break;
        }

        case actionTypes.UN_HOLD_RETURNING_SEATS: {
            state = {
                ...state,
                holdingSeats:false,
                selectedReturningSeats: action.newSeats,
            }
            break;
        }

        case actionTypes.HOLD_SINGLE_GOING_SEAT: {
            const isEqual = Utils.compareArrays(action.newSeats, state.originalGoingSeats);
            state = {
                ...state,
                holdingSeats:false,
                selectedGoingSeats: action.newSeats,
                goingSeatsChanged: !isEqual
            }
            break;
        }

        case actionTypes.HOLD_SINGLE_RETURNING_SEAT: {
            const isEqual = Utils.compareArrays(action.newSeats, state.originalReturningSeats);
            state = {
                ...state,
                holdingSeats:false,
                selectedReturningSeats: action.newSeats,
                returningSeatsChanged: !isEqual
            }
            break;
        }

        case actionTypes.RESET_GOING_SEAT: {
            state = {
                ...state,
                holdingSeats:false,
                selectedGoingSeats: state.originalGoingSeats
            }
            break;
        }

        case actionTypes.RESET_RETURNING_SEAT: {
            state = {
                ...state,
                holdingSeats:false,
                selectedReturningSeats: state.originalReturningSeats,
            }
            break;
        }

        case actionTypes.RESET_TRIPS_STATE: {
            store2.remove('GBCard');
            return {
                ...state,
                holdingSeats: false,
                selectedReturningSeats: [],
                selectedGoingSeats: [],
                GBCard: {},
                bookingSuccess: false,
            }
        }

        case actionTypes.UPDATE_SEATS_HOLD_TIME: {
            state = {
                ...state,
                seatsHoldTime: action.seatsHoldTime
            }
            break;
        }

        case actionTypes.HELD_SEATS_TIMEOUT: {
            state = {
                ...state
            }
            break;
        }

        case actionTypes.SET_CC_NUMBER: {
            store2.set("card_number", action.card_number);
            break;
        }

        case actionTypes.BOOKING_AMAN: {
            state = {
                ...state,
                booking: true
            }
            break;
        }

        case actionTypes.BOOKING_RESULT_AMAN: {
            if (action.result.data.success) {
                store2.set("fawry_job_id", action.result.data.result.JobID);
                store2.set("fawry_job_date", new Date());

                state = {
                    ...state,
                    booking: false,
                    bookingSuccess: true
                }

                setTimeout(() => {
                    // ApiCacher.invalidate('history_');
                    action.history.push(`/Thanks/${action.result.data.result.CardID}`)
                    // window.location.replace(`/Thanks/${action.result.data.result.CardID}`)
                }, 700);
            } else {
                console.error("CASH: Error", action.result);
                let error = i18next.t('checkout_page.payment.errors.cash.unhandled_error', 'عملية غير ناجحة');

                if (action.result.msg === 'cash_payment_failed') {
                    error = i18next.t('checkout_page.payment.errors.cash.cash_payment_failed', "عملية غير ناجحة");
                } else 
                if (action.result.msg === 'cash_payment_no_phone') {
                    error = i18next.t('checkout_page.payment.errors.cash.cash_payment_no_phone', 'رقم الهاتف غير مسجل');
                } else 
                if (action.result.msg === 'cash_payment_no_seats') {
                    error = i18next.t('checkout_page.payment.errors.cash.cash_payment_no_seats', 'الكراسي غير متاحة');
                } else 
                if (action.result.msg === 'cash_payment_card_invalid') {
                    error = i18next.t('checkout_page.payment.errors.cash.cash_payment_card_invalid', 'صلاحية الحجز منتهية, رجاءاً اعد عملية الحجز مرة أخرى');
                }

                return {
                    ...state,
                    booking: false,
                    amanErrors: [error]
                }
            }
            break;
        }

        case actionTypes.CANCELLING_TICKET: {
            state = {
                ...state,
                cancelling_ticket: true,
            }
            break;
        }

        case actionTypes.CANCEL_TICKET_RESULT: {
            let { result } = action;

            if(result.isSuccess === true) {
                state = {
                    ...state,
                    cancelling_ticket: false,
                    cancel_ticket_success: "تم الغاء الحجز بنجاح"
                }
            } else if (result.Result === "you Cann't Cancel in this time"){
                state = {
                    ...state,
                    cancelling_ticket: false,
                    cancel_ticket_failed: "لا يمكن الغاء الحجز في هذا الوقت."
                }
            } else {
                state = {
                    ...state,
                    cancelling_ticket: false,
                    cancel_ticket_failed: i18next.t('common.errors.general', 'لقد حدث خطاً ما.')
                }
            }

            break;
        }

        case actionTypes.CANCEL_SUCCESS: {
            return {
                ...state,
                cancel_card_success: true
            }
        }

        case actionTypes.CLEAR_CANCEL: {
            return {
                ...state,
                cancel_card_success: false
            }
        }

        //================= PromoCode  =======================
        
        case actionTypes.APPLYING_PROMO_CODE: {
            state = {
                ...state,
                applyingPromoCode:true,
            }
            break;
        }

        case actionTypes.UNDO_PROMOCODE: {
            return {
                ...state,
                applyingPromoCode:false,
                promoCode: {}
            }
        }

        case actionTypes.APPLYING_PROMO_CODE_ERROR: {
            state = {
                ...state,
                applyingPromoCode:false,
                promoCodeError:action.error
            }
            break;
        }

        case actionTypes.APPLYING_PROMO_CODE_SUCCESS: {
            state = {
                ...state,
                applyingPromoCode:false,
                promoCodeError:"",
                promoCode:action.promoCode
            }
            break;
        }
            
        case actionTypes.CLEAR_PROMOCODE_ERR: {
            return {
                ...state,
                promoCodeError: ""
            }
        }

        case actionTypes.TRIP_LOADER_STATE: {
            return {
                ...state,
                loading: action.loading
            }
        }

        default: return state;        
    }


    return state;
}

export default TripReducer
