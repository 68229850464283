import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Helmet from "react-helmet";
import { corpUrl } from '../../Api/Base';

// Components
import  MainLayoutComponent from '../MainLayoutComponent'
import SearchBoxComponent from '../SearchBox/SearchBoxComponent'
import debug from '../../Helpers/DebugLog';
import { bindActionCreators } from 'redux';
import CorporateApi from '../../Api/CorporateApi';
import { getDestinations, getHomePage } from '../../Actions/CorporateActions';
import { connect } from 'react-redux';
import ImageLoader from '../../Helpers/ImageLoader';

import Slider from "react-slick";
import FeaturedRoutes from '../Corporate/FeaturedRoutes';
import FeaturedDiscount from '../FeaturedDiscount';
import RoutesLinks from '../Corporate/RoutesLinks';
import Utils from '../../Helpers/Utils';


function mapStateToProps(state) {
    return {
        destinations: state.corporate.destinations,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        getDestinations: bindActionCreators(getDestinations, dispatch),
        getHomepage: bindActionCreators(getHomePage, dispatch)
    }
}
class HomeTemp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pages: [],
            posts: [],
            loadedImages: []
        }

    }

    componentWillMount() {
        // this.props.getDestinations();
    }

    componentDidMount() {
        // this.props.getHomepage();
        let baseurl = `${corpUrl}/pages/5`;
        fetch(baseurl)
            .then(response => response.json())
            .then(response => {
                this.setState({
                    pages: response
                })
            })
        
        let blogsurl = `${corpUrl}/posts`;
        fetch(blogsurl)
            .then(response => response.json())
            .then(response => {
                this.setState({
                    posts: response
                })
            })
    }

    render() {
        // Hero ACF
        let HeroTitle = (this.state.pages.acf) ? this.state.pages.acf.title : null;
        let HeroTagline = (this.state.pages.acf) ? this.state.pages.acf.tagline : null;
        let HeroImageURL = (this.state.pages.acf) ? this.state.pages.acf.hero_image : null;
        let HeroImage = {
            backgroundImage: `url(${HeroImageURL})`
        };
        let Payments = (this.state.pages.acf) ? this.state.pages.acf.payments.map((payment, index) => {
            return (
                <li key={index}>
                    <ImageLoader
                        width={165}
                        height={50}
                        imageUrl={payment.image.url}
                        />
                </li>
            )

        }) : null;

        // App
        let AppImage = (this.state.pages.acf) ? this.state.pages.acf.app_image.sizes.large : null;
        let AppTitle = (this.state.pages.acf) ? this.state.pages.acf.app_title : null;
        let AppTagline = (this.state.pages.acf) ? this.state.pages.acf.app_tagline : null;
        let AppSmallTitle = (this.state.pages.acf) ? this.state.pages.acf.app_small_title : null;
        let GooglePlayLink = (this.state.pages.acf) ? this.state.pages.acf.google_play_link : null;
        let AppStoreLink = (this.state.pages.acf) ? this.state.pages.acf.app_store_link : null;



        // Coupoun
        let CouponName = (this.state.pages.acf) ? this.state.pages.acf.coupon_name : null;
        let CouponLabel = (this.state.pages.acf) ? this.state.pages.acf.coupon_label : null;
        let CouponBigTitle = (this.state.pages.acf) ? this.state.pages.acf.coupon_big_title : null;
        let CouponDescription = (this.state.pages.acf) ? this.state.pages.acf.coupon_description : null;
        let CouponCode = (this.state.pages.acf) ? this.state.pages.acf.coupon_code : null;
        let CouponBtnURL = (this.state.pages.acf) ? this.state.pages.acf.button_url : null;
        let CouponBtnName = (this.state.pages.acf) ? this.state.pages.acf.button_name : null;

        // Destinations Images
        var divStyle1 = { backgroundImage: 'url(/images/featured-routes/item-1.jpg)', };
        var divStyle2 = { backgroundImage: 'url(/images/featured-routes/item-2.jpg)', };
        var divStyle3 = { backgroundImage: 'url(/images/featured-routes/item-3.jpg)', };
        var divStyle4 = { backgroundImage: 'url(/images/featured-routes/item-4.jpg)', };
        var divStyle5 = { backgroundImage: 'url(/images/featured-routes/item-5.jpg)', };
        var divStyle6 = { backgroundImage: 'url(/images/featured-routes/item-6.jpg)', };


        // More Blog
        let MoreBlog = this.state.posts.map((posts, index) => {
            let imgUrl = 
            (posts.better_featured_image !== null) &&(
                posts.better_featured_image.source_url
            )
            let NewImg = {
                backgroundImage: `url(${ imgUrl })`
            };
            if(index > 4){

                return (
                    (posts.better_featured_image !== null ) &&(
                            <Link to={`news/${posts.id}`} className={
                                (posts.better_featured_image !== null) ?
                                    "news-widget"
                                    :
                                    "news-widget empty-widdget"
                            } key={index} onClick={() => Utils.scrollTo(0, 0)} >
                                {(posts.better_featured_image !== null) &&(
                                <div className="news-image" style={NewImg}></div>
                                )}
                                <div className="news-content">
                                    {(posts.better_featured_image === null) &&(<i className="material-icons">play_arrow</i>)}
                                    <p>{posts.title.rendered}</p>
                                    <span>{posts.date}</span>
                                </div>
                            </Link>
                    )
                )
            }
        })

        var slidersettings = {
            dots: false,
            infinite: true,
            speed: 500,
            variableWidth: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            rtl: true,
            centerMode: true,
          };
        return (
            <MainLayoutComponent 
                page={this.props.location.pathname}>
                <Helmet key={window.location.href} >
                    <title>Go Bus | V3</title>
                </Helmet>
                <div className="HomePage">
                    <div className="hero" style={HeroImage}>
                        <div className="container">
                            <div className="hero-con">
                                <SearchBoxComponent 
                                    page="home"
                                    location={this.props.location}
                                    history={this.props.history}
                                    match={this.props.match}
                                />
                                <div className="hero-content">
                                    <h1>{HeroTitle}</h1>
                                    <p>{HeroTagline}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page-content">
                        <div className="payment-methods">
                            <h2>طرق دفع متعددة</h2>
                            <p>إختر وسيلة الدفع المناسبة لك و إستمتع بحجز رحلتك القادمة مع جو باص</p>
                            <ul>
                                {Payments}
                            </ul>
                        </div>
                        <div className="mobile-app">
                            <div className="app-con">
                                <div className="app-image">
                                    {/* <ImageLoader 
                                        width={200}
                                        height={100}
                                        imageUrl={AppImage}
                                        /> */}
                                    <img src={AppImage} alt="" />
                                </div>

                                <div className="app-content">
                                    <span>{AppSmallTitle}</span>
                                    <h3>{AppTitle}</h3>
                                    <p>{AppTagline}</p>
                                    <ul>
                                        <li><a href={GooglePlayLink} target="_blank" rel="noopener noreferrer"><img src="images/mobile-app/link-google.png" alt="Google play Logo" /></a></li>
                                        <li><a href={AppStoreLink} target="_blank" rel="noopener noreferrer"><img src="images/mobile-app/link-apple.png" alt="App Store Logo" /></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {
                            CouponCode &&
                                <FeaturedDiscount 
                                    title={CouponBigTitle}
                                    label={CouponLabel}
                                    name={CouponName}
                                    description={CouponDescription}
                                    code={CouponCode}
                                    />
                        }

                        {/* <FeaturedRoutes max={6} /> */}
                        <div className="featrured-routes">
                            <div className="section-title">
                                <h3>خصوص سفر أتوبيسات جوباص</h3>
                                <p>أكثر من  20 خط لنقل الركاب في جمهورية مصر العربية</p>
                            </div>
                            <div className="routes-links">
                                <div className="container">
                                    <div className="links">
                                        <RoutesLinks />
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <FeaturedRoutes />
                        {/* <RoutesLinks /> */}
                        
                        <div className="bus-classes">
                            <div className="container">
                                <div className="section-title">
                                    <h3>فئات جوباص</h3>
                                    <p>استكشف الرحلات الأكثر حجزا في مصر عبر جوباص</p>
                                </div>
                                <div className="bus-classes-con">
                                <Slider {...slidersettings}>
                                <div className="bus-class-item">
                                    <div className="bus-class">
                                        <span className="bus-class-name bus-class-name-elite">إيليت بلس</span>
                                        <ul>
                                            <li>
                                                <img src="images/fet-1.png" alt="image" />
                                            </li>
                                            <li>
                                                <img src="images/fet-2.png" alt="image" />
                                            </li>
                                            <li>
                                                <img src="images/fet-3.png" alt="image" />
                                            </li>
                                            <li>
                                                <img src="images/fet-4.png" alt="image" />
                                            </li>
                                        </ul>
                                        <img src="images/bus-classes/elite-plus/14.jpg" alt="elite plus image" className="class-bus-image" />
                                        <p>متوسط سعر التذكرة</p>
                                        <h4>90 EGP - 120 EGP</h4>
                                    </div>
                                    </div>
                                    <div className="bus-class-item">
                                    <div className="bus-class">
                                        <span className="bus-class-name bus-class-name-classic">كلاسيك</span>
                                        <ul>
                                            <li>
                                                <img src="images/fet-1.png" alt="image" />
                                            </li>
                                            <li>
                                                <img src="images/fet-2.png" alt="image" />
                                            </li>
                                            <li>
                                                <img src="images/fet-3.png" alt="image" />
                                            </li>
                                            <li>
                                                <img src="images/fet-4.png" alt="image" />
                                            </li>
                                        </ul>
                                        <img src="images/bus-classes/classic/1.jpg" alt="classic class image" className="class-bus-image" />
                                        <p>متوسط سعر التذكرة</p>
                                        <h4>90 EGP - 120 EGP</h4>
                                    </div>
                                    </div>
                                    <div className="bus-class-item">
                                    <div className="bus-class">
                                        <span className="bus-class-name bus-class-name-deluxe">دولكس</span>
                                        <ul>
                                            <li>
                                                <img src="images/fet-1.png" alt="image" />
                                            </li>
                                            <li>
                                                <img src="images/fet-2.png" alt="image" />
                                            </li>
                                            <li>
                                                <img src="images/fet-3.png" alt="image" />
                                            </li>
                                            <li>
                                                <img src="images/fet-4.png" alt="image" />
                                            </li>
                                        </ul>
                                        <img src="images/bus-classes/deluxe/1.jpg" alt="deluxe class image" className="class-bus-image" />
                                        <p>متوسط سعر التذكرة</p>
                                        <h4>90 EGP - 120 EGP</h4>
                                    </div>
                                    </div>
                                    <div className="bus-class-item">
                                        <div className="bus-class">
                                            <span className="bus-class-name bus-class-name-business">بيزنس</span>
                                            <ul>
                                                <li>
                                                    <img src="images/fet-1.png" alt="image" />
                                                </li>
                                                <li>
                                                    <img src="images/fet-2.png" alt="image" />
                                                </li>
                                                <li>
                                                    <img src="images/fet-3.png" alt="image" />
                                                </li>
                                                <li>
                                                    <img src="images/fet-4.png" alt="image" />
                                                </li>
                                            </ul>
                                            <img src="images/bus-classes/business/9.jpg" alt="business class image" className="class-bus-image" />
                                            <p>متوسط سعر التذكرة</p>
                                            <h4>90 EGP - 120 EGP</h4>
                                        </div>
                                    </div>
                                    <div className="bus-class-item">
                                        <div className="bus-class">
                                            <span className="bus-class-name bus-class-name-elite">إيليت</span>
                                            <ul>
                                                <li>
                                                    <img src="images/fet-1.png" alt="image" />
                                                </li>
                                                <li>
                                                    <img src="images/fet-2.png" alt="image" />
                                                </li>
                                                <li>
                                                    <img src="images/fet-3.png" alt="image" />
                                                </li>
                                                <li>
                                                    <img src="images/fet-4.png" alt="image" />
                                                </li>
                                            </ul>
                                            <img src="images/bus-classes/elite/1.jpg" alt="elite class image" className="class-bus-image" />
                                            <p>متوسط سعر التذكرة</p>
                                            <h4>90 EGP - 120 EGP</h4>
                                        </div>
                                    </div>
                                    <div className="bus-class-item">
                                        <div className="bus-class">
                                            <span className="bus-class-name bus-class-name-royal">رويال</span>
                                            <ul>
                                                <li>
                                                    <img src="images/fet-1.png" alt="image" />
                                                </li>
                                                <li>
                                                    <img src="images/fet-2.png" alt="image" />
                                                </li>
                                                <li>
                                                    <img src="images/fet-3.png" alt="image" />
                                                </li>
                                                <li>
                                                    <img src="images/fet-4.png" alt="image" />
                                                </li>
                                            </ul>
                                            <img src="images/bus-classes/royal/1.jpg" alt="royal class image" className="class-bus-image" />
                                            <p>متوسط سعر التذكرة</p>
                                            <h4>90 EGP - 120 EGP</h4>
                                        </div>
                                    </div>
                                    <div className="bus-class-item">
                                        <div className="bus-class">
                                            <span className="bus-class-name bus-class-name-royal">رويال بلس</span>
                                            <ul>
                                                <li>
                                                    <img src="images/fet-1.png" alt="image" />
                                                </li>
                                                <li>
                                                    <img src="images/fet-2.png" alt="image" />
                                                </li>
                                                <li>
                                                    <img src="images/fet-3.png" alt="image" />
                                                </li>
                                                <li>
                                                    <img src="images/fet-4.png" alt="image" />
                                                </li>
                                            </ul>
                                            <img src="images/bus-classes/royal-plus/1.jpg" alt="royal-plus class image" className="class-bus-image" />
                                            <p>متوسط سعر التذكرة</p>
                                            <h4>90 EGP - 120 EGP</h4>
                                        </div>
                                    </div>

                                    </Slider>
                                </div>
                            </div>
                        </div>
                        {/* <div className="news new-news">
                            <div className="container new-news-con" style={{display:"flex", flexDirection:"column", alignItems: "center"}}>
                                <div className="section-title">
                                    <h3>أخبار جوباص</h3>
                                    <p>استكشف الرحلات الأكثر حجزا في مصر عبر جوباص</p>
                                </div>
                                <div className="news-list">
                                    <div className="news-list-con">
                                        {MoreBlog}
                                    </div>
                                </div>
                            </div>
                        </div> */}

                    </div>
                </div>
            </MainLayoutComponent>            
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeTemp);