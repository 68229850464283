import React from 'react'
import moment from 'moment';

export function Years(props) {
  let { startYear, yearsToAdd, defaultValue, ...rest } = props;

  let years = [];

  for (let i = 0; i < Number(yearsToAdd); i++) {
    years.push(Number(startYear) + i);
  }

  return (
    <select defaultValue={defaultValue? defaultValue : startYear} {...rest}>
      {years.map(year => {
        return <option key={year.toString()} value={year.toString().slice(2)}>{year}</option>;
      })}
    </select>
  );
}

export function Months(props) {
  let { format, lang, ...rest } = props;

  const months = [];

  for (let i = 0; i < 12; i++) {
    months.push(moment().month(i).locale(lang || 'ar').format(format));
  }

  return (
    <select {...rest}>
      {months.map((m, i) => {
        const value = pad(i+1, 2);
        return <option value={value}>{value} {m}</option>
      })}
    </select>
  )
}

function pad(a, b){
  return(1e15+a+"").slice(-b)
}