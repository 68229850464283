import React from 'react';

import  {tripTimeSegmentName} from '../../../Helpers/TimeParser'
import debug from '../../../Helpers/DebugLog';

export const CheckBoxComponent = (props) => {
    let {title, type ,segments, clickHandler} = props 
    if(segments.length > 0){
        return (

            <ul className="list-filter-sec" >
                <li>
                    <h4>{title}</h4>
                </li>
                {
                    (segments).map((segment,i) => {
                        return (
                            <li key={i}>
                                <input type="checkbox" className="filled-in" id={`${type}-${i}`} onClick={clickHandler.bind(this,segment)} />
                                <label className="checkbox-label" htmlFor={`${type}-${i}`}>{tripTimeSegmentName(segment)} </label>
                            </li>
                        )
                    })
                }
            </ul>       
        )
    }else{
        return(null)
    }
}
