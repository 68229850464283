import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useMediaQuery } from 'react-responsive'

import * as SearchActions from "../../Actions/SearchActions";
import * as TripActions from "../../Actions/TripActions";
import { defaultLoaderDelay } from "../../Api/Base";
import debug from "../../Helpers/DebugLog";
import InputHandler from "../../Helpers/InputHandler";

import i18next from "i18next";
import { isGoMini } from "../../Helpers/Utils";
import { useCheckoutStore } from "../../new-store/checkoutStore";
import Bus from "./AdvancedBus/Bus";

const EditSeatsComponent = (props) => {

	const data = {
		going: {},
		returning: {}
	}
	let previousWidth = window.screen.availWidth;

	const [state, setState] = useState({
		hasError: false,
		errMsg: "",
		viewGoing: true,
		viewReturning: false,
		eliteDDHeight: window.screen.availWidth > 1111 ? -240 : -140
	});

	const { seats, goingTripInfo, returnTripInfo, localUpdateSeats } = useCheckoutStore();

	const isMobile = useMediaQuery({ query: '(max-width: 769px)' });

	useEffect(() => {
		InputHandler.listen(handleKeyDown);

		if ((data.going.trip && data.going.trip.TripServKind_NameA === "Elite D.D") ||
			(data.returning.trip && data.returning.trip.TripServKind_NameA === "Elite D.D")) {
			window.addEventListener('resize', handleResize);
		}

		return () => {
			window.removeEventListener('resize', handleResize);
		}
	}, [])

	const handleResize = () => {
		if (previousWidth > 1111 && window.screen.availWidth < 1111) {
			setState({ eliteDDHeight: -140 })
		} else
			if (previousWidth < 1111 && window.screen.availWidth > 1111) {
				setState({ eliteDDHeight: -215 });
			}

		previousWidth = window.screen.availWidth;
	}

	const handleKeyDown = key => {
		if (!props.active) return;
		if (key === InputHandler.KEYS.Escape) {
			props.close();
		}
	};

	const handleClickAway = () => {
		if (!props.active) return;
		props.tripActions.resetSeats();
		props.close();
	};

	const switchView = () => {
		setState({
			viewGoing: !state.viewGoing,
			viewReturning: !state.viewReturning
		});
	};

	const holdSeat = (tripType, seatId) => {
		if (props.viewMode === "view") {
			return;
		}

		let holdSeats
		// let originalSeats;
		// let tripType =  props.tripType;

		debug("trip type", tripType);
		if (tripType === "going") {
			holdSeats = seats.modifiedGoingSeats.length? seats.modifiedGoingSeats: [...seats.selectedGoingSeats];
			// originalSeats = seats.selectedGoingSeats;
		} else {
			holdSeats = seats.modifiedReturnSeats.length? seats.modifiedReturnSeats: [...seats.selectedReturnSeats];
			// originalSeats = seats.selectedReturnSeats;
		}

		let newSeats;

		if (holdSeats.length >= props.searchStore.passengersNo) {
			newSeats = [...holdSeats.slice(1, holdSeats.length), seatId];
		} else {
			holdSeats.push(seatId);
			newSeats = [...holdSeats];
		}

		localUpdateSeats(newSeats, tripType);

		// update the Store
		// props.tripActions.holdSingleSeat(tripType, newSeats);
		// const context = this;
		// process.nextTick(() => {
		// 	context.updateSeats(props.tripType);
		// })
	};

	const unHoldSeat = (tripType, seatId) => {
		if (props.viewMode === "view") {
			return;
		}

		let holdSeats, originalSeats;

		if (tripType === "going") {
			holdSeats = props.tripsStore.selectedGoingSeats;
			originalSeats = props.tripsStore.originalGoingSeats;
		} else {
			holdSeats = props.tripsStore.selectedReturningSeats;
			originalSeats = props.tripsStore.originalReturningSeats;
		}

		if (holdSeats.length < 1) {
			return;
		}

		// removing the Seat from the array
		let newSeats = holdSeats.filter(s => s !== seatId);

		// update the Store
		props.tripActions.unHoldSeat(tripType, newSeats);
	}

	const readySeatsData = (type) => {
		if (type === "going") {
			data[type] = {
				type,
				trip: goingTripInfo,
				seats: seats.goingSeats,
				originalSeats: seats.selectedGoingSeats,
				seatsChanged: seats.isGoingModified
			};
		} else
			if (type === "returning") {
				data[type] = {
					type,
					trip: returnTripInfo,
					seats: seats.returnSeats,
					originalSeats: seats.selectedReturnSeats,
					seatsChanged: seats.isReturnModified
				};
			} else {
				console.error(`SeatsLayout Error: got invalid type ${type}`)
			}

	};

	const handleHoldResults = (result, tripType, goingTrip, returningTrip) => {
		if (result === "callback") return;
		if (result !== true) {
			props.tripActions.setLoading(false);
			let error = i18next.t('search_page.errors.hold_fail_no_seats', 'للأسف! لا توجد كراسي متاحة، قد تم حجز الأتوبيس بالكامل');

			if (result === "user Token Not Found") {
				error = i18next.t('search_page.errors.hold_fail_relogin', 'لم نتمكن من حجز كراسي, رجاءاً حاول تسجيل الدخول مرة أخرى');
			}

			if (result === "Going Trip Time Error") {
				error = i18next.t('search_page.errors.hold_fail_invalid_date', 'لم نتمكن من حجز كراسي, موعد الرحلة غير صحيح. رجاءاً اعد البحث مرة اخرى');
			}

			if (result === 'no_seats_available') {
				error = i18next.t('search_page.errors.hold_fail_no_seats', 'للأسف! لا توجد كراسي متاحة، قد تم حجز الأتوبيس بالكامل');
			}

			if (result === "Go goMini Error:المقاعد محجوزة") {
				error = i18next.t('search_page.errors.hold_fail_no_seats', 'للأسف! لا توجد كراسي متاحة، قد تم حجز الأتوبيس بالكامل');
			}

			return alert(error);
		}

		if (result && result.length > 0) {
			props.tripActions.setLoading(false);
			return setState({
				hasError: true,
				errMsg: "Error"
			})
		}
		if (result === "Some Seats not available now") {
			props.tripActions.setLoading(false);
			return setState({
				hasError: true,
				errMsg: "بعض الكراسي محجوزة, رجاءاً اختر كراسي أخرى"
			});
		}

		if (result === "Going Trip Time Error") {
			props.tripActions.setLoading(false);
			return setState({
				hasError: true,
				errMsg: "خطأ في وقت الرحلة, رجاءاً اعد الحجز مرة اخرى"
			});
		}
		setTimeout(() => {
			props.tripActions.setLoading(false);
			props.close();

			if (tripType === "going") {
				props.tripActions.getGoingTripSeats(
					goingTrip.TripSubData_Id
				);
			} else {
				props.tripActions.getReturningSeats(
					returningTrip.TripSubData_Id
				);
			}
		}, defaultLoaderDelay);
	}

	const updateSeats = (tripType) => {
		props.tripActions.setLoading(true);
		let tripsStore = props.tripsStore;
		// check if the number of passengers is right
		if (
			tripsStore.selectedGoingSeats.length !==
			tripsStore.originalGoingSeats.length ||
			tripsStore.selectedReturningSeats.length !==
			tripsStore.originalReturningSeats.length
		) {
			// console.log('NOT EQUAL SEATS')
			setState({
				hasError: true,
				errMsg: "عدد الكراسي المحدد غير كافي"
			});
			return;
		}

		let tripSeats = {
			userID: props.userStore.user.UserID
		};
		// ================ Going trip Data ==============
		let goingTrip = tripsStore.selectedGoingTrip;
		tripSeats.goTripSubData_Id = goingTrip.TripSubData_Id;
		// converting array into comma separated string
		tripSeats.goSeats = tripsStore.selectedGoingSeats.join(",");

		// ================ Retuning trip Data ==============
		let returningTrip = tripsStore.selectedReturningTrip;
		if (returningTrip != null) {
			// gettingRandomSeats
			// let availableReturningSeats = [];
			tripSeats.comeTripSubData_Id = returningTrip.TripSubData_Id;
			// converting array into comma separated string
			tripSeats.comeSeats = tripsStore.selectedReturningSeats.join(",");
		}

		props.tripActions
			.holdSeats(tripSeats, null, (res) => handleHoldResults(res, tripType, goingTrip, returningTrip))
			.then((res) => handleHoldResults(res, tripType, goingTrip, returningTrip))
			.catch(err => {
				props.tripActions.setLoading(false);
			});
	};

	const { lang } = props.locale;

	if (props.active && !state.hasError) {
		readySeatsData(props.tripType);
	}

	let tripTypeClass = "";
	if (!data.returning.trip) tripTypeClass = "returning";

	let PopupChangeClasses = props.active
		? `popup popup-changeseats ${tripTypeClass} active`
		: `popup popup-changeseats ${tripTypeClass}`;


	const renderEditSeats = (data, base_seats, _, tripType, lang) => {
		const fromOfficeName =
			lang === "ar" ? "TripOfficeFrom_NameA" : "TripOfficeFrom_NameE";
		const toOfficeName =
			lang === "ar" ? "TripOfficeTo_NameA" : "TripOfficeTo_NameE";

		const modifiedSeatsKey = `modified${tripType === 'going'? 'Going': 'Return'}Seats`;
		const selectedSeatsKey = `selected${tripType === 'going'? 'Going': 'Return'}Seats`;

		const held_seats = seats[modifiedSeatsKey].length? seats[modifiedSeatsKey]: seats[selectedSeatsKey];

		return data.seats && data.seats.length > 0 && (
			<Bus
				classes={{
					dlxPlus61: data.trip.TripServKind_NameA === "Deluxe Plus" && data.trip.TotalChairs == 61,
					eliteDD: data.trip.TripServKind_NameA === "Elite D.D"
				}}
				isMobile={isMobile}
				loading={seats.fetching || seats.holding}
				additionalHeight={() => {
					const serviceName = data.trip.TripServKind_NameA;
					if (serviceName === "Royal Plus") {
                        return 125;
                    } else if (isGoMini({ serviceName })) {
                        return 265;
                    } else if (serviceName === "Aero") {
                        return 60;
                    } else if (serviceName === "Elite D.D") {
                        return state.eliteDDHeight;
                    } else if (serviceName === "Elite Plus +") {
                        return 0;
                    } else if (
                        serviceName === "Deluxe Plus" &&
                        data.trip.TotalChairs == 61
                    ) {
                        return -100;
                    } else {
                        return 0;
                    }
				}}
				seats={data.seats}
				baseSeats={base_seats}
				heldSeats={held_seats}
				holdSeat={id => {
					holdSeat(tripType, id);
				}}
				unHoldSeat={id => {
					unHoldSeat(tripType, id);
				}}
			/>
		)
	}

	if (data.going.trip) {
		return renderEditSeats(
			data.going,
			seats.selectedGoingSeats,
			seats.selectedGoingSeats,
			"going",
			lang
		)
	} else {
		if (data.returning.trip) {
			return renderEditSeats(
				data.returning,
				seats.selectedGoingSeats,
				seats.selectedReturnSeats,
				"returning",
				lang
			)
		} else {
			return null;
		}
	}
}

function mapStateToProps(store) {
	return {
		locale: store.locale,
		userStore: store.user,
		searchStore: store.search,
		tripsStore: {
            selectedGoingSeats: store.trips.selectedGoingSeats,
            originalGoingSeats: store.trips.originalGoingSeats,
            selectedReturningSeats: store.trips.selectedReturningSeats,
            originalReturningSeats: store.trips.originalReturningSeats,
            selectedGoingTrip: store.trips.selectedGoingTrip,
            selectedReturningTrip: store.trips.selectedReturningTrip,
        }
	};
}

function mapDispatchToProps(dispatch) {
	return {
		searchActions: bindActionCreators(SearchActions, dispatch),
		tripActions: bindActionCreators(TripActions, dispatch)
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(EditSeatsComponent);
