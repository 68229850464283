import React, { Component } from "react";
import {
  nativeDateToTimestamp,
  FullDate
} from "../../Helpers/TimeParser";
import DayPicker, { DateUtils } from "react-day-picker";
import moment from "moment";
import Utils from "../../Helpers/Utils";
import Dialog from '@material-ui/core/Dialog';

import { ClickAwayListener } from "@material-ui/core"
import { Trans } from 'react-i18next';
import { connect } from 'react-redux'
import i18next from 'i18next';
import DiscountLabel from "../Label/DiscountLabel";


class DatePicker extends Component {
  constructor(props) {
    super(props);

    this.styles = {
      container: {
        display: "flex"
      },
      selectedPicker: {
        border: "1px solid black"
      }
    };

    this.pickers = {
      departure: 0,
      return: 1
    };

    this.types = {
      oneway: "oneWay" || "going",
      twoway: "round"
    };

    this.state = {
      from: props.from.toDate(),
      to: null,
      enteredTo: props.type !== this.types.twoway? null : props.to ? props.to.toDate() : null, // Keep track of the last day for mouseEnter.
      type: this.types.oneway,
      showPicker: false,
      selectedPicker: null,
      error: false,
      errorMsg: null
    };

    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleDayMouseEnter = this.handleDayMouseEnter.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
  }

  componentDidMount() {
    // document.addEventListener("mousedown", this.handleMouseDown);
  }

  componentWillMount() {
    // document.removeEventListener("mousedown", this.handleMouseDown);
  }

  handleMouseDown(event) {
    let pickerNode = document.querySelector('.DayPicker');

    let isPicker = false;
    let isDepartureBtn0 = false;
    let isDepartureBtn1 = false;
    let isReturnBtn0 = false;
    let isReturnBtn1 = false;

    if(pickerNode) {
      let picker = pickerNode.getBoundingClientRect();
      isPicker = Utils.isPointInRect(event.x, event.y, picker.x, picker.y, picker.width, picker.height);
    }

    if(this.departureNode0) {
      let departureBtn0 = this.departureNode0.getBoundingClientRect();
      isDepartureBtn0 = Utils.isPointInRect(event.x, event.y, departureBtn0.x, departureBtn0.y, departureBtn0.width, departureBtn0.height);
    }

    if(this.departureNode1) {
      let departureBtn1 = this.departureNode1.getBoundingClientRect();
      isDepartureBtn1 = Utils.isPointInRect(event.x, event.y, departureBtn1.x, departureBtn1.y, departureBtn1.width, departureBtn1.height);
    }

    if(this.returnNode0) {
      let returnBtn0 = this.returnNode0.getBoundingClientRect();
      isReturnBtn0 = Utils.isPointInRect(event.x, event.y, returnBtn0.x, returnBtn0.y, returnBtn0.width, returnBtn0.height);
    }

    if(this.returnNode1) {
      let returnBtn1 = this.returnNode1.getBoundingClientRect();
      isReturnBtn1 = Utils.isPointInRect(event.x, event.y, returnBtn1.x, returnBtn1.y, returnBtn1.width, returnBtn1.height);
    }

    if(!isPicker &&
        !isDepartureBtn0 && !isReturnBtn0 &&
        !isDepartureBtn1 && !isReturnBtn1) {
      this.close();
    }
  }

  close = () => {
    if(this.state.showPicker === true) {
      this.setState({
        selectedPicker: null,
        showPicker: false
      });
    }
  }

  isSelectingFirstDay(from, to, day) {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  }

  handleDayClick(day, state) {
    // const { from, to } = this.state;
    // if (from && to && day >= from && day <= to) {
    //   return;
    // }

    if (this.state.selectedPicker === this.pickers.departure) {
      this.props.changeFrom(day, state);

      if (this.props.type === this.types.oneway) {
        this.setState({
          enteredTo: null,
          showPicker: false,
          selectedPicker: null
        });
      } else {
        this.setState({
          enteredTo: null,
          selectedPicker: this.pickers.return
        });
      }
    } else if (this.state.selectedPicker === this.pickers.return) {
      if (moment(day).startOf('day').isBefore(this.props.from.startOf('day'))) return;
      this.props.changeTo(day, state);
      this.setState({
        enteredTo: day,
        showPicker: false,
        selectedPicker: null
      });
    }
  }

  handleDayMouseEnter(day) {
    if (this.state.selectedPicker === this.pickers.departure) return;
    this.setState({
      enteredTo: day
    });
  }

  selectedPicker = picker => {
    if (this.state.selectedPicker === picker) return;
    this.setState({
      selectedPicker: picker,
      showPicker: true
    });
  };

  changeType = type => {
    if (type === this.types.oneway) {
      this.props.changeType(this.types.oneway);
      this.setState({
        selectedPicker: null,
        showPicker: false,
        enteredTo: null
      });
    } else {
      this.props.changeType(this.types.twoway);
      this.setState({
        selectedPicker: this.pickers.return,
        showPicker: true
      });
    }
  };

  invalidDate = error => {
    this.setState({
      error: true,
      errorMsg: i18next.t('common.errors.invalid_date', "التاريخ المختار غير صحيح")
    })

    // Error states
    // switch(error) {
    //   case 'below_min':
        
    //     break;
    //   case 'above_max':
        
    //     break;
    // }
  }

  clearError = () => {
    this.setState({
      error: false,
      errorMsg: null
    })
  }

  renderSearchDatePicker = ({from, to, changeFrom, changeTo, type}) => {
    const fromDateFormated = FullDate(from, this.props.locale.lang);
    const toDateFormated = FullDate((to || from), this.props.locale.lang);

    const fromDateConstraints = {
      min: moment().locale('en').format("YYYY-MM-DD"),
      max: moment().add(2, "years").locale('en').format("YYYY-MM-DD")
    }

    const toDateConstraints = {
      min: moment(from).add(1, 'days').locale('en').format("YYYY-MM-DD"),
      max: moment().add(2, "years").locale('en').format("YYYY-MM-DD")
    }

    return (
      <React.Fragment>
        <div className="col date small">
          <div className="input-field">
            <label>
              <i className="material-icons">date_range</i> <Trans i18nKey="searchbox.from_date">تاريخ السفر</Trans>
            </label>
            <input  style={
                      this.state.selectedPicker === this.pickers.departure
                        ? this.styles.selectedPicker
                        : null
                    }
              type="text"
              ref={ref => this.departureNode1 = ref}
              value={fromDateFormated}
              onClick={() => this.selectedPicker(this.pickers.departure)}
              onChange={() => {}}
            />
            <input
              type="date"
              min={fromDateConstraints.min}
              max={fromDateConstraints.max}
              value={moment(from).locale('en').format("YYYY-MM-DD")}
              onChange={e => {
                nativeDateToTimestamp(e, fromDateConstraints, changeFrom, this.invalidDate)
              }}
            />
          </div>
        </div>

        <div className="col date small">
          <div className="input-field date">
            {type === this.types.oneway ? (
              <React.Fragment>
                <div style={{ height: "19px", marginBottom: "5px" }} />
                <p
                  onClick={this.changeType.bind(this, this.types.twoway)}
                  className="msgSelect"
                >
                  <Trans i18nKey="searchbox.select_return_date">اختر ميعاد الرجوع</Trans> 
                </p>
                <DiscountLabel/>
              </React.Fragment>
              
            ) : (
              <div className="col date small">
                <div className="input-field">
                  <label>
                    <i className="material-icons">date_range</i> <Trans i18nKey="searchbox.to_date">تاريخ العودة</Trans>
                  </label>
                  <input
                    type="text"
                    value={toDateFormated}
                    ref={ref => this.returnNode1 = ref}
                    style={
                      this.state.selectedPicker === this.pickers.return
                        ? this.styles.selectedPicker
                        : null
                    }
                    onClick={() => this.selectedPicker(this.pickers.return)}
                    onChange={() => {}}
                  />
                  <input
                    type="date"
                    min={toDateConstraints.min}
                    max={toDateConstraints.max}
                    value={moment(to || from).locale('en').format("YYYY-MM-DD")}
                    onChange={e =>
                      nativeDateToTimestamp(e, toDateConstraints, changeTo, this.invalidDate)
                    }
                    />
                  <i
                    onClick={this.changeType.bind(this, this.types.oneway)}
                    className="material-icons closeBtn"
                  >
                    close
                  </i>
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderHomeDatePicker = ({from, to, changeFrom, changeTo, type}) => {
    const fromDateFormated = FullDate(from, this.props.locale.lang);
    const toDateFormated = FullDate((to || from), this.props.locale.lang);

    const fromDateConstraints = {
      min: moment().locale('en').format("YYYY-MM-DD"),
      max: moment().add(2, "years").locale('en').format("YYYY-MM-DD")
    }

    const toDateConstraints = {
      min: moment(from).add(1, 'days').locale('en').format("YYYY-MM-DD"),
      max: moment().add(2, "years").locale('en').format("YYYY-MM-DD")
    }
    
    return (
      <React.Fragment>
        <div className="trip-date">
          <label>
            <i className="material-icons">date_range</i> <Trans i18nKey="searchbox.from_date">تاريخ السفر</Trans>
          </label>
          <div
            className="input datepicker tarvel-date"
            ref={ref => this.departureNode0 = ref}
            style={
              this.state.selectedPicker === this.pickers.departure
                ? this.styles.selectedPicker
                : null
            }
          >
            <input
              type="text"
              value={fromDateFormated}
              onClick={() => this.selectedPicker(this.pickers.departure)}
              onChange={() => {}}
            />
            <input
              type="date"
              min={fromDateConstraints.min}
              max={fromDateConstraints.max}
              value={moment(from).locale('en').format("YYYY-MM-DD")}
              onChange={e => nativeDateToTimestamp(e, fromDateConstraints, changeFrom, this.invalidDate)}
            />
          </div>
        </div>

        <div className="trip-date trip-back">
          {type === this.types.oneway ? (
            <div className="change-trip-link">
              <p
                onClick={() => {
                  this.changeType(this.types.twoway)
                  setTimeout(() => {
                    let dp = document.querySelector('#arrival-dp')
                    if(dp) dp.click();
                  }, 200);
                }}
                className="msgSelect "
              >
                <Trans i18nKey="searchbox.select_return_date">أضف تاريخ العودة</Trans>
               

              </p>
              <DiscountLabel/>
            </div>
            
          ) : (
            <React.Fragment>
              <label>
                <i className="material-icons">date_range</i> <Trans i18nKey="searchbox.to_date">تاريخ العودة</Trans>
              </label>
              <div
                className="input datepicker tarvel-date"
                ref={ref => this.returnNode0 = ref}
                style={
                  this.state.selectedPicker === this.pickers.return
                    ? this.styles.selectedPicker
                    : null
                }
              >
                <input
                  type="text"
                  value={toDateFormated}
                  onClick={() => this.selectedPicker(this.pickers.return)}
                  onChange={() => {}}
                />
                <input
                  type="date"
                  id="arrival-dp"
                  min={toDateConstraints.min}
                  max={toDateConstraints.max}
                  value={moment(to || from).locale('en').format("YYYY-MM-DD")}
                  onChange={e => nativeDateToTimestamp(e, toDateConstraints, changeTo, this.invalidDate)}
                />
                <i
                  onClick={this.changeType.bind(this, this.types.oneway)}
                  className="material-icons closeBtn"
                >
                  close
                </i>
              </div>
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { /*from, to,*/ enteredTo } = this.state;

    let { from, to, type, changeFrom, changeTo, page } = this.props;

    const modifiers = { start: from.toDate(), end: enteredTo };
    const selectedDays = [
      from.toDate(),
      { from: from.toDate(), to: enteredTo }
    ];


    return (
      <React.Fragment>
        <Dialog 
          open={this.state.error} 
          onClose={this.clearError}>
          <span>{this.state.errorMsg}</span>
        </Dialog>
        {page === "search"
          ? this.renderSearchDatePicker({from, to, changeFrom, changeTo, type})
          : this.renderHomeDatePicker({from, to, changeFrom, changeTo, type})}
        {this.state.showPicker ? (
          <ClickAwayListener onClickAway={this.close}>
            <DayPicker
              initialMonth={moment().toDate()}
              month={from.toDate()}
              selectedDays={selectedDays}
              disabledDays={[
                {
                  before:
                    this.state.selectedPicker === this.pickers.departure
                      ? moment().toDate()
                      : from.toDate(),
                  after: moment()
                    .add(2, "years")
                    .toDate()
                }
              ]}
              numberOfMonths={type === this.types.twoway? 2 : 1}
              modifiers={modifiers}
              onDayClick={this.handleDayClick}
              onDayMouseEnter={this.handleDayMouseEnter}
            />
          </ClickAwayListener>
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: state.locale
})


export default connect(mapStateToProps)(DatePicker);