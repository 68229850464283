import { appActions as actions } from '../Reducers/AppReducer';

export function setOnline(state) {
  if (state === false) {
    document.body.classList.add('topmsg');
  } else {
    document.body.classList.remove('topmsg');
  }
  return dispatch => {
    dispatch({type: actions.setOnline, data: state});
  }
}
