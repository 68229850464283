import * as actionTypes from '../Actions/ActionTypes'
import moment from 'moment';
import qs from 'query-string';
import store2 from "store2";
import { useCheckoutStore } from '../new-store/checkoutStore';
import Store from '../Store';
import { resetTripsState } from '../Actions/TripActions';

const dateFormat = "DD/MM/YYYY";
let urlQueryParams = qs.parse(window.location.search)
if (urlQueryParams.departureDate === 'tomorrow') {
    urlQueryParams.departureDate = moment().add(1, 'day');
}

let departureDate = moment(urlQueryParams.departureDate, dateFormat).isValid() ? moment(urlQueryParams.departureDate, dateFormat) : moment()
let arrivalDate = moment(urlQueryParams.arrivalDate, dateFormat).isValid() ? moment(urlQueryParams.arrivalDate, dateFormat) : moment().add(1, 'days')

if (departureDate.isSame(arrivalDate, 'day')) {
    arrivalDate.add(1, 'day');
}

let tripType = 'oneWay'
if (moment(urlQueryParams.departureDate, dateFormat).isValid() && moment(urlQueryParams.arrivalDate, dateFormat).isValid()){
    tripType = 'round'
}

let default_search = {
    transportationType: 'bus',
    tripType: tripType,//tripType, // [round, oneWay, returning ]
    departureStation: null,
    departureStationId: urlQueryParams.departureStation || 8 ,
    arrivalStation:null,
    arrivalStationId: urlQueryParams.arrivalStation || 1,
    departureDate: departureDate,
    arrivalDate: arrivalDate,
    passengersNo: urlQueryParams.passengersNo || 1,
    bus_stations:[],    
    boats_stations: [],
    stations:[],
};

// let store = store2.get("search_store");
// let stored_search = store;

// if(!store) stored_search = default_search

store2.remove("search_store");
let store = null;
let stored_search = default_search;



stored_search.departureStationId = Number(urlQueryParams.departureStation || stored_search.departureStationId);
stored_search.arrivalStationId = Number(urlQueryParams.arrivalStation || stored_search.arrivalStationId);

// fix NaN parsing
if (isNaN(stored_search.departureStationId) || stored_search.departureStationId === 0) {
    stored_search.departureStationId = 8;
}

if (isNaN(stored_search.arrivalStationId) || stored_search.arrivalStationId === 0) {
    stored_search.arrivalStationId = 1;
}

// set departure and arrival stations
stored_search.departureStation = stored_search.stations.find(s => Number(s.Code) === Number(stored_search.departureStationId));
stored_search.arrivalStation = stored_search.stations.find(s => Number(s.Code) === Number(stored_search.arrivalStationId));

// if departure date provided in url
if(urlQueryParams.departureDate) {
    departureDate = moment(urlQueryParams.departureDate, dateFormat);
    
// default or stored departure date
} else {
    departureDate = moment(stored_search.departureDate)
}

stored_search.departureDate = departureDate
        .isBefore(moment())
            ? moment()
            : departureDate;

// if arrival date provided in url
if(urlQueryParams.arrivalDate) {
    arrivalDate = moment(urlQueryParams.arrivalDate, dateFormat);
// default or stored arrival date
} else {
    arrivalDate = moment(stored_search.arrivalDate);
}

stored_search.arrivalDate = arrivalDate;

// fallback to default store if the dates are invalid
if(!stored_search.departureDate.isValid()) {
    store2.remove("search_store");
    stored_search = default_search
}

// if there was stored search update trip type
if(store) {
    stored_search.tripType = stored_search.arrivalDate.isValid()? "round" : "oneWay";
}
export function SearchReducer(state = stored_search, action) {
    switch (action.type) {
        case actionTypes.FETCHING_DATA: {
            state = {
                ...state,
                fetching: true,
            }
            break;
        }
        
        case actionTypes.CHANGE_TRANSPORTATION_TYPE: {
            state = {
                ...state,
                transportationType: action.transportation
            }
            break;
        }

        case actionTypes.UPDATE_SEARCH_PARAMS: {
            let arrivalDate = action.searchParams.arrivalDate;
            let departureDate = action.searchParams.departureDate;

            if(arrivalDate) {
                if(arrivalDate < departureDate && arrivalDate.day() < departureDate.day()) {
                    arrivalDate = moment(departureDate).add(1, 'days');
                }
            }

            state = {
                ...state,
                transportationType: action.searchParams.transportationType || 'bus',
                tripType: action.searchParams.tripType || 'oneWay',
                departureStationId: action.searchParams.departureStation  ,
                arrivalStationId: action.searchParams.arrivalStation,
                departureStation: state.stations.find(s => Number(s.Code) === Number(action.searchParams.departureStation)),
                arrivalStation: state.stations.find(s => Number(s.Code) === Number(action.searchParams.arrivalStation)),
                departureDate: departureDate,
                arrivalDate: arrivalDate,
                passengersNo: action.searchParams.passengersNo || 1,
            }

            useCheckoutStore.setState({ passengersNo: state.passengersNo });
            useCheckoutStore.getState().resetCart();

            break;
        }

        case actionTypes.GET_STATIONS:{
            if(!action.stations.length){
                delete state.departureStationId;
                delete state.departureStation;
                delete state.arrivalStation;
                delete state.arrivalStationId;
            }
            state = {...state,
                stations: action.stations 
            }
            break;
        }

        case actionTypes.SET_STATION_IDS: {
            state = {
                ...state,
                departureStationId: Number(action.departureId),
                arrivalStationId: Number(action.arrivalId),
                departureStation: state.stations.find(s => s.Code === Number(action.departureId)),
                arrivalStation: state.stations.find(s => s.Code === Number(action.arrivalId))
            }

            break;
        }

        case actionTypes.SET_DEFAULT_DEPARTURE_STATION: {
            state = {
                ...state,
                departureStation: state.stations.find(s => s.Code === state.departureStationId),
            }            
            break;
        }

        case actionTypes.SET_DEFAULT_ARRIVAL_STATION: {
            state = {
                ...state,
                arrivalStation: state.stations.find(s => s.Code === state.arrivalStationId),
            }
            break;
        }

        case actionTypes.SET_DATE: {
            return {
                ...state,
                ...action.data
            }
        }

        default: return state;
    }


    return state;
}

export default SearchReducer
