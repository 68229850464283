import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
	tripFullDateNoYear, tripTime,
	tripTimeSegment
} from "../../Helpers/TimeParser";

// Actions
import * as SearchActions from "../../Actions/SearchActions";
import * as TripActions from "../../Actions/TripActions";

import { Trans } from "react-i18next";
import { busClassNameMap, getClassi18nKey } from "../../appConfig";
import { useCheckoutStore } from "../../new-store/checkoutStore";
import TripClassComponent from "../Search/Trip/TripClassComponent";
import EditSeatsComponent from './EditSeatsComponent';
import _ from "lodash";

function mapStateToProps(store) {
	return {
		locale: store.locale,
		userStore: store.user,
		searchStore: store.search,
		tripsStore: {
            selectedGoingSeats: store.trips.selectedGoingSeats,
            originalGoingSeats: store.trips.originalGoingSeats,
            selectedReturningSeats: store.trips.selectedReturningSeats,
            originalReturningSeats: store.trips.originalReturningSeats,
            selectedGoingTrip: store.trips.selectedGoingTrip,
            selectedReturningTrip: store.trips.selectedReturningTrip,
            loading: store.trips.loading,
        }
	};
}

function mapDispatchToProps(dispatch) {
	return {
		searchActions: bindActionCreators(SearchActions, dispatch),
		tripActions: bindActionCreators(TripActions, dispatch)
	};
}

const TripCardComponent = (props) => {
	const [state, setState] = useState({ isActiveEditSeats: false });
	const { seats, goingTripInfo, returnTripInfo } = useCheckoutStore();

	const toggleEditSeats = () => {
		setState({ isActiveEditSeats: !state.isActiveEditSeats });
	};

	const updateSeats = () => {
		let tripsStore = props.tripsStore;
		// check if the number of passengers is right
		if (
			tripsStore.selectedGoingSeats.length !==
			tripsStore.originalGoingSeats.length ||
			tripsStore.selectedReturningSeats.length !==
			tripsStore.originalReturningSeats.length
		) {
			// console.log("NOT EQUAL SEATS");
			setState({
				hasError: true,
				errMsg: "seats are not equal"
			});
			return;
		}

		let tripSeats = {
			userID: props.userStore.user.UserID
		};
		// ================ Going trip Data ==============
		let goingTrip = tripsStore.selectedGoingTrip;
		tripSeats.goTripSubData_Id = goingTrip.TripSubData_Id;
		// converting array into comma separated string
		tripSeats.goSeats = tripsStore.selectedGoingSeats.join(", ");

		// ================ Retuning trip Data ==============
		let returningTrip = tripsStore.selectedReturningTrip;
		if (returningTrip != null) {
			// gettingRandomSeats
			tripSeats.comeTripSubData_Id = returningTrip.TripSubData_Id;
			// converting array into comma separated string
			tripSeats.comeSeats = tripsStore.selectedReturningSeats.join(", ");
		}

		props.tripActions
			.holdSeats(tripSeats)
			.then(() => {
				props.close();
			})
			.catch(err => {
			});
	};

	let trip, originalGoingSeats, originalReturningSeats;
	if (props.tripType === "returning") {
		trip = returnTripInfo;
		originalReturningSeats = seats.selectedReturnSeats;
	} else {
		trip = goingTripInfo;
		originalGoingSeats = seats.selectedReturnSeats;
	}

	// return if selected going trip is removed/empty
	if (!trip) return null;

	const { lang } = props.locale;

	const officeFromKey =
		lang === "ar" ? "TripOfficeFrom_NameA" : "TripOfficeFrom_NameE";
	const officeToKey =
		lang === "ar" ? "TripOfficeTo_NameA" : "TripOfficeTo_NameE";

	const busWidgetClasses = props.tripsStore.loading ? "bus-widget component-loader" : "bus-widget";
	const busClassName = _.get(
        busClassNameMap,
        trip.TripServKind_NameE.trim().toLowerCase(),
        trip.TripServKind_NameE
    );

	return (
		<div className={busWidgetClasses} style={{ minHeight: '240px' }}>
			<div className="bus-info">
				{state.hasError && (
					<div className="err-msg">
						<p> {state.errMsg}</p>
					</div>
				)}
				<ul>
					<li> {tripFullDateNoYear(trip.TripDateTime, lang)}</li>
					<li>
						<strong>
							{tripTime(trip.TripDateTime)}
							<span>
								{tripTimeSegment(
									trip.TripDateTime,
									undefined,
									props.locale.lang
								)}
							</span>
						</strong>
					</li>
					<TripClassComponent
						i18nKey={getClassi18nKey(trip.TripServKind_Code)}
						TripServKind_Code={trip.TripServKind_Code}
						TripServKind_NameA_Short={busClassName}
						TripServKind_NameA={busClassName}
					/>
					<li>
						<span>
							<Trans i18nKey="checkout_page.edit_seats.travel_from">
								سفر من
								</Trans>
								: {trip[officeFromKey]}
						</span>
					</li>
					<li>
						<span>
							<Trans i18nKey="checkout_page.edit_seats.travel_to">
								وصول إلي
								</Trans>{" "}
								: {trip[officeToKey]}
						</span>
					</li>
				</ul>
			</div>
			<div className="bus-info" style={{ position: 'relative' }}>
				<EditSeatsComponent
					tripType={props.tripType}
					active={true}
					toggle={() => { }}
					close={() => { }} />
			</div>
		</div>
	);
}

const SeatControls = props => {
	let { seats } = props;

	if (seats && seats.length > 0) {
		return (
			<React.Fragment>
				<label className="seats-label">
					<Trans i18nKey="checkout_page.edit_seats.seats_numbers">
						ارقام الكراسي
					</Trans>
				</label>
				<div className="held-seats-controls">
					<ul className="held-seats-list">
						{seats.map(seat => {
							return (
								<li key={seat} className="selected">
									{seat}
								</li>
							);
						})}
					</ul>
					<span
						onClick={props.editSeatsTrigger}
						className="edit-seats-link">
						<Trans i18nKey="checkout_page.edit_seats.edit_seats">
							تعديل الكراسي
						</Trans>
					</span>
					{/* <div
            className="edit-seats-link"
            onClick={() => {
              editAction();
            }}
          >
            <i className="material-icons">airline_seat_recline_normal</i> تعديل
            الكراسي
          </div> */}
				</div>
				<div>
					<h4 className="auto-seats-warn">
						<Trans i18nKey="checkout_page.edit_seats.selected_seats_hint">
							تم آختيار أرقام الكراسي تلقائيا للتغير الرجاء الضغط
							علي تعديل الكراسي
						</Trans>
					</h4>
				</div>
			</React.Fragment>
		);
	} else {
		return null;
	}
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(TripCardComponent);
