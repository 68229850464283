import React, { Component } from "react";
import Helmet from "react-helmet";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import MainLayoutComponent from "../MainLayoutComponent";
import * as UserActions from "../../Actions/UserActions";
import ErrorComponent from "../Utils/ErrorComponent";
import debug from "../../Helpers/DebugLog";
import SuccessComponent from "../Utils/SuccessComponent";
import { Trans } from 'react-i18next';
import i18next from 'i18next';

class ResetPasswordComponent extends Component {
  constructor(props) {
    super(props);

    this.token = props.match.params.token;
    // this.email = props.match.params.email;
    this.fields = {};

    this.state = {
      errors: []
    };
  }

  onDataChange = e => {
    this.setState({errors: []})
    this.fields[e.target.name] = e.target.value;
  };

  resetPassword = () => {
    let errors = [];
    if (!this.fields.new_password) errors.push(i18next.t('common.errors.password_missing', 'من فضلك ادخل كلمة السر'));
    if (!this.fields.confirm_new_password)
      errors.push(i18next.t('common.errors.verify_password_missing', 'من فضلك ادخل تأكيد كلمة السر'));

    if (errors.length > 0) return this.setState({ errors });

    if (this.fields.new_password !== this.fields.confirm_new_password)
      errors.push(i18next.t('reset_password_page.errors.confirm_password_invalid', 'كلمتي السر غير متطابقتين'));

    if (errors.length > 0) return this.setState({ errors });

    this.props.userActions.resetPassword(this.fields, this.token);
  };

  render() {
    const formClasses = `forgot-pw-component${
      this.props.userStore.loading ? " component-loader" : ""
    }`;

    return (
      <MainLayoutComponent>
        <Helmet key={window.location.href} >
          <title>Forgot Password | Go Bus</title>
        </Helmet>
        <div className="page-content">
          <div className="AccPage">
            <div className="container">
              <div className={`account-container ${formClasses}`}>
                <div className="account-content active">
                  <ErrorComponent animated={true} state={this.state.errors.length} errors={this.state.errors} />
                  <ErrorComponent animated={true} state={this.props.userStore.hasError} errors={this.props.userStore.errorMessage} />
                  <SuccessComponent animated={true} state={this.props.userStore.resetPasswordSuccess} msg={i18next.t('reset_password_page.success.password_reset', 'تم تجديد كلمة السر بنجاح, سوف يتم تحويلك للصفحة الرئيسية الاَن')}/>
                  <div className={this.props.userStore.loading? "edit-account component-loader" : "edit-account"}>
                    <h3><Trans i18nKey="reset_password_page.heading">تجديد كلمة السر</Trans></h3>
                    <div className="input-field">
                      <label htmlFor="new_password"><Trans i18nKey="common.password">كلمة السر</Trans></label>
                      <input
                        name="new_password"
                        type="password"
                        onChange={this.onDataChange}
                      />
                    </div>
                    <div className="input-field">
                      <label htmlFor="confirm-new-password">
                        <Trans i18nKey="reset_password_page.verify_password_label">تأكيد كلمة السر</Trans>
                      </label>
                      <input
                        name="confirm_new_password"
                        type="password"
                        onChange={this.onDataChange}
                      />
                    </div>
                    <div className="input-field">
                      <button
                        className="btn btn-orange"
                        onClick={this.resetPassword}
                      >
                        <Trans i18nKey="reset_password_page.edit">تعديل</Trans>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainLayoutComponent>
    );
  }
}

function mapStateToProps(state) {
  return {
    locale: state.locale,
    userStore: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(UserActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordComponent);
