import axios from 'axios';
import { wpPostIdMap } from '../appConfig';
import { corpUrl, corpUrlEn } from './Base';


class CorporateApi {
    static async translationsRequest(arUrl, enUrl, options = { withHeaders: [] }) {
        const results = await Promise.all([
            axios.get(arUrl).catch(console.error),
            axios.get(enUrl).catch(console.error)
        ])

        const data = {
            ar: results[0] && results[0].data,
            en: results[1] && results[1].data
        };

        if (options.withHeaders.length) {
            options.withHeaders.forEach(header => data[header] = results[0].headers[header]);
        }

        return data;
    }

    static async getAbout() {
        const arUrl = `${corpUrl}/pages/${wpPostIdMap.about.ar}`;
        const enUrl = `${corpUrlEn}/pages/${wpPostIdMap.about.en}`;

        return this.translationsRequest(arUrl, enUrl);
    }
    static async getNBE() {
        const arUrl = `${corpUrl}/pages/${wpPostIdMap.nbe.ar}`;
        const enUrl = `${corpUrlEn}/pages/${wpPostIdMap.nbe.en}`;

        return this.translationsRequest(arUrl, enUrl);
    }
    static getTerms() {
        const ar = `${corpUrl}/pages/${wpPostIdMap.terms.ar}`;
        const en = `${corpUrlEn}/pages/${wpPostIdMap.terms.en}`;

        return this.translationsRequest(ar, en);
    }
    static getPolicy() {
        const ar = `${corpUrl}/pages/${wpPostIdMap.policy.ar}`;
        const en = `${corpUrlEn}/pages/${wpPostIdMap.policy.en}`;

        return this.translationsRequest(ar, en);
    }

    static async getStation() {
        const stationPostfix = '/station?per_page=99';
        const stationCatPostfix = '/city?parent=0&per_page=99';
        const arUrl = `${corpUrl}${stationPostfix}`;
        const enUrl = `${corpUrlEn}${stationPostfix}`;
        const arCatUrl = `${corpUrl}${stationCatPostfix}`;
        const enCatUrl = `${corpUrlEn}${stationCatPostfix}`;

        const results = await Promise.all([
            await this.translationsRequest(arCatUrl, enCatUrl),
            await this.translationsRequest(arUrl, enUrl)
        ]);

        return {
            stationCategories: results[0],
            station: results[1]
        };
    }

    static async getFAQs() {
        const faqPostfix = '/faq';
        const faqCatPostfix = '/faq_category';
        const arUrl = `${corpUrl}${faqPostfix}`;
        const enUrl = `${corpUrlEn}${faqPostfix}`;
        const arCatUrl = `${corpUrl}${faqCatPostfix}`;
        const enCatUrl = `${corpUrlEn}${faqCatPostfix}`;

        const results = await Promise.all([
            await this.translationsRequest(arCatUrl, enCatUrl),
            await this.translationsRequest(arUrl, enUrl)
        ]);

        return {
            faqCategories: results[0],
            faq: results[1]
        };
    }

    static async getContactUs() {
        const arUrl = `${corpUrl}/pages/${wpPostIdMap.contact_us.ar}`;
        const enUrl = `${corpUrlEn}/pages/${wpPostIdMap.contact_us.en}`;

        return await this.translationsRequest(arUrl, enUrl);
    }

    static async getFAQsPage() {
        const arUrl = `${corpUrl}/pages/${wpPostIdMap.faq.ar}`;
        const enUrl = `${corpUrlEn}/pages/${wpPostIdMap.faq.en}`;

        return await this.translationsRequest(arUrl, enUrl);
    }

    static async getDestinations() {
        const arUrl = `${corpUrl}/destination`;
        const enUrl = `${corpUrlEn}/destination`;

        return this.translationsRequest(arUrl, enUrl);
    }

    static async getSingleDestination(id) {
        const prefix = `/destination/${id}`;
        const arUrl = `${corpUrl}${prefix}`;
        const enUrl = `${corpUrlEn}${prefix}`;

        return this.translationsRequest(arUrl, enUrl);
    }

    static getHomePage() {
        const arUrl = `${corpUrl}/pages/${wpPostIdMap.home.ar}`;
        const enUrl = `${corpUrl}/pages/${wpPostIdMap.home.en}`;
        return this.translationsRequest(arUrl, enUrl);
    }

    static async getBlogs() {
        const postfix = '/posts?page=1&per_page=15';
        const ar = `${corpUrl}${postfix}`;
        const en = `${corpUrlEn}${postfix}`;

        return await this.translationsRequest(ar, en);
    }
    static async getBlogsPage() {
        const ar = `${corpUrl}/pages/${wpPostIdMap.blogs.ar}`;
        const en = `${corpUrl}/pages/${wpPostIdMap.blogs.en}`;

        return await this.translationsRequest(ar, en);
    }

    static async getRoutes() {
        const postfix = `/routes?per_page=100`;
        const arUrl = `${corpUrl}${postfix}`;
        const enUrl = `${corpUrlEn}${postfix}`;

        return this.translationsRequest(arUrl, enUrl);
    }

    static async getMoreNews(per_page, page) {
        const prefix = `/posts?categories=1&per_page=${per_page}&page=${page}`;
        const ar = `${corpUrl}${prefix}`;
        const en = `${corpUrlEn}${prefix}`;

        return await this.translationsRequest(ar, en, { withHeaders: ['x-wp-totalpages'] });
    }

    static async getHotNews() {
        const prefix = `/posts?categories=6`;
        const ar = `${corpUrl}${prefix}`;
        const en = `${corpUrlEn}${prefix}`;

        return await this.translationsRequest(ar, en);
    }

    static async getSingleNews(slug) {
        const prefix = `/posts?slug=${slug}`;
        const ar = `${corpUrl}${prefix}`;
        const en = `${corpUrlEn}${prefix}`;

        return await this.translationsRequest(ar, en);
    }
    
}

export default CorporateApi;