import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";

// Actions 
import * as SearchActions from '../../../Actions/SearchActions'
import * as TripActions from '../../../Actions/TripActions'
import * as UserActions from '../../../Actions/UserActions'


// Components
import TripComponent from './TripComponent'
import NextAndPreviousDaySearchBox from './NextAndPreviousDaySearchBox'
import LoaderComponent from '../../Utils/LoaderComponent'
import TripsLoaderComponent from '../../Utils/Placeholders/TripsPlaceholder'
import PopupErrorComponent from '../../Utils/PopupErrorComponent'


// Libs
import { FullDate, fullDateDigits, dayNamedAndNumbered } from '../../../Helpers/TimeParser'
import debug from '../../../Helpers/DebugLog';
import { scrollToElement } from '../../../Helpers/UI';
import Utils from '../../../Helpers/Utils';
import { defaultLoaderDelay } from '../../../Api/Base';
import { Trans } from 'react-i18next';
import i18next from 'i18next';


function mapStateToProps(store) {
    return {
        locale: store.locale,
        userStore: store.user,
        searchStore: store.search,
        tripsStore: store.trips
    }
}

function mapDispatchToProps(dispatch) {
    return {
        searchActions: bindActionCreators(SearchActions, dispatch),
        tripActions: bindActionCreators(TripActions, dispatch),
        userActions: bindActionCreators(UserActions, dispatch),
    }
}


class TripListComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            numberOfTripsToShow: 4,
            showPopUpError: false,
            errMsg: '',
        }
        this.showAllTrips = this.showAllTrips.bind(this)
        this.hideSomeTrips = this.hideSomeTrips.bind(this)

        this.selectTrip = this.selectTrip.bind(this)
        this.removeTrip = this.removeTrip.bind(this);
        this.selectGoingTrip = this.selectGoingTrip.bind(this)
        this.selectReturningTrip = this.selectReturningTrip.bind(this)
        // this.holdSeats =  this.holdSeats.bind(this)
        this.redirectToBookingPage = this.redirectToBookingPage.bind(this)


    }

    componentDidMount() {
        setTimeout(() => {
            this.tripListRef = document.querySelectorAll('.list-component-con')[1];
        }, 500)
    }

    scrollToGoList = () => {
        let tripListRef = document.querySelectorAll('.list-component-con')[0];
        Utils.scrollToElement(tripListRef);
    }
    scrollToReturnList = () => {
        let tripListRef = document.querySelectorAll('.list-component-con')[1];
        Utils.scrollToElement(tripListRef);
        // this.tripListRef.scrollIntoView({block: "start", behavior: "smooth"})
    }

    /**
     *  Selecting the trip Handler
     * @author Ezzat
     */
    selectTrip(TripSubData_Id) {
        // check if user is LoggedIn
        if (!this.props.userStore.auth) {
            this.props.userActions.toggleAuthPopUp(this.selectTrip, TripSubData_Id)
            return
        }

        this.props.tripActions.setLoading(true);

        setTimeout(() => {
            if (this.props.listType === 'going') {
                this.selectGoingTrip(TripSubData_Id)
                this.scrollToReturnList()
            } else {
                this.selectReturningTrip(TripSubData_Id)
            }
            this.props.tripActions.setLoading(false);
        }, defaultLoaderDelay)
    }

    removeTrip(tripType) {
        this.props.tripActions.setLoading(true);

        setTimeout(() => {
            this.props.tripActions.setLoading(false);
            switch (tripType) {
                case "going":
                    this.props.tripActions.removeGoingTrip()
                    break;
                case "returning":
                    this.props.tripActions.removeReturningTrip()
                    break;
            }
        }, defaultLoaderDelay)
    }

    /**
     *  Selecting the Going Trip 
     * if there's an already hold trip it replace it with the new one 
     * IE: it has nth to do with the seats => it should be deleted 
     * @todo delete the hold seats 
     * @author Ezzat
     */
    selectGoingTrip(TripSubData_Id) {
        let trips = this.props.tripsStore.displayedGoingTrips
        let trip = trips.find(t => {
            return t.TripSubData_Id === TripSubData_Id
        })

        // console.log('zero')
        this.props.tripActions.selectGoingTrip(trip)//.then(() => {
        // if(this.props.searchStore.tripType === 'oneWay')  {
        // this.holdSeats() 
        // }
        // }).catch(err => {
        // some error happened  in getting trip seats
        // console.log('Err happened')
        // })
    }

    /**
     *  Selecting the Returning Trip 
     * if there's an already hold trip it replace it with the new one 
     * IE: it has nth to do with the seats => it should be deleted 
     * @fires Hold
     * @todo delete the hold seats 
     * @author Ezzat
     */
    selectReturningTrip(TripSubData_Id) {
        if (!this.props.tripsStore.selectedGoingTrip) {
            this.scrollToGoList();
            if (toast.isActive(0)) return;
            return toast.warn(i18next.t('search_page.errors.select_going_first'), {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                className: "toast-warning",
                toastId: 0
            });
        }
        let trips = this.props.tripsStore.displayedReturningTrips
        let trip = trips.find(t => {
            return t.TripSubData_Id === TripSubData_Id
        })
        this.props.tripActions.selectReturningTrip(trip)//.then(()=> {
        // this.holdSeats()
        // })


    }

    getNextDay() {
        let search_store_data = this.props.searchStore
        // update data for search and for date storage in store
        search_store_data.departureStation = search_store_data.departureStation.Code
        search_store_data.arrivalStation = search_store_data.arrivalStation.Code

        // update Departure or Arrival Date
        if (this.props.listType === 'going') {
            search_store_data.departureDate = moment(search_store_data.departureDate).add(1, 'days')
        } else {
            search_store_data.arrivalDate = moment(search_store_data.arrivalDate).add(1, 'days')
        }

        this.props.searchActions.updateSearchParams(search_store_data)
        this.props.tripActions.searchTrips(search_store_data, this.props.listType);
    }


    /**
     * Hold Seats for ["oneWay", "round"] trip
     * Getting First free Seats from the available Seats and hold them
     * Get Called after the select Seats Promise is done and after getting all seats
     * @fires holdSeats which call the Hold Api from GoBus 
     * @todo Hide the trips which has freeSeats less than the passengersNo
     * @author Ezzat
     * @modified_by Kareem G. Diab - moved to TripActions under the name getTripSeats()
     */
    /*
    holdSeats(){
        let tripSeats = {
            userID:this.props.userStore.user.UserID
        }
        let passengersNo = this.props.searchStore.passengersNo

        // ================ Going trip Data ==============
        let goingTrip = this.props.tripsStore.selectedGoingTrip
        let availableGoingSeats = []  
        this.props.tripsStore.goingTripSeats.map(s => {
            if(s.Status === 0) {
                availableGoingSeats.push(s.SeatNo) 
            }
        })
        tripSeats.goTripSubData_Id = goingTrip.TripSubData_Id
        // getting First Nth Seats converting array into comma separated string
        tripSeats.goSeats = availableGoingSeats.slice(0, passengersNo).join(", ")
    
        // ================ Retuning trip Data ==============
        let returningTrip = this.props.tripsStore.selectedReturningTrip    
        if(returningTrip != null){
            // gettingRandomSeats 
            let availableReturningSeats = []  
            this.props.tripsStore.returningTripSeats.map(s => {
                if(s.Status === 0) {
                    availableReturningSeats.push(s.SeatNo) 
                }
            })
            tripSeats.comeTripSubData_Id = returningTrip.TripSubData_Id
            // getting First Nth Seats converting array into comma separated string
            tripSeats.comeSeats = availableReturningSeats.slice(0, passengersNo).join(", ")
        }
        

        this.props.tripActions.holdSeats(tripSeats).then(()=> {
            this.redirectToBookingPage()
        }).catch(err => {

        })
    }
    */

    /**
     * go to the booking page
     * @author Ezzat
     */
    redirectToBookingPage() {
        const location = {
            pathname: `/Booking`,
            // search: this.searchKeys,
        };
        this.props.history.push(location);
    }

    /**
     * show All trips 
     * @author Ezzat
     */
    showAllTrips() {
        let tripsNo;
        if (this.props.listType === 'going') {
            tripsNo = this.props.tripsStore.displayedGoingTrips.length
        } else {
            tripsNo = this.props.tripsStore.displayedReturningTrips.length
        }
        this.setState({
            numberOfTripsToShow: tripsNo
        })
    }

    /**
     * Hide Some of trips
     * @author Ezzat
     */
    hideSomeTrips() {
        if (this.tripListRef)
            Utils.scrollToElement(this.tripListRef);
        setTimeout(() => {
            this.setState({
                numberOfTripsToShow: 4
            })
        }, 700);
    }

    render() {
        let trips,
            fetching,
            departureStation,
            arrivalStation,
            tripsDate,
            goTitle = "",
            returnTitle = "",
            isGoingSuggestion = false,
            isReturnSuggestion = false,
            isToPortSaid = false;

        const { lang } = this.props.locale;
        const regionKey = lang === 'ar' ? "RegionNameA_Short" : "RegionNameE_Short";
        const stationKey = lang === 'ar' ? "NameA" : "NameE";

        if (this.props.listType === 'going') {
            isGoingSuggestion = this.props.tripsStore.isGoingSuggestion;
            trips = this.props.tripsStore.displayedGoingTrips
            fetching = this.props.tripsStore.fetchingGoingTrips
            departureStation = this.props.searchStore.departureStation
            arrivalStation = this.props.searchStore.arrivalStation
            tripsDate = this.props.searchStore.departureDate || moment()
            isToPortSaid =
                arrivalStation != null &&
                arrivalStation.TripRegion_Code === 35 &&
                departureStation.TripRegion_Code === 2;
        } else {
            isReturnSuggestion = this.props.tripsStore.isReturnSuggestion;
            trips = this.props.tripsStore.displayedReturningTrips
            fetching = this.props.tripsStore.fetchingReturningTrips
            departureStation = this.props.searchStore.arrivalStation
            arrivalStation = this.props.searchStore.departureStation
            tripsDate = this.props.searchStore.arrivalDate || moment().add(1, "days");
        }

        if (departureStation) {
            if (/[-]/g.test(departureStation[stationKey])) {
                goTitle = Utils.sliceAfter("-", departureStation[stationKey]);
            } else if (/[()]/g.test(departureStation[stationKey])) {
                goTitle = Utils.sliceBetween("()", departureStation[stationKey]);
            } else {
                goTitle = departureStation[stationKey]
            }
        }

        if (arrivalStation) {
            if (/[-]/g.test(arrivalStation[stationKey])) {
                returnTitle = Utils.sliceAfter("-", arrivalStation[stationKey]);
            } else if (/[()]/g.test(arrivalStation[stationKey])) {
                returnTitle = Utils.sliceBetween("()", arrivalStation[stationKey]);
            } else {
                returnTitle = arrivalStation[stationKey]
            }
        }

        return (
            <div className="list-component-con">
                <div className="list-head">
                    <h4>
                        <span><Trans i18nKey="searchbox.from">سفر من</Trans> {departureStation ? departureStation[regionKey] : ""}</span> {goTitle}</h4>
                    <div>
                        {/* <i className="material-icons">directions_bus</i> */}
                        <i className="fas fa-bus"></i>
                        <i className="fas fa-long-arrow-alt-left"></i>
                    </div>
                    <h4>
                        <span><Trans i18nKey="searchbox.to">وصول إلي</Trans> {arrivalStation ? arrivalStation[regionKey] : ""}</span> {returnTitle} </h4>
                </div>
                {isToPortSaid && <div className='list-notice'>
                    <i className='fas fa-ticket-alt'></i>
                    <Trans i18nKey='search_page.alerts.port_said'>أسعار خاصة لخط القاهرة بورسعيد تبدأ من 75 جنيه</Trans>
                </div>}
                <div className="trip-date">{FullDate(tripsDate, this.props.locale.lang)}</div>

                {(!fetching) &&
                    <NextAndPreviousDaySearchBox
                        listType={this.props.listType}
                    />}


                {(fetching) &&
                    <TripsLoaderComponent />
                }

                {
                    (!fetching && (isGoingSuggestion || isReturnSuggestion)) ?
                        <p className="MsgforNoTrip">
                            <strong><Trans i18nKey="search_page.errors.no_trips">لايوجد رحلات متاحة من المحطات المطلوبة في هذا التاريخ</Trans></strong>
                            {trips.length && <span><Trans i18nKey="search_page.errors.no_trips_sub">ولكن يوجد رحلات متاحة من وإلي محطات أخري في نفس المدن</Trans></span>}
                        </p>
                        :
                        (
                            (this.state.numberOfTripsToShow < trips.length) &&
                            <p className="toggleList more" onClick={this.showAllTrips}>
                                <span><Trans i18nKey="search_page.trips.show_more">أعرض كل المواعيد</Trans></span>
                                <i className="material-icons">arrow_downward</i>
                            </p>
                        )
                }

                {
                    trips.map((trip, index) => {
                        if (index < this.state.numberOfTripsToShow) {
                            return (
                                <TripComponent
                                    key={index}
                                    index={index}
                                    trip={trip}
                                    seatsCount={this.props.searchStore.passengersNo}
                                    tripType={this.props.listType}
                                    select={this.selectTrip}
                                    remove={this.removeTrip}
                                    selectedGoingTrip={this.props.tripsStore.selectedGoingTrip}
                                    selectedReturningTrip={this.props.tripsStore.selectedReturningTrip}
                                />
                            )
                        }
                    })
                }
            </div>
        )
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(TripListComponent)
