import moment from "moment";
import { hideLoading, showLoading } from "react-redux-loading-bar";
import store2 from "store2";

import TripApi from "../Api/TripApi";
import * as actionTypes from "./ActionTypes";

import i18next from "i18next";
import { TiktokPixelHelper } from "../Helpers";
import debug from "../Helpers/DebugLog";
import Facebook from "../Helpers/FacebookHelper";
import { setUserVars } from "../Helpers/FullStory";
import { fullDateDigits } from "../Helpers/TimeParser";
import Utils, { isGoMini } from "../Helpers/Utils";
import { useCheckoutStore } from "../new-store/checkoutStore";
import { fbLogin, login } from "./UserActions";

let countdownId;
/***************************** Actions ********************** */

/**
 * Search Trips
 * @invoked when clikced on Search Btn
 * @param {object} tripData
 * @author Ezzat
 */
export function searchTrips(tripData, route) {
  // remove currently set promocode
  store2.remove("promoCode");

  // remove beneficiary data
  store2.remove("beneficiary");

  let departureStation = tripData.departureStation;
  let arrivalStation = tripData.arrivalStation;
  let departureDate = fullDateDigits(tripData.departureDate);
  let arrivalDate = fullDateDigits(tripData.arrivalDate);

  return function(dispatch) {
    dispatch(clearHeldSeatsCountdown());
    dispatch(showLoading());
    if (route === "returning") {
      dispatch(
        getReturningTrips(arrivalStation, departureStation, arrivalDate)
      );
    }
    if (route === "round") {
      dispatch(getGoingTrips(departureStation, arrivalStation, departureDate));
      dispatch(
        getReturningTrips(arrivalStation, departureStation, arrivalDate)
      );
    }

    if (route === "oneWay" || route === "going") {
      dispatch(getGoingTrips(departureStation, arrivalStation, departureDate));
    }
  };
}

/**
 * @param {int} from
 * @param {int} to
 * @param {date} date
 * @example of {date}  '14/09/2018'
 * @author Ezzat
 */
export function getGoingTrips(from, to, date) {
  return function(dispatch, getState) {
    dispatch(handle_fetching_going_trips());
    TripApi.trips(from, to, date)
      .then(async (trips) => {
        //! BUG: this is a workaround of gobus system internal failure in some search trips
        if (trips && (trips.ExceptionMethod || trips.ClassName)) trips = [];

        if (!Array.isArray(trips)) trips = [];

        let isGoingSuggestion = false;
        if (!trips.length) {
            const departureStation = getState().search.departureStation;
            const arrivalStation = getState().search.arrivalStation;
            const departureDate = fullDateDigits(getState().search.departureDate);
            let tripsByCity = [];

            try {
                // get suggestions
                tripsByCity = await TripApi.getTripsByCity(departureStation.TripRegion_Code, arrivalStation.TripRegion_Code, departureDate);
            } catch (error) {
                console.error(`TripApi.getTripsByCity Error: `, error);
                dispatch(handle_getting_going_trips([]));
                getFilters(dispatch, [], "going");
            }

            trips = tripsByCity;
            isGoingSuggestion = true;
        }

        trips = trips.filter((trip) => {
            flagTrips(trip, Number(getState().search.passengersNo));
            if (trip._remove === true) {
                return false;
            }
            addTimeSegmentsToTrip(trip);
            return trip._remove !== true;
        });

        dispatch(handle_getting_going_trips(trips, isGoingSuggestion));

        getFilters(dispatch, trips, "going");
      })
      .catch((err) => {
        console.error("Error Occured!", err);
        dispatch(handle_getting_going_trips([]));
        getFilters(dispatch, [], "going");
      });
  };
}

/**
 * @param {int} from
 * @param {int} to
 * @param {date} date
 * @example of {date}  '14/09/2018'
 * @author Ezzat
 */
export function getReturningTrips(from, to, date) {
  return function(dispatch, getState) {
    dispatch(handle_fetching_returning_trips());
    TripApi.trips(from, to, date)
      .then(async (trips) => {
        //! BUG: this is a workaround of gobus system internal failure in some search trips
        if (trips && (trips.ExceptionMethod || trips.ClassName)) trips = [];

        if (!Array.isArray(trips)) trips = [];

        let isReturnSuggestion = false;
        if (!trips.length) {
            const departureStation = getState().search.departureStation;
            const arrivalStation = getState().search.arrivalStation;
            const arrivalDate = fullDateDigits(getState().search.arrivalDate);

            let tripsByCity = [];
            try {
                // get suggestions
                tripsByCity = await TripApi.getTripsByCity(arrivalStation.TripRegion_Code, departureStation.TripRegion_Code, arrivalDate)
            } catch (error) {
                console.error(`TripApi.getTripsByCity Error: `, error);
                dispatch(handle_getting_going_trips([]));
                getFilters(dispatch, [], "going");
            }

            trips = tripsByCity;
            isReturnSuggestion = true;
        }

        trips = trips.filter((trip) => {
            flagTrips(trip, Number(getState().search.passengersNo));
            if (trip._remove === true) {
                return false;
            }
            addTimeSegmentsToTrip(trip);
            return trip._remove !== true;
        });

        dispatch(handle_getting_returning_trips(trips, isReturnSuggestion));
        getFilters(dispatch, trips, "return");
      })
      .catch((err) => {
        console.error("Error Occured!", err);
      });
  };
}

/**
 * Add Departure Time Segment and Arrival time segment to the trips list
 * helps in filtering in the next phase
 * @add departureTimeSegment
 * @add arrivalTimeSegment
 * @param {array} trips
 * @author Ezzat
 */
function addTimeSegmentsToTrip(trip) {
  // trips.forEach((trip, i) => {
  let departure_time = moment(trip.TripDateTime)
    .locale("en")
    .format("A");
  let arrival_time =
    trip.TimeMinutes &&
    moment(trip.TripDateTime)
      .add(trip.TimeMinutes, "minutes")
      .locale("en")
      .format("A");

  if (departure_time === "AM") {
    trip.departureTimeSegment = 0;
  } else if (departure_time === "PM") {
    trip.departureTimeSegment = 1;
  } else trip.departureTimeSegment = -1;

  if (arrival_time === "AM") {
    trip.arrivalTimeSegment = 0;
  } else if (arrival_time === "PM") {
    trip.arrivalTimeSegment = 1;
  } else trip.arrivalTimeSegment = -1;
  // // Departure time periods
  // if (departure_time >= 0 && departure_time < 2) {
  //   trip.departureTimeSegment = 1;
  // } //saba7an
  // if (departure_time >= 2 && departure_time < 5) {
  //   trip.departureTimeSegment = 0;
  // } //fajran
  // if (departure_time >= 5 && departure_time < 12) {
  //   trip.departureTimeSegment = 1;
  // } // Saba7an
  // if (departure_time >= 12 && departure_time < 18) {
  //   trip.departureTimeSegment = 2;
  // } // Zohran
  // if (departure_time >= 18 && departure_time < 24) {
  //   trip.departureTimeSegment = 3;
  // } // Masa2an
  // // Arrival time periods
  // if (arrival_time >= 0 && arrival_time < 2) {
  //   trip.arrivalTimeSegment = 1;
  // } //saba7an
  // if (arrival_time >= 2 && arrival_time < 5) {
  //   trip.arrivalTimeSegment = 0;
  // } //fajran
  // if (arrival_time >= 5 && arrival_time < 12) {
  //   trip.arrivalTimeSegment = 1;
  // } // Saba7an
  // if (arrival_time >= 12 && arrival_time < 18) {
  //   trip.arrivalTimeSegment = 2;
  // } // Zohran
  // if (arrival_time >= 18 && arrival_time < 24) {
  //   trip.arrivalTimeSegment = 3;
  // } // Masa2an
  // });
  return trip;
}

export function flagTrips(trip, passengersNo) {
  let current_time = Number(moment());

  let timeMinusFour = moment(trip.TripDateTime).subtract(30, "minutes");

  if (
    timeMinusFour.diff(current_time) < 0 ||
    trip.RemainChairs < passengersNo
  ) {
    trip._remove = true;
  }
}

/**
 * Extract the filters from the trips
 * @param {array} trips
 * @author Ezzat
 */
function getFilters(dispatch, trips, trips_type) {
  if (trips && trips.length < 1) {
    return dispatch(hideLoading());
  }

  let buses_filters = getBussesFilters(trips);
  let time_segment_filters = getTimeSegmentFilters(trips, trips_type);
  let filters = { ...buses_filters, ...time_segment_filters };
  filters.buses = Array.from(new Set(filters.buses));
  // filters.buses = translateFilters(filters.buses, 'ar');
  // console.log("Filters", filters);
  if (trips_type === "going") {
    dispatch(handle_setting_going_filters(filters));
  } else {
    dispatch(handle_setting_returning_filters(filters));
  }
  dispatch(hideLoading());
}

/**
 * Get the Buses from the Trips
 * @param {array} trips
 * @author Ezzat
 */
function getBussesFilters(trips) {
  const perceived = [];
  let buses = [];
  trips.forEach((trip) => {
    if (!perceived.includes(trip.TripServKind_Code)) {
      perceived.push(trip.TripServKind_Code);
      buses.push({
        en: trip.TripServKind_NameE,
        ar: trip.TripServKind_NameA_Short,
        code: trip.TripServKind_Code,
      });
    }
  });
  // get the Unique Buses
  // buses = Array.from(new Set(buses));
  return { buses };
}

/**
 * Get the Time segments from the Trips
 * @param {array} trips
 * @author Ezzat
 * @returns 0,1,2,3 => [fajran, zohran, 3asran, masa2an]
 * @returns {object}
 */
function getTimeSegmentFilters(trips) {
  let departure_time_segment = new Set();
  let arrival_time_segment = new Set();

  trips.forEach((trip) => {
    const dept_segment = moment(trip.TripDateTime)
      .locale("en")
      .format("A");
    const arrival_segment = moment(trip.TripDateTime)
      .add(trip.TimeMinutes, "minutes")
      .locale("en")
      .format("A");

    if (dept_segment === "AM") {
      departure_time_segment.add(0);
    } else {
      departure_time_segment.add(1);
    }

    if (arrival_segment === "AM") {
      arrival_time_segment.add(0);
    } else {
      arrival_time_segment.add(1);
    }
  });

  departure_time_segment = Array.from(departure_time_segment).sort(
    (a, b) => a - b
  );
  arrival_time_segment = Array.from(new Set(arrival_time_segment)).sort(
    (a, b) => a - b
  );
  // console.log(
  //   `Departure Filter: ${departure_time_segment} -- Arrival Filter ${arrival_time_segment} `
  // );

  return {
    departureTimeSegment: departure_time_segment,
    arrivalTimeSegment: arrival_time_segment,
  };
}

/**
 * Select the going trip and saves it to store and localStorage
 * and gets the   seats
 * @param {object} trip
 * @return {promise}
 * @author Ezzat
 */
export function selectGoingTrip(trip) {
  // remove beneficiary if user edits his trip selection [TOCART]
  store2.remove("beneficiary");
  return function(dispatch, getState) {
    store2.set("selectedGoingTrip", trip);
    dispatch({
      type: actionTypes.SELECT_GOING_TRIP,
      trip,
    });
    const goState = getState();
    console.log("Select Going State:", goState.trips.selectedGoingTrip);
    setTimeout(() => {
      sendAddToCartPixel(goState);
    }, 2000);
    // Getting trip  Seats
    // Return a promise
    // return dispatch(getGoingTripSeats(trip.TripSubData_Id));
  };
}
/**
 * Select the returning trip and saves it to store and localStorage
 * and gets the   seats
 * @param {object} trip
 * @return {promise}
 * @author Ezzat
 */
export function selectReturningTrip(trip) {
  // remove beneficiary if user edits his trip selection
  store2.remove("beneficiary");
  return function(dispatch, getState) {
    store2.set("selectedReturningTrip", trip);
    dispatch({
      type: actionTypes.SELECT_RETURNING_TRIP,
      trip,
    });
    const returnState = getState();
    console.log(
      "Select Return State:",
      returnState.trips.selectedReturningTrip
    );
    setTimeout(() => {
      sendAddToCartPixel(returnState);
    }, 2000);
    // Getting trip  Seats
    // return dispatch(getReturningSeats(trip.TripSubData_Id));
  };
}

/**
 * Getting Going Seats
 * @param {integer} tripSubDataId
 * @return {promise}
 * @author Ezzat
 */
export function getGoingTripSeats(tripSubDataId) {
  return function(dispatch, getState) {
    const state = getState();
    dispatch(handle_fetching_going_seats());
    // to return a promise
    return TripApi.seats(tripSubDataId)
      .then((seats) => {
        const CardID = state.trips.GBCard.CardID;

        if (CardID) {
          const passengersNo = state.search.passengersNo;
          const autoSelection = seats
            .filter((seat) => seat.Status === 0 && seat.SeatNo !== 0)
            .slice(0, passengersNo)
            .map((seat) => seat.SeatNo);

          dispatch(holdSingleSeat("going", autoSelection));
          dispatch(handle_setting_hold_going_seats(autoSelection));
        }
        dispatch(handle_setting_going_seats(seats));
      })
      .catch((err) => {
        console.error("Error Occured!", err);
      });
  };
}

/**
 * Getting Returning Seats
 * @param {integer} tripSubDataId
 * @return {promise}
 * @author Ezzat
 */
export function getReturningSeats(tripSubDataId) {
  return function(dispatch, getState) {
    const state = getState();
    dispatch(handle_fetching_returning_seats());
    return TripApi.seats(tripSubDataId)
      .then((seats) => {
        const CardID = state.trips.GBCard.CardID;

        if (CardID) {
          const passengersNo = getState().search.passengersNo;
          const autoSelection = seats
            .filter((seat) => seat.Status === 0 && seat.SeatNo !== 0)
            .slice(0, passengersNo)
            .map((seat) => seat.SeatNo);

          dispatch(holdSingleSeat("returning", autoSelection));
          dispatch(handle_setting_hold_returning_seats(autoSelection));
        }

        dispatch(handle_setting_returning_seats(seats));
      })
      .catch((err) => {
        console.error("Error Occured!", err);
      });
  };
}

/**
 * remove the Going and returning Trip (can't return without going!)
 * both from store and from localStorage
 * @author Ezzat
 * @todo unHold Seats from GoBus
 */
export function removeGoingTrip() {
  store2.remove("selectedGoingTrip");
  store2.remove("GBCard");
  // remove beneficiary if user edits his trip selection
  store2.remove("beneficiary");
  removeReturningTrip();
  return {
    type: actionTypes.REMOVE_GOING_TRIP,
  };
}

/**
 * remove the Returning
 * both from store and from localStorage
 * @author Ezzat
 */
export function removeReturningTrip() {
  // remove beneficiary if user edits his trip selection
  store2.remove("beneficiary");
  store2.remove("selectedReturningTrip");
  return {
    type: actionTypes.REMOVE_RETURNING_TRIP,
  };
}
/**
 * update the dispalayed going trips
 * @param {array} trips
 * @author Ezzat
 */
export function updateDisplayedGoingTrips(trips) {
  return {
    type: actionTypes.UPDATE_DISPLAYED_GOING_TRIPS,
    trips,
  };
}
/**
 * update the dispalayed returing trips
 * @param {array} trips
 * @author Ezzat
 */
export function updateDisplayedReturningTrips(trips) {
  return {
    type: actionTypes.UPDATE_DISPLAYED_RETURNING_TRIPS,
    trips,
  };
}

/**************************** SEATS Actions ******************************/

/**
 * Holding from backEnd for both oneWay and round trips
 * book seats and update store and localStorage
 * @todo Get AvailableSeats as promise then get first number of seats then book them
 * @param {object} tripSeats
 * @author Ezzat
 * @modified_by Kareem G. Diab
 */
export function holdSeats(toHold, lastAttempt = false, callback, isReloggedIn) {
  return function(dispatch, getState) {
    const state = getState();
    dispatch(handle_holding_seats());

    const tripSeats = userSelectedSeats(state);

    debug("tripSeats", tripSeats);

    if (!tripSeats) {
      return dispatch(handleHoldResult("no_seats_available"));
      // console.log('NO Seats')
      // return Promise.resolve("no_seats_available")
    }

    return TripApi.holdSeats(tripSeats, state.user.user.UserToken)
      .then(async ([error, GBCard]) => {
        debug("hold seats result", GBCard);
        if (error) {
          console.error(error);
          return;
        }
        if (GBCard.Result.length > 1) {
          if (GBCard.Result === "user Token Not Found" && !lastAttempt) {
            // encrypted credentials
            const encrypted = store2.get("_t", {});
            // actual credentials
            let cred = {};
            try {
              cred = Utils.decrypt(encrypted.data, encrypted.iv);
            } catch (error) {
              console.error(error);
            }
            // check if email and password exists (user logged in once and has storage enabled).
            if (cred.type === "classic" && cred.email && cred.password) {
              // attempt to login
              await login(cred.email, cred.password, undefined, {
                force: true,
                callback: () =>
                  dispatch(holdSeats(toHold, true, callback, true)),
              })(dispatch, getState);
            } else if (cred.type === "facebook" && cred.accessToken) {
              try {
                await fbLogin(
                  { accessToken: cred.accessToken, picture: cred.picture },
                  undefined,
                  {
                    force: true,
                    callback: () =>
                      dispatch(holdSeats(toHold, true, callback, true)),
                  }
                )(dispatch, getState);
              } catch (error) {
                console.error(error);
              }
            }
          } else {
            if (isReloggedIn) {
              callback(GBCard.Result);
            } else {
              // Dispatch Error booking
              return GBCard.Result;
            }
          }

          return "callback";
        }

        if (GBCard.CardID !== 0) {
          setUserVars({ CardID: GBCard.CardID });
        }

        GBCard.selectedGoingSeats = tripSeats.goSeats
          .split(",")
          .map(function(item) {
            return parseInt(item, 10);
          });
        if (tripSeats.comeTripSubData_Id) {
          // save seats to GoingTrip, making string into Array
          dispatch(
            handle_setting_hold_going_seats(
              tripSeats.goSeats.split(",").map(function(item) {
                return parseInt(item, 10);
              })
            )
          );
          // save seats to ReturningSeats, Making String Into Array
          dispatch(
            handle_setting_hold_returning_seats(
              tripSeats.comeSeats.split(",").map(function(item) {
                return parseInt(item, 10);
              })
            )
          );
          GBCard.selectedReturningSeats = tripSeats.comeSeats
            .split(",")
            .map(function(item) {
              return parseInt(item, 10);
            });
        } else {
          dispatch(
            handle_setting_hold_going_seats(
              tripSeats.goSeats.split(",").map(function(item) {
                return parseInt(item, 10);
              })
            )
          );
        }

        // For Storing it on the LocalStorage and in the store
        store2.set("GBCard", GBCard);
        dispatch(handle_holding_seats_successfully(GBCard, dispatch));

        setTimeout(() => {
          sendCheckOutPixel(state);
        }, 2000);

        let promoCode = state.trips.promoCode.code;
        if (promoCode) {
          dispatch(applyPromoCode(promoCode));
        }

        if (isReloggedIn) {
          callback(true);
        } else {
          return true;
        }
      })
      .catch((err) => {
        console.log("Error Occured !", err);
      });
  };
}

function handleHoldResult(result) {
  return function(dispatch) {
    if (result !== true) {
      dispatch(setLoading(false));
      let error = i18next.t(
        "search_page.errors.hold_fail_no_seats",
        "للأسف! لا توجد كراسي متاحة، قد تم حجز الأتوبيس بالكامل"
      );

      if (result === "user Token Not Found") {
        error = i18next.t(
          "search_page.errors.hold_fail_relogin",
          "لم نتمكن من حجز كراسي, رجاءاً حاول تسجيل الدخول مرة أخرى"
        );
      }

      if (result === "Going Trip Time Error") {
        error = i18next.t(
          "search_page.errors.hold_fail_invalid_date",
          "لم نتمكن من حجز كراسي, موعد الرحلة غير صحيح. رجاءاً اعد البحث مرة اخرى"
        );
      }

      if (result === "no_seats_available") {
        error = i18next.t(
          "search_page.errors.hold_fail_no_seats",
          "للأسف! لا توجد كراسي متاحة، قد تم حجز الأتوبيس بالكامل"
        );
      }

      if (result === "Go goMini Error:المقاعد محجوزة") {
        error = i18next.t(
          "search_page.errors.hold_fail_no_seats",
          "للأسف! لا توجد كراسي متاحة، قد تم حجز الأتوبيس بالكامل"
        );
      }

      return dispatch(setHoldingSeatsError(error));
    }
  };
}

function sendAddToCartPixel(store) {
  let CardID = store.trips.GBCard.CardID;
  //let goingTrip = store.trips.selectedGoingTrip;
  //let returningTrip = store.trips.selectedReturningTrip;

  let goingTrip = store.trips.selectedGoingTrip;
  let returningTrip = store.trips.selectedReturningTrip;

  let passengersNo = store.search.passengersNo;
  let totalPrice = 0,
    goingPrice = 0,
    returningPrice = 0;
  const content_ids = [String(goingTrip.TripSubData_Id)];

  if (returningTrip) {
    goingPrice = (passengersNo * goingTrip.TripPrice_GoCome) / 2;
    returningPrice = (passengersNo * returningTrip.TripPrice_GoCome) / 2;
    totalPrice = goingPrice + returningPrice;
    content_ids.push(String(returningTrip.TripSubData_Id));
  } else {
    goingPrice = passengersNo * goingTrip.TripPrice;
    totalPrice = passengersNo * goingTrip.TripPrice;
  }

  let cart = {
    id: CardID,
    value: totalPrice,
    content_ids: content_ids,
    from: goingTrip.TripOfficeFrom_NameE,
    to: goingTrip.TripOfficeTo_NameE,
  };

  Facebook.AddToCart(cart);
  TiktokPixelHelper.AddToCart(cart);
}

function sendCheckOutPixel(store) {
  //get final checkout data
  let CardID = store.trips.GBCard.CardID;
  let goingTrip = store.trips.selectedGoingTrip;
  let returningTrip = store.trips.selectedReturningTrip;

  let passengersNo = store.search.passengersNo;
  let totalPrice = 0,
    goingPrice = 0,
    returningPrice = 0;
  let ticket_type;
  const content_ids = [String(goingTrip.TripSubData_Id)];

  if (returningTrip) {
    goingPrice = (passengersNo * goingTrip.TripPrice_GoCome) / 2;
    returningPrice = (passengersNo * returningTrip.TripPrice_GoCome) / 2;
    totalPrice = goingPrice + returningPrice;
    content_ids.push(String(returningTrip.TripSubData_Id));
    ticket_type = "going_returning_ticket";
  } else {
    goingPrice = passengersNo * goingTrip.TripPrice;
    totalPrice = passengersNo * goingTrip.TripPrice;
    ticket_type = "going_ticket";
  }

  let checkData = {
    id: CardID,
    value: totalPrice,
    content_ids: content_ids,
    content_category: ticket_type,
    from: goingTrip.TripOfficeFrom_NameE,
    to: goingTrip.TripOfficeTo_NameE,
  };
  Facebook.InitiateCheckout(checkData);
  TiktokPixelHelper.InitiateCheckout(checkData);
}
export function resetSeats() {
  return (dispatch) => {
    dispatch({ type: actionTypes.RESET_GOING_SEAT });
    dispatch({ type: actionTypes.RESET_RETURNING_SEAT });
  };
}

/**
 * unHold Seats, this happens from the frontEnd, it updates the
 * "selectedGoingSeats" or "selectedReturningSeats" array in the store
 * it doesn't call any backEnd  Action
 * @param {string} tripType
 * @param {array} newSeats
 */
export function unHoldSeat(tripType, newSeats) {
  if (tripType === "going") {
    return {
      type: actionTypes.UN_HOLD_GOING_SEATS,
      newSeats,
    };
  } else {
    return {
      type: actionTypes.UN_HOLD_RETURNING_SEATS,
      newSeats,
    };
  }
}

/**
 * HOLD Single Seats, this happens from the frontEnd, it updates the
 * "selectedGoingSeats" or "selectedReturningSeats" array in the store
 * it doesn't call any backEnd  Action
 * @param {string} tripType
 * @param {array} newSeats
 */
export function holdSingleSeat(tripType, newSeats) {
  if (tripType === "going") {
    return {
      type: actionTypes.HOLD_SINGLE_GOING_SEAT,
      newSeats,
    };
  } else {
    return {
      type: actionTypes.HOLD_SINGLE_RETURNING_SEAT,
      newSeats,
    };
  }
}

export function setCreditCardNumber(cardnumber) {
  return function(dispatch) {
    dispatch({
      type: actionTypes.SET_CC_NUMBER,
      card_number: cardnumber,
    });
  };
}

export function paymentSuccess(history) {
  const {
    goingTripInfo,
    returnTripInfo,
    passengersNo,
    pricing,
    cartId
  } = useCheckoutStore.getState();

  let go_service_type = isGoMini({
    tripsStore: { goingTrip: goingTripInfo, returnTrip: returnTripInfo },
  })
    ? "GM"
    : "GB";
  const products = [];

  // going trip gtm data
  products.push({
    name: goingTripInfo.TripOfficeFrom_NameE,
    id: goingTripInfo.TripSubData_Id,
    price: goingTripInfo.TripPrice,
    quantity: passengersNo,
    service: go_service_type,
  });

  if (returnTripInfo) {
    let re_service_type = isGoMini({ tripsStore: {
      goingTrip: goingTripInfo,
      returnTrip: returnTripInfo
    } }) ? "GM" : "GB";
    products.push({
      name: returnTripInfo.TripOfficeFrom_NameE,
      id: returnTripInfo.TripSubData_Id,
      price: returnTripInfo.TripPrice,
      quantity: passengersNo,
      service: re_service_type,
    });
  }

  Facebook.Purchase(pricing.totalPrice, products);
  TiktokPixelHelper.Purchase(pricing.totalPrice, products);
  history.push(`/Thanks/${cartId}`)
}

export function resetSeatsToOriginal(tripType) {
  if (tripType === "going") {
    return {
      type: actionTypes.RESET_GOING_SEAT,
    };
  } else {
    return {
      type: actionTypes.RESET_RETURNING_SEAT,
    };
  }
}

/**************************** Payment Actions ******************************/
export function setOtherPassengerSelected(isSelected) {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.SET_OTHER_PASSENGER_SELECTED,
      otherPassengerSelected: isSelected,
    });
  };
}
export function setOtherPassengerData(otherPassenger) {
  return {
    type: actionTypes.SET_OTHER_PASSENGER_DATA,
    otherPassenger,
  };
}

export function otherPassengerFieldErrors(errors) {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.OTHER_PASSENGER_ERROR,
      errors,
    });
  };
}

export function applyPromoCode(promoCode) {
  let promoCodeData = {
    couponCode: promoCode,
    cardId: useCheckoutStore.getState().cartId, // store2.get("GBCard").CardID
  };
  return function(dispatch) {
    dispatch(handle_applying_promo_code());
    TripApi.applyPromoCode(promoCodeData).then((promoCode) => {
      if (promoCode.Result.length > 1) {
        // Dispatch Error booking
        if (promoCode.Result === "invaild promo code") {
          return dispatch(
            handle_applying_promo_code_error(
              i18next.t(
                "cart.promocode.errors.invalid_promocode",
                "كود الخصم غير صحيح"
              )
            )
          );
        }

        dispatch(handle_applying_promo_code_error(promoCode.Result));
      } else {
        promoCode.code = promoCodeData.couponCode;
        dispatch(handle_applying_promo_code_success(promoCode));
        useCheckoutStore
          .getState()
          .applyPromoCode({
            ...promoCode,
            couponCode: promoCodeData.couponCode,
          });
      }
    });
  };
}

export function undoPromocode() {
  return async (dispatch, getState) => {
    dispatch(handle_applying_promo_code());
    const { cartId } = useCheckoutStore.getState();
    const [response, error] = await TripApi.undoPromoCode(cartId);

    if (error) {
      alert('Something went wrong!');
      return;
    }

    // Result has an error
    if (response.Result.length > 1) {
      dispatch(
        handle_applying_promo_code_error(
          response.Result
        )
      );
    } else {
      dispatch({ type: actionTypes.UNDO_PROMOCODE });
  
      // apply correct price for the ticket
      let gbCard = store2.get("GBCard", {});
      // gbCard.totalPrice = trips.selectedGoingTrip.TripPrice * search.passengersNo;
      // store2.set("GBCard", gbCard);
      dispatch(handle_gb_card_update(gbCard));
      useCheckoutStore.getState().undoPromocode();
    }
  };
}

export function clearPromoCodeError() {
  return (dispatch) =>
    dispatch({
      type: actionTypes.CLEAR_PROMOCODE_ERR,
    });
}

export function bookWithAman(history, data, payment_method) {
  return function(dispatch, state) {
    dispatch(_cashBookingLoading());

    const {
      goingTripInfo,
      returnTripInfo,
      passengersNo,
      pricing
    } = useCheckoutStore.getState();

    const products = [];
    let go_service_type = isGoMini({
      tripsStore: { goingTrip: goingTripInfo, returnTrip: returnTripInfo },
    })
      ? "GM"
      : "GB";

    // going trip gtm data
    products.push({
      name: goingTripInfo.TripOfficeFrom_NameE,
      id: goingTripInfo.TripSubData_Id,
      price: goingTripInfo.TripPrice,
      quantity: passengersNo,
      service: go_service_type,
    });

    if (returnTripInfo) {
      let re_service_type = isGoMini({
        goingTrip: goingTripInfo,
        returnTrip: returnTripInfo,
      })
        ? "GM"
        : "GB";
      products.push({
        name: returnTripInfo.TripOfficeFrom_NameE,
        id: returnTripInfo.TripSubData_Id,
        price: returnTripInfo.TripPrice,
        quantity: passengersNo,
        service: re_service_type,
      });
    }

    return TripApi.bookTrip(data, payment_method)
      .then((res) => {
        res = res.data;
        if (res.data.success) {
            Facebook.Purchase(pricing.totalPrice, products);
            TiktokPixelHelper.Purchase(pricing.totalPrice, products);
        }

        dispatch({
          type: actionTypes.BOOKING_RESULT_AMAN,
          result: res,
          history,
        });

        return res;
      })
      .catch((err) => {
        console.error(err);
        return dispatch({
          type: actionTypes.BOOKING_RESULT_AMAN,
          history,
        });
      });
  };
}

export function cancelSuccess() {
  return (dispatch) =>
    dispatch({
      type: actionTypes.CANCEL_SUCCESS,
    });
}

export function clearCancel() {
  return (dispatch) =>
    dispatch({
      type: actionTypes.CLEAR_CANCEL,
    });
}

export function setLoading(loading) {
  return function(dispatch) {
    dispatch({
      type: actionTypes.TRIP_LOADER_STATE,
      loading,
    });
  };
}

export function _cashBookingLoading() {
  return (dispatch) =>
    dispatch({
      type: actionTypes.BOOKING_AMAN,
    });
}

/**************************** Handlers ******************************/

/**
 * For making Departure Trip Loader
 * @author Ezzat
 */
function handle_fetching_going_trips() {
  return {
    type: actionTypes.FETCHING_GOING_TRIPS,
  };
}

/**
 * For Storing Departure Trips
 * @param {array} trips
 * @author Ezzat
 */
export function handle_getting_going_trips(trips, isGoingSuggestion) {
  return {
    type: actionTypes.GET_GOING_TRIPS_SUCCESS,
    trips,
    isGoingSuggestion
  };
}

/**
 * For making Arrival Trip Loader
 * @author Ezzat
 */
function handle_fetching_returning_trips() {
  return {
    type: actionTypes.FETCHING_RETURNING_TRIPS,
  };
}

/**
 * For Storing Arrival Trips
 * @param {array} trips
 * @author Ezzat
 */
export function handle_getting_returning_trips(trips, isReturnSuggestion) {
  return {
    type: actionTypes.GET_RETURNING_TRIPS_SUCCESS,
    trips,
    isReturnSuggestion
  };
}

/**
 * set the going trips filters
 * @param {object} filters
 * @author Ezzat
 */
export function handle_setting_going_filters(filters) {
  return {
    type: actionTypes.SET_GOING_FILTERS,
    filters,
  };
}

/**
 * set the Returning trips filters
 * @param {object} filters
 * @author Ezzat
 */
export function handle_setting_returning_filters(filters) {
  return {
    type: actionTypes.SET_RETURNING_FILTERS,
    filters,
  };
}

/**
 * For making GoingTrip Seats Loader
 * @author Ezzat
 */
export function handle_fetching_going_seats() {
  return {
    type: actionTypes.FETCHING_GOING_SEATS,
  };
}

/**
 * For making ReturningTrip Seats Loader
 * @author Ezzat
 */
export function handle_fetching_returning_seats() {
  return {
    type: actionTypes.FETCHING_RETURNING_SEATS,
  };
}

/**
 * For Setting Going Seats
 * @param {array} seats
 * @author Ezzat
 */
export function handle_setting_going_seats(seats) {
  return {
    type: actionTypes.SET_GOING_SEATS,
    seats,
  };
}

/**
 * For Setting Returning Seats
 * @param {array} seats
 * @author Ezzat
 */
export function handle_setting_returning_seats(seats) {
  return {
    type: actionTypes.SET_RETURNING_SEATS,
    seats,
  };
}

/**
 * Holding Seats init
 * For showing Loader (if there's)
 * @author Ezzat
 */
function handle_holding_seats() {
  return {
    type: actionTypes.HOLDING_SEATS,
  };
}

/**
 * Holding Seats Success
 * For hiding Loader (if there's)
 * @author Ezzat
 */
function handle_holding_seats_successfully(GBCard, dispatch) {
  // let seatsHoldTime = 20 //state.trips.selectedGoingTrip.TimeMinutes / 60;
  dispatch(clearHeldSeatsCountdown());
  let base_hold_time = moment().add(20, "minutes");
  let remaining_hold_time = base_hold_time - moment();
  store2.set("base_seats_hold_time", base_hold_time);
  store2.set("seats_hold_time", remaining_hold_time);

  return {
    type: actionTypes.HOLDING_SEATS_SUCCESSFULLY,
    GBCard,
    seatsHoldTime: remaining_hold_time,
  };
}

function handle_gb_card_update(GBCard) {
  return {
    type: actionTypes.UPDATE_GBCARD,
    GBCard,
  };
}

/**
 * Setting the Going Hold  Seats
 * @param {array}  seats
 * @author Ezzat
 */
function handle_setting_hold_going_seats(seats) {
  return {
    type: actionTypes.SET_HOLD_GOING_SEATS,
    seats,
  };
}

/**
 * Setting the Returning Hold  Seats
 * @param {array}  seats
 * @author Ezzat
 */
function handle_setting_hold_returning_seats(seats) {
  return {
    type: actionTypes.SET_HOLD_RETURNING_SEATS,
    seats,
  };
}

/**
 * Applying Promo Code for a loader in future
 * @param {array}  seats
 * @author Ezzat
 */
function handle_applying_promo_code() {
  return {
    type: actionTypes.APPLYING_PROMO_CODE,
  };
}

/**
 * ErrorHappened when making PromoCode
 * @param {string}  error
 * @author Ezzat
 */
export function handle_applying_promo_code_error(error) {
  return {
    type: actionTypes.APPLYING_PROMO_CODE_ERROR,
    error,
  };
}

/**
 * PromoCode Success
 * @param {object}  promoCode
 * @author Ezzat
 */
function handle_applying_promo_code_success(promoCode) {
  return {
    type: actionTypes.APPLYING_PROMO_CODE_SUCCESS,
    promoCode,
  };
}

function userSelectedSeats(state) {
  const tripsStore = state.trips;
  const userStore = state.user.user;
  const searchStore = state.search;

  const tripSeats = {
    userID: userStore.UserID,
    goTripSubData_Id: tripsStore.selectedGoingTrip.TripSubData_Id,
    goSeats: tripsStore.selectedGoingSeats.join(","),
  };

  if (tripsStore.selectedReturningTrip) {
    tripSeats.comeTripSubData_Id =
      tripsStore.selectReturningTrip.TripSubData_Id;
    tripSeats.comeSeats = tripsStore.selectedReturningSeats.join(",");
  }
  return tripSeats;
}
function getTripSeats(state) {
  const userStore = state.user.user;
  const searchStore = state.search;
  const tripsStore = state.trips;

  let tripSeats = {
    userID: userStore.UserID,
  };
  let passengersNo = searchStore.passengersNo;

  // ================ Going trip Data ==============
  let goingTrip = tripsStore.selectedGoingTrip;
  let availableGoingSeats = [];
  Array.isArray(tripsStore.goingTripSeats) &&
    tripsStore.goingTripSeats.forEach((s) => {
      if (s.Status === 0 && s.SeatNo !== 0) {
        availableGoingSeats.push(s.SeatNo);
      }
    });

  // return null if available going seats are empty
  if (!availableGoingSeats.length) return null;

  tripSeats.goTripSubData_Id = goingTrip.TripSubData_Id;
  // getting First Nth Seats converting array into comma separated string
  tripSeats.goSeats = availableGoingSeats.slice(0, passengersNo).join(",");

  // ================ Retuning trip Data ==============
  let returningTrip = tripsStore.selectedReturningTrip;
  if (returningTrip != null) {
    // gettingRandomSeats
    let availableReturningSeats = [];
    tripsStore.returningTripSeats.forEach((s) => {
      if (s.Status === 0 && s.SeatNo !== 0) {
        availableReturningSeats.push(s.SeatNo);
      }
    });

    // return null if available going seats are empty
    if (!availableReturningSeats.length) return null;

    tripSeats.comeTripSubData_Id = returningTrip.TripSubData_Id;
    // getting First Nth Seats converting array into comma separated string
    tripSeats.comeSeats = availableReturningSeats
      .slice(0, passengersNo)
      .join(",");
  }

  return tripSeats;
}

export function resetTripsState() {
  return function(dispatch) {
    dispatch({ type: actionTypes.RESET_TRIPS_STATE });
  };
}

export function setHoldingSeatsError(error) {
  return function(dispatch) {
    dispatch({ type: actionTypes.SET_HOLDING_SEATS_ERROR, error });
  };
}

export function startHeldSeatsCountdown() {
  return function(dispatch) {
    if (countdownId) return;
    let base_hold_time = store2.get("base_seats_hold_time", moment().add(10, "minutes"));
    let remaining_hold_time = base_hold_time - moment();
    store2.set("seats_hold_time", remaining_hold_time);

    let targetTime = moment(store2.get("base_seats_hold_time"));
    countdownId = setInterval(() => {
      let currentTime = moment();
      let remainingTime = targetTime - currentTime;

      //disable the timer and redirect page if remaining time is over
      if (remainingTime <= 0) {
        return dispatch(stopHeldSeatsCountdown(true));
      }

      store2.set("seats_hold_time", remainingTime);
      dispatch({
        type: actionTypes.UPDATE_SEATS_HOLD_TIME,
        seatsHoldTime: remainingTime,
      });
    }, 1000); //every second
  };
}

export function clearHeldSeatsCountdown() {
  clearInterval(countdownId);
  countdownId = undefined;
  store2.remove("seats_hold_time");
  store2.remove("base_seats_hold_time");

  return {
    type: actionTypes.CLEAR_COUNTDOWN_TIMER,
  };
}

/**
 * @todo unhold all held seats, stop the timer on:
 *  - new search
 *  - clicking modify trip
 *  * look for edge cases where stopping timer is required.
 */
export function stopHeldSeatsCountdown(timeout) {
  //shutdown the interval
  clearInterval(countdownId);
  //set interval id to undefined so that we can start another interval
  countdownId = undefined;
  store2.remove("base_seats_hold_time");

  return function(dispatch) {
    alert("لقد نفذ وقت حجز الكراسي, سوف يتم تحويلك الى الصفحة الرئيسية");
    if (timeout && window.location.pathname.toLowerCase() === "/booking")
      window.location.replace("/");
    dispatch(removeGoingTrip());
    dispatch(removeReturningTrip());
  };
}
