import React from "react";

export function Tooltip({ content, children }) {
    const [isHover, setIsHover] = React.useState(false);
    const rootStyle = {
        position: "relative",
    };
    const contentStyle = {
        opacity: isHover ? 1 : 0,
        position: "absolute",
        top: "-20px",
        left: "50%",
        transform: "translateX(-50%)",
        backgroundColor: "white",
        padding: "4px 8px",
        borderRadius: "8px",
        boxShadow: "1px 2px 10px rgba(0, 0, 0, 0.2)",
        whiteSpace: "nowrap",
        zIndex: 10,
        transition: "opacity 0.2s ease-in-out",
    };
    return (
        <div style={rootStyle} onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
            <div style={contentStyle}>{content}</div>
            <div>{children}</div>
        </div>
    );
}
