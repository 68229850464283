export function identify(user_id, user_name, user_email, custom_data) {
  if(!window.FS) return;
  if(!window.FS.identify) return;
  
  window.FS.identify(String(user_id), {
    displayName: String(user_name),
    email: String(user_email),
    ...custom_data
  });
}

export function setUserVars(obj) {
  if(!window.FS) return;
  if(!window.FS.setUserVars) return;

  window.FS.setUserVars(obj);
}

export const FullStory = {
  setUserVars
}