import React from "react";

const yoast_meta_map = {
  "yoast_wpseo_focuskw"               : "",
  "yoast_wpseo_title"                 : "",
  "yoast_wpseo_metadesc"              : createMetaObject("description"),
  "yoast_wpseo_linkdex"               : "",
  "yoast_wpseo_metakeywords"          : "",
  "yoast_wpseo_meta-robots-noindex"   : "",
  "yoast_wpseo_meta-robots-nofollow"  : "",
  "yoast_wpseo_meta-robots-adv"       : "",
  "yoast_wpseo_canonical"             : createMetaObject(null, "og:url"),
  "yoast_wpseo_redirect"              : "",
  "yoast_wpseo_opengraph-title"       : createMetaObject(null, "og:title"),
  "yoast_wpseo_opengraph-description" : createMetaObject(null, "og:description"),
  "yoast_wpseo_opengraph-image"       : createMetaObject(null, "og:image"),
  "yoast_wpseo_twitter-title"         : createMetaObject(null, "twitter:title"),
  "yoast_wpseo_twitter-description"   : createMetaObject(null, "twitter:description"),
  "yoast_wpseo_twitter-image"         : createMetaObject(null, "twitter:image")
}

function createMetaObject(name, property) {
  return function (content) {
    if(content) {
      let o = {};
      if(name) o.name = name;
      if(property) o.property = property;
      if(content) o.content = content;
      return o;
    }
  }
}

/**
 * 
 * @param {*} yoast_data 
 * @returns {Array<{name?:string, property?:string, value:string}>} meta_tags
 * @description use to manually render tags, see example below:
 * buildYoastMetaTags(yoast_data).forEach(tag => <meta {...tag} />)
 */
export function buildYoastMetaTags(yoast_data) {
  if(!yoast_data) return [];
  let yoastMeta = Object.keys(yoast_meta_map).map(key => {
    if("function" === typeof yoast_meta_map[key]) {
      return yoast_meta_map[key](yoast_data[key]);
    }
  });

  return yoastMeta;
}

/**
 * 
 * @param {*} props.yoast_data
 * @ignore
 * doesn't work with react-helmet
 */
export function YoastMetaTags({ yoast_data }) {
  if(!yoast_data) return null;

  let metaTags = buildYoastMetaTags(yoast_data);

  return(
    metaTags.map(tag_data => {
      return <meta {...tag_data} />
    })
  )
}
