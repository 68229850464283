import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { loadingBarMiddleware } from "react-redux-loading-bar";
import logger from "redux-logger";
import thunk from "redux-thunk";
import store2 from "store2";
import moment from "moment";

import promise from "redux-promise-middleware";
import reducer from "./Reducers/ReducersWrapper"; //Reducer wrapper
import GTMMiddleware from "./ReduxMiddlerwares/GTMMiddleware";
import debug from "./Helpers/DebugLog";
import { default_corporate_state } from "./Reducers/CorporateReducer";

/**
 * THIS IS TEMPROAORY until go bus makes an endpoint to get user data from token
 * @author Ezzat
 */
const userExpireTokenMiddleware = store => next => action => {
  if (store2.has("user")) {
    let user = store2.get("user");
    // check if the ttl is expired or not remove user data
    if (moment().isAfter(user.ttl, "minute")) {
      store2.remove("user");
      store2.remove("selectedGoingTrip");
      store2.remove("selectedReturningTrip");
      store2.remove("GBCard");
      window.location.reload();
    }
  }
  return next(action);
};

const searchStoreStorageMiddleware = store => next => action => {
  let search = store.getState().search;
  store2.set("search_store", search);
  next(action);
};

let middleware = null;

if (process.env.REACT_APP_API === "production") {
  middleware = applyMiddleware(
    thunk,
    promise(),
    GTMMiddleware, /*userExpireTokenMiddleware*/
    searchStoreStorageMiddleware,
    // logger
  );
} else {
  if(process.title === "node") {
    middleware = applyMiddleware(
      thunk,
      promise(),
      // userExpireTokenMiddleware,
      GTMMiddleware,
      searchStoreStorageMiddleware,
    );
  } else {
    middleware = applyMiddleware(
      thunk,
      promise(),
      // userExpireTokenMiddleware,
      GTMMiddleware,
      searchStoreStorageMiddleware,
      // logger
    );
  }
}

let initialState = undefined;

if( window.__REDUX_DATA__ ) {
  try {
    initialState = window.__REDUX_DATA__
  } catch (err) {
    console.log('failed to parse state');
  }
};

if( initialState && Object.keys(initialState).length < 1 ) {
  initialState = default_corporate_state
}

export default createStore(reducer, {
  corporate: initialState
}, composeWithDevTools(middleware));
