import React, { Component } from 'react'
import Slider from "react-slick";
import "./react-slick-override.css";

import GlobalModal from '../../../Utils/GlobalModal';
import styles from "./ClassPreview.module.css";
import TripClassComponent from '../TripClassComponent';
import { getClassi18nKey, getFeaturedClass } from '../../../../appConfig';
import GalleryPopup from '../../../Popups/Gallery-popup';

export default class ClassPreviewComponent extends Component {

  state = {
    open: false,
    id: 0
  }

  content = null;

  open = () => {
    this.setState({
      open: true,
      id: 0
    })
  }

  close = () => {
    this.setState({
      open: false
    })
  }

  render() {
    let {
      open,
      onClose,
      images,
      classData,
      locale,
      tripClassComponent
    } = this.props;

    var sliderSettings = {
      dots: true,
      infinite: true,
      speed: 250,
      variableWidth: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      rtl: true,
      centerMode: false,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />
    };

    return <GalleryPopup
      view='trip-list'
      locale={locale}
      overrideImages={images}
      overrideTripClass={tripClassComponent}
      overrideShowPopup={open}
      overrideSetShowPopup={onClose}
      overrideFeaturedClass={getFeaturedClass(classData.TripServKind_Code)}/>
    
    return (
      <GlobalModal
        open={open}
        onClose={onClose}
        withPaper={true}
      >
        <div className={styles.carouselHeader}>
          <div className={styles.carouselHeader__right}>
            <TripClassComponent 
              i18nKey={getClassi18nKey(classData.TripServKind_Code)}
              TripServKind_Code={classData.TripServKind_Code}
              TripServKind_NameE={classData.TripServKind_NameE}
              TripServKind_NameA_Short={classData.TripServKind_NameA_Short}
              />
          </div>
          <div className={styles.carouselHeader__left} onClick={onClose}>
            <i class="fas fa-times"></i>
          </div>
        </div>
        <div className={styles.carousel}>
          <Slider className="content" {...sliderSettings} >
            {
              images.map((img) => {
                return <div><img src={img} /></div>
              })
            }
          </Slider>
        </div>
      </GlobalModal>
    )
  }
}

function PrevArrow(props) {
  return <i className="fas fa-chevron-left" onClick={props.onClick}></i>
}

function NextArrow(props) {
  return <i className="fas fa-chevron-right" onClick={props.onClick} />
}
