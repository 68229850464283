import React, { Component } from "react";
import { Trans } from 'react-i18next';

export default class TripInstructions extends Component {
  render() {
    return (
      <div className="trip-instructions">
        <label className="tp-label" htmlFor="">
          <Trans i18nKey="checkout_page.payment.trip_instructions.title">تعليمات السفر</Trans>
        </label>
        <ul>
          {this.props.paymentMethod === "cash" && (
            <React.Fragment>
              {this.props.page === "booking" && (
                <li className="aman-bullets">
                  <p>
                    <Trans i18nKey="checkout_page.payment.trip_instructions.cash.i1">
                      يظهر كود الدفع في الصفحة التالية مباشرةاً و يمكن الحصول عليه
                      من الحجوزات السابقة.
                    </Trans>
                  </p>
                </li>
              )}
              <li className="aman-bullets">
                <Trans i18nKey="checkout_page.payment.trip_instructions.cash.i2">
                  <p>اَخر ميعاد للدفع يكون فى خلال 3 ساعات من ميعاد الحجز.</p>
                </Trans>
              </li>
              <li className="aman-bullets">
                <Trans i18nKey="checkout_page.payment.trip_instructions.cash.i3">
                  <p>هذا الحجز مؤقت و غير مؤكد حتى يتم دفع المبلغ المستحق.</p>
                </Trans>
              </li>
            </React.Fragment>
          )}
          <li>
            <Trans i18nKey="checkout_page.payment.trip_instructions.all.i1">
              <p>التأكد من تاريخ وميعاد و اتجاه الرحلة مسئولية الراكب</p>
              </Trans>
          </li>
          <li>
            <Trans i18nKey="checkout_page.payment.trip_instructions.all.i2">
              <p>الأطفال فوق 4 سنوات تذكرة كاملة ولا يوجد نصف تذكرة</p>
            </Trans>
          </li>
          <li>
            <p>
              <Trans i18nKey="checkout_page.payment.trip_instructions.all.i4">
                يحق للراكب اصطحاب عدد 2 شنطة ملابس متوسطة الحجم وما زاد على ذلك
                يتم تقدير قيمة شحن
              </Trans>
            </p>
          </li>
          <li>
            <p>
              <Trans i18nKey="checkout_page.payment.trip_instructions.all.i5">
                الرجاء المحافظة على التذكرة وتقديمها عند الطلب وفي حالة فقد
                التذكرة يتم إركاب العميل بعد مراجعة الرحلة بمحطة المغادرة النهائية
                بإيصال نقدي
              </Trans>
            </p>
          </li>
          <li>
            <p>
              <Trans i18nKey="checkout_page.payment.trip_instructions.all.i6">
                في حالة تسبب الشركة في فقد احد الامتعة يكون الحد الأقصى للتعويض
                100 جنية مصري والشركة غير مسئولة عما بداخل الحقائب
              </Trans>
            </p>
          </li>
          <li>
            <p>
              <Trans i18nKey="checkout_page.payment.trip_instructions.all.i7">
                الشركة غير مسئولة عن الامتعة صحبة الراكب داخل صالون السيارة ولا
                يجوز التعويض عنها
              </Trans>
            </p>
          </li>
          <li>
            <p>
              <Trans i18nKey="checkout_page.payment.trip_instructions.all.i8">
                ممنوع اصطحاب او نقل الحيوانات او الطيور أو الأسماك أو المواد
                القابلة للاشتعال أو المواد السائلة
              </Trans>
            </p>
          </li>
        </ul>
      </div>
    );
  }
}
