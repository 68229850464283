import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import styles from "./styles/Blog.module.css";
import * as CorporateActions from "../../Actions/CorporateActions";
import { Link } from "react-router-dom";
import moment from "moment";

// Componnets
import MainLayoutComponent from "../MainLayoutComponent";
import { scrollingEvent } from "../../Helpers/UI";
import { buildYoastMetaTags } from "../../Helpers/Yoast";

function mapStateToProps(store) {
  return {
    lang: store.locale.lang,
    corporates: store.corporate
  };
}

function mapDispatchToProps(dispatch) {
  return {
    corporateActions: bindActionCreators(CorporateActions, dispatch)
  };
}

class BlogsComponent extends Component {
  constructor() {
    super();
    this.state = {
      posts: [],
      per_page: 5
    };

    this.page = 1;
    this.paginate = this.paginate.bind(this);
    this.getMoreNews = this.getMoreNews.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  /**
   * get the blogs page
   * @author Ojja
   */
  componentWillMount() {
    this.getMoreNews();
    this.getBlogsPage();
    // console.log(this.props.corporateActions)
    !Object.keys(this.props.corporates.news.hot_news).length && this.props.corporateActions.getHotNews();
    window.addEventListener("scroll", this.handleScroll);
    // !this.props.corporates.blogs && this.props.corporateActions.getBlogs() ;
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  getContent = (options = { key: 'data', defaultReturn: {} }) => {
    const news = this.props.corporates.news[options.key] || {};
    const { lang } = this.props;
    // translated data & undefined safety object
    return news[lang]? news[lang]: news['ar'] || options.defaultReturn;
  }

  handleScroll(e) {
    let state = this.props.corporates.news;
    scrollingEvent(
      this.scrollRef,
      100, //percentage of body to trigger the callback when reached
      this.page < state.max_pages,
      this.paginate
    );
  }

  getMoreNews() {
    let state = this.props.corporates.news;
    // don't ask the api for more news if the store has already got the requested page before
    if (state.available_pages.includes(this.page)) return;

    this.props.corporateActions.getMoreNews(this.state.per_page, this.page);
  }

  getBlogsPage() {
    !this.props.corporates.news.page && this.props.corporateActions.getBlogsPage();
  }

  paginate() {
    let state = this.props.corporates.news;
    // return if corporates are fetching data
    if (this.props.corporates.fetching) return;
    if (this.page >= state.max_pages) {
      this.props.corporateActions.gotMaxNews();
      window.removeEventListener("scroll", this.handleScroll);
      return;
    }
    this.page++;
    this.getMoreNews();
  }

  renderHotBlogs = () => {
    let state = this.getContent({ key: 'hot_news', defaultReturn: [] });//this.props.corporates.news.hot_news;
    return state.map((posts, index) => {
      let imgUrl =
        posts.better_featured_image !== null &&
        posts.better_featured_image.source_url;
      let NewImg = {
        backgroundImage: `url(${imgUrl})`
      };
      return (
        // (posts.acf.featured_small !== false ||
          // posts.acf.featured_big !== false) && (
          <div
            className={
              posts.acf.featured_small !== false
                ? "news-widget-newest sm-widget"
                : "news-widget-newest bg-widget"
            }
            key={index}
          >
            <Link
              to={`/blog/${posts.slug}`}
              // onClick={() => Utils.scrollTo(0, 0)}
              style={NewImg}
            >
              <div className="hidden-box">
                <div className="box-content">
                  <strong>جديد</strong>
                  <p>{posts.title.rendered}</p>
                  <span>{moment(posts.date).format("ddd D MMMM  YYYY")}</span>
                </div>
              </div>
            </Link>
          </div>
        // )
      );
    });
  };
  renderNewBlogs = () => {
    let state =  this.getContent({ key: 'data', defaultReturn: [] }); //this.props.corporates.news;
    return state.map((posts, index) => {
      let imgUrl =
        posts.better_featured_image !== null &&
        posts.better_featured_image.source_url;
      let NewImg = {
        backgroundImage: `url(${imgUrl})`
      };

      return (
        // posts.acf.featured_small === false ||
        // (posts.acf.featured_big === false && (
          <div className="item">
            <Link
              to={`/blog/${posts.slug}`}
              className={
                posts.better_featured_image !== null
                  ? "news-widget"
                  : "news-widget empty-widdget"
              }
              key={index}
              // onClick={() => Utils.scrollTo(0, 0)}
            >
              {posts.better_featured_image !== null && (
                <div className="news-image" style={NewImg} />
              )}
              <div className="news-content">
                {posts.better_featured_image === null && (
                  <i className="material-icons">play_arrow</i>
                )}
                <p>{posts.title.rendered}</p>
                <span>{moment(posts.date).format("ddd D MMMM  YYYY")}</span>
              </div>
            </Link>
          </div>
        // ))
      );
    });
  };
  renderBlogLayout(blogsPage) {
    return (
      <div className="page-content news-page">
        <div className="page-title news-title">
          <div className="container">
            {this.props.title ? (
              <h2 className="single-title">{this.props.title}</h2>
            ) : (
              <React.Fragment>
                <h1>{blogsPage.title.rendered}</h1>
                <div dangerouslySetInnerHTML={{ __html: blogsPage.content.rendered }}></div>
              </React.Fragment>
            )}
          </div>
        </div>
        <div className="container" ref={ref => (this.scrollRef = ref)}>
          {/* <div className={this.props.corporates.fetching ? "news-loader active" : "news-loader"} /> */}
          <div className="news-list">
            <div className="news-list-con">
              {this.renderHotBlogs()}
              {this.renderNewBlogs()}
            </div>
          </div>

          <div className={styles.news_actions}>
            <div
              className={`${styles.load_more} ${
                this.props.corporates.fetching ? styles.bounce : ""
              }`}
            >
              <img src="/images/loader-icon.png" alt="icon" />
            </div>
            {!this.props.corporates.news.got_max_news && (
              <React.Fragment>
                {/* <a className="btn" onClick={this.paginate}>
                                المزيد من الأخبار
                            </a> */}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }

  render() {
    let blogsPage =  this.getContent({ key: 'page', defaultReturn: { title: {}, content: {} } })

    let LayoutWrapper = this.props.noTitle
      ? props => props.children
      : MainLayoutComponent;

    return (
      <LayoutWrapper
        page={this.props.location ? this.props.location.pathname : "/blogs"}
      >
        {this.props.noTitle ? null : (
          <Helmet key={window.location.href} >
            <title>
              {blogsPage ? blogsPage.title.rendered : "Blogs | Go Bus"}
            </title>
            {buildYoastMetaTags(blogsPage.yoast_meta).map(tag => (
              <meta {...tag} />
            ))}
          </Helmet>
        )}
        {/* {this.props.corporates.fetching && <AboutPlaceholder />  }  */}
        {this.renderBlogLayout(blogsPage)}
      </LayoutWrapper>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BlogsComponent);
