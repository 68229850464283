import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';

// Actions 
import * as SearchActions from '../../Actions/SearchActions'
import * as TripActions from '../../Actions/TripActions'
import Utils from '../../Helpers/Utils';
import ErrorComponent from '../Utils/ErrorComponent';
import { Trans } from 'react-i18next';

function mapStateToProps(store) {
    return {
        locale: store.locale,
        userStore: store.user,
        searchStore: store.search,
        tripsStore:store.trips
    }
}

function mapDispatchToProps(dispatch) {
    return {
        searchActions: bindActionCreators(SearchActions, dispatch),
        tripActions: bindActionCreators(TripActions, dispatch),
    }
}

class UserInfoComponent extends Component {
    constructor(props){
        super(props)

        this.state = {
            bookForAnotherPerson:false
        }

        this.numberMap = {
			"١": "1",
			"٢": "2",
			"٣": "3",
			"٤": "4",
			"٥": "5",
			"٦": "6",
			"٧": "7",
			"٨": "8",
			"٩": "9",
			"٠": "0"
		};

        this.name = '';
        this.phone = '';

        // Methods
        this.handleBookingForAnotherPerson = this.handleBookingForAnotherPerson.bind(this)
        this.getOtherPassengerName = this.getOtherPassengerName.bind(this)
        this.getOtherPassengerPhone = this.getOtherPassengerPhone.bind(this)
        this.updateOtherPassengerData = this.updateOtherPassengerData.bind(this)


    }

    /**
     * show/hide book for another passenger Form
     * @author Ezzat
     */
    handleBookingForAnotherPerson(){
        this.props.tripActions.setOtherPassengerSelected(!this.props.tripsStore.otherPassengerSelected);
    }

    /**
     * getting Other Passenger name
     * @author Ezzat
     */
    getOtherPassengerName(input){
        this.name = input.target.value
        this.updateOtherPassengerData()
    }
    
    /**
     * getting Other Passenger Phone
     * @author Ezzat
     */
    getOtherPassengerPhone(input){
        this.phone = input.target.value;
        this.phone = this.phone.replace(/./g, (m) => {
			if (this.numberMap[m]) {
				return this.numberMap[m];
			}
			return m;
		});
        this.updateOtherPassengerData()
    }

    /**
     * Setting the OtherPassenger Data in the Store
     * @author Ezzat
     */
    updateOtherPassengerData(){
        let otherPassenger = {
            name:this.name,
            phone:this.phone,
        }
        this.props.tripActions.setOtherPassengerData(otherPassenger)
    }

    render(){
        return(
            <div className="user-widget">
                <div className="booking-user-data">
                    <img src={this.props.userStore.user.Pic_Url || "/images/img_avatar.png"} alt="" />
                    <div className="txt">
                        <p className="black">{this.props.userStore.user.UserName}</p>
                        <p>{this.props.userStore.user.UserEmail}</p>
                        <p>{this.props.userStore.user.UserTelNo}</p>
                    </div>
                </div>
                <div className="book-for">
                    <label htmlFor="checkbox1" className="checkbox" onChange={this.handleBookingForAnotherPerson}>
                        <input type="checkbox" id="checkbox1" checked={this.props.tripsStore.otherPassengerSelected}/>
                        <span className="switch-icon"></span>
                        <span className="name"><Trans i18nKey="checkout_page.customer.beneficiary_checkbox">شخص آخر سوف يقدم التذاكر أثناء السفر</Trans></span>
                    </label>
                    {
                        (this.props.tripsStore.otherPassengerSelected)&&
                        <div className="book-for-form">
                            <ErrorComponent animated={true} state={this.props.tripsStore.otherPassengerErrors.length} errors={this.props.tripsStore.otherPassengerErrors} />
                            <div className="input-field">
                                <label><Trans i18nKey="common.beneficiary_name">اسم المستفيد</Trans></label>
                                <input type="text" onChange={this.getOtherPassengerName} />
                            </div>
                            <div className="input-field">
                                <label><Trans i18nKey="common.beneficiary_phone">رقم هاتف المستفيد</Trans></label>
                                <input type="tel" onKeyPress={e => (Utils.isNumberKey(e))} minLength="11" maxLength="11" onChange={this.getOtherPassengerPhone}/>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserInfoComponent)
