// Libraries
import React from "react";

const ErrorComponent = props => {
  let { errors, page, state, animated } = props;
  let rendered = <p>- Un Expected Error</p>;

  if (typeof errors === "string") {
    rendered = <p>- {errors}</p>;
  } else if (Array.isArray(errors)) {
    rendered = errors.map((o, i) => <p key={i}>- {o}</p>);
  } else if (Object.keys(errors).length) {
    rendered = Object.keys(errors).map((o, i) => <p key={i}>- {errors[o]}</p>);
  }

  if (page === "tickets" || animated) {
    return (
      <React.Fragment>
        <div
          className={
            state ? "error-validition height200" : "error-validition height0"
          }
        >
          {rendered}
        </div>
      </React.Fragment>
    );
  }

  return <div className="error-validition">{rendered}</div>;
};

export default ErrorComponent;
