import { combineReducers } from 'redux'

import { reducer as formReducer } from 'redux-form';
import { loadingBarReducer } from 'react-redux-loading-bar'

// My Reducers
import { UserReducer } from './UserReducer'
import { SearchReducer } from './SearchReducer'
import { TripReducer } from './TripReducer'
import { CorporateReducer } from './CorporateReducer'
import GTMReducer from './GTMReducer';
import LocaleReducer from './LocaleReducer';
import AppReducer from './AppReducer';



// get all reducers and link it to the state
export default combineReducers({
    user: UserReducer,
    trips: TripReducer,
    search: SearchReducer,
    app: AppReducer,
    loadingBar: loadingBarReducer,
    corporate: CorporateReducer,
    form: formReducer,
    gtm: GTMReducer,
    locale: LocaleReducer
});



