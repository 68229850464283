import Axios from 'axios';
import _ from "lodash";
import url from "url";
import Store from '../Store';
import { logout } from '../Actions/UserActions';

let sessExpiredOnce = false;

// Add a response interceptor
Axios.interceptors.response.use(function (response) {
  const path = url.parse(response.config.url).pathname;

  // reset sessExpiry on login
  if (path === '/auth/user/login' || path === '/auth/user/facebook-login') {
    sessExpiredOnce = false;
  }

  // Do something with response data
  if (_.get(response.data, 'data.message.message') === 'jwt expired' && !sessExpiredOnce) {
    alert('Your session has expired, please re-login!');
    sessExpiredOnce = true;
    Store.dispatch(logout());
  } else {
    return response;
  }
}, function (error) {
  
  if (error.message === 'Network Error') {
    alert('Network error, please try again later.');
  }

  return Promise.reject(error);
});