import React from 'react';

// Components 
import ErrorComponent from '../../Utils/ErrorComponent'
import { Trans } from 'react-i18next';

const LoginForm = (props) => {
    let { toggleRegisterForm, setEmail, setPassword, showForgotPasswordForm, resetPasswordSent, login, hasError,  errorMessage} = props

    return(
        <form className="login-form active" name="user-auth-form" autocomplete="on"  onSubmit={login}>
            {
                resetPasswordSent
                    ? <h4>تم ارسال رسالة على بريدك الإلكتروني.</h4>
                    : <React.Fragment>
                        <h4><Trans i18nKey="auth.login_email.heading">دخول حساب</Trans></h4>
            <h4>
                {(hasError)   &&
                    <ErrorComponent 
                        errors={errorMessage}
                    />
                }    
            </h4>
            
            <div className="input-field">
                <label><Trans i18nKey="common.email">البريد الإلكتروني</Trans></label>
                <input
                    type="email"
                    name="user-email"
                    autoComplete="on"
                    onChange={setEmail.bind(this)}
                />
            </div>
            <div className="input-field">
                <label><Trans i18nKey="common.password">كلمة السر</Trans></label>
                <input
                    type="password"
                    name="user-password"
                    onChange={setPassword.bind(this)}
                />
                <p className="lost_password" onClick={showForgotPasswordForm}>
                <Trans i18nKey="auth.login_email.forgot_password.redirect_btn">نسيت كلمة السر؟</Trans>
                </p>
            </div>
            <button type="submit" className="btn btnSubmit"><Trans i18nKey="auth.login_email.login_btn">دخول حساب</Trans></button>
            
            <div className="login-foot">
                <button
                    type="button"
                    className="btn btn-new-acc"
                    onClick={() => {  toggleRegisterForm() }} >
                    <i className="material-icons">account_box</i>
                    <Trans i18nKey="auth.login.register_new">تسجيل حساب جديد بالإيميل</Trans>
                </button>
            </div>
                    </React.Fragment>
            }
        </form>
    )
}
export default LoginForm


