import React, { Component } from "react";
import { Link } from "react-router-dom";
import ProfileNavigationComponent from "./ProfileNavigationComponent";
import { ClickAwayListener } from "@material-ui/core";
import InputHandler from "../../Helpers/InputHandler";

import { connect } from "react-redux";
import { Trans } from 'react-i18next';

class ProfileMenuComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false
    };

    this.defaults = {
      profile_picture: "/images/img_avatar.png"
    };
  }

  componentDidMount() {
    InputHandler.listen(this.handleEscapeBtn);
  }

  handleEscapeBtn = key => {
    if (key === InputHandler.KEYS.Escape && this.state.active) {
      this.toggle();
    }
  };

  toggle = () => {
    this.setState({
      active: !this.state.active
    });
  };

  close = () => {
    if (!this.state.active) return;

    this.setState({
      active: false
    });
  };

  render() {
    const isNameEN = this.props.username.charCodeAt(0) <=126;
    return (
      // <div className="header-btn">
      <ClickAwayListener onClickAway={this.close}>
        <div
          onClick={() => {
            if (window.innerWidth <= 767) {
              this.props.toggleMobileNavMenu("user");
            } else this.toggle();
          }}
          className="profile-menu-component"
        >
          <div
            className={
              this.props.userStore.loading
                ? "pm-btn component-loader"
                : "pm-btn"
            }
          >
            <div>
              <img
                src={this.props.profile_picture !== "undefined"? this.props.profile_picture || this.defaults.profile_picture: this.defaults.profile_picture}
                alt="image"
              />
              <h4><Trans i18nKey="header.profile_menu.myaccount">حسابي</Trans></h4>
            </div>
            <div>
              {!this.state.active && this.props.totalPoints && (
                <ul class="points">
                  <li>
                    <span className="box">
                      <span className="box-points upper">{this.props.totalPoints}</span>
                      <span className="box-points lower"><Trans i18nKey="cart.points.point">نقطه</Trans></span>
                    </span>
                  </li>
                </ul>
              )}
              <a>
                <h4
                >
                {isNameEN && <><Trans i18nKey="header.profile_menu.welcome">مرحباً</Trans>{" "}</>}
                {this.props.username}
                {!isNameEN && <>{" "}<Trans i18nKey="header.profile_menu.welcome">مرحباً</Trans></>}
                
                </h4>
                <i className="material-icons">arrow_drop_down</i>
              </a>
            </div>

            {/* <Link to="/profile/edit">
          <button className="btn">حسابي</button>
        </Link> */}
          </div>
          <div className={this.state.active ? "pm-menu active" : "pm-menu"}>
            <ProfileNavigationComponent
              showPointsProgram
              arrowTop={true}
              logout={this.props.logout}
              active={this.state.active}
            />
          </div>
        </div>
      </ClickAwayListener>
    );
  }
}

function mapStateToProps(store) {
  return {
    userStore: store.user,
    totalPoints: store.user.points.total
  };
}

export default connect(mapStateToProps)(ProfileMenuComponent);
