import React, { Component } from "react";
import { connect } from "react-redux";

import MainLayoutComponent from "../MainLayoutComponent";
import _ from "lodash"; //
import "./Rating.css";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import i18next from "i18next";
import RatingStar from "./RatingStars.jsx";

import * as RatingApi from "../../Api/RatingApi";
import debug from "../../Helpers/DebugLog";
import clsx from "clsx";

import moment from "moment";
import { FullDateNoYear, tripTimeSegment } from "../../Helpers/TimeParser";
import ErrorComponent from "../Utils/ErrorComponent";
import NodeAuth from "node-auth-api";
import Store from "../../Store";
import { toggleAuthPopUp } from "../../Actions/UserActions";
import SuccessComponent from "../Utils/SuccessComponent";

class Rating extends Component {
	state = {
		loading: true,
		error: null,
		trip_found: true,
		rating_trip: false,
		show_rating_success: false,
		tripData: {
			bookingID: null,
			bookingDate: null,
			tripDate: null,
			trip: {},
			passengers: null,
			oldRating: null
		},

		bus_cleanliness: 0,
		driver_performance: 0
	};

	getTripCount = 0;

	componentWillMount() {
		if (!this.props.user.auth) {
			Store.dispatch(toggleAuthPopUp());
		}
	}

	goToHomePage = () => {
		this.props.history.push('/');
	}

	componentWillReceiveProps(newProps) {
		// user was not logged in and is currently logged in
		if (!this.props.user.auth && newProps.user.auth) {
			this.getTrip(newProps.user.user.UserEmail);
		}
		// user was already logged in and gettrips wasn't called
		else if (this.props.user.auth && !this.getTripCount) {
			this.getTrip(newProps.user.user.UserEmail);
        }
        
        if (!newProps.user.show_auth_popup && !this.props.user.auth) {
            this.goToHomePage();
        }
	}
//[CHECK] - update trip data presenting
	async getTrip(email) {
		this.getTripCount++;
		let req_item = this.props.match.params; //trip_id, trip_sub_id
		let ticketID = req_item.trip_id;
		let ticketSubID = req_item.trip_sub_id;
		console.log('Rating - check trip params, email:', email,'Trip_ID:',ticketID,'Sub_ID:',ticketSubID);

		const goingTrip = await RatingApi.getTrip(ticketID, ticketSubID, email);
		console.log('Request rating trip result:', goingTrip.data);
		//get the trip data
		const goTrip = goingTrip.data;
		
		//[SOLVED] - trips always not found
		if (!goTrip) {
			console.log('goTrip', goTrip);
            this.setState({ trip_found: false, loading: false });
            setTimeout(() => {
				this.goToHomePage();
            }, 3000);
			return;
		}
		
		let checkRated = null;
		if(goTrip.rating){
			checkRated = goTrip.rating;
		}
		console.log('check if old rating', checkRated);
		const myGoTrip = goTrip.myTrips[0];
		console.log("Trip details", myGoTrip);
		//set the trip data
		this.setState({
			loading: false,
			tripData: {
				bookingID: goTrip.CardID,
				bookingDate: goTrip.ActionDate,
				tripDate: myGoTrip.TripDateTime,
				trip: myGoTrip,
				passengers: myGoTrip.TripSeatsCount,
				oldRating: checkRated
			}
		});
	}
	render() {
		const { lang } = this.props.locale;

		let fromOfficeKey = "TripOfficeFrom_NameA";
		let toOfficeKey = "TripOfficeTo_NameA";
		let serviceKey = "TripServKind_NameA";

		if (lang === "en") {
			fromOfficeKey = "TripOfficeFrom_NameE";
			toOfficeKey = "TripOfficeTo_NameE";
			serviceKey = "TripServKind_NameE";
		}

		const trip_data = this.state.tripData;
		console.log('Current trip data:', trip_data);
		const classes = clsx([
			"ratingComponent",
			"bkGrundDiv",
			(this.state.loading || !this.props.user.auth) && "component-loader"
		]);

		const containerClasses = clsx(['ratingCont', this.state.rating_trip && "component-loader"]);

		return (
			<MainLayoutComponent>
				<div className={classes}>
					<div className={containerClasses}>
						{this.state.error && (
							<ErrorComponent errors={[this.state.error]} />
						)}
                        {!this.state.trip_found && <ErrorComponent errors={[i18next.t('rating.errors.trip_not_found', 'عفواً, لم يتم العثور على الرحلة')]} />}
						{this.state.show_rating_success && <SuccessComponent msg={i18next.t('rating.success_msg')} />}
						{!this.state.show_rating_success && this.getTripCount && this.state.trip_found && this.renderTripContent({ lang, fromOfficeKey, toOfficeKey, serviceKey, trip_data })}
					</div>
				</div>
			</MainLayoutComponent>
		);
	}

	renderTripContent = ({ lang, fromOfficeKey, toOfficeKey, serviceKey, trip_data }) => {
		return (
			<>
				<div className='headDiv'>
					<h1>
						<Trans i18nKey='rating.trip_rate'>
							تقييم رحلة جوباص
						</Trans>
					</h1>
					<h2>
						<Trans i18nKey='rating.trip_help'>
							تقييمك للرحلة يساعدنا كثيراً علي تطوير وتحسين مستوي
							الخدمة
						</Trans>
					</h2>
				</div>
				<div className='rateDiv'>
					<div className='ratingAct'>
						<div className='tripInfo'>
							<p>
								<Trans i18nKey='rating.booking_no'>
									رقم الحجز
								</Trans>
								<br />
								<span>{trip_data.bookingID}</span>
							</p>
							<p>
								<Trans i18nKey='rating.booking_date'>
									تاريخ إصدار الحجز
								</Trans>
								<br />
								<span> 
									{FullDateNoYear(
										moment(trip_data.bookingDate),
										lang
									)}
								</span>
							</p>
						</div>

						<div className='ratingOpt'>
							<p>
								<Trans i18nKey='rating.bus_rate'>
									تقييم نظافة الأتوبيس
								</Trans>
								<br />
								<RatingStar
									className='ratingStars'
									interactive
									value={this.state.bus_cleanliness}
									onChange={v =>
										this.setState({ bus_cleanliness: v, error: null })
									}
								/>
							</p>
							<p>
								<Trans i18nKey='rating.driver_rate'>
									تقييم كفاءة السائق
								</Trans>
								<br />
								<RatingStar
									className='ratingStars'
									interactive
									value={this.state.driver_performance}
									onChange={v =>
										this.setState({ driver_performance: v, error: null })
									}
								/>
							</p>
						</div>

						<button className='rateBtn' onClick={this.rateTrip}>
							<Trans i18nKey='rating.rate_Btn'>
								أضغط للتقييم
							</Trans>
						</button>
					</div>
				</div>
				<div className='detailsDiv'>
					<div className='tripDetails'>
						<p className='tgoTitle'>
							<i className='fas fa-check' />
							<Trans i18nKey='thanks_page.trips.going_trip'>
								رحلة الذهاب
							</Trans>
						</p>
						<p className='tripDate'>
							{FullDateNoYear(moment(trip_data.tripDate), lang)}
						</p>
						<p className='tripTime'>
							<span>
								{moment(trip_data.tripDate)
									.locale("en")
									.format("hh:mm")}
							</span>
							{tripTimeSegment(trip_data.tripDate, null, lang)}
						</p>
						<p className='tDetails'>
							<Trans i18nKey='thanks_page.travel_from'>
								سفر من
							</Trans>
							: {trip_data.trip[fromOfficeKey]}
							<br />
							<Trans i18nKey='thanks_page.travel_to'>
								وصول إلي
							</Trans>{" "}
							: {trip_data.trip[toOfficeKey]}
							<br />
							<Trans i18nKey='thanks_page.trips.class_type'>
								نوع الخدمة
							</Trans>
							: {trip_data.trip[serviceKey]}
							<br />
							<Trans i18nKey='common.passengers_no'>
								عدد المسافرين
							</Trans>
							: {trip_data.passengers}
						</p>
					</div>
				</div>
				<div className='complainsDiv'>
					<div className='contactInfo'>
						<h3>
							<Trans i18nKey='thanks_page.complaints_inquiries.title'>
								الأستعلام و الشكاوي
							</Trans>
						</h3>
						<p>
							<Trans i18nKey='thanks_page.complaints_inquiries.headline'>
								يمكنا تقديم المساعدة والرد علي كل تسأولتكم من
								خلال موقع الشركة،
							</Trans>
							<Link to='/contact-us' target='_blank'>
								<Trans i18nKey='thanks_page.complaints_inquiries.click_here'>
									أظغط هنا.
								</Trans>
							</Link>
							<br />
							<Trans i18nKey='thanks_page.complaints_inquiries.subline'>
								أو الأتصال علي رقم ١٩٥٦٧
							</Trans>
						</p>
						<img src='/images/go-bus-dark.png' alt='gologo' />
					</div>
				</div>
			</>
		);
	};

	rateTrip = async () => {
		// validate user selected ratings, rating can't be zero.
		if (!this.validateRating()) {
			// update state with an error to the user
			return this.setState({
				error: i18next.t(
					"rating.errors.missing_rating",
					"رجاءا اختر تقيم لكل العناصر"
				)
			});
		}

		// update state with success
		this.setState({ rating_trip: true, error: null });

		const trip_id = _.get(this.props, "match.params.trip_id");
		const trip_sub_id = _.get(this.props, "match.params.trip_sub_id");
		const bus_cleanliness = this.state.bus_cleanliness;
		const driver_performance = this.state.driver_performance;
		const email = _.get(this.props, "user.user.UserEmail");
		const oldRating = this.state.tripData.oldRating;
		//Note: oldRating --> if going trip is rated. newRating --> if return trip to be rated
		console.log('check if previous rating:', oldRating);

		const result = await RatingApi.rateTrip(
			{ email: email, trip_id },
			{trip_sub_id, bus_cleanliness, driver_performance },
			oldRating
		);

		// validate result
		if (!result.success) {
			// update state with an error
			// TODO: Complete
			return this.setState({
				error: i18next.t("rating.errors.failed_to_rate", "حدث خطأ ما"),
				rating_trip: false
			});
		}

		// update state with success
		this.setState({ show_rating_success: true, rating_trip: false, error: null });
		setTimeout(() => {
			this.goToHomePage();
		}, 3000);
	};

	validateRating = () => {
		const bus_cleanliness = this.state.bus_cleanliness;
		const driver_performance = this.state.driver_performance;
		return (
			typeof bus_cleanliness === "number" &&
			typeof driver_performance === "number" &&
			bus_cleanliness > 0 &&
			driver_performance > 0
		);
	};
}

function mapStateToProps(state) {
	return {
		locale: state.locale,
		user: state.user
	};
}

export default connect(mapStateToProps)(Rating);
