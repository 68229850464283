import { EventEmitter } from "events";
import moment from "moment";
import store2 from "store2";
import { startHeldSeatsCountdown } from "../Actions/TripActions";
import { TiktokPixelHelper } from "../Helpers";
import Facebook from "../Helpers/FacebookHelper";
import { FullStory } from "../Helpers/FullStory";
import Utils from "../Helpers/Utils";
import { useCheckoutStore } from "../new-store/checkoutStore";
import Store from "../Store";

export const eventMgr = new EventEmitter();

eventMgr.on("held_seats", function(GBCard, history) {
    const state = useCheckoutStore.getState();

    // init hold timer
    const base_hold_time = moment().add(10, "minutes");
    store2.set("base_seats_hold_time", base_hold_time);

    // timer start
    Store.dispatch(startHeldSeatsCountdown());

    // set card id for fullstory
    FullStory.setUserVars(GBCard.CardID);

    // update cart id
    useCheckoutStore.setState({ cartId: GBCard.CardID });

    // update total price
    useCheckoutStore.getState().setTotalPrice(GBCard.totalPrice);

    // save part of the state in localstorage
    useCheckoutStore.getState().saveState();

    // send facebook add to cart pixel
    Facebook.sendAddToCartPixel();

    // send tiktok add to cart pixel
    TiktokPixelHelper.sendAddToCartPixel();

    // redirect to checkout page
    Utils.scrollTo({ top: 0, behavior: "smooth" });
    if (window.location.pathname.toLowerCase() !== "/booking" && history) {
        const location = {
            pathname: `/Booking`
        };
        if (history) {
            history.push(location);
        } else {
            window.location.replace(location.pathname);
        }
    }
});
