import React, { Component } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import SideBar from "./SideBar";

import { bindActionCreators } from "redux";
import * as UserActions from "../../../Actions/UserActions";

// Components
import moment from "moment";
import config, { CancelledCardStatus } from "../../../appConfig";
import MainLayoutComponent from "../../MainLayoutComponent";
import SuccessComponent from "../../Utils/SuccessComponent";

import i18next from 'i18next';
import { Trans } from 'react-i18next';

function mapStateToProps(store) {
  return {
    getting_tickets: store.user.getting_tickets,
    locale: store.locale,
    userStore: store.user,
    tripsStore: store.trips
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(UserActions, dispatch)
  };
}

class MyBookings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSuccess: false
    };
    this.handleClick = () => {
      // console.log("Parent got the click");
    };
  }

  componentWillMount() {
    // this.props.userActions.preLogin();
    if (process.title === "browser") {
      this.props.userActions
        .getTickets()
        .then(() => {
          // setTimeout(() => {
          //   if (this.parent) this.parent.classList.add("active");
          // }, 100);
        })
        .catch(err => {
          // offf!!
        });
    }
  }

  componentDidMount() {
    // setTimeout(() => {
    //   this.parent.classList.add("active");
    // }, 200);
  }

  showSuccess = () => {
    this.setState({
      showSuccess: true
    });
    this.props.userActions.getTickets();
  };

  hideSuccess = () => {
    this.setState({
      showSuccess: false
    });
  };
 
  render() {
    if (!this.props.userStore.auth) return null;
    let tickets = this.props.userStore.tickets;
    const { lang } = this.props.locale;

    let { UserName, UserEmail, UserTelNo, Pic_Url } = this.props.userStore.user;

    return (
      <MainLayoutComponent page={this.props.location.pathname}>
        <Helmet key={window.location.href} >
          <title>My Tickets | Go Bus</title>
          <meta name="description" content="الحجوزات السابقة لك من جو باص" />
        </Helmet>
        <div className="page-content">
          <div className="AccPage">
            <div className="container">
              <div className="page-title">
                <h5><Trans i18nKey="profile_page.bookings.headline">حسابي</Trans></h5>
                <h1><Trans i18nKey="profile_page.bookings.subline">حجوزاتي السابقة</Trans></h1>
              </div>
              <div className="account-container">
                <SideBar
                  username={UserName}
                  email={UserEmail}
                  phone={UserTelNo}
                  profile_picture={Pic_Url}
                  logout={() =>
                    this.props.userActions.logout(this.props.history)
                  }
                  // currentPage={this.props.history.location.pathname} />
                />
                <div
                  className="account-content active"
                  ref={ref => (this.parent = ref)}
                >
                  <SuccessComponent
                    page="tickets"
                    msg={i18next.t('profile_page.cancel_ticket.success.booking_cancelled')}
                    state={this.props.tripsStore.cancel_card_success}
                  />
                  <Tickets
                    lang={lang}
                    tickets={tickets}
                    getting_tickets={this.props.getting_tickets}
                    userActions={this.props.userActions}
                    tripsStore={this.props.tripsStore}
                    showSuccess={this.showSuccess}
                    hideSuccess={this.hideSuccess}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainLayoutComponent>
    );
  }
}

class Tickets extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isActiveFullCancellation: false,
      isActiveSeatCancellation: false
    };
  }

  toggleFullCancellation = ticket => {
    this.setState({
      isActiveFullCancellation: !this.state.isActiveFullCancellation,
      ticket,
      cancel_ticket_error: false,
      errMsg: []
    });
  };
  toggleSeatCancellation = ticket => {
    this.setState({
      isActiveSeatCancellation: !this.state.isActiveSeatCancellation,
      ticket,
      cancel_ticket_error: false,
      errMsg: []
    });
  };

  cancelBooking = () => {
    let checkbox = document.querySelector("#full-cancel-terms");

    if (!checkbox.checked) {
      this.setState({
        cancel_ticket_error: true,
        errMsg: [i18next.t('common.errors.accept_terms', 'من فضلك وافق على الشروط و الاحكام')]
      });
      return;
    }
    this.props.userActions
      .cancelTicket(this.state.ticket)
      .then(res => {
        if (res.data && res.data.result === "SUCCESS") {
          this.setState({
            cancel_ticket_error: false,
            isActiveFullCancellation: !this.state.isActiveFullCancellation
          });
          this.props.showSuccess();
          setTimeout(() => {
            this.props.hideSuccess();
          }, 5000);
        } else {
          // something else
          let error = i18next.t('profile_page.cancel_ticket.errors.unhandled_error', 'لم نتمكن من إلغاء الحجز');

          if (res.msg === 'invalid_refund_amount') {
            error = i18next.t('profile_page.cancel_ticket.errors.invalid_refund_amount', "لا يمكن إلغاء هذا الحجز مع العلم انه يتم خصم 15جنيها لكل كرسي بالرحلة");
          } else
          if (res.msg === 'mpgs_refund_failed') {
            error = i18next.t('profile_page.cancel_ticket.errors.mpgs_refund_failed', "تم إلغاء الحجز و لاكن فشل إرجاع المبلغ المستحق, سيتواصل معك احد ممثلي خدمة العملاء في اقرب وقت.");
          } else
          if (res.msg === 'gobus_cancel_time_error') {
            error = i18next.t('profile_page.cancel_ticket.errors.gobus_cancel_time_error', "لا يمكن الغاء الحجز في هذا الوقت.");
          } else
          if (res.msg === 'gobus_seats_error') {
            error = i18next.t('profile_page.cancel_ticket.errors.gobus_seats_error', "خطأ في الكراسي");
          }

          this.setState({
            cancel_ticket_error: true,
            errMsg: [error]
          });
        }
      })
      .catch(err => {
        console.error(err);
        this.setState({
          cancel_ticket_error: true,
          errMsg: [i18next.t('common.errors.general', 'لقد حدث خطاً ما.')]
        });
      });
  };

  renderEmpty() {
    const { lang } = this.props;

    /** @type {import("react").CSSProperties} */
    const style = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "16px",
        minHeight: "452px",
        fontSize: "20px",
        fontWeight: "bold",
        color: "#6c707d"
    };

    /** @type {import("react").CSSProperties} */
    const iconStyle = {
      fontSize: '48px'
    };

    return (
        <div style={style}>
            <i className="fas fa-ticket-alt" style={iconStyle} />
            <p>{lang === "en" ? "No bookings available" : "لا يوجد حجوزات"}</p>
        </div>
    );
  }

  render() {
    // let FullCancellationClasses = this.state.isActiveFullCancellation
    //   ? "popup popup-cancel active"
    //   : "popup popup-cancel";
    let SeatCancellationClasses = this.state.isActiveSeatCancellation
      ? "popup popup-cancel active"
      : "popup popup-cancel";

    let { tickets, lang, getting_tickets } = this.props;

    if (!Array.isArray(tickets)) tickets = [];
    return (
        <div
            className={
                getting_tickets
                    ? "my-bookings BookingsList component-loader"
                    : "my-bookings BookingsList"
            }
        >
            {tickets.length ? (
                <table className="BookingTable">
                    {tickets.map(ticket => {
                        return (
                            <Ticket
                                key={ticket.CardID}
                                lang={lang}
                                ticket={ticket}
                                toggleSeatCancellation={
                                    this.toggleSeatCancellation
                                }
                                toggleFullCancellation={
                                    this.toggleFullCancellation
                                }
                            />
                        );
                    })}
                </table>
            ) : (
                this.renderEmpty()
            )}
        </div>
    );
  }
}

class Ticket extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isActiveAction: false
    };
  }

  toggleActionClasses = () => {
    this.setState({ isActiveAction: !this.state.isActiveAction });
  };

  BookingStatus = (status, cancelStatusID, fromDate) => {
    if (moment(fromDate).isSameOrBefore(moment())) return null;
    switch (status) {
      case 3:
        return <span className="booking-status temporary"><Trans i18nKey="profile_page.bookings.statuses.pending">حجز مؤقت</Trans></span>;
      case 4:
        const defaultConfig = { className: 'confirmed', i18nKey: null, initialText: null };
        const statusMap = {
          [CancelledCardStatus.FULL_CANCEL]: { className: 'cancelled', i18nKey: 'cancelled', initialText: 'حجز ملغي' },
          [CancelledCardStatus.PARTIAL_CANCEL]: { className: 'confirmed', i18nKey: null, initialText: null },
          [CancelledCardStatus.NOT_CANCELLED]: { className: 'confirmed', i18nKey: null, initialText: null }
        }

        let config = statusMap[cancelStatusID];
        if (!config) {
          config = defaultConfig;
        }

        return (
          <span className={`booking-status ${config.className}`}><Trans i18nKey={`profile_page.bookings.statuses.${config.i18nKey || 'paid'}`}>{config.initialText || 'حجز مدفوع ومؤكد'}</Trans></span>
        );

      default:
        return <span className="booking-status cancelled">حجز ملغي</span>;
    }
  };

  render() {
    let ActionsClasses = this.state.isActiveAction
      ? "actions active"
      : "actions";

    let { ticket, toggleSeatCancellation, toggleFullCancellation, lang } = this.props;

    const fromOfficeKey = lang === 'ar'? "TripOfficeFrom_NameA": "TripOfficeFrom_NameE";
    const toOfficeKey = lang === 'ar'? "TripOfficeTo_NameA": "TripOfficeTo_NameE";

    let date = ticket.ActionDate;
    let number = ticket.CardID;
    let type =
      ticket.myTrips && ticket.myTrips.length === 1 ? "oneway" : "twoway";

    let from = ticket.myTrips[0][fromOfficeKey];
    let to = ticket.myTrips[0][toOfficeKey];
    let fromDate = ticket.myTrips[0].TripDateTime;
    let toDate =
      ticket.myTrips && ticket.myTrips.length > 1
        ? ticket.myTrips[1].TripDateTime
        : null;

    let seatsCount = ticket.CardSeatsCount;
    let price = ticket.CardTotalPrice;
    let status = ticket.CardStatusID;

    //convert date to the required format
    const format = "dddd DD MMMM YYYY";
    date = moment(date).format(format);
    fromDate = moment(fromDate).format(format);
    toDate = toDate
      ? moment(toDate).format(format)
      : null;

    return (
      <React.Fragment>
        {/* <div className="BookingCard">
          <div className="BookingCard-top">

          </div>
          <div className="BookingCard-middle"></div>
          <div className="BookingCard-bottom"></div>
        </div> */}
        <tr className="BookingItem">
          <td>
            <div className="BookingItem_num">
              <h5 className="label"><Trans i18nKey="profile_page.bookings.booking_number">رقم الحجز</Trans></h5>
              <p>
                <Link
                  to={"/SingleBooking/" + ticket.CardID}
                  className="actions-box-link"
                >
                  {number}
                </Link>
              </p>
            </div>
          </td>
          <td>
            <div className="BookingItem_date">
              <h5 className="label"><Trans i18nKey="profile_page.bookings.booking_date">تاريخ الحجز</Trans></h5>
              <p>{date}</p>
            </div>
          </td>
          <td>
            <div className="BookingItem_type">
              <h5 className="label"><Trans i18nKey="profile_page.bookings.booking_type">نوع الحجز</Trans></h5>
              <p>{type === "oneway" ? i18next.t('profile_page.bookings.oneway', { defaultValue: 'ذهاب' }) : i18next.t('profile_page.bookings.round', { defaultValue: 'ذهاب وعودة' })}</p>
            </div>
          </td>
          <td className="BookingItem_details_td">
            <div className="BookingItem_directions">
              <div className="directions">
                <p>
                  <span><Trans i18nKey="profile_page.bookings.travel_from">سفر من</Trans></span>{" "}
                  {from.includes("-")
                    ? from.slice(0, from.indexOf("-") - 1)
                    : from}
                </p>
                {/* <p>{from.includes('-')? from.slice(0, from.indexOf('-') - 1) : from}</p> */}
                <div className="track">
                  <span className="line" />
                </div>
                <p>
                  {" "}
                  <span><Trans i18nKey="profile_page.bookings.travel_to">وصول إلي</Trans></span>{" "}
                  {to.includes("-") ? to.slice(0, to.indexOf("-") - 1) : to}
                </p>
                {/* <p>{to.includes('-')? to.slice(0, to.indexOf('-') - 1) : to}</p> */}
              </div>
              <div className="details">
                <p>
                  <Trans i18nKey="profile_page.bookings.travel">سفر</Trans> {fromDate} {toDate ? i18next.t('profile_page.bookings.and_return', { defaultValue: 'والعودة ' })+ " " + toDate : null}
                </p>
              </div>
            </div>
          </td>
          <td>
            <div className="BookingItem_details">
              <div className="base-details">
                <h5 className="label">{seatsCount} <Trans i18nKey="common.chairs">كراسي</Trans></h5>
                <h4 className="price">
                  {price}.00<span className="symbol"><Trans i18nKey="common.currency">جنيه</Trans></span>
                </h4>
                {this.BookingStatus(status, ticket.cardCancelID, ticket.myTrips[0].TripDateTime)}
              </div>
              <div className={ActionsClasses}>
                <button
                  className="actions-open material-icons"
                  onClick={() => {
                    this.toggleActionClasses();
                  }}
                >
                  more_vert
                </button>
                <div className="actions-box">
                  <button
                    className="actions-close material-icons"
                    onClick={() => {
                      this.toggleActionClasses();
                    }}
                  >
                    close
                  </button>

                  <Link
                    to={"/profile/tickets/" + ticket.CardID}
                    className="actions-box-link"
                  >
                    بيانات الحجز
                  </Link>
                  {ticket.CardTypeID === config.cardTypes.credit_card && (
                    <button
                      onClick={() => {
                        toggleFullCancellation(ticket);
                        this.toggleActionClasses();
                      }}
                      className="actions-box-link"
                    >
                      إلغاء الحجز بالكامل
                    </button>
                  )}

                  {/* <button
                  onClick={() => {
                    toggleSeatCancellation(ticket);
                    this.toggleActionClasses();
                  }}
                  className="actions-box-link"
                >
                  إلغاء جزئي للكراسي
                </button> */}
                </div>
              </div>
            </div>
          </td>
        </tr>
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyBookings);
