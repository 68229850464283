import Axios from "axios";
import { gobus_payUrl } from "./Base";

/*
rating:[
    {
        'trip_sud_id': 123,
        bus: {
                cleanliness: bus_cleanliness
            },
            driver: {
                performance: driver_performance
            }
    },
    {
        'trip_sud_id': 123,
        bus: {
                cleanliness: bus_cleanliness
            },
            driver: {
                performance: driver_performance
            }
    }
]
 */
export async function rateTrip({ email, trip_id },
    { trip_sub_id, bus_cleanliness, driver_performance },
    oldRating) {

    let rating_array = [];
    //current trip rating data
    let ratingData = {
        trip_sub_Id: trip_sub_id,
        bus: {
            cleanliness: bus_cleanliness
        },
        driver: {
            performance: driver_performance
        }
    };

    if(oldRating && oldRating.length){
        console.log('Going rate:',oldRating[0]);
        rating_array.push(oldRating[0]);
    }
    rating_array.push(ratingData);
    console.log('Final Rating Array to be inserted in db:', rating_array);

    const url = `${gobus_payUrl}/rate/${trip_id}`;
    const result = await Axios.post(url, {
        user: {
            UserEmail: email,
        },
        trip_subID: trip_sub_id,
        rating: rating_array
    });
    console.log('rating result:',result.data);
    return result.data;
}
//[CHECK] - edit url
export function getTrip (trip_id, trip_sub_id, email){
    return Axios.get(`${gobus_payUrl}/ticket/${trip_id}/${trip_sub_id}?userEmail=${email}`)
        .then(res => {
            console.log('[Rating Round] - get trip data result', res.data);
            return res.data;
        }).catch(err => {
            console.log('Error ', err);
            return err;
        });

}

//GET http://localhost:3003/gobus/ticket/1994262?userEmail=karim.diab@mitchdesigns.com

