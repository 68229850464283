import React, { Component } from "react";
import Helmet from "react-helmet";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// Actions
import * as UserActions from "../../Actions/UserActions";

// Components
import MainLayoutComponent from "../MainLayoutComponent";
import SideBar from "../Pages/Account/SideBar";
import LoaderComponent from "../Utils/LoaderComponent";
import ErrorComponent from "../Utils/ErrorComponent";
import SuccessComponent from "../Utils/SuccessComponent";
import Utils from "../../Helpers/Utils";
import { Trans } from 'react-i18next';
import i18next from 'i18next';

import NodeAuth from "node-auth-api";
import Store from '../../Store';
import { logout, isLoggedIn } from '../../Actions/UserActions';
function mapStateToProps(store) {
  return {
    locale: store.locale,
    userStore: store.user,
    searchStore: store.search,
    tripsStore: store.trips
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(UserActions, dispatch)
  };
}

class EditProfileComponent extends Component {
  constructor(props) {
    super(props);

    const user = props.userStore.user;

    this.numberMap = {
        "١": "1",
        "٢": "2",
        "٣": "3",
        "٤": "4",
        "٥": "5",
        "٦": "6",
        "٧": "7",
        "٨": "8",
        "٩": "9",
        "٠": "0"
    };

    this.state = {
      isActiveAction: false,
      errors: [],

      userId: user.UserID,
      username: user.UserName,
      "phone-number": user.UserTelNo,
      email: user.UserEmail
    };

    this.fields = [
      {
        name: "username",
        label: "الأسم بالكامل",
        i18nKey: 'common.fullname',
        type: "text",
        value: this.state["username"]
      },
      {
        name: "phone-number",
        label: "رقم المحمول",
        i18nKey: 'common.phone',
        type: "tel",
        value: this.state["phone-number"]
      },
      {
        name: "email",
        label: "البريد الإلكتروني",
        i18nKey: 'common.email',
        type: "email",
        value: this.state["email"]
      }
    ];
  }

  componentWillMount() {
      // this.props.userActions.preLogin();
  }

  componentDidMount() {
    this.props.userActions.reset();
    setTimeout(() => {
      this.parent.classList.add("active");
    }, 100);
  }

  toggleActionClasses = () => {
    this.setState({ isActiveAction: !this.state.isActiveAction });
  };

  handleChange = e => {
    let value = e.target.value;
    let newState = {
      ...this.state
    };

    value = value.replace(/./g, (m) => {
        if (this.numberMap[m]) {
            return this.numberMap[m];
        }
        return m;
    });

    newState[e.target.name] = value;
    this.setState(newState);
  };

  editProfile = () => {
    if(!isLoggedIn()){
      alert('Your session expired, Please Re-Login!');
      Store.dispatch(logout(this.props.history))
      //return
    }else{
      if (this.validate()) {
        this.props.userActions.editProfile({
          id: this.state.userId,
          name: this.state.username,
          email: this.state.email,
          telNo: this.state["phone-number"]
        });
      }
    } 
  };

  validate = () => {
    const errors = [];

    // validate username not empty
    if(!this.state.username) {
      errors.push(i18next.t('common.errors.username_missing', 'من فضلك ادخل الاسم'))
    }

    // validate phone number is valid
    if (!Utils.isValidPhone(this.state["phone-number"])) {
      errors.push(i18next.t('common.errors.invalid_phone_format', 'من فضلك ادخل بريد الكتروني صحيح'));
    }
    
    // validate email is valid
    if (!Utils.isValidEmail(this.state.email)) {
      errors.push(i18next.t('common.errors.invalid_email_format', 'من فضلك ادخل رقم هاتف صحيح'));
    }

    if (errors.length > 0) {
      this.setState({ errors: errors });
    } else {
      this.setState({ errors: [] });
      return true;
    }
  };

  render() {
    if (!this.props.userStore.auth) return null;
    // let ActionsClasses = this.state.isActiveAction
    //   ? "actions-box active"
    //   : "actions-box";

    let {
      editing_profile,
      edit_profile_result,
      errorMessage
    } = this.props.userStore;
    let { UserName, UserEmail, UserTelNo, Pic_Url } = this.props.userStore.user;

    return (
      <MainLayoutComponent page={this.props.location.pathname}>
        <Helmet key={window.location.href} >
          <title>Edit Profile | Go Bus</title>
        </Helmet>
        <div className="page-content">
          <div className="AccPage">
            <div className="container">
              <div className="page-title">
                <h5><Trans i18nKey="profile_page.headline">حسابي</Trans></h5>
                <h1><Trans i18nKey="profile_page.subline">بيانات حسابي</Trans></h1>
              </div>
              <div className="account-container">
                <SideBar
                  username={UserName}
                  email={UserEmail}
                  phone={UserTelNo}
                  profile_picture={Pic_Url}
                  logout={() =>
                    this.props.userActions.logout(this.props.history, true)
                  }
                />
                <div
                  className="account-content"
                  ref={ref => (this.parent = ref)}
                >
                  <div className="edit-account">
                    {this.state.errors.length > 0 && (
                      <ErrorComponent errors={this.state.errors} />
                    )}
                    {errorMessage && errorMessage.length > 0 ? (
                      <ErrorComponent errors={errorMessage} />
                    ) : (
                      edit_profile_result === "fail" && (
                        <ErrorComponent errors={[i18next.t('common.errors.general', 'لقد حدث خطاً ما.')]} />
                      )
                    )}
                    {edit_profile_result === "success" && (
                      <SuccessComponent msg={i18next.t('profile_page.edit_profile.success.data_updated', 'تم تعديل البيانات بنجاح.')} />
                    )}
                    <h3><Trans i18nKey="profile_page.edit_data">تعديل البيانات</Trans></h3>
                    <form>
                      {this.fields.map(field => {
                        return (
                          <div key={field.name} className="input-field">
                            <label htmlFor={field.name}><Trans i18nKey={field.i18nKey}>{field.label}</Trans></label>
                            <input
                              name={field.name}
                              type={field.type}
                              value={this.state[field.name]}
                              onChange={this.handleChange}
                            />
                          </div>
                        );
                      })}
                      <div className="input-field">
                        <button
                          type="button"
                          className="btn btn-orange"
                          onClick={() => {
                            !editing_profile && this.editProfile();
                          }}
                        >
                          {editing_profile ? (
                            <LoaderComponent
                              background-color="#fc9900"
                              foreground-color="#ffffff"
                            />
                          ) : (
                            <span><Trans i18nKey="profile_page.edit_btn">تعديل</Trans></span>
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainLayoutComponent>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditProfileComponent);
