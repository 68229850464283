import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// Components 
import LoginForm from './Views/LoginForm';
import RegisterForm from './Views/RegisterForm';
import ForgotPasswordForm from './Views/ForgotPasswordForm';
import AuthOptionsForm from './Views/AuthOptionsForm';

// Actions
import * as UserActions from '../../Actions/UserActions'; 
import debug from '../../Helpers/DebugLog';
import FBSignupForm from './Views/FBSignupForm';
import i18next from 'i18next';

function mapStateToProps(store) {
	return {
		userStore: store.user,
		searchStore: store.search,
		tripsStore: store.trips
	}
}

function mapDispatchToProps(dispatch) {
	return {
		userActions: bindActionCreators(UserActions, dispatch),
	}
}

class AuthComponent extends Component {

	constructor() {
		super()
		this.state = {
			showLoginForm:false,
			showForgotPasswordForm:false,
			showRegisterForm:false,
			showMainAuthOptions:true,
			loading: false,
		};

		this.numberMap = {
			"١": "1",
			"٢": "2",
			"٣": "3",
			"٤": "4",
			"٥": "5",
			"٦": "6",
			"٧": "7",
			"٨": "8",
			"٩": "9",
			"٠": "0"
		};

		// Methods
		this.toggleRegisterForm = this.toggleRegisterForm.bind(this)
		this.toggleLoginForm = this.toggleLoginForm.bind(this)
		this.clearInputValues = this.clearInputValues.bind(this)
		this.setEmail = this.setEmail.bind(this)		
		this.setPassword = this.setPassword.bind(this)		
		this.setName = this.setName.bind(this)		
		this.setMobile = this.setMobile.bind(this)		
		this.login = this.login.bind(this)

		this.register = this.register.bind(this)
		this.authWithFb = this.authWithFb.bind(this)
		this.validate = this.validate.bind(this)
		this.close = this.close.bind(this)

		this.handleKeyDown = this.handleKeyDown.bind(this);

		// Values
		this.email = '';
		this.password = '';
		this.mobile = '';
		this.name = '';
	}

	componentWillMount() {
		document.addEventListener('keydown', this.handleKeyDown);
		document.addEventListener('mousedown', this.handleMouseDown);

		// disable scroll
		document.body.style.overflow = "hidden";
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.handleKeyDown);
		document.removeEventListener('mousedown', this.handleMouseDown);

		// re-enable scroll
		document.body.style.overflow = "unset";
	}

	handleKeyDown(event) {
		//escape key
		if(event.keyCode === 27) {
			this.props.close();
		}
	}

	handleMouseDown = (event) => {
		const path = event.path || (event.composedPath && event.composedPath());
		if(!path || !Array.isArray(path)) return;
		let selector = path.filter(p => (p.className === "popup__window" || p.className === "popup__window component-loader"))
		if(selector.length === 0) {
			this.props.close();
		}
	}

	toggleLoginForm(){
		this.setState({
			showLoginForm:!this.state.showLoginForm,
			showRegisterForm:false,
			showMainAuthOptions:false,
			showForgotPasswordForm: false
		})
		this.clearInputValues()
	}
	
	toggleRegisterForm(){
		this.setState({
			showRegisterForm:!this.state.RegisterForm,
			showLoginForm:false,
			showMainAuthOptions:false,
			showForgotPasswordForm: false
		})
		this.clearInputValues()
	}

	showForgotPasswordForm = () => {
		this.email = '';
		
		this.setState({
			showForgotPasswordForm: true,
			showRegisterForm: false,
			showLoginForm: false
		})
	}
	
	clearInputValues(){
		this.props.userActions.clearErrors()
		this.email ='';
		this.password ='';
		this.mobile ='';
		this.name ='';
	}
	/**
	 * Get The Reference of the Email input to extract the value of it
	 * get email value
	 * @author Ezzat
	 */
	setEmail(e){
		if(this.props.userStore.hasError) this.props.userActions.clearErrors();
		this.email = e.target.value;
	}
	
	/**
	 * Get The Reference of the Password input to extract the value of it
	 * get Password value
	 * @author Ezzat
	 */
	setPassword(e){
		if(this.props.userStore.hasError) this.props.userActions.clearErrors();
		this.password = e.target.value;
	}

	/**
	 * Get The Reference of the Mobile input to extract the value of it
	 * get Mobile value
	 * @author Ezzat
	 */
	setMobile(e){
		if(this.props.userStore.hasError) this.props.userActions.clearErrors();
		let value = e.target.value;
		value = value.replace(/./g, (m) => {
			if (this.numberMap[m]) {
				return this.numberMap[m];
			}
			return m;
		});
		this.mobile = value;
	}
	
	/**
	 * Get The Reference of the Name input to extract the value of it
	 * get Name value
	 * @author Ezzat
	 */
	setName(e){
		if(this.props.userStore.hasError) this.props.userActions.clearErrors();
		this.name = e.target.value;
	}

	/**
	 * @param {Event} e
	 */
	forgotPassword = (e) => {
		e.preventDefault();
		this.props.userActions.forgotPassword(this.email);
	}

	login(e){
		e.preventDefault();
		// console.log('Logging !');
		this.props.userActions.login(this.email, this.password)
	}

	register(){
		this.props.userActions.register(this.name, this.email, this.mobile, this.password)
	}

	validate(){

	}

	
	authWithFb = (res) => {
		const context = this;
		debug('fb resp', res)
		if(!res.id || !res.name) {
			this.props.userActions.dispatchLoading(false);
			return debug('err', 'facebook response is empty!');
		}
		if(!res.email) {
			// this.props.userActions.dispatchLoading(false);
		
			window.FB.api('/me/permissions', function(response) {
				var declined = [];
				for (let i = 0; i < response.data.length; i++) { 
					if (response.data[i].status === 'declined') {
						declined.push(response.data[i].permission)
					}
				}

				console.error('Facebook Error: User declined permissions ', declined.join(','));
				
				window.FB.api('/me/permissions', 'delete', function (r1) {
					// FB.login relies on FB.getLoginStatus.
					// Force reloading the login status.
					if (r1.success) {
						alert(i18next.t('common.errors.fb_email_permission_missing', 'رجاءا وافق على استخدام البريد الإلكتروني لتسجيل الدخول بالفيسبوك'));
						context.props.userActions.dispatchLoading(false);
					}
				});
			});
			return;
			// return this.props.userActions.fbEmailMissing(res);
			// return alert("Facebook email permission is revoked");
		}
		this.props.userActions.fbLogin(res);
	}

	startLoader = (e) => {
		this.props.userActions.dispatchLoading();
	}

	close(){
		this.props.close();
	}
	

	render() {

		if(this.props.userStore.auth){
			this.close()
		}
		debug('loading', this.props.userStore.loading);

		return (
			<div className="popup popup-login active">
				<div className="tbl">
					<div className="tcl">
						<div className={this.props.userStore.loading? "popup__window component-loader" : "popup__window"}>
							{
								!(this.props.userStore.showFBSignup) &&
									<i class="popup__close fas fa-times" onClick={() => {this.props.close();}}></i>
									// <button
									// 	className="popup__close material-icons"
									// 	onClick={() => {this.props.close();}}>
									// 	close
									// </button>
							}
							
							
							{/* Main Options Component */}
							{
								(this.state.showMainAuthOptions) && !(this.props.userStore.showFBSignup) && 
									<AuthOptionsForm 
										toggleRegisterForm={this.toggleRegisterForm}
										toggleLoginForm={this.toggleLoginForm}
										authWithFb={this.authWithFb}
										startLoader={this.startLoader}
										errorMessage={this.props.userStore.errorMessage}
										hasError={this.props.userStore.hasError}
									/>		
							}
							{/* Login Form */}
							{
								(this.state.showLoginForm) && 
									<LoginForm 
										toggleRegisterForm={this.toggleRegisterForm}
										setEmail={this.setEmail}
										setPassword={this.setPassword}
										login={this.login}
										showForgotPasswordForm={this.showForgotPasswordForm}

										errorMessage={this.props.userStore.errorMessage}
										hasError={this.props.userStore.hasError}
									/>
							}

							{
								(this.state.showRegisterForm) && 
									<RegisterForm
										toggleLoginForm={this.toggleLoginForm}
										setEmail={this.setEmail}
										setPassword={this.setPassword}
										setMobile={(this.setMobile)}
										setName={this.setName}
										register={this.register}

										errorMessage={this.props.userStore.errorMessage}
										hasError={this.props.userStore.hasError}
									/>
							}

							{
								(this.state.showForgotPasswordForm) &&
									<ForgotPasswordForm 
										toggleLoginForm={this.toggleLoginForm}
										forgotPassword={this.forgotPassword}
										setEmail={this.setEmail}
										resetPasswordSent={this.props.userStore.resetPasswordSent}
										
										errorMessage={this.props.userStore.errorMessage}
										hasError={this.props.userStore.hasError}
										/>
							}

							{
								(this.props.userStore.showFBSignup) &&
									<FBSignupForm />
							}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthComponent)
