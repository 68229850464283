import Axios from "axios";
import { useEffect, useState } from "react";

/**
 *
 * @param {import("axios").AxiosRequestConfig} options
 * @param {{ loading: boolean; result: any; error: any; }} initialState
 */
export function useRequest(options, initialState) {
    const [state, setState] = useState({
        loading: false,
        result: null,
        error: null,
        ...initialState
    });

    async function makeRequest() {
        setState(prev => ({ ...prev, loading: true }));
        try {
            const result = await Axios(options);
            setState({ loading: false, result, error: null });
            return result.data;
        } catch (error) {
            setState({ loading: false, result: null, error });
        }
    }

    return { ...state, run: makeRequest };
}
