import * as actionTypes from '../Actions/ActionTypes'
import store2 from 'store2';

import NodeAuth from "node-auth-api";
import Utils from '../Helpers/Utils';

let user = { user: {}, points: {} }
let auth = false;

if(NodeAuth.User.isLoggedIn()) {
    let temp = NodeAuth.User.getTokenPayload('user');

    let avatar = store2.get("user_avatar", temp.avatar);
    user = {
        UserID: temp.extras? temp.extras.UserID: null,
        UserToken: temp.extras? temp.extras.UserToken: null,
        UserName: temp.name, //Utils.decodeUtf8(temp.name),
        UserEmail: temp.email,
        UserTelNo: temp.phone,
        Pic_Url: avatar
    }
    auth = true;
}

export function UserReducer(state = {
    show_auth_popup:false,
    show_invalid_phone_popup: false,
    session_id: null,
    fetching: false,
    editing_profile: false,
    edit_profile_result: null,
    errorMessage: [],
    hasError: false,
    loading: false,
    
    //-- action holder for executing after login success --//
    postLoginAction: null,
    postLoginData: null,
    //--

    //-- fb user not found --//
    showFBSignup: false,
    fbResponse: null,
    // --

    //-- user points --/
    points: {
        total: 0,
        usable: 0,
        value: 0,
        amount: 0,
        ploader: false,
        promoloader: false,
        _fetched: false
    },
    // --

    resetPasswordSent: false,
    resetPasswordSuccess: false,

    auth: auth,
    user: user,
}, action) {

    switch (action.type) {
        case actionTypes.TOGGLE_INVALID_PHONE_POPUP: {
            const newState = {
                ...state,
                show_invalid_phone_popup: action.payload
            };
            return newState;
        }
        case actionTypes.TOGGLE_AUTH_POPUP: {
            let newState = {
                ...state,
                postLoginAction: action.postLoginAction,
                postLoginData: action.postLoginData,
                show_auth_popup: !state.show_auth_popup,

                //remove resetpasswordsent state when auth popup changes
                resetPasswordSent: false
            }
            //if show_auth_popup was active and will be closed.
            if(state.show_auth_popup) {
                // remove fb signup popup state
                newState.showFBSignup = false;
                newState.fbResponse = null;

                // remove previous errors
                newState.hasError = false;
                newState.errorMessage = [];
            }
            // state = {
            //     ...state,
            //     show_auth_popup: !state.show_auth_popup,
            //     postLoginAction: action.postLoginAction,
            //     postLoginData: action.postLoginData
            // }
            return newState;
        }

        case actionTypes.CLEAR_ERRORS: {
            state = {
                ...state,
                errorMessage: [],
                hasError: false,
                auth: false,
                loading: false,
            }
            break;
        }

        case actionTypes.FETCHING_DATA: {
            state = {
                ...state,
                fetching: true,
            }
            break;
        }

        case actionTypes.USER_LOGIN: {
            state = {
                ...state,
                user: action.user,
                auth: true,
                errorMessage: [],
                hasError: false,
                loading: false,
            }
            
            break;
        }

        case actionTypes.USER_LOGOUT: {
            state = {
                ...state,
                user: null,
                auth: false,
                errorMessage: [],
                hasError: false,
                loading: false,
                points: {
                    amount: 0,
                    total: 0,
                    usable: 0,
                    value: 0
                }
            }
            break;
        }

        case actionTypes.USER_SOFT_LOGOUT: {
            state = {
                ...state,
                user: { user: {}, points: {} },
                auth: false,
                errorMessage: [],
                hasError: false,
                loading: false
            }
            break;
        }

        case actionTypes.FB_USER_NOT_FOUND: {
            return {
                ...state,
                showFBSignup: true,
                fbResponse: action.fbResponse,
                loading: false
            }
        }
        
        case actionTypes.USER_LOGIN_ERROR: {
            state = {
                ...state,
                errorMessage: action.error,
                hasError: true,
                auth: false,
                loading: false,
            }
            break;
        }

        case actionTypes.USER_REGISTER: {
            state = {
                ...state,
                user: action.user,
                auth: true,
                errorMessage: [],
                hasError: false,
                loading: false
            }
            break;
        }

        case actionTypes.USER_REGISTER_ERROR: {
            state = {
                ...state,
                errorMessage: action.error,
                hasError: true,
                auth: false,
                loading: false
            }
            break;
        }

        case actionTypes.CLEAR_POST_LOGIN: {
            state = {
                ...state,
                postLoginAction: null,
                postLoginData: null
            }
            break;
        }

        case actionTypes.EDITING_PROFILE: {
            state = {
                ...state,
                editing_profile: true,
                edit_profile_result: null,
                errorMessage: []
            }
            break;
        }

        case actionTypes.EDIT_PROFILE_SUCCESS: {
            state = {
                ...state,
                editing_profile: false,
                edit_profile_result: "success",
                user: action.user
            }
            break;
        }

        case actionTypes.EDIT_PROFILE_FAILED: {
            state = {
                ...state,
                editing_profile: false,
                edit_profile_result: "fail",
                errorMessage: action.error
            }
            break;
        }

        case actionTypes.GETTING_TICKETS: {
            state = {
                ...state,
                getting_tickets: true
            }
            break;
        }

        case actionTypes.GET_TICKETS_SUCCESS: {
            state = {
                ...state,
                getting_tickets: false,
                tickets: action.tickets
            }
            break;
        }

        case actionTypes.GET_TICKETS_FAILED: {
            state = {
                ...state,
                getting_tickets: false,
            }
            break;
        }
        
        case actionTypes.USER_DATA_RESET: {
            state = {
                ...state,
                editing_profile: false,
                edit_profile_result: null,
                errorMessage: []
            }
            break;
        }

        case actionTypes.LOADING_USER: {
            if(typeof action.bool === "boolean") {
                return {
                    ...state,
                    loading: action.bool
                }
            }
            return {
                ...state,
                loading: true
            }
        }

        case actionTypes.UPDATE_POINTS_TO_USE: {
            return {
                ...state,
                points: {
                    ...state.points,
                    amount: action.data,
                    usable: state.points.amount
                        // return usable points to its original amount and subtract new amount
                        ? state.points.usable + state.points.amount - action.data
                        // subtract amount from usable
                        : state.points.usable - action.data
                }
            }
        }

        case actionTypes.UPDATE_USER_POINTS: {
            return {
                ...state,
                points: action.data
            }
        }
            
        case actionTypes.RESET_PWD_EMAIL_SENT: {
            return {
                ...state,
                resetPasswordSent: true
            }
        }

        case actionTypes.RESET_PWD_FAIL: {
            return {
                ...state,
                hasError: true,
                errorMessage: action.errors
            }
        }

        case actionTypes.RESET_PWD_SUCCESS: {
            return {
                ...state,
                resetPasswordSuccess: true
            }
        }

        case actionTypes.APPLY_POINT_PROMO: {
            return {
                ...state,
                points: {
                    ...state.points,
                    [action.key]: action.load
                }

            }
        }

        default: 
            return state;
    }

    return state;
}

