import React from 'react';
import ContentLoader, { BulletList} from 'react-content-loader'

const TripsLoaderComponent = props => (
    <div>
        <ContentLoader
            height={160}
            width={700}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
            {...props}
        >
            <rect x="456" y="42.09" rx="3" ry="3" width="144.9" height="13.36" />
            <rect x="141" y="70.05" rx="0" ry="0" width="0" height="0" />
            <rect x="486.63" y="65.05" rx="0" ry="0" width="112.69999999999999" height="29.9" />
            <rect x="339" y="99.05" rx="0" ry="0" width="0" height="0" />
            <rect x="356" y="70.05" rx="0" ry="0" width="88.45" height="19.5" />
            <rect x="78" y="69.05" rx="0" ry="0" width="0" height="0" />
            <rect x="93" y="49.05" rx="0" ry="0" width="95.29" height="35" />
            <rect x="11" y="50.05" rx="0" ry="0" width="52.46" height="25.21" />
            <rect x="161" y="67.05" rx="0" ry="0" width="0" height="0" />
            <rect x="232.75" y="55.05" rx="0" ry="0" width="97.9" height="11" />
            <rect x="258" y="74.05" rx="0" ry="0" width="67" height="7.5" />
            <rect x="193" y="119.05" rx="0" ry="0" width="0" height="0" />
        </ContentLoader>

        <ContentLoader
            height={160}
            width={700}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
            {...props}
        >
            <rect x="456" y="42.09" rx="3" ry="3" width="144.9" height="13.36" />
            <rect x="141" y="70.05" rx="0" ry="0" width="0" height="0" />
            <rect x="486.63" y="65.05" rx="0" ry="0" width="112.69999999999999" height="29.9" />
            <rect x="339" y="99.05" rx="0" ry="0" width="0" height="0" />
            <rect x="356" y="70.05" rx="0" ry="0" width="88.45" height="19.5" />
            <rect x="78" y="69.05" rx="0" ry="0" width="0" height="0" />
            <rect x="93" y="49.05" rx="0" ry="0" width="95.29" height="35" />
            <rect x="11" y="50.05" rx="0" ry="0" width="52.46" height="25.21" />
            <rect x="161" y="67.05" rx="0" ry="0" width="0" height="0" />
            <rect x="232.75" y="55.05" rx="0" ry="0" width="97.9" height="11" />
            <rect x="258" y="74.05" rx="0" ry="0" width="67" height="7.5" />
            <rect x="193" y="119.05" rx="0" ry="0" width="0" height="0" />
        </ContentLoader>

        
    </div>
)

export default TripsLoaderComponent