import i18next from "i18next";
import create from "zustand";
import store2 from "store2";
import TripApi from "../Api/TripApi";
import { eventMgr } from "../events";
import Store from "../Store";
import { toast } from 'react-toastify';
import debug from "../Helpers/DebugLog";
import { PaymentMethods } from "../Components/Booking/PaymentComponent";
import { soft_logout, toggleAuthPopUp } from "../Actions/UserActions";
import { undoPromocode as reduxUndoPromocode } from "../Actions/TripActions";
import Utils from '../Helpers/Utils';

function scrollToGoList() {
	let tripListRef = document.querySelectorAll('.list-component-con')[0];
	Utils.scrollToElement(tripListRef);
}

function scrollToReturnList() {
	let tripListRef = document.querySelectorAll('.list-component-con')[1];
	Utils.scrollToElement(tripListRef);
}

const log = (config) => (set, get, api) =>
  config(
    (args) => {
      debug("Applying", args);
      set(args);
      debug("New State", get());
    },
    get,
    api
  );

export const useCheckoutStore = create(
  log((set, get) => ({
    __loading_state: false,

    cartId: 0,

    // state
    isRoundTrip: false,
    goingTripId: null,
    returnTripId: null,

    goingTripInfo: null,
    returnTripInfo: null,

    passengersNo: 1,

    // reducers
    promocode: null,
    points: 0,

    // pricing
    pricing: {
      goingPrice: 0,
      returnPrice: 0,
      totalPrice: 0,
      totalPriceNoDiscount: 0,
      displayPrice: 0,
      goingTripNoDiscount: 0,
      returnTripNoDiscount: 0
    },

    // seats
    seats: {
      fetching: false,
      holding: false,
      holdError: null,
      goingSeats: null,
      returnSeats: null,
      modifiedGoingSeats: [],
      modifiedReturnSeats: [],
      selectedGoingSeats: [],
      selectedReturnSeats: [],
    },

    showPayment: false,
    paymentMethod: PaymentMethods.default,

    // actions
    setPaymentMethod(method) {
      set({ paymentMethod: method });
      // Store.dispatch(undoPromocode());
      get().calcTotalPrice();
    },

    setOrUnsetTrip(trip, type) {
      let action = 'set';
      const newState = {};
      const currentState = get();

      newState[type === "going" ? "goingTripId" : "returnTripId"] =
        trip.TripSubData_Id;
      newState[type === "going" ? "goingTripInfo" : "returnTripInfo"] = trip;
      newState.isRoundTrip = type !== "going";

      if (newState.isRoundTrip === true && currentState.goingTripInfo == null) {
        toast.warn(i18next.t('search_page.errors.select_going_first'), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            className: "toast-warning",
            toastId: 0
        });
      	scrollToGoList();
        return;
      }

      // unset if already set
      if (newState.goingTripId === currentState.goingTripId) {
        newState.goingTripId = null;
        newState.goingTripInfo = null;

        // unset return also, can't select returning before going
        newState.returnTripId = null;
        newState.returnTripInfo = null;
        newState.isRoundTrip = false;
	    action = 'unset';
      }

      if (newState.returnTripId === currentState.returnTripId) {
        newState.returnTripId = null;
        newState.returnTripInfo = null;
        newState.isRoundTrip = false;
	    action = 'unset';
      }

      set(newState);

      currentState.calcTotalPrice();

      if (action === 'set' && type === 'going') {
      	scrollToReturnList();
      } else if (action === 'unset') {
      	scrollToGoList();
      }
    },

    calcTotalPrice() {
      const state = get();

      if (!state.goingTripInfo) return;

      let seatsCount = state.passengersNo;

      if (state.isRoundTrip) {
        seatsCount = state.passengersNo * 2;
      }

      let goingPrice = state.passengersNo * state.goingTripInfo.TripPrice;
      let returnPrice = 0;
      let totalPrice = state.passengersNo * state.goingTripInfo.TripPrice;
      let totalPriceNoDiscount =
        state.goingTripInfo.TripPrice * state.passengersNo;
      let displayPrice = 0;
      let goingTripNoDiscount = state.goingTripInfo.TripPrice * seatsCount;
      let returnTripNoDiscount;

      if (state.isRoundTrip && state.returnTripInfo) {
        goingPrice =
          (state.passengersNo * state.goingTripInfo.TripPrice_GoCome) / 2;
        returnPrice =
          (state.passengersNo * state.returnTripInfo.TripPrice_GoCome) / 2;
        totalPrice = goingPrice + returnPrice;
        totalPriceNoDiscount =
          (state.goingTripInfo.TripPrice + state.returnTripInfo.TripPrice) *
          state.passengersNo;

        goingTripNoDiscount = state.isRoundTrip? ((seatsCount/2) * state.goingTripInfo.TripPrice_GoCome) / 2: goingTripNoDiscount;
        //((seatsCount/2) * state.goingTripInfo.TripPrice_GoCome) / 2;
        returnTripNoDiscount = state.isRoundTrip? ((seatsCount/2) * state.returnTripInfo.TripPrice_GoCome) / 2: state.returnTripInfo.TripPrice * seatsCount;
          //((seatsCount/2) * state.returnTripInfo.TripPrice_GoCome) / 2;
      }

      if (state.promocode) {
        totalPrice = state.promocode.Total_AfterDisc;
      }

      displayPrice = totalPrice;

      // points discount
      if (state.points) {
        totalPrice = totalPrice - (state.points / 100) * 5;
        displayPrice = totalPrice;
      }

      set({
        pricing: {
          goingPrice,
          returnPrice,
          totalPrice,
          totalPriceNoDiscount,
          displayPrice,
          goingTripNoDiscount,
          returnTripNoDiscount
        },
      });
    },

    async applyPromoCode(promocode) {
      set({
        promocode: {
          TotalPrice: promocode.TotalPrice,
          Total_AfterDisc: promocode.Total_AfterDisc,
          code: promocode.couponCode,
          discountAmount: promocode.val,
        },
      });
      get().calcTotalPrice();
    },

    undoPromocode() {
        set({
          promocode: null
        })
        get().calcTotalPrice();
    },

    async setPoints(amount) {
      set({ points: amount });
      get().calcTotalPrice();
    },

    setTotalPrice(totalPrice) {
      const state = get();
      set({ pricing: { ...state.pricing, totalPrice } });
    },

    async updateSeats() {
      const state = get();
      set({ seats: { ...state.seats, fetching: true } });
      if (state.goingTripId == null) {
        return;
      }
      const requests = [TripApi.seats(state.goingTripId)];

      if (state.isRoundTrip) {
        requests.push(TripApi.seats(state.returnTripId))
      }

      const results = await Promise.all(requests);

      set({
        seats: { ...state.seats, goingSeats: results[0], returnSeats: results[1], fetching: false },
      });
    },

    async cancelSeatModifications() {
      const state = get();
      const update = {
        seats: {
          ...state.seats,
          holdError: null,
          modifiedGoingSeats: [],
          modifiedReturnSeats: [],
        },
        showPayment: true
      }

      set(update);
      state.localHoldSeats();
    },

    async localUpdateSeats(seats, type) {
      Store.dispatch(reduxUndoPromocode());
      const state = get();
      const modKey = `modified${type === "going" ? "Going" : "Return"}Seats`;
      const selKey = `selected${type === "going" ? "Going" : "Return"}Seats`;

      const update = {
        seats: {
          ...state.seats,
          holdError: null,
          [modKey]: seats
        },
        showPayment: false
      }

      set(update);
      state.localHoldSeats();
    },

    async localHoldSeats({ autoSelect = false } = {}) {
      const { seats, passengersNo, goingTripId, returnTripId, isRoundTrip } = get();
      const { UserID: userID, UserToken } = Store.getState().user.user;
      const availableSeatsFilter = (s) => s.Status === 0 && s.SeatNo !== 0;

      let goingSeats = seats.goingSeats;
      let returnSeats = seats.returnSeats;
      let goSeats = seats.selectedGoingSeats;
      let comeSeats = seats.selectedReturnSeats;

      if (seats.modifiedGoingSeats.length) {
        goSeats = seats.modifiedGoingSeats;
        const holdSeatsRegex = new RegExp("^(" + goSeats.join("|") + ")$");
        const unholdSeatsRegex = new RegExp("^(" + seats.selectedGoingSeats.join("|") + ")$");
        goingSeats = goingSeats.map((seat) => {
          if (holdSeatsRegex.test(seat.SeatNo)) {
            seat.Status = 0;
          }

          if (unholdSeatsRegex.test(seat.SeatNo)) {
            seat.Status = 1;
          }

          return seat;
        });
      }

      if (seats.modifiedReturnSeats.length) {
        comeSeats = seats.modifiedReturnSeats;

        const holdSeatsRegex = new RegExp("^(" + comeSeats.join("|") + ")$");
        const unholdSeatsRegex = new RegExp("^(" + seats.selectedReturnSeats.join("|") + ")$");
        returnSeats = returnSeats.map((seat) => {
          if (holdSeatsRegex.test(seat.SeatNo)) {
            seat.Status = 0;
          }

          if (unholdSeatsRegex.test(seat.SeatNo)) {
            seat.Status = 1;
          }

          return seat;
        });
      }

      if (autoSelect && goingSeats) {
          goSeats = goingSeats
            .filter(availableSeatsFilter)
            .slice(0, passengersNo)
            .map((s) => s.SeatNo)


          if (isRoundTrip && returnSeats) {
              comeSeats = returnSeats
                .filter(availableSeatsFilter)
                .slice(0, passengersNo)
                .map((s) => s.SeatNo)
          }
      }

      const update = {
        seats: {
          ...seats,
          goingSeats,
          returnSeats,
          holding: false,
        },
      }

      if (autoSelect) {
        update.seats.selectedGoingSeats = goSeats.map((s) => +s);
        update.seats.selectedReturnSeats =
          (isRoundTrip && comeSeats.map((s) => +s)) || [];
      }

      set(update);
      if (autoSelect) {
        get().saveState();
      }
    },

    async holdSeats(history) {
      const {
        isRoundTrip,
        goingTripId,
        returnTripId,
        seats,
        passengersNo,
        resetCart,
      } = get();
      const { goingSeats, returnSeats, selectedGoingSeats, selectedReturnSeats, modifiedGoingSeats, modifiedReturnSeats } = seats;
      const { UserID: userID, UserToken } = Store.getState().user.user;
      const availableSeatsFilter = (s) => s.Status === 0 && s.SeatNo !== 0;

      set({ seats: { ...seats, holding: true } })

      if (!Array.isArray(selectedGoingSeats)) {
        // err
        return console.error("Error! no going seats");
      }

      if (isRoundTrip && !Array.isArray(selectedReturnSeats)) {
        // err
        return console.error("Error! no return seats");
      }

      const holdData = {
        userID,
        goTripSubData_Id: goingTripId
      };

      if (!modifiedGoingSeats.length && goingSeats) {
        holdData.goSeats = goingSeats
          .filter(availableSeatsFilter)
          .slice(0, passengersNo)
          .map((s) => s.SeatNo)
          .join(",")
      } else {
        holdData.goSeats = modifiedGoingSeats.join(',')
      }

      if (isRoundTrip) {
        holdData.comeTripSubData_Id = returnTripId;
        if (!modifiedReturnSeats.length && returnSeats) {
          holdData.comeSeats = returnSeats
            .filter(availableSeatsFilter)
            .slice(0, passengersNo)
            .map((s) => s.SeatNo)
            .join(",");
        } else {
          holdData.comeSeats = modifiedReturnSeats.join(',')
        }
      }

      const [err, response] = await TripApi.holdSeats(holdData, UserToken);

      // token is expired or user not logged in
      // show popup and try to hold seats again after user is successfully logged in
      if (response && response.Result && response.Result.trim().toLowerCase() === "user Token Not Found".trim().toLowerCase()) {
        set({
          showPayment: false,
          seats: {
            ...seats,
            holding: false,
          }
        });

        localStorage.removeItem('user_token');
        Store.dispatch(soft_logout());

        Store.dispatch(toggleAuthPopUp(() => {
          get().holdSeats(history);
        }, true));

        return;
      }

      const error = TripApi.handleHoldResult(err, response)

      if (error == null) {
        set({
          showPayment: true,
          seats: {
            ...seats,
            holding: false,
            modifiedGoingSeats: [],
            modifiedReturnSeats: [],
            selectedGoingSeats: holdData.goSeats.split(",").map((s) => +s),
            selectedReturnSeats:
              (isRoundTrip && holdData.comeSeats.split(",").map((s) => +s)) ||
              [],
          },
          points: 0
        });
        eventMgr.emit("held_seats", response, history);
      } else {
        set({
          showPayment: false,
          seats: {
            ...seats,
            holdError: error
          }
        })
      }
    },

    saveState() {
      const {
        cartId,
        goingTripId,
        returnTripId,
        points,
        promocode,
        pricing,
        passengersNo,
        seats: { selectedGoingSeats, selectedReturnSeats },
      } = get();
      store2.set(
        "zcss",
        btoa(
          JSON.stringify({
            cartId,
            passengersNo,
            points,
            promocode,
            pricing,
            goingTripId,
            returnTripId,
            selectedGoingSeats,
            selectedReturnSeats,
          })
        )
      );
    },

    async loadState() {
      document.body.classList.add("site_loader");
      set({ __loading_state: true });
      const state = get();
      const newState = { seats: state.seats };
      const zcss = store2.get("zcss");
      const data =
        typeof zcss === "string" && zcss.length > 0 && JSON.parse(atob(zcss));

      if (!state.goingTripId && data && data.goingTripId) {
        newState.goingTripId = data.goingTripId;
      }

      if (!state.returnTripId && data && data.returnTripId) {
        newState.returnTripId = data.returnTripId;
        newState.isRoundTrip = true;
      }

      if (data.points) {
        newState.points = data.points;
      }

      if (data.promocode) {
        newState.promocode = data.promocode;
      }

      if (data.cartId) {
        newState.cartId = data.cartId;
        newState.showPayment = true;
      }

      if (data.passengersNo) {
        newState.passengersNo = data.passengersNo;
      }

      if (data.pricing) {
        newState.pricing = data.pricing;
      }

      // load previously selected seats
      if (data.selectedGoingSeats) {
        newState.seats.selectedGoingSeats = data.selectedGoingSeats.map(
          (s) => +s
        );
      }

      if (data.selectedReturnSeats) {
        newState.seats.selectedReturnSeats = data.selectedReturnSeats.map(
          (s) => +s
        );
      }

      // load trip info if its not there
      const requests = [];

      if (
        (window.location.pathname === '/Booking' || window.location.pathname === '/Thanks') && (
          state.goingTripInfo === null ||
          state.goingTripInfo.TripSubData_Id !== state.goingTripId
        )
      ) {
        requests.push(TripApi.getTripInfo(newState.goingTripId));
      }

      if (
        (window.location.pathname === '/Booking' || window.location.pathname === '/Thanks') && (
          state.returnTripInfo === null ||
          state.returnTripInfo.TripSubData_Id !== state.returnTripId
        )
      ) {
        requests.push(TripApi.getTripInfo(newState.returnTripId));
      }

      if (requests.length) {
        const [goingTripInfos, returnTripInfos] = await Promise.all(requests);
        if (Array.isArray(goingTripInfos) && goingTripInfos.length > 0) {
          newState.goingTripInfo = goingTripInfos[0];
        }
        if (Array.isArray(returnTripInfos) && returnTripInfos.length > 0) {
          newState.returnTripInfo = returnTripInfos[0];
        }
      }

      newState.__loading_state = false;
      set(newState);

      // update seats
      if ((!state.seats.goingSeats || !state.seats.returnSeats) && window.location.pathname === '/Booking') {
        process.nextTick(async () => {
          await state.updateSeats();
        });
      }

      state.calcTotalPrice();

      // if (window.location.pathname === '/Booking') {
      //     state.holdSeats();
      // }
      document.body.classList.remove("site_loader");
    },

    resetCart() {
      set({
        cartId: 0,

        goingTripId: null,
        returnTripId: null,

        goingTripInfo: null,
        returnTripInfo: null,

        // reducers
        promocode: null,
        points: 0,

        // pricing
        pricing: {
          goingPrice: 0,
          returnPrice: 0,
          totalPrice: 0,
          totalPriceNoDiscount: 0,
          displayPrice: 0,
        },

        // seats
        seats: {
          fetching: false,
          holding: false,
          goingSeats: null,
          returnSeats: null,
          modifiedGoingSeats: [],
          modifiedReturnSeats: [],
          selectedGoingSeats: [],
          selectedReturnSeats: [],
        },

        showPayment: false,
      });

      store2.remove("zcss");
    },
  }))
);

window.zcss = useCheckoutStore.getState;
