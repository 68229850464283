import React, { useState, useEffect } from 'react';
import clsx from "clsx";

export default function({ className, maxRating = 5, color, colorMap, value, onChange, interactive }) {
	const [highlightTo, setHighlightTo] = useState(-1);
	const [events, setEvents] = useState({
		onMouseEnter: undefined,
		onMouseLeave: undefined,
		onClick: undefined,
		hovering: false,
	});
	//const [currentColor, setColor] = useState(colorMap? colorMap[highlightTo > -1? highlightTo: value]: color);
	const classes = clsx([className, 'md-rating']);
	const ratings = Array(maxRating).fill(0);

	useEffect(() => {
		if (interactive) {
			const onMouseEnter = (e) => {
				const newVal = parseInt(e.target.dataset.value);
				setHighlightTo(newVal);
				setEvents(prev => ({ ...prev, hovering: true }));
				//colorMap && setColor(colorMap[newVal + 1]);
			}
			const onMouseLeave = () => {
				setHighlightTo(-1);
				setEvents(prev => ({ ...prev, hovering: false }));
				//setColor(colorMap[value]);
			}
			const onClick = (e) => {
				const setVal = parseInt(e.target.dataset.value) + 1;
				onChange && onChange(setVal);
				//setColor(colorMap[setVal]);
			}
			setEvents({ onMouseEnter, onMouseLeave, onClick });
		}
	}, [value]);

	const { onClick, onMouseLeave, onMouseEnter, hovering } = events;

	return (
		<div className={classes} onMouseLeave={onMouseLeave}>
			{ratings.map((_r, i) => {
				//const colorClasses = ['circle'];
				// set second color if we're rendering non relative rating
				let starIcon = 'star_border';
				if ((value > i && !hovering) || highlightTo >= i) starIcon = 'star';
				// classes -> {red || green || orange}
				return (
					<div key={i} onClick={onClick} onMouseEnter={onMouseEnter}>
						<i data-value={i} className="material-icons">{starIcon}</i>
					</div>
				);
			})}
		</div>
	);
}