import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';


import { tripPrice, tripTime, tripTimeSegment } from '../../Helpers/TimeParser'

import * as CorporateActions from '../../Actions/CorporateActions'

// Componnets 
import MainLayoutComponent from '../MainLayoutComponent'
import StationPlaceholder from '../Utils/Placeholders/StationPlaceholder';
import SuggestedTripsComponent from './SuggestedTripsComponent'
import TripClassComponent from '../Search/Trip/TripClassComponent'

function mapStateToProps(store) {
    return {
        corpStore: store.corporate
    }
}

function mapDispatchToProps(dispatch) {
    return {
        corporateActions: bindActionCreators(CorporateActions, dispatch)
    }
}

class SingleStationComponent extends Component {
    
    constructor(props) {
        super(props);

        // Properties
        this.stationId = this.props.match.params.id

        // Methods 
    }



    /**
     * get the Single Station Trips
     * @author Ezzat
     */
    componentDidMount() {
        this.props.corporateActions.getStation(this.stationId)
    }

    render() {
        // debugger;
        return (
            <MainLayoutComponent page={this.props.location.pathname}>
                {this.props.corpStore.fetching && <StationPlaceholder />  } 
                {this.props.corpStore.station && this.renderStation() }
            </MainLayoutComponent>
        );
    }

    renderStation(){
        let going_cities = this.props.corpStore.station.going_cities
        let returning_cities = this.props.corpStore.station.returning_cities
        let station = this.props.corpStore.station.station || {}
        console.log('Station is', station)
        return(
                <div className="page-content single-station">

                    <div className="station-hero">
                        <div className="map-station">
                            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13809.338187992144!2d31.336996199999998!3d30.0846037!3m2!1i1024!2i768!4f13.1!4m3!3e6!4m0!4m0!5e0!3m2!1sen!2seg!4v1523885826300" width="100%" height="100%" frameborder="0" allowfullscreen></iframe> */}
                            
                        </div>

                        <div className="container">
                            <div className="station-intro">
                                <h1>محطة {station.OfficeNameA}</h1>
                                <h2> NIA وسط البلد - القاهرة</h2>
                                <p>رقم تليفون محطة جوباص {station.OfficeNameA}</p>
                                <span>19567</span>
                                <p>أستخدم كوبون الخصم أحجز الآن </p>
                                <span>GoDownTown</span>
                                <a href="#">نسخ الكوبون</a>
                            </div>
                        </div>

                    </div>

                    <div className="container">
                        <div className="destinations">
                            <div className="destinations-con">
                                <div className="station-destination">
                                    <h3>وجهات السفر</h3>
                                    <p>من وإلي محطة {station.OfficeNameA}</p>
                                    <a href="#">المزيد من وجهات السفر
                                        <i className="material-icons">arrow_back</i>
                                    </a>
                                </div>
                                {
                                    (going_cities) && 
                                        <SuggestedTripsComponent
                                            trips={going_cities}
                                        />
                                }
                                
                            </div>
                        </div>
                        <div className="travel-dates">
                            <div className="sec-title">
                                <h3>مواعيد السفر والوصول</h3>
                                <p>من وإلي محطة {station.OfficeNameA}</p>
                            </div>

                            <div className="stationSchedulesBox">
                                <div className="stationSchedulesBox-head">
                                    <div className="select-month">
                                        <span>المواعيد طوال الأسبوع لشهر</span>
                                        <select>
                                            <option value="فبراير ٢٠١٨">فبراير ٢٠١٨</option>
                                            <option value="مارس ٢٠١٨">مارس ٢٠١٨</option>
                                        </select>
                                    </div>
                                    <div className="titles">
                                        <h4>مواعيد السفر اليومية</h4>
                                        <p>من محطة {station.OfficeNameA}</p>
                                    </div>
                                </div>
                                <div className="SchedulesData">
                                    <div className="SchedulesData-head">
                                        <h5>وجهة السفر</h5>
                                        <h5>المواعيد</h5>
                                    </div>
                                    <div className="Data">
                                        {
                                            Object.keys(going_cities).map(city => {
                                                return (
                                                    <div className="Data-row">
                                                        <div className="Data-title">
                                                            <h4>{city}</h4>
                                                            <p>أسعار تبدأ من {going_cities[city].cheapest_trip.price} جنيه</p>
                                                        </div>            
                                                        <ul className="Data-items">
                                                            {
                                                                going_cities[city].trips.map(trip => {
                                                                    return (
                                                                        <li className="Data-item">
                                                                            <span>{`${tripTime(trip.departure_date)}  ${tripTimeSegment(trip.departure_date)}`} </span>
                                                                            {
                                                                                (trip.class) && 
                                                                                <TripClassComponent 
                                                                                    TripServKind_Code={trip.class.TripServKind_Code}
                                                                                    TripServKind_NameA={trip.class.TripServKind_NameA}
                                                                                    TripServKind_NameE={trip.class.TripServKind_NameE}
                                                                                    TripServKind_NameA_Short={trip.class.TripServKind_NameA_Short}
                                                                                />
                                                                            }
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="more-list"><button><i class="material-icons">arrow_downward</i>أعرض كل المواعيد</button></div>
                                </div>
                            </div>

                            <div className="stationSchedulesBox">
                                <div className="stationSchedulesBox-head">
                                    <div className="select-month">
                                        <span>المواعيد طوال الأسبوع لشهر</span>
                                        <select>
                                            <option value="فبراير ٢٠١٨">فبراير ٢٠١٨</option>
                                            <option value="مارس ٢٠١٨">مارس ٢٠١٨</option>
                                        </select>
                                    </div>
                                    <div className="titles">
                                        <h4>مواعيد الوصول اليومية</h4>
                                        <p>إلي محطة {station.OfficeNameA}</p>
                                    </div>
                                </div>
                                <div className="SchedulesData">
                                    <div className="SchedulesData-head">
                                        <h5>وجهة السفر</h5>
                                        <h5>المواعيد</h5>
                                    </div>
                                    <div className="Data">
                                        {
                                                Object.keys(going_cities).map(city => {
                                                    return (
                                                        <div className="Data-row">
                                                            <div className="Data-title">
                                                                <h4>{city}</h4>
                                                                <p>أسعار تبدأ من {going_cities[city].cheapest_trip.price} جنيه</p>
                                                            </div>            
                                                            <ul className="Data-items">
                                                                {
                                                                    going_cities[city].trips.map(trip => {
                                                                        return (
                                                                            <li className="Data-item">
                                                                                <span>{`${tripTime(trip.departure_date)}  ${tripTimeSegment(trip.departure_date)}`} </span>
                                                                                <strong className="bus-class-name bus-class-name-elite ">{(trip.class) ? trip.class.Srv_NameA : ''}</strong>
                                                                                {
                                                                                    (trip.class) && 
                                                                                        <TripClassComponent 
                                                                                            TripServKind_Code={trip.class.TripServKind_Code}
                                                                                            TripServKind_NameA={trip.class.TripServKind_NameA}
                                                                                            TripServKind_NameE={trip.class.TripServKind_NameE}
                                                                                            TripServKind_NameA_Short={trip.class.TripServKind_NameA_Short}
                                                                                        />
                                                                                }
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    )
                                                })
                                            }
                                    </div>
                                    <div className="more-list"><button><i class="material-icons">arrow_downward</i>أعرض كل المواعيد</button></div>
                                </div>
                            </div>

                        </div>
                        <div className="stations-page">
                            <div className="stations-list-con">
                                <h2> محطات أخري</h2>
                                <div className="station-list">
                                    <div className="location">
                                        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13809.338187992144!2d31.336996199999998!3d30.0846037!3m2!1i1024!2i768!4f13.1!4m3!3e6!4m0!4m0!5e0!3m2!1sen!2seg!4v1523885826300" width="100%" height="100%" frameborder="0" allowfullscreen></iframe> */}
                                    </div>
                                    <div className="station-details">
                                        <h3>عبد المنعم رياض</h3>
                                        <span>التحرير</span>
                                        <a href="#">أكثر من 20 وجهة سفر
                                            <i className="material-icons">arrow_back</i>
                                        </a>
                                    </div>
                                </div>
                                <div className="station-list">
                                    <div className="location">
                                        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13809.338187992144!2d31.336996199999998!3d30.0846037!3m2!1i1024!2i768!4f13.1!4m3!3e6!4m0!4m0!5e0!3m2!1sen!2seg!4v1523885826300" width="100%" height="100%" frameborder="0" allowfullscreen></iframe> */}
                                    </div>
                                    <div className="station-details">
                                        <h3>عبد المنعم رياض</h3>
                                        <span>التحرير</span>
                                        <a href="#">أكثر من 20 وجهة سفر
                                            <i className="material-icons">arrow_back</i>
                                        </a>
                                    </div>
                                </div>
                                <div className="station-list">
                                    <div className="location">
                                        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13809.338187992144!2d31.336996199999998!3d30.0846037!3m2!1i1024!2i768!4f13.1!4m3!3e6!4m0!4m0!5e0!3m2!1sen!2seg!4v1523885826300" width="100%" height="100%" frameborder="0" allowfullscreen></iframe> */}
                                    </div>
                                    <div className="station-details">
                                        <h3>عبد المنعم رياض</h3>
                                        <span>التحرير</span>
                                        <a href="#">أكثر من 20 وجهة سفر
                                            <i className="material-icons">arrow_back</i>
                                        </a>
                                    </div>
                                </div>
                                <div className="station-list">
                                    <div className="location">
                                        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13809.338187992144!2d31.336996199999998!3d30.0846037!3m2!1i1024!2i768!4f13.1!4m3!3e6!4m0!4m0!5e0!3m2!1sen!2seg!4v1523885826300" width="100%" height="100%" frameborder="0" allowfullscreen></iframe> */}
                                    </div>
                                    <div className="station-details">
                                        <h3>عبد المنعم رياض</h3>
                                        <span>التحرير</span>
                                        <a href="#">أكثر من 20 وجهة سفر
                                            <i className="material-icons">arrow_back</i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleStationComponent);


