import React, { Component } from "react";
import Helmet from "react-helmet";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as CorporateActions from "../../Actions/CorporateActions";

// Componnets
import MainLayoutComponent from "../MainLayoutComponent";
import { buildYoastMetaTags } from "../../Helpers/Yoast";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import Utils from "../../Helpers/Utils";
import FeaturedDiscount from "../FeaturedDiscount";
import Footer from "../Footer";

function mapStateToProps(store) {
  return {
    lang: store.locale.lang,
    corpStore: store.corporate
  };
}

function mapDispatchToProps(dispatch) {
  return {
    corporateActions: bindActionCreators(CorporateActions, dispatch)
  };
}

class NBEThanksComponent extends Component {


  componentWillMount() {
    !this.props.corpStore.nbe && this.props.corporateActions.getNBE();
  }
  getContent = (options = { defaultReturn: {} }) => {
    const nbe = this.props.corpStore.nbe || {};
    const { lang } = this.props;
    // translated data & undefined safety object
    return nbe[lang]? nbe[lang]: nbe['ar'] || options.defaultReturn;
  }
  render() {
    const nbe = this.getContent({ defaultReturn: { title: {} } });

    const content = this.getContent();

    let thanks_title = content.acf && content.acf.thanks_title;
    let thanks_sub_title = content.acf && content.acf.thanks_sub_title;
    let thanks_background_percentage_num = content.acf && content.acf.thanks_background_percentage_num;
    let thanks_box_green_label = content.acf && content.acf.thanks_box_green_label;
    let thanks_box_title = content.acf && content.acf.thanks_box_title;
    let thanks_box_gray_label = content.acf && content.acf.thanks_box_gray_label;
    let coupon = content.acf && content.acf.coupon;
    return (
      <>
        <Helmet key={window.location.href} >
          <title>{"Thanks You | Go Bus"}</title>
          {this.props.corpStore.nbe && buildYoastMetaTags(nbe.yoast_meta).map(tag => <meta {...tag} />)}
        </Helmet>
        <div className="nbe-thanks-page">
          <header className="custom-hedear">
            <Link
                  to="/"
                  onClick={() => Utils.scrollTo({ top: 0, behavior: "smooth" })}
                >
                  <img src="/images/go-bus.png" alt="logo" />
                </Link>
          </header>
          <h1>{thanks_title}</h1>
          <h2>{thanks_sub_title}</h2>
          <FeaturedDiscount
                title={thanks_background_percentage_num}
                label={thanks_box_green_label}
                name={thanks_box_title}
                description={thanks_box_gray_label}
                code={coupon}
                booking_button
            />
            <footer className="custom-footer">

            <div className="footer-logo">
                  <Link
                    onClick={() =>
                      Utils.scrollTo({ top: 0, behavior: "smooth" })
                    }
                    to="/"
                  >
                    <img src="/images/go-bus.png" alt="GoBus-logo" />
                  </Link>
                </div>
              <div className="copyright">
                <p>
                  <Trans i18nKey="footer.copyright">جميع حقوق التصميم والملكية الفكرية مسجلة ومحفوظة لشركة جوباص © {{year: new Date().getFullYear()}}</Trans>
                </p>
                <p>
                  WEB DESIGN & DEVELOPMENT <a href="https://www.mitchdesigns.com/" title="Mitch Designs | Web Design - Development Company Egypt | Web Agency Cairo" target="_blank" rel="noopener noreferrer">by MitchDesigns</a>
                </p>
              </div>
            </footer>
        </div>
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NBEThanksComponent);
