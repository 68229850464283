import React from 'react';

export default function Hint({ title, message }) {
  return (
    <div className="hint-component">
        <div className="hint-component-img">
            <i className="material-icons">add_alert</i>
        </div>
        <div className="hint-component-content">
            <h2>{title}</h2>
            <p>{message}</p>
        </div>
    </div>
  );
}
